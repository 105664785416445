import React from 'react';
import autoBind from 'react-autobind';
import { Modal, Button, Popconfirm, message } from 'antd';
//
import CommonLoadingView from '../CommonLoadingView';
import Utils from '../../../components/Utils';

export default class PendingInvitationsModal extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      ids: [],
      isLoading: true,
      visible: false,
      programName: null,
      currentInviteIndex: 0,
      currentInviteData: null,
    };
  }

  componentDidMount() {
    const tenantConfig = this.props.app.sharedCache().getTenantConfig();
    this.setState({ programName: tenantConfig.organizationName });
  }

  async attemptToShow(userEmail, onComplete) {
    const resp = await this.props.app.organization
    .organizationAppInvitation.getMyOrganizationAppInvitation(userEmail);

    if (
      resp.statusCode == 200
      && resp.body
      && resp.body.invitations
      && resp.body.invitations.length > 0
    ) {
      const ids = resp.body.invitations.map(invitation => invitation.orgID);
      const orgModEmployeeMode = this.props.app.sharedCache().getTenantConfig()?.orgModEmployeeMode;

      this.setState({
        ids,
        visible: !orgModEmployeeMode,
        currentInviteIndex: 0,
      });

      this.fetchOrgData(ids[0], () => {
        if (orgModEmployeeMode) {
          this.handleAccept(true);
        }
      });
    } else {
      if (this.props.onComplete) this.props.onComplete();
      if (onComplete) onComplete();
    }
  }

  handleReject() {
    this._approveOrReject('reject');
  }

  handleAccept(silent = false) {
    this._approveOrReject('approve', silent);
  }

  render() {
    return (
      <Modal
        title="You have pending Invitations"
        visible={this.state.visible}
        closable={false}
        className="invitationsModal"
        footer={[
          <Popconfirm
            placement="top"
            title="Are you sure that you want to reject this invitation?"
            onConfirm={this.handleReject}
            okText="Yes"
            cancelText="No"
            key="reject"
          >
            <Button>
              Reject
            </Button>
          </Popconfirm>,
          <Popconfirm
            placement="top"
            title="Are you sure that you want to accept this invitation?"
            onConfirm={() => this.handleAccept()}
            okText="Yes"
            cancelText="No"
            key="accept"
          >
            <Button
              type="primary"
              style={{ backgroundColor: '#af3947', border: 0 }}
            >
              Accept
            </Button>
          </Popconfirm>,
        ]}
      >
        <CommonLoadingView isLoading={this.state.isLoading}/>

        {this.state.currentInviteData && (
          <p>
            You have been invited to join the {this.state.currentInviteData.name} organization in {this.state.programName}.
            <br />
            This will grant {this.state.currentInviteData.name} the ability to monitor your progress as well as to provide you with access
            <br />
            to {Utils.replacePlaceholders('{ORG_NAME}', this.props.app.themeManager.theme)} courses and exams.
          </p>
        )}
      </Modal>
    );
  }

  async fetchOrgData(id, callback) {
    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
    }

    const resp = await this.props.app.organization.organizationApp.getOrganizationApp(id);

    if (resp.statusCode == 200 && resp.body) {
      this.setState({ currentInviteData: resp.body }, () => {
        callback?.();
      });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.setState({ isLoading: false });
  }

  async _approveOrReject(type, silent = false) {
    this.setState({ isLoading: true });

    const orgModEmployeeMode = this.props.app.sharedCache().getTenantConfig()?.orgModEmployeeMode;
    const { id, email } = this.props.app.sharedCache().getProgramUser();
    const userID = orgModEmployeeMode ? id : email;

    let resp = {};
    if (type == 'approve') {
      resp = await this.props.app.organization
        .organizationAppInvitation.approveOrganizationAppInvitation(
          this.state.currentInviteData.orgID, userID,
        );
    } else {
      resp = await this.props.app.organization
        .organizationAppInvitation.rejectOrganizationAppInvitation(
          this.state.currentInviteData.orgID, userID,
        );
    }

    if (resp.statusCode == 200) {
      if (!silent) {
        message.success(`Invite successfully ${type == 'approve' ? 'approved' : 'rejected'}`);
      }

      this.setState({ currentInviteData: resp.body });
      this.nextInvite(() => {
        if (silent) {
          this._approveOrReject('approve')
        }
      });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.setState({ isLoading: false });
  }

  nextInvite(callback) {
    const { ids, currentInviteIndex } = this.state;
    const nextID = ids[currentInviteIndex + 1];

    if (nextID) {
      this.setState({ currentInviteIndex: currentInviteIndex + 1 });
      this.fetchOrgData(nextID, callback);
      return;
    }

    if (this.props.onComplete) this.props.onComplete();
    this.setState({ visible: false });
  }
}
