import React, { Fragment } from "react";
import { Divider, Checkbox, Select, Col, Row, Form, Input, DatePicker, Switch } from 'antd';
//
import Globals from '../../../config/Globals';
//
import CommonOrganizationSelectionInput from "../OrganizationSelection/CommonOrganizationSelectionInput";
import PhoneInput from "@/ui-components/PhoneInput";

//props are: app, isDesktop, additionalInputs, onOrgChange, selectedOrg, onChange
export default class CommonDynamicForm extends React.Component {
  //Actions
  handleChange() { if (this.props.onChange) this.props.onChange(); }
  //UI
  render() {
    const editMode = true;
    const { isDesktop, additionalInputs } = this.props;
    const { fields, sections } = additionalInputs || {};
    if (!fields || Object.keys(fields).length <= 0) return (<></>);
    //
    const offset = isDesktop ? 1 : 0;
    const colSpan = isDesktop ? 23 : 24;
    //sort by order in row
    let orderedRows = Object.entries(fields || {})
          .sort(([, a], [, b]) => a.orderInRow - b.orderInRow)
          .reduce((acc, [key, value]) => ({ ...acc,  [key]: value }), {});
    //order by row priority and group by row priority with array of the fields key
    orderedRows = Object.keys(orderedRows).sort((a, b) => fields[a].row - fields[b].row).reduce((prev, curr) => {
      if (!prev[fields[curr].row]) prev[fields[curr].row] = [];
      prev[fields[curr].row].push(curr);
      return prev;
    }, {})
    //
    return (
      <>
        {Object.keys(orderedRows).map((row) => {
          const section = (sections ? sections.find((s) => s.beforeRow == row) : null);
          return (
            <Fragment key={row}>
              {section && <Divider orientation="left">{section.name}</Divider>}
              <Row gutter={24}>
                {orderedRows[row].map((fieldKey, index) => (
                  <Fragment key={fieldKey + index}>
                    <Col offset={offset} md={parseInt(fields[fieldKey].widthPercentage ? colSpan * (fields[fieldKey].widthPercentage / 100) : colSpan / orderedRows[row].length)} xs={colSpan}>
                      {this._renderDynamicFormItem(fields[fieldKey], editMode)}
                    </Col>
                  </Fragment>
                ))}
              </Row>
            </Fragment>
          )
        })}
      </>
    );
  }
  _renderDynamicFormItem(input, editMode) {
    //Handle special cases
    if (input.type == Globals.RegOrgEmploymentSelectionType) {
      return <CommonOrganizationSelectionInput app={this.props.app} input={input} isEdit={editMode} selectedOrg={this.props.selectedOrg}
                isAdmin={this.props.app.isAdmin()} onSelection={this.props.onOrgChange}/>;
    }
    //Format rules
    const rules = [];
    if (input.required && input.type != 'boolean') rules.push({ required: true, message: 'This field is required!' });
    if (input.type === 'date' && !input.allowFutureValue) {
      rules.push({
        validator: (field, value) => {
          return new Promise((resolve, reject) => {
            if (value && value.isAfter(new Date())) reject('Cannot be a future date.');
            else resolve();
          });
        }
      });
    }
    if (input?.customProps?.maxLength) rules.push({ max: input.customProps.maxLength, message: `Must be lower than ${input.customProps.maxLength}` });
    if (input?.customProps?.pattern) {
      const type = typeof input.customProps.pattern;
      const pattern = type == 'object' ? input.customProps.pattern.regex : input.customProps.pattern;
      const message = type == 'object' ? input.customProps.pattern.message : 'Invalid format';
      rules.push({ pattern, message });
    }
    //Render common form items
    let extraProps = {};
    if (input.type == 'boolean') extraProps = { valuePropName: 'checked', noStyle: true };
    if (input.type == 'switch') extraProps = { valuePropName: 'checked', noStyle: false };
    return (
      <Form.Item name={input.id} label={input.label} rules={rules} {...extraProps} extra={input.extra}>
        {input.type === 'date'  ? (
            <DatePicker onChange={this.handleChange.bind(this)} style={{ width: '100%' }} placeholder={input.placeholder || ''} format={input.format}/>
          ) : input.type === 'list' ? (
            <Select onChange={this.handleChange.bind(this)} showSearch options={input.customProps.values}/>
          ) : input.type === 'boolean' ? (
            <Checkbox onChange={this.handleChange.bind(this)} style={{ /*display: 'block', */marginLeft: 20 }}>{input.label}</Checkbox>
          ) : input.type === 'tel' ? (
            <PhoneInput onChange={this.handleChange.bind(this)} id={input.id} type={input.type} disabled={!editMode} placeholder={input.placeholder || ''}/>
          ) : input.type === 'switch' ? (
            <Switch onChange={this.handleChange.bind(this)} id={input.id} type={input.type} disabled={!editMode}/>
          ) : (
            <Input onChange={this.handleChange.bind(this)} id={input.id} type={input.type} disabled={!editMode} placeholder={input.placeholder || ''}/>
          )
        }
      </Form.Item>
    );
  }
}
