import React from 'react';
import autoBind from 'react-autobind';
import { Table } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
import Utils from '../../components/Utils';
//
import '../../assets/stylesheets/AdminSearchUsersView.less';
//
export default class CommonReservationsTable extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: { columnKey: 'createdOn', order: 'descend' }, reservations: [] };
  }

  //Table support
  handleFilterChange(pagination, filters, sorter) { 
    this.setState({ sortedInfo: sorter, filters }); 
  }

  getTableRowProps(record) {
    return {
      onClick: () => this.props.onRow && this.props.onRow(record),
    };
  }
  
  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    
    const columns = [
      {
        title: 'Date', dataIndex: 'createdOn', key: 'createdOn',
        render: createdOn => createdOn ? Utils.getDateOnUIFormatByTimestamp(createdOn) : '',
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
      },
      { 
        title: 'Email',  dataIndex: 'reservedForID', key: 'reservedForID',
        sortOrder: sortedInfo.columnKey === 'reservedForID' && sortedInfo.order,
        sorter: (a, b) => a.reservedForID.localeCompare(b.reservedForID),
      },
      { 
        title: 'Reservation ID',  key: 'id',
        sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
        sorter: (a, b) => a.id.localeCompare(b.id),
        render: props => props?.id ? props?.id : '-',
      },
    ];

    const props = { loading: this.props.isLoading, onChange: this.handleFilterChange,
                    locale: {emptyText: 'No Reservations found!'},
                    pagination: { pageSize: 50, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] },};
    return (<Table columns={columns} dataSource={this.props.reservations} onRow={this.getTableRowProps} {...props}/>);
  }
}