import React from 'react';
import autoBind from 'react-autobind';
import { isMobile } from 'react-device-detect';
//
import { Col, Row, Form, Typography, Divider, Button, Tooltip, Tag, message, InputNumber, Spin } from 'antd';
import { InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons';
//
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
//props are: product, licenseOrder, invoiceMode
export default class CommonLicenseOrderPreview extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = { licenseOwner: null, downloadingReceipt: false };
  }
  //Life cycle
  componentDidUpdate() {
    if (this.props.licenseOrder?.externalID && !this.state.licenseOwner) this._fetchLicenseOwner();
  }
  //Actions
  handleReceiptDownload() { this._downloadReceipt(); }
  //UI
  render() {
    const subValue = (this.props.licenseOrder.valueOriginal > 0 ? (this.props.licenseOrder.valueOriginal * this.props.licenseOrder.quantity) : 0);
    const discountValue = (this.props.licenseOrder.valueDiscount > 0 ? (this.props.licenseOrder.valueDiscount * this.props.licenseOrder.quantity) * -1 : 0);
    return (
      <>
        <Divider orientation="left">Order Information</Divider>
        <Row type="flex" justify="center">
          <Col span={(isMobile ? 24 : 6)}>
            <Form.Item label="Order #">
              {this.props.licenseOrder && this.props.licenseOrder.id}
            </Form.Item>
          </Col>
          <Col span={(isMobile ? 24 : 5)} offset={(isMobile ? 0 : 1)}>
            <Form.Item label="Status">
              {this.props.licenseOrder && this.props.licenseOrder.status && <Tag color={Globals.getColorByOrderState(this.props.licenseOrder.status)}>{this.props.licenseOrder.status}</Tag>}
            </Form.Item>
          </Col>
          <Col span={(isMobile ? 24 : 7)} offset={(isMobile ? 0 : 1)}>
            {this.props.invoiceMode ?
              <Form.Item label="Created On"> {Utils.getDateAndTimeOnUIFormatByTimestamp(this.props.licenseOrder.createdOn)} </Form.Item> :
              <Form.Item label="Completed On"> {Utils.getDateAndTimeOnUIFormatByTimestamp(this.props.licenseOrder.completedOn)} </Form.Item>
            }
          </Col>
          {this.props.licenseOrder?.providerTransactionID && Object.values(Globals.LicenseOrder_ReceiptEnabled_Providers).includes(this.props.licenseOrder?.providerID) && 
            <Col span={(isMobile ? 24 : 1)} offset={(isMobile ? 0 : 1)}>
              <Tooltip placement="bottomLeft" title='Download Receipt'>
                <Button type="primary" icon={<DownloadOutlined />} 
                        loading={this.state.downloadingReceipt} onClick={this.handleReceiptDownload} />
              </Tooltip>
            </Col>
          }
        </Row>
        <Row>
          <Col span={(isMobile ? 24 : 7)} offset={1}>
            <Form.Item label="Purchaser">
              {this.props.licenseOrder.name}
              <Tooltip title={`Purchaser email(s): ${this.props.licenseOrder.email}`} placement="topLeft">
                <InfoCircleOutlined style={{ marginLeft: 10 }} />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={(isMobile ? 24 : 12)}>
            <Form.Item label="License Owner">
              {this.state.licenseOwner ?? <><Spin size="small" /> Loading...</>}
            </Form.Item>
          </Col>
          <Col span={(isMobile ? 24 : 8)} />
        </Row>
        <Row type="flex" justify="center">
          <Col span={(isMobile ? 24 : 6)}>
            <Form.Item label="Transaction ID">
              {this.props.licenseOrder && this.props.licenseOrder.providerTransactionID}
            </Form.Item>
          </Col>
          <Col span={(isMobile ? 24 : 6)} offset={(isMobile ? 0 : 1)}>
            {this.props.licenseOrder && this.props.licenseOrder.voucherID && !this.props.licenseOrder.isVoucherPrivate && <Form.Item label="Voucher"> <Tag>{this.props.licenseOrder.voucherID}</Tag> </Form.Item>}
          </Col>
          <Col span={(isMobile ? 0 : 8)} offset={(isMobile ? 0 : 1)}></Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={(isMobile ? 24 : 7)}>
            <div className='purchaseModalOverviewTableHeader'> <Typography.Text> Product </Typography.Text> </div>
            <Form.Item> {this.props.product.name}</Form.Item>
          </Col>
          <Col span={(isMobile ? 24 : 3)}>
            <div className='purchaseModalOverviewTableHeader'> <Typography.Text> Price </Typography.Text> </div>
            <Form.Item>
              <Tooltip title={"The original price of the license."} placement="topLeft">
                <InputNumber precision={2} decimalSeparator="." disabled formatter={Utils.defaultCurrenyInputFormatter}
                  parser={Utils.defaultCurrentInputParser} className="purchaseModalInputBoderless" value={this.props.licenseOrder.valueOriginal} />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={(isMobile ? 0 : 2)}>
            <div className='purchaseModalOverviewTableHeader'> &nbsp; </div>
          </Col>
          <Col span={(isMobile ? 24 : 2)}>
            <div className='purchaseModalOverviewTableHeader'> <Typography.Text> Quantity </Typography.Text> </div>
            <Form.Item name='quantity'>
              <Tooltip title={"The number of licenses (activations) you are purchasing."} placement="topLeft">
                {this.props.licenseOrder.quantity}
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={(isMobile ? 0 : 5)}>
            <div className='purchaseModalOverviewTableHeader'> &nbsp; </div>
          </Col>
          <Col span={(isMobile ? 24 : 3)}>
            <div className='purchaseModalOverviewTableHeader'> <Typography.Text> Subtotal </Typography.Text> </div>
            <Form.Item>
              <InputNumber precision={2} decimalSeparator="." disabled formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser} className="purchaseModalInputBoderless" value={subValue} />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={(isMobile ? 12 : 2)} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Discount </Typography.Text>
          </Col>
          <Col span={(isMobile ? 12 : 4)}>
            <Form.Item>
              <Tooltip title={"Discounts can be applied by using cupom codes."} placement="topLeft">
                <InputNumber precision={2} decimalSeparator="." disabled formatter={Utils.defaultCurrenyInputFormatter}
                  parser={Utils.defaultCurrentInputParser} className="purchaseModalInputBoderless" value={discountValue} />
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={(isMobile ? 12 : 2)} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Tax </Typography.Text>
          </Col>
          <Col span={(isMobile ? 12 : 4)}>
            <Form.Item>
              <InputNumber precision={2} decimalSeparator="." disabled formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser} className="purchaseModalInputBoderless" value={this.props.licenseOrder.valueTax} />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={(isMobile ? 12 : 2)} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Total </Typography.Text>
          </Col>
          <Col span={(isMobile ? 12 : 4)}>
            <Form.Item>
              <InputNumber precision={2} decimalSeparator="." disabled formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser} className="purchaseModalInputBoderless" value={this.props.licenseOrder.valueTotal} />
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }

  // Private methods
  async _fetchLicenseOwner() {
    const { externalID } = this.props.licenseOrder;
    let licenseOwner = 'N/A';

    const idmResp = await this.props.app.idm.api.user.read(externalID);
    if (idmResp.statusCode == 200 && idmResp.body) {
      licenseOwner = `${idmResp.body.firstName} ${idmResp.body.lastName}`;
    } else {
      const orgResp = await this.props.app.organization.organizationApp.getOrganizationApp(externalID);
      if (orgResp.statusCode == 200 && orgResp.body) {
        licenseOwner = orgResp.body.name;
      }
    }
    this.setState({ licenseOwner });
  }
  async _downloadReceipt() {
    this.setState({ downloadingReceipt: true });
    const resp = await this.props.app.license.order.getOrderReceipt(this.props.licenseOrder?.externalID, this.props.licenseOrder?.id);
    if (resp.statusCode == 200) Utils.downloadArrayBuffer(resp.body.data, `receipt-${this.props.licenseOrder?.id}`, 'pdf');
    else message.error(`Error while download order receipt. Please, try again!`);
    this.setState({ downloadingReceipt: false });
  }
}
