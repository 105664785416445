import { Modal, Steps, message } from 'antd';
import React from 'react';
import autoBind from 'react-autobind';
import { Beforeunload } from 'react-beforeunload';
import { isMobile } from 'react-device-detect';
//
import CustomComponent from '../../../components/CustomComponent';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
import CommonLicensePurchaseModalStep_LoadingView from "./CommonLicensePurchaseModal_steps/CommonLicensePurchaseModalStep_LoadingView";
import CommonLicensePurchaseModalStep_OverviewView from "./CommonLicensePurchaseModal_steps/CommonLicensePurchaseModalStep_OverviewView";
import CommonLicensePurchaseModalStep_PaymentView_Braintree from "./CommonLicensePurchaseModal_steps/CommonLicensePurchaseModalStep_PaymentView_Braintree";
import CommonLicensePurchaseModalStep_PaymentView_Federated, { PAYMENT_TYPE } from "./CommonLicensePurchaseModal_steps/CommonLicensePurchaseModalStep_PaymentView_Federated";
import CommonLicensePurchaseModalStep_PaymentView_Moneris from "./CommonLicensePurchaseModal_steps/CommonLicensePurchaseModalStep_PaymentView_Moneris";
import CommonLicensePurchaseModalStep_ResultView from "./CommonLicensePurchaseModal_steps/CommonLicensePurchaseModalStep_ResultView";
import CommonLicensePurchaseModalStep_SummaryView_Braintree from "./CommonLicensePurchaseModal_steps/CommonLicensePurchaseModalStep_SummaryView_Braintree";
import CommonLicensePurchaseModalStep_SummaryView_Federated from "./CommonLicensePurchaseModal_steps/CommonLicensePurchaseModalStep_SummaryView_Federated";
import CommonLicensePurchaseModalStep_SummaryView_Moneris from "./CommonLicensePurchaseModal_steps/CommonLicensePurchaseModalStep_SummaryView_Moneris";
//
import '../../../assets/stylesheets/CommonLicensePurchaseModal.less';
import CommonLicensePurchaseModalStep_InfoConfirmation from './CommonLicensePurchaseModal_steps/CommonLicensePurchaseModalStep_InfoConfirmation';
//
export const STEPS = {
  OVERVIEW: 'OVERVIEW',
  SENSITIVE: 'SENSITIVE',
  SUMMARY: 'SUMMARY',
  SENDING_ORDER: 'SENDING_ORDER',
  CONFIRMING_ORDER: 'CONFIRMING_ORDER',
  RESULT: 'RESULT',
  INFO_CONFIRMATION: 'INFO_CONFIRMATION',
};

//State management
const INITIAL_STATE = ({redirectOrderID, redirectPurchaseID, redirectProductID, redirectCustomID}, sessionID, primaryStep) => {
  const isRedirecting = !!(redirectOrderID && redirectPurchaseID && redirectProductID);
  return {
    isLoading: false, overrideValue: null, stepper: {
      //stepper possible statuses - waiting, process, finish, error
      hasInfoConfirmationStep: false,
      current: (isRedirecting ? STEPS.SENDING_ORDER : primaryStep), status: (isRedirecting ? 'process' : 'waiting'),
    }, optExplicitProductObject: null,
    //Main product stuff
    selectedObjectIndex: 0, selectedProduct: null,
    productObjects: null,
    chargingAmount: 0, //for one unit of the selected product
    taxAmount: 0, totalAmount: 0, quantity: 0, discountAmount: 0,
    //Voucher support
    applingVoucher: false, validatedVoucher: null, selectedReferral: { id: null, name: null },
    // Custom material shipping info
    materialShippingInfo: null,
    //Purchase info
    payload: {  /* here we store information coming from begin order, and also from redi*/
      error: null,
      /* redirect support - federated */ redirectOrderID, redirectPurchaseID, redirectProductID, redirectCustomID, isRedirecting,
      /* federated secure form */ gatewayUrl: null,
      /* moneris support */ providerLib: null, providerEnv: null, ticketID: null,
      /* course/classroom auto enroll */ sessionID,
    },
    //Meta
    session: null
  };
};
const RESET_STATE = (previous, primaryStep) => {
  return {
    isLoading: false, stepper: { current: primaryStep, status: 'waiting' }, overrideValue: previous.overrideValue, optExplicitProductObject: null,
    //Main product stuff
    selectedObjectIndex: 0, selectedProduct: previous.selectedProduct, productObjects: previous.productObjects,
    chargingAmount: 0, //for one unit of the selected product
    taxAmount: 0, totalAmount: 0, quantity: previous.quantity, discountAmount: 0,
    //Purchase info
    payload: {  /* here we store sensitive information*/ sessionID: previous?.payload?.sessionID },
    //Meta
    session: previous?.session
  };
};

//constructor accepts second param so subclasses can specify primary step!
//required props are: app, onRequiresAttention
//other props are:
  // - autoRedeem (will auto redeem license after purchase)
    // - certification (required when autoredeeming course products)
    // - application (required when autoredeeming application products)
  // - fixedQuantity (will not allow quantity change)
  // - quantity (optional quantity, if not specified it will use 1)
  // - user (the user to be used if admin)
  // - organization (the org to be used if admin or org manager)
  // - sessionCharge (optional - it's a charge made by admin upon session completion)
  // - enrolment (optional - it's used to create enrolment charge made by admin upon session completion)
  // - cancelCharge (optional - it's a enrolment cancelation charge)
  // - onCancelation (optional - enrolment cancelation method)
export default class CommonLicensePurchaseModal extends CustomComponent {
  constructor(props, optionalCustomPrimaryStep) {
    super(props);
    autoBind(this);
    this.calculateDebounce = null;
    this.calculatePromisesCallbacks = [];
    this.isSubmittingSecureForm = false; //needs to be outside state because we don't want to update state when setting and checking it
    this.primaryStep = (optionalCustomPrimaryStep && optionalCustomPrimaryStep.length > 0 ? optionalCustomPrimaryStep : STEPS.OVERVIEW);
    const redirectOrderID = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_LicensePurchase_OrderID);
    const redirectPurchaseID = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_LicensePurchase_TokenID);
    const redirectProductID = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_LicensePurchase_ProductID);
    const redirectCustomID = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_LicensePurchase_CustomID);
    const sessionID = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_LicensePurchase_SessionID);
    this.state = INITIAL_STATE({redirectOrderID, redirectPurchaseID, redirectProductID, redirectCustomID}, sessionID, this.primaryStep);
  }
  //Life cycle
  componentDidMount() {
    //check if is on redirect, if so, ask parent for attention (display modal with loaded data)
    if (this.state.payload.isRedirecting && this.props.onRequiresAttention) this.props.onRequiresAttention(this);
  }
  /* Public */
  loadModalInfo(productID, overrideValue, optExplicitProductObject, cancelPolicy) {
    //Get all interesting ivars
    const isRedirecting = this.state.payload.isRedirecting;
    let productObjects = productID ? [this.props.app.sharedCache().getProductRelatedObjectByProductID(productID, optExplicitProductObject)] : this.props.app.sharedCache().getAllProductRelatedObjects();
    const selectedObjectIndex = 0;
    //If coming from redirect, grab product from URL and find product object
    if (!productID && this.state.payload.redirectProductID) {
      //We use the custom ID to get proper product object when the same productID is used multiple times
      if (this.state.payload.redirectCustomID) {
        optExplicitProductObject = this.props.app.sharedCache().getCourseByID(this.state.payload.redirectCustomID);
      }
      productObjects = [ this.props.app.sharedCache().getProductRelatedObjectByProductID(this.state.payload.redirectProductID, optExplicitProductObject) ];
    }
    //Collect info
    const productSpecs = this.props.app.sharedCache().getProductByProductRelatedObject(productObjects[selectedObjectIndex]);
    const quantity = this.props.quantity || 1;
    const originalAmount = overrideValue ? overrideValue * quantity : this._getProductPriceByQuantity(quantity, productSpecs);
    this.setState({ chargingAmount: (this.props.app.isAdmin() && !this.props.sessionCharge && !this.props.cancelCharge && this.props.isGrant ? 0 : originalAmount),
                    quantity, taxAmount: 0, totalAmount: 0, discountAmount: 0,
                    productObjects, selectedObjectIndex, selectedProduct: productSpecs,
                    isLoading: false, overrideValue, optExplicitProductObject, cancelPolicy }, () => {
      if (isRedirecting) this._completeOrder();
      else {
        //org managers may have hidden vouchers :)
        if (this._hasHiddenVoucher() && !this.props.app.isAdmin()) this._validateVoucher(this._getHiddenVoucher());
        else this.handleOverviewChange(null); //refreshed first step with just set information
      }
    });
  }
  /* Protected interface */
  setSessionID(sessionID, sessionData) {
    const shouldShowInfoConfirmStep = this.props.app.isUser() || this.props.app.isSysAdmin();
    const currentStep = sessionData?.confirmInfoOnEnrolment && shouldShowInfoConfirmStep
      ? STEPS.INFO_CONFIRMATION
      : STEPS.OVERVIEW;

    this.setState({
      session: sessionData,
      payload: {
        ...(this.state.payload || {}),
        sessionID,
      },
      stepper: {
        current: currentStep,
        status: 'waiting',
        hasInfoConfirmationStep: !!sessionData?.confirmInfoOnEnrolment && shouldShowInfoConfirmStep,
      }
    }, () => {
      this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_LicensePurchase_SessionID, sessionID);
      if (this.state.payload.redirectOrderID) this._cancelOrder(false);
      this.handleOverviewChange(null);
    });
  }
  closeModal() { this.handleCancel(); }
  getCurrentStep() { return this.state.stepper.current; }
  getCurrentProductRelatedObject() { return this.state?.productObjects?.[this.state.selectedObjectIndex]; }

  //Actions
    //Handle changes on overview step
  async handleOverviewChange(value, id) {
    let { chargingAmount, selectedObjectIndex, quantity, productObjects } = this.state;
    let product = this.props.app.sharedCache().getProductByProductRelatedObject(productObjects[selectedObjectIndex]);
    //
    this.setState({ stepper: { ...this.state.stepper, status: 'process', error: null }, isLoading: true });
    if (id == 'selectedReferral') {
      this.setState({ selectedReferral: value });
    }
    //Check for the amount to be set
    if (id == 'object') {
      selectedObjectIndex = value;
      product = this.props.app.sharedCache().getProductByProductRelatedObject(productObjects[selectedObjectIndex]);
      chargingAmount = (this.props.app.isAdmin() && !this.props.sessionCharge && !this.props.cancelCharge && this.props.isGrant ? 0 : this._getProductPriceByQuantity(quantity || 1, product));
    } else if (id == 'quantity') {
      quantity = value;
      //Not a normal user (is org) and not admin
      if (!this._isNormalUser() && !this.props.app.isAdmin()) chargingAmount = this._getProductPriceByQuantity(value || 0);
    } else if (id == 'amount') chargingAmount = value;

    //Get server side calculate values
    let serverValues = {};
    if (quantity != undefined) {
      serverValues = await this._scheduleCalculateOrderValues({ selectedProduct: product, chargingAmount, quantity });
      if (!serverValues) return;
    }
    //
    this.setState({ chargingAmount: serverValues.valueOriginal || 0, selectedObjectIndex,
                    quantity: serverValues.quantity, taxAmount: serverValues.valueTax || 0,
                    totalAmount: serverValues.valueTotal || 0, discountAmount: serverValues.valueDiscount || 0,
                    selectedProduct: product,
                    isLoading: false, stepper: { ...this.state.stepper, status: 'waiting', error: null } });
  };
    //Handle primary actions on each step
  async handleStepPrimaryAction(data) {
    //Overview
    if (this.state.stepper.current == STEPS.OVERVIEW) {
      const resp = await this.currentChild.validateFields(); //validate form
      console.log('*-* discount policy', this.state.discountPolicy);
      const hasDiscountPolicy = this.state.selectedProduct?.discountPolicy && Array.isArray(this.state.selectedProduct?.discountPolicy?.employerCUs) && this.state.selectedProduct?.discountPolicy?.employerCUs.length > 0 && !this.props.isGrant;
      if (resp && this.state.totalAmount <= 0 && !hasDiscountPolicy) { //check if is waiving, skip to begin order, no payment method needed
        if (this.props.app.isOrgManager()) this._submitInvoiceOrder(this._getCurrentUser()); //Org managers should do invoice, even with 0 value orders
        else this._beginOrder(this._getCurrentUser()); //other users are OK. (actually this should only be possible for admins or 100% vouchers)
      } else if (resp) this.setState({ stepper: { current: STEPS.SENSITIVE, status: 'waiting' } }); //just present sentitive
    }
    //Payment info
    else if (this.state.stepper.current == STEPS.SENSITIVE) {
      const resp = await this.currentChild.validateFields();
      if (resp && resp.paymentMethod == PAYMENT_TYPE.INVOICE) { //Check if is invoice, dont call begin order, skip to summary
        this.setState({
          stepper: { ...this.state.stepper, current: STEPS.SUMMARY, status: 'waiting', error: null },
          payload: { ...this.state.payload },
          isLoading: false
        }, () => { this.currentChild.setSensitiveInfo({ ...resp }); });
      } else if (resp) { this._beginOrder(resp); } //CC payment
    }
    //Summary
    else if (this.state.stepper.current == STEPS.SUMMARY) {
      //check for payment method
      if (data.paymentMethod == PAYMENT_TYPE.CARD) {
        this.isSubmittingSecureForm = true; //CC
        if (this.props.app.license.order.isMonerisEnabledForProduct(this.state.selectedProduct)) this._completeOrder();
        else if (this.props.app.license.order.isBraintreeEnabledForProduct(this.state.selectedProduct)) this._completeOrder();
      } else {
        const org = await this.props.app.organization.organizationApp.getOrganizationApp(this.props.app.urlManager.selectedOrg);
        if (org.statusCode != 200 || !org.body) {
          this.props.app.alertController.showAPIErrorAlert(null, org);
        }
        this._submitInvoiceOrder(this._getCurrentUser(true, org?.body)); //Invoice
      }
    }
    //Result
    else if (this.state.stepper.current == STEPS.RESULT) {
      this.handleCancel();
      // Leaving this here as legacy code, we were skipping the
      // resulting error and resetting the modal, throwing the user
      // back to the primary/overview step, now we want the user back
      // to the course card so we can check possible lic tickets on EX
      // and direct the user to the correct modal
      // //errored, reset
      // if (this.state.payload.error) {
      //   this.setState(RESET_STATE(this.state, this.primaryStep), () => {
      //     this.isSubmittingSecureForm = false;
      //     const selectedObject = this.state.productObjects[this.state.selectedObjectIndex];
      //     const productID = (selectedObject ? this.props.app.sharedCache().getProductByProductRelatedObject(selectedObject).id : null);
      //     this.loadModalInfo(productID, null, selectedObject);
      //   });
      // }
      // else { this.handleCancel(); } //close
    }
    // Info confirmation
    else if (this.state.stepper.current == STEPS.INFO_CONFIRMATION) {
      this.setState({
        materialShippingInfo: data.materialShippingInfo,
        stepper: { ...this.state.stepper, current: STEPS.OVERVIEW, status: 'waiting', error: null },
      });
    }
  };
    //Handle previous into steps
  handlePreviousAction(data) {
    if (this.state.stepper.current == STEPS.SUMMARY) {
      this.setState({ stepper: { current: STEPS.SENSITIVE, status: 'waiting' } }, () => {
        if (this.state.payload.redirectOrderID) this._cancelOrder(false);
        this.currentChild.setInfo(data); //sensitive (federated and moneris)
      });
    } else if (this.state.stepper.current == STEPS.SENSITIVE) {
      this.setState({ stepper: { current: STEPS.OVERVIEW, status: 'waiting' } }, () => {
        if (this.state.payload.redirectOrderID) this._cancelOrder(false); //moneris or any other
      });
    } else if (this.state.stepper.current == STEPS.OVERVIEW && STEPS.OVERVIEW != this.primaryStep) {
      this.setState({ stepper: { current: this.primaryStep, status: 'waiting' } }, () => {
        if (this.state.payload.redirectOrderID) this._cancelOrder(false); //moneris or any other
      });
    } else if (this.state.stepper.current == STEPS.INFO_CONFIRMATION) {
      this.setState({ stepper: { current: this.primaryStep, status: 'waiting' } }, () => {
        if (this.state.payload.redirectOrderID) this._cancelOrder(false);
      });
    }
  };
    //Handle cancel actions on each step (or programatically)
  handleCancel() {
    if (this.currentChild && this.currentChild.form) this.currentChild.form.resetFields();
    //Make cancellation request if on second step and redirectPurchaseID is set!
    if (this.state.payload.redirectOrderID) this._cancelOrder(true);
    else this.props.onChange();
  };
    //Handle modal after close, reset modal for next usage
  handleAfterClose() {
    this.setState(INITIAL_STATE({}, null, this.primaryStep));
    this.isSubmittingSecureForm = false;
  };
    //Handler voucher create
  handleVoucherApply(voucherCode) { this._validateVoucher(voucherCode); }

  //UI
  render() {
    return (
      <Modal maskClosable={false} title={this._getTitle()} afterClose={this.handleAfterClose.bind(this)}
             visible={this.props.isVisible} confirmLoading={this.state.isLoading || this.state.applingVoucher} closable={false}
             footer={null}>
         {this.props.isVisible && <Beforeunload onBeforeunload={() => { /* handler seems to keep installed if not rendered after rendering */
            if (!this.props.isVisible || this.isSubmittingSecureForm) return false; //allow exit
            return "Your order will be cancelled, are you sure you want to cancel it?";
         }} />}
        <Steps {... this.state.stepper} current={this._getStepIndex()} size='small' direction={(isMobile ? 'vertical' : 'horizontal')} className='paymentSteps'>
          {this._renderStepperSteps()}
        </Steps>
        {this._renderSelectedStep()}
      </Modal>
    );
  }

  /* protected - this is what should be overwritten when extending this modal */
  _getStepIndex() {
    const { hasInfoConfirmationStep} = this.state.stepper;
    const increaseStepIdx = hasInfoConfirmationStep ? 1 : 0;

    if (hasInfoConfirmationStep && this.state.stepper.current == STEPS.INFO_CONFIRMATION) return 0;
    if (this.state.stepper.current == STEPS.OVERVIEW) return 0 + increaseStepIdx;
    if (this.state.stepper.current == STEPS.SENSITIVE) return 1 + increaseStepIdx;
    if (this.state.stepper.current == STEPS.SUMMARY) return 2 + increaseStepIdx;
    if (this.state.stepper.current == STEPS.SENDING_ORDER) return 3 + increaseStepIdx;
    if (this.state.stepper.current == STEPS.CONFIRMING_ORDER) return 4 + increaseStepIdx;
    if (this.state.stepper.current == STEPS.RESULT) return 5 + increaseStepIdx;
  }
  _renderStepperSteps() {
    const { hasInfoConfirmationStep} = this.state.stepper;

    return (
      <>
        {hasInfoConfirmationStep && <Steps.Step title="Confirmation" />}
        <Steps.Step title="Order Info" />
        <Steps.Step title={(this.state.selectedProduct && (
            this.props.app.license.order.isMonerisEnabledForProduct(this.state.selectedProduct) || this.props.app.license.order.isBraintreeEnabledForProduct(this.state.selectedProduct)
          ) ? "Payment Type" : "Billing Info")} />
        <Steps.Step title="Summary" />
        <Steps.Step title="Processing" />
        <Steps.Step title="Confirming" />
        <Steps.Step title={this.state.stepper.error ? "Failed" : "Completed"} />
      </>
    );
  }
  _renderSelectedStep() {
    if (this.state.stepper.current == STEPS.INFO_CONFIRMATION) return this._renderStepInfoConfirmation();
    if (this.state.stepper.current == STEPS.OVERVIEW) return this._renderStepOverview();
    else if (this.state.stepper.current == STEPS.SENSITIVE) return this._renderStepPayment();
    else if (this.state.stepper.current == STEPS.SUMMARY) return this._renderStepSummary();
    else if (this.state.stepper.current == STEPS.SENDING_ORDER) return this._renderStepLoading(0);
    else if (this.state.stepper.current == STEPS.CONFIRMING_ORDER) return this._renderStepLoading(1);
    else if (this.state.stepper.current == STEPS.RESULT) return this._renderStepResult();
  }

  /* private render */
  _renderStepInfoConfirmation() {
    return (
      <CommonLicensePurchaseModalStep_InfoConfirmation
        app={this.props.app}
        selectedSession={this.state.session}
        onNext={this.handleStepPrimaryAction.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        onPrevious={this.handlePreviousAction.bind(this)}
        user={this._getCurrentUser()}
      />
    );
  }

  _renderStepOverview() {
    return (<CommonLicensePurchaseModalStep_OverviewView product={this.state.selectedProduct} productObjects={this.state.productObjects}
                          app={this.props.app} isLoading={this.state.isLoading} selectedObjectIndex={this.state.selectedObjectIndex}
                          quantity={this.state.quantity} taxAmount={this.state.taxAmount} totalAmount={this.state.totalAmount} discountAmount={this.state.discountAmount}
                          chargingAmount={this.state.chargingAmount} fixedQuantity={this.props.fixedQuantity}
                          cancelPolicy={this.state.cancelPolicy}
                          onPrevious={this.handlePreviousAction.bind(this)} hasPrevious={this.primaryStep != STEPS.OVERVIEW} hasSessionID={!!this.state.payload?.sessionID}
                          onChange={this.handleOverviewChange.bind(this)} onCancel={this.handleCancel.bind(this)} onNext={this.handleStepPrimaryAction.bind(this)} onVoucherApply={this.handleVoucherApply.bind(this)} selectedSession={this.state.session} selectedReferral={this.state.selectedReferral}
                          applingVoucher={this.state.applingVoucher} validatedVoucher={this.state.validatedVoucher} isHiddenVoucher={this._hasHiddenVoucher()}
                          {... Utils.propagateRef(this, 'currentChild')} autoRedeem={this.props.autoRedeem} />);
  }
  _renderStepPayment() {
    const Class = (this.props.app.license.order.isMonerisEnabledForProduct(this.state.selectedProduct) ? CommonLicensePurchaseModalStep_PaymentView_Moneris : (
      this.props.app.license.order.isBraintreeEnabledForProduct(this.state.selectedProduct) ? CommonLicensePurchaseModalStep_PaymentView_Braintree : CommonLicensePurchaseModalStep_PaymentView_Federated
    ));
    return (<Class app={this.props.app} onPrevious={this.handlePreviousAction.bind(this)}
                   onCancel={this.handleCancel.bind(this)} onNext={this.handleStepPrimaryAction.bind(this)} user={this._getCurrentUser()}
                   totalAmount={this.state.totalAmount} isNormalUser={this._isNormalUser()}
                   product={this.state.selectedProduct} {... Utils.propagateRef(this, 'currentChild')} isLoading={this.state.isLoading}/>);
  }
  _renderStepSummary() {
    const Class = (this.props.app.license.order.isMonerisEnabledForProduct(this.state.selectedProduct) ? CommonLicensePurchaseModalStep_SummaryView_Moneris : (
      this.props.app.license.order.isBraintreeEnabledForProduct(this.state.selectedProduct) ? CommonLicensePurchaseModalStep_SummaryView_Braintree : CommonLicensePurchaseModalStep_SummaryView_Federated
    ));
    return (<Class onCancel={this.handleCancel.bind(this)} onPrevious={this.handlePreviousAction.bind(this)} onNext={this.handleStepPrimaryAction.bind(this)}
      {...Utils.propagateRef(this, 'currentChild')} app={this.props.app} isLoading={this.state.isLoading || this.state.applingVoucher}
      payload={this.state.payload} product={this.state.selectedProduct} selectedSession={this.state.session}
      quantity={this.state.quantity} taxAmount={this.state.taxAmount} totalAmount={this.state.totalAmount}
      chargingAmount={this.state.chargingAmount} user={this._getCurrentUser()} discountAmount={this.state.discountAmount}
      productObject={this.state.productObjects[this.state.selectedObjectIndex]} autoRedeem={this.props.autoRedeem}/>);
  }
  _renderStepLoading(type) { return (<CommonLicensePurchaseModalStep_LoadingView loadingType={type}/> ); }
  _renderStepResult() {
    return (<CommonLicensePurchaseModalStep_ResultView error={this.state.payload.error} isInvoice={this.state.payload.isInvoice}
      onNext={this.handleStepPrimaryAction.bind(this)} onCancel={this.handleCancel.bind(this)} isLoading={this.state.isLoading || this.state.applingVoucher}
      app={this.props.app}/>);
  }

  async _getEmployerObject() {
    const tenant = this.props.app.sharedCache().getTenantConfig();
    let cus = [];
    if (tenant.orgModEmployeeMode == false && tenant.orgModDisabled == false) {
      cus = await this._getUserOrgsCus();
    }

    if (!this.props.app.isAdmin()) {
      const empOrg = this.props.app.sharedCache().getEmployeeOrg();
      if (Array.isArray(empOrg?.cus)) cus = [...new Set(cus.concat(empOrg.cus))];
      return { ...empOrg, cus };
    }

    if (this.props.certification) {
      const { userID } = this.props.certification;
      const employeeResp = await this.props.app.organization.employee.getEmployeeByEmployeeID(userID, true);

      if (employeeResp.statusCode == 200 && employeeResp.body) {
        if (!employeeResp.body.org) employeeResp.body.org = {};
        employeeResp.body.org.cus = employeeResp.body.org.cus ? employeeResp.body.org.cus.map(cu => cu.id) : [];
        return {
          id: employeeResp.body.org?.id,
          name: employeeResp.body.org?.name,
          cus: [...new Set(cus.concat(employeeResp.body.org.cus))]
        };
      }
    } return false;
  }
  async _getUserOrgsCus() {
    if (this.props.user && !(this.props.user.memberOf && Array.isArray(this.props.user.memberOf)) && !this.props.app.isAdmin()) await this.props.app.sharedCache().reloadUserObj(); //reload user on cache
    const user = this._getCurrentUser();
    let promises = [];
    if (user.memberOf && Array.isArray(user.memberOf)) {
      for (const orgID of user.memberOf) {
        promises.push(new Promise(async (resolve) => {
          const orgCusResp = await this.props.app.organization.organizationCU.getAllOrganizationCUs(orgID);
          if (orgCusResp.statusCode == 200 && orgCusResp.body) resolve(orgCusResp.body.cus ? orgCusResp.body.cus.map(cu => cu.id) : []);
          else resolve([]);
        }));
      }
    }
    const operation = await Promise.all(promises);
    let cus = [];
    for (const orgCus of operation) {
      if (Array.isArray(orgCus) && orgCus.length > 0) cus = cus.concat(orgCus);
    }
    return [...new Set(cus)];
  }

  /* multi user support */
  _getCurrentUser(isOrgMngr, org) {
    //props needed: id, firstName, lastName, email
    if (this.props.user && !this.props.user?.orgCharge && !isOrgMngr) return this.props.user;
    else if (this.props.org) {
      let usr = {};
      if (this.props.user?.orgCharge) usr = this.props.user;
      else usr = this.props.app.sharedCache().getProgramUser();
      return {
        id: this.props.org.orgID,
        firstName: this.props.org.name,
        lastName: `${(usr ? `- ${usr.firstName} ` : '')}${(usr ? `${usr.lastName} ` : '')}${(usr ? `${usr.email} ` : '')}`,
        email: ( this.props.org.managers ? this.props.org.managers.map( m => m.email ) : [] )
      }
    } else if (isOrgMngr && org) {
      const usr = this.props.app.sharedCache().getProgramUser();
      return {
        id: org.orgID,
        firstName: org.name,
        lastName: `${(usr ? `- ${usr.firstName} ` : '')}${(usr ? usr.lastName : '')}`,
        email: ( org.managers ? org.managers.map( m => m.email ) : [] )
      }
    } else return this.props.app.sharedCache().getProgramUser();
  }
  _isNormalUser() { return !!(this.props.user); }
  _getHiddenVoucher() {
    //{valid discPercent discAmount}
    if (this.props.org && this.props.org.metadata) return this.props.org.metadata?.voucherID;
    return null;
  }
  _hasHiddenVoucher() {
    if (this.props.org && this.props.org.metadata && this.props.org.metadata?.voucherID) return this.props.org.metadata?.voucherID?.length > 0;
    return false;
  }
  _getProductPriceByQuantity(quantity, optionalProduct) {
    let product = optionalProduct || this.props.app.sharedCache().getProductByProductRelatedObject(this.state.productObjects[this.state.selectedObjectIndex]);
    if (product.bulkPrice && !this._isNormalUser() ) {
      //Reorder just to make sure
      product.bulkPrice = product.bulkPrice.sort((a, b) => a.numLicenses - b.numLicenses);
      //Match first
      for (let priceSchema of product.bulkPrice) {
        if (priceSchema.numLicenses >= quantity) return priceSchema.discountedPrice;
      }
    } return (this.state.overrideValue || product.price) * quantity;
  }
  _getTitle() {
    const object = this.state.productObjects?.[this.state.selectedObjectIndex];
    if (object && !object.isApplication && !object.isRenewal && !object.isExternal) {
      const courseName = object.displayName;
      const staticTitle = courseName + (this.props.sessionCharge ? ' - Session Charge' : (this.props.cancelCharge ? ' - Cancel Enrolment Charge' : ` - ${Utils.capitalizeString(object.uiTerm || '')} License Order`));
      const certSpecs = (this.props.certification ? this.props.app.sharedCache().getCertificationByID(this.props.certification.certificationID) : null);
      return (certSpecs ? certSpecs.description + ' - ' + staticTitle : staticTitle);
    } else if (object && object.isApplication) {
      return (object.description + ' - Application License Order');
    } else if (object && object.isRenewal) {
      return (object.description + ' - Renewal License Order');
    } else if (object && object.isExternal) {
      return (object.name + ' - License Order');
    } return '';
  }

  /* API + transitions */
  async _beginOrder(data) {
    this.setState({ stepper: { ...this.state.stepper, status: 'process', error: null }, isLoading: true });
    //Generate request
    const user = this._getCurrentUser();
    const t = this.props.app.sharedCache().getTenantConfig();
    const provider = this.props.app.license.order.getProviderFromProduct(this.state.selectedProduct);
    //Extra product identifier logic
    const selectedObject = this.state.productObjects[this.state.selectedObjectIndex];
    const shouldUseExtraProductID = ((this.props.autoRedeem && t.licModUseProdSuffixOnTx && !selectedObject.isApplication && !selectedObject.isRenewal && !selectedObject.isExternal) || this.props.sessionCharge);
    const certSpecs = (this.props.certification ? this.props.app.sharedCache().getCertificationByID(this.props.certification.certificationID) : null);
    // Get employer cus
    let employerCUs = null;
//    if (this.state.selectedProduct?.discountPolicy && !this.props.app.isAdmin()) {
    if (this.state.selectedProduct?.discountPolicy && !this.props.sessionCharge) {
      const org = await this._getEmployerObject();
      employerCUs = org?.cus;
    }

    //Make request
    const paymentResp = await this.props.app.license.order.beginOrder({
      productID: this.state.selectedProduct.id,
      customID: this.state.optExplicitProductObject?.id, //course ID used on federated payment calls
      externalProductID: this.state.optExplicitProductObject?.id, //course ID used on affiliate payment object
      user: {
        id: user.id, firstName: data.firstName, lastName: data.lastName,
        email: user.email, referrerName: user.referredByName
      },
      extraProductIdentifier: (shouldUseExtraProductID ? (this.props.sessionCharge ? 'Charged upon session completion' : certSpecs.title) : null),
      additionalAttributes: {
        type: (selectedObject.isApplication ? 'Application' : (selectedObject.isExternal ? 'External' : (selectedObject.isRenewal ? 'Application Renewal' : 'Course'))) ,
        ...(this.props.autoRedeem && certSpecs ? { certification_name: certSpecs.title } : {}),
      },
      taxPercentage: this._getTaxPercentage(),
      callbackURL: window.location.href, quantity: this.state.quantity,
      shouldUseBulkPricing: (!this._isNormalUser() && !this.props.app.isAdmin()),
      unitValue: this.state.chargingAmount,
      voucherID: (this.state.validatedVoucher ? this.state.validatedVoucher.voucherID : null),
      employerCUs: employerCUs,
      isSessionCompletionOrder: !!this.props.sessionCharge || !!this.props.cancelCharge
    }, provider);
    if (!this._isMounted) return;
    //Response
    console.debug('Order begin resp:', paymentResp);
    if (paymentResp.statusCode == 200 && paymentResp.body && paymentResp.body.orderID) {
      //If product has discount policy with any CUs registered we want to require CC from user
      console.log('*-* discount policy', this.state.discountPolicy);
      const hasDiscountPolicy = this.state.selectedProduct?.discountPolicy && Array.isArray(this.state.selectedProduct?.discountPolicy?.employerCUs) && this.state.selectedProduct?.discountPolicy?.employerCUs.length > 0 && !this.props.isGrant && !this.props.sessionCharge;
      //Check if want to skip payment - disallow org manager grant
      if (this.state.totalAmount === 0 && !hasDiscountPolicy && !this.props.app.isOrgManager()) {
        this.setState({
          stepper: { ...this.state.stepper, current: STEPS.SENDING_ORDER, status: 'waiting', error: null },
          payload: { ...this.state.payload, redirectOrderID: paymentResp.body.orderID },
          isLoading: false
        }, () => {
          message.info('Skipping Payment!');
          this._completeOrder();
        });
      } else {
        this.setState({
          stepper: { ...this.state.stepper, current: STEPS.SUMMARY, status: 'waiting', error: null },
          payload: { ...this.state.payload, gatewayUrl: paymentResp.body.url, redirectOrderID: paymentResp.body.orderID, ...paymentResp.body /* moneris params */ },
          isLoading: false
        }, () => { this.currentChild.setSensitiveInfo(data); });
      }
    } else {
      this.setState({ stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'error' }, isLoading: false, payload: { ...this.state.payload, error: paymentResp.body || 'Unknown error!' } });
      this.props.app.alertController.showAPIErrorAlert(null, paymentResp);
    }
  }
  async _completeOrder() {
    let session = null;
    if (this.state.payload.sessionID) session = await this.props.app.classroom.session.getSession(this.state.payload.sessionID, false);
    let paymentResp = null;
    const provider = this.props.app.license.order.getProviderFromProduct(this.state.selectedProduct);
    if (session &&
        ((session?.body?.capacity > 0 && session?.body?.capacity - (session?.body?.enrolmentCount || 0) - session?.body?.reservedCapacity <= 0) ||
        session?.body?.state != Globals.Session_State.AVAILABLE)) {
      paymentResp = { statusCode: 400, body: { err: 'The session you selected is full, please select another Session!' } };
    } else {
      //Clean URL
      this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_LicensePurchase_OrderID, null);
      this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_LicensePurchase_TokenID, null);
      this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_LicensePurchase_ProductID, null);
      this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_LicensePurchase_SessionID, null);
      //Start loading
      this.setState({ stepper: { ...this.state.stepper, status: 'process', error: null }, isLoading: true });
      //Make request
      const user = this._getCurrentUser();
      const t = this.props.app.sharedCache().getTenantConfig();
      let additionalReceiptDescription = '';
      if (session?.body?.type?.startsWith('SCHEDULED')) additionalReceiptDescription = await this._getSessionAdditionalDescription(session.body);
      let doNotSendEmail = false;
      if (session?.body?.disableLicenseEmails) doNotSendEmail = true;
      if (this.props.sessionCharge) additionalReceiptDescription += '\n' + user.firstName + ' ' + user.lastName;
      paymentResp = await this.props.app.license.order.completeOrder({
        orderID: this.state.payload.redirectOrderID + '',
        confirmationID: this.state.payload.redirectPurchaseID || this.state.payload.ticketID,
        user: { id: user.id }, doNotSendEmail, autoRedeem: this.props.autoRedeem, additionalReceiptDescription
      }, provider);
      if (!this._isMounted) return;
      //Response
      console.debug('Order completion resp: ', paymentResp);
    }
    if (paymentResp.statusCode == 200 && paymentResp.body) {
      message.success('Order Completed!');
      if (this.props.sessionCharge) {
        const chargeObj = {
          timestamp: Date.now(),
          amount: this.state.totalAmount,
          chargeResult: Globals.SessionEnrolment_ChargeResult.SUCCESS,
          description: 'Charged upon session completion',
          paymentProvider: provider,
          providerTransactionID: this.state.payload.redirectOrderID + ''
        };
        const { sessionID, userID, externalID } = this.props.enrolment;
        await this.props.app.classroom.sessionEnrolment.chargeSessionEnrolment(sessionID, userID, externalID, chargeObj);
      }
      if (this.props.cancelCharge) await this.props.onCancelation();
      //Should auto redeem?
      if (this.props.autoRedeem && (this.props.certification || this.props.application)) {
        this.setState({ stepper: { ...this.state.stepper, current: STEPS.CONFIRMING_ORDER, status: 'process', error: null }, isLoading: false });
        //Decide if should redeem license to course (unlock course) or application (aka. submission)
        const selectedObject = this.state.productObjects[this.state.selectedObjectIndex];
        if (!selectedObject.isApplication && !selectedObject.isRenewal && !selectedObject.isExternal) this._redeemCourseLicense(paymentResp.body.activationKeys[0]);
        else if (!selectedObject.isExternal) this._redeemApplicationLicense(paymentResp.body.activationKeys[0]);
        else { console.debug('No redeeem process implemented for external license types!'); }
      } else {
        this.setState({ stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'finish', error: null }, isLoading: false, payload: { /* reset */ } });
      }
    } else {
      if (this.props.sessionCharge) {
        const chargeObj = {
          timestamp: Date.now(),
          amount: this.state.totalAmount,
          chargeResult: Globals.SessionEnrolment_ChargeResult.FAIL,
          description: 'Charged upon session completion',
          paymentProvider: provider,
          providerTransactionID: this.state.payload.redirectOrderID + ''
        };
        const { sessionID, userID, externalID } = this.props.enrolment;
        await this.props.app.classroom.sessionEnrolment.chargeSessionEnrolment(sessionID, userID, externalID, chargeObj);
      }
      this.setState({
        stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'error' }, isLoading: false,
        payload: { ...this.state.payload, error: paymentResp.body || 'Unknown error!' }
      });
      this.props.app.alertController.showAPIErrorAlert(null, paymentResp);
    }
  }
  async _cancelOrder(modifyStep) {
    //Start loading
    this.setState({ stepper: { ...this.state.stepper, status: 'process', error: null }, isLoading: true });
    //Make request
    const user = this._getCurrentUser();
    const provider = this.props.app.license.order.getProviderFromProduct(this.state.selectedProduct);
    const paymentResp = await this.props.app.license.order.cancelOrder({
      orderID: this.state.payload.redirectOrderID + '',
      user: { id: user.id }
    }, provider);
    if (!this._isMounted) return;
    //Response
    console.debug('Order cancellation resp: ', paymentResp);
    if (paymentResp.statusCode == 200 && paymentResp.body) {
      if (modifyStep) {
        message.warning('Order Cancelled!');
        this.props.onChange(); //ask to hide modal
      } else {
        this.setState({
          payload: { ...this.state.payload, redirectOrderID: null, redirectPurchaseID: null, redirectProductID: null },
          stepper: { ...this.state.stepper, status: 'waiting', error: null },
          isLoading: false
        });
      }
    } else {
      if (modifyStep) this.setState({ stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'error' }, isLoading: false, payload: { ...this.state.payload, error: paymentResp.body || 'Unknown error!' } });
      this.props.app.alertController.showAPIErrorAlert(null, paymentResp);
    }
  }
  async _submitInvoiceOrder(data) {
    //Start loading
    this.setState({ stepper: { ...this.state.stepper, current: STEPS.SENDING_ORDER, status: 'process', error: null }, isLoading: true });
    //Make request
    const paymentResp = await this.props.app.license.order.submitInvoiceOrder({
      productID: this.state.selectedProduct.id,
      user: { id: data.id, name: `${data.firstName} ${data.lastName}`, email: data.email },
      taxPercentage: this._getTaxPercentage(),
      appURL: this.props.app.urlManager.appURL, quantity: this.state.quantity,
      unitValue: this.state.chargingAmount,
      shouldUseBulkPricing: (!this._isNormalUser() && !this.props.app.isAdmin()),
      voucherID: (this.state.validatedVoucher ? this.state.validatedVoucher.voucherID : null),
    });
    if (!this._isMounted) return;
    //Response
    console.debug('Invoice submission resp: ', paymentResp);
    if (paymentResp.statusCode == 200 && paymentResp.body) {
      message.success('Invoice Submitted!');
      this.setState({ stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'finish', error: null }, isLoading: false, payload: { isInvoice: true/* reset */ } });
    } else {
      this.setState({
        stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'error' }, isLoading: false,
        payload: { ...this.state.payload, error: paymentResp.body || 'Unknown error!' }
      });
      this.props.app.alertController.showAPIErrorAlert(null, paymentResp);
    }
  }
  async _redeemCourseLicense(licenseKey) {
    //Start loading
    this.setState({ stepper: { ...this.state.stepper, current: STEPS.CONFIRMING_ORDER, status: 'process', error: null }, isLoading: true });

    const employer = await this._getEmployerObject();
    // TODO: check if a user should be able to buy a license without an employer
    // if (!employer) {
    //   this.setState({
    //     stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'error' }, isLoading: false,
    //     payload: { ...this.state.payload, error: 'Error while loading employer data.' }
    //   });
    //   return;
    // }

    //Make request
    const { id, userID } = this.props.certification;
    const selectedObject = this.state.productObjects[this.state.selectedObjectIndex];
    const redeemResp = await this.props.app.api.certification.redeemLicense({
      userID,
      certID: id,
      courseID: selectedObject.id,
      activationCode: licenseKey,
      sessionID: this.state.payload?.sessionID,
      referralID: this.state.selectedReferral.id,
      referralName: this.state.selectedReferral.name,
      employerID: employer?.id,
      employerName: employer?.name,
      employerCUs: employer?.cus,
      invitationCode: this.props.invitationCode,
      materialShippingInfo: this.state.materialShippingInfo,
      autoEnrolSessionID: this.state.session?.autoEnrolOnSession || null
    });
    if (!this._isMounted) return;
    //Response
    console.debug('License redeem resp: ', redeemResp);
    if (redeemResp.statusCode == 200 && redeemResp.body) {
      message.success('License Redeemed!');
      this.setState({ stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'finish', error: null }, isLoading: false, payload: { /* reset */ } });
    } else {
      let doubleBook = false;
      if (redeemResp.body?.sessionFull && this.state.totalAmount > 0) doubleBook = true;
      //We add double book here because the most likely reason for the api request to fail is session double booking
      //double book adds a custom message for the client
      this.setState({
        stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'error' }, isLoading: false,
        payload: { ...this.state.payload, redirectOrderID: null, error: { ...redeemResp.body, doubleBook } }
      });
      this.props.app.alertController.showAPIErrorAlert(null, redeemResp);
    }
  }
  async _redeemApplicationLicense(licenseKey) {
    //Start loading
    this.setState({ stepper: { ...this.state.stepper, current: STEPS.CONFIRMING_ORDER, status: 'process', error: null }, isLoading: true });
    //Make request
    const { userID, certProcID, type } = this.props.application;
    const redeemResp = await this.props.app.api.application.submit(userID, certProcID, type, licenseKey)
    if (!this._isMounted) return;
    //Response
    console.debug('Application redeem/submit resp: ', redeemResp);
    if (redeemResp.statusCode == 200 && redeemResp.body) {
      message.success('Application Submitted!');
      this.setState({ stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'finish', error: null }, isLoading: false, payload: { /* reset */ } });
    } else {
      this.setState({
        stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'error' }, isLoading: false,
        payload: { ...this.state.payload, error: redeemResp.body }
      });
      this.props.app.alertController.showAPIErrorAlert(null, redeemResp);
    }
  }
  async _validateVoucher(voucherID) {
    //Start loading
    this.setState({ applingVoucher: true });
    //Make request
    const verifyResp = await this.props.app.license.voucher.verifyVoucher(encodeURIComponent(voucherID));
    if (!this._isMounted) return;
    //Response
    console.debug('Voucher verify resp: ', verifyResp);
    if (verifyResp.statusCode == 200 && verifyResp.body) {
      if (verifyResp.body.valid == true) {
        if (!this._hasHiddenVoucher()) message.success(`Voucher validated with discount of ${(verifyResp.body.discPercent ? `${verifyResp.body.discPercent * 100}%` : `$${verifyResp.body.discAmount}`)}!`);
        this.setState({ applingVoucher: false, validatedVoucher: { ...verifyResp.body, voucherID } }, () => {
          this.handleOverviewChange(null); //reload data
        });
      } else {
        message.error(`Voucher is not valid!`);
        this.setState({ applingVoucher: false, validatedVoucher: null }, () => {
          this.handleOverviewChange(null); //reload data
        });
      }
    } else {
      this.setState({ applingVoucher: false, validatedVoucher: null }, () => {
        this.handleOverviewChange(null); //reload data
      });
      this.props.app.alertController.showAPIErrorAlert(null, verifyResp);
    }
  }
  async _calculateOrderValues(data) {
    //Generate request
    const user = this._getCurrentUser();
    // Get employer cus
    let employerCUs;
    //if (this.state.selectedProduct?.discountPolicy && !this.props.app.isAdmin()) {
    if (data.selectedProduct?.discountPolicy && !this.props.sessionCharge) {
      const org = await this._getEmployerObject();
      employerCUs = org?.cus || [];
    }
    //Make request
    const resp = await this.props.app.license.order.preOrder({
      productID: data.selectedProduct?.id, user: { id: user.id },
      taxPercentage: this._getTaxPercentage(),
      shouldUseBulkPricing: (!this._isNormalUser() && !this.props.app.isAdmin()),
      unitValue: data.chargingAmount, quantity: data.quantity,
      voucherID: (this.state.validatedVoucher ? this.state.validatedVoucher.voucherID : null),
      employerCUs,
    });
    if (!this._isMounted) return null;
    //Response
    console.debug('Pre Order resp:', resp);
    if (resp.statusCode == 200 && resp.body) return resp.body;
    else {
      this.setState({ stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'error' }, isLoading: false, payload: { ...this.state.payload, error: resp.body || 'Unknown error!' } });
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return null;
    }
  }
  async _getSessionAdditionalDescription(session) {
    //Generate location and time string
    let additionalDescription = null;
    const { timezone } = this.props.app.sharedCache().getTenantConfig();
    if (session.startDate && session.startDate.length > 0 && session.type.startsWith('SCHEDULED_PRESENTIAL')) {
      //Load locations on cache
      if (!this.props.app.sharedCache().getVenueByID(session.venueID)) {
        await Promise.all([
          this.props.app.sharedCache().getCities(),
          this.props.app.sharedCache().getVenues(),
        ]);
      }
      const venue = this.props.app.sharedCache().getVenueByID(session.venueID);
      const city = this.props.app.sharedCache().getCityByID(venue?.cityID);
      const location = { venue, city };
      additionalDescription = 'LOCATION AND TIME';
      if (location.venue?.address) additionalDescription += `\n\n${location.venue.name}\n${location.venue.address.street1} ${location.venue.address.street2}`;
      if (location.city) additionalDescription += `\n${location.city.name}, ${location.city.province}`;
      if (location.venue?.address) additionalDescription += `, ${location.venue.address.postalCode}`;
      if (session.venueComplement) additionalDescription += `\n${session.venueComplement}`;
      for (let i = 0; i < session.startDate.length; i++) {
        additionalDescription += `\n\n${Utils.getDateOnSessionFormatByTimestamp(session.startDate[i])}`;
        additionalDescription += `\n\n${Utils.getTimesOnSessionFormatByTimestamps([session.startDate[i]], [session.endDate[i]], timezone)}`;
      }
    } else {
      additionalDescription = 'Location: Online'
      if (session.startDate && session.startDate.length > 0 && session.type.startsWith('SCHEDULED')) {
        for (let i = 0; i < session.startDate.length; i++) {
          additionalDescription += `\n\n${Utils.getDateOnSessionFormatByTimestamp(session.startDate[i])}`;
          additionalDescription += `\n\n${Utils.getTimesOnSessionFormatByTimestamps([session.startDate[i]], [session.endDate[i]], timezone)}`;
        }
      }
    }
    return additionalDescription;
  }

  /* Pre order debounce */
  _getTaxPercentage() {
    const selectedObject = this.state.productObjects[this.state.selectedObjectIndex];
    if (selectedObject.isApplication && selectedObject.application?.taxRate != undefined) return selectedObject.application?.taxRate;
    if (selectedObject.isRenewal && selectedObject.renewal?.taxRate != undefined) return selectedObject.renewal?.taxRate;
    return this.props.app.sharedCache().getTenantConfig().taxRate;
  }
  async _scheduleCalculateOrderValues(data) {
    //cleanup previous
    if (this.calculateDebounce) {
      clearTimeout(this.calculateDebounce);
      this.calculateDebounce = null;
    }
    //
    return new Promise((resolve) => {
      this.calculatePromisesCallbacks.push(resolve);
      this.calculateDebounce = setTimeout(async () => {
        const resp = await this._calculateOrderValues(data);
        this.calculatePromisesCallbacks.forEach((p) => p(resp));
        this.calculatePromisesCallbacks = [];
      }, 300);
    })
  }

}
