import React from 'react';
import autoBind from 'react-autobind';

import { Layout, PageHeader, Table, Button, Tooltip, Tag } from 'antd';
import { EditOutlined, ReloadOutlined } from '@ant-design/icons';

import CustomComponent from '../../components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';

import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
import config from '../../config/config';

export default class AdminPendingApplicationsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    const applications = this.props.app.sharedCache().getPendingApplications();

    this.state = {
      isLoading: false,
      applications,
      users: [],
    };
  }

  handleReview(application) {
    this.props.app.urlManager.pushPageWithThirdID(
      config.ApplicationRoutes.reviewApplication,
      null,
      application.certProcID,
      application.userID,
      application.type,
    );
  }

  async handleReloadList() {
    this.setState({ isLoading: true });
    await this.props.app.reloadPendingApplications();
    const applications = this.props.app.sharedCache().getPendingApplications();
    this.setState({ isLoading: false, applications });
  }

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: ['user', 'fullName'],
      },
      {
        title: 'Certification',
        dataIndex: 'certificationID',
        render: certificationID => certificationID ? this.props.app.sharedCache().getCertificationByID(certificationID)?.description || '-' : '-',
      },
      {
        title: 'Application start',
        dataIndex: 'createdOn',
        render: date => Utils.getDateOnUIFormatByTimestamp(date),
      },
      {
        title: 'Application type',
        render: application => {
          const applicationState = application.state == Globals.ApplicationState.PRE_APPROVAL ? <Tag key="state" color="blue">Pre-approval</Tag> : null;
          const applicantType = application.type == Globals.ApplicationTypes.NEW ? <Tag key="type" color="green">New applicant</Tag> : <Tag key="type">Renewal</Tag>;

          return [applicationState, applicantType];
        },
      },
      {
        title: '',
        align: 'right',
        render: application => (
          <Tooltip placement="bottomLeft" title="Review">
            <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.handleReview.bind(this, application)} />
          </Tooltip>
        ),
      },
    ];

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />

        <PageHeader
          className="pageHeader"
          title="Pending Applications"
          subTitle={(
            <Tooltip title="Refresh">
              <Button icon={<ReloadOutlined />} onClick={this.handleReloadList} />
            </Tooltip>
          )}
        />
        <Layout.Content>
          <Table
            rowKey="id"
            dataSource={this.state.applications}
            loading={this.state.isLoading}
            columns={columns}
            pagination={{
              hideOnSinglePage: true,
            }}
            onRow={(application) => ({
              onClick: this.handleReview.bind(this, application),
            })}
          />
        </Layout.Content>
      </Layout.Content>
    );
  }
}
