import React, { Fragment } from 'react';
import autoBind from 'react-autobind';
import { Form, Input, Divider, Col, Row, DatePicker, Checkbox, Select } from 'antd';
import moment from 'moment';

import CustomComponent from '../../../../components/CustomComponent';
import Utils from '../../../../components/Utils';
import Globals from '../../../../config/Globals';
import CommonDynamicForm from '../../Forms/CommonDynamicForm';

class UserProfileStep extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { };
  }

  //Life cycle
  componentDidMount = async () => {
    try {
      //Load user and persist
      const user = await this._getUser();
      this.setState({ user, selectedOrg: user?.employementInfo?.org });
      //Set form data
      this.form.setFieldsValue(user);
      // I called the validation to show explicitly to user the fields that they need to fill
      const resp = await this.form.validateFields();
      this.props.onChange({ isFormValidProfile: !!resp, isLoading: false });
    } catch (err) {
      console.error(err);
      this.props.onChange({ isFormValidProfile: false, isLoading: false });
    }
  };

  //Actions
  handleOrgChange(orgObj) { this.setState({ selectedOrg: orgObj }, this.handleValidation); }
  async handleValidation(event) {
    try {
      // await this.form.setFieldsValue({ [event.target.name]: event.target.value });
      const resp = await this.form.validateFields();
      this.props.onChange({ isFormValidProfile: true });
      return { ...resp, ...this.state };
    } catch (err) {
      console.error('error ', err);
      this.props.onChange({ isFormValidProfile: false });
    }
  }
  async handleSubmit() {
    return await this.handleValidation();
  }
  //UI
  render() {
    return (
      <>
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')} style={{ marginTop: 20 }}>
          <CommonDynamicForm app={this.props.app} selectedOrg={this.state.selectedOrg} isDesktop onChange={this.handleValidation}
            additionalInputs={this.props.app.sharedCache().getTenantRegistrationConfig()} onOrgChange={this.handleOrgChange}/>
        </Form>
      </>
    );
  }
  /* API */
  async _getUser() {
    const promises = [];
    const userID = this.props.app.getAuthorizedUserID();
    //get user and check for failure
    let userObj = null;
    promises.push(new Promise(async (resolve) => {
      userObj = (await this.props.app.idm.api.user.read(userID));
      if (userObj.statusCode == 200) {
        userObj = userObj.body;
        resolve(true);
      } else {
        this.props.app.alertController.showAPIErrorAlert(null, userObj);
        resolve(false);
      }
    }));

    //Load employment info
    let employementInfo = null;
    const additionalInputs = this.props.app.sharedCache().getTenantRegistrationConfig().fields;
    if (Object.values(additionalInputs || {}).find((f) => f.type == Globals.RegOrgEmploymentSelectionType)) {
      promises.push(new Promise(async (resolve) => {
        let employmentResp = (await this.props.app.organization.employee.getEmployeeByEmployeeID(userID));
        if (employmentResp.statusCode == 200) employementInfo = employmentResp.body;
        resolve(true);
      }));
    }

    //get partitions data
    const wantedParts = this._getAvailablePartitions(true);
    let partData = null;
    if (wantedParts.length > 0) {
      promises.push(new Promise(async (resolve) => {
        partData = await this.props.app.idm.api.userPartition.readSome(wantedParts, userID);
        partData = partData?.body?.parts || {};
        resolve(true);
      }));
    }

    //Resolve alll, continue if all true
    const resolveAll = await Promise.all(promises);
    if (!resolveAll || resolveAll.find((r) => !r)) return;
    //Return parsed info
    return this._parseUserResponses(userObj, partData, employementInfo);
  }
  _parseUserResponses(userObj, partData, employementInfo) {
    //
    try {
      //Buildup form data
      const formData = {};
      const fields = this.props.app.sharedCache().getTenantRegistrationConfig().fields;
      for (let fieldKey of Object.keys(fields || {})) {
        const field = fields[fieldKey];
        if (field.partitionID && field.partitionID.length > 0) {
          const part = partData.find(part => (part.partID == field.partitionID));
          formData[field.id] = Utils.getNestedObject(part ? part.value : null, field.id);
          if (formData[field.id] && field.type == 'date') formData[field.id] = moment(formData[field.id]);
        }
        if (field.saveOnUser && !formData[field.id]) {
          formData[field.id] = Utils.getNestedObject(userObj, field.id);
          if (formData[field.id] && field.type == 'date') formData[field.id] = moment(formData[field.id]);
        }
        if (!formData[field.id]) { formData[field.id] = ''; /* unknown source */ }
        if (field.type == 'boolean' || field.type == 'switch') formData[field.id] = !!formData[field.id];
      }
      //Set form data
      return { ...formData, ...userObj, partData, employementInfo };
    } catch (err) {
      console.error(err);
      return null;
    }
  }
  _getAvailablePartitions(noSanitize) {
    let parts = [];
    const additionalInputs = this.props.app.sharedCache().getTenantRegistrationConfig().fields;
    for (let input of Object.values(additionalInputs || {})) {
      const partID = (noSanitize ? input.partitionID : input.partitionID.replace(/\./g,'_'));
      if (parts.indexOf(partID) == -1) parts.push(partID);
    } return parts;
  }
}

export default UserProfileStep;
