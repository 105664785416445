import React from 'react';
import autoBind from 'react-autobind';
import { Button, Input, Layout, Row, Col, PageHeader, Tabs } from 'antd';
//
import Globals from '@/config/Globals';
import Utils from '@/components/Utils';
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import CustomComponent from '../../../components/CustomComponent';
import CommonDetailsLicensesForm from '@/views/commonComponents/Forms/CommonDetailsLicensesForm';
import CommonAssignmentsTable from '@/views/commonComponents/CommonAssignmentsTable';
import CommonReservationsTable from '@/views/commonComponents/CommonReservationsTable';
import CommonLicenseViewActivationTable from '@/views/commonComponents/CommonLicenseViewActivationTable';
import WhiteBox from '@/views/commonComponents/WhiteBox';

export default class AdminLicensesView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.activationKeyFromParams = this.props.match.params.id;

    this.state = {
      isLoading: false,
      activationKey: this.activationKeyFromParams && this.activationKeyFromParams !== ':id' ? this.activationKeyFromParams : '', 
      licenses: {},
      activations: [],
      assignments: [],
      reservations: [],
      selectedTab: 'details',
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    const key = this.state.activationKey;
    const selectedTab = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_TabID);
   
    if (this.state.selectedTab != selectedTab && selectedTab) this.handleTabChange(selectedTab);
    else this.handleTabChange(this.state.selectedTab);
    if (key) { await this.fetchData(key, selectedTab); }
  }
  
  async fetchData(key) {
    if (!key) return;
    this.startLoading();
    await this._loadLicenses(key);
    this.stopLoading();
    this._reloadURLParams();
  }

  async handleTabChange(selectedTab) {
    this.setState({ selectedTab }, async () => {
      this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_TabID, selectedTab);  
    });
    const key = this.state.activationKey;
    if (selectedTab === 'activations' && key) { await this._loadActivations(key); }
    if (selectedTab === 'assignments' && key) { await this._loadAssignments(key); }
    if (selectedTab === 'reservations' && key) { await this._loadReservations(key); }
  }

  handleInputChange(event) {
    this.setState({ activationKey: event.target.value });
  }

  async handleSearch() {
    const key = this.state.activationKey;
    const selectedTab = this.state.selectedTab;
    if (key) { await this.fetchData(key); }
    if (selectedTab === 'activations' && key) { await this._loadActivations(key); }
    if (selectedTab === 'assignments' && key) { await this._loadAssignments(key); }
    if (selectedTab === 'reservations' && key) { await this._loadReservations(key); }
  }

  _reloadURLParams() {
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_ID_Placeholder, this.state.activationKey);
  }

  //UI
  render() {
    const items = [
      { label: 'Details', key: 'details', children: <div><CommonDetailsLicensesForm app={this.props.app} licenses={this.state.licenses} {...Utils.propagateRef(this, 'detailsForm')}/></div> },
      { label: 'Activations', key: 'activations', children: <div><CommonLicenseViewActivationTable activations={this.state.activations} app={this.props.app} isLoading={this.state.isLoading}/></div> },
      { label: 'Assignments', key: 'assignments', children: <div><CommonAssignmentsTable assignments={this.state.assignments} app={this.props.app} isLoading={this.state.isLoading}/></div> },
      { label: 'Reservations', key: 'reservations', children: <div><CommonReservationsTable reservations={this.state.reservations} app={this.props.app} isLoading={this.state.isLoading}/></div> },
    ];
  
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
        <PageHeader className="pageHeader" title="License Viewer" onBack={() => this.props.app.urlManager.pushBack()} />
        <WhiteBox>
          <Layout.Content>
            <Row type="flex" justify="start">
              <Col>
                <span style={{ marginRight: 8 }}>License:</span>
                <Input style={{ width: 300, borderRadius: 0 }} placeholder="Enter Activation Key" value={this.state.activationKey} onChange={this.handleInputChange} />
              </Col>
              <Col>
                <Button style={{ marginLeft: 20 }} type="primary" onClick={this.handleSearch}>Search</Button>
              </Col>
            </Row>
            <br />
            <Tabs activeKey={this.state.selectedTab} onChange={this.handleTabChange} items={items} />
          </Layout.Content>
        </WhiteBox>
      </Layout.Content>
    );
  }

  /* private API */
  async _loadLicenses(key) {
    let licenses = {};
    if(!key) return;
    this.setState({ isLoading: true });
    const resp = await this.props.app.license.key.getKey(key, true);
    if (resp.statusCode === 200 && resp.body) {
      licenses = resp.body;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return {};
    }
    this.setState({ licenses, isLoading: false });
  }

  async _loadActivations(key) {
    let activations = [];
    if(!key) return;
    this.setState({ isLoading: true });
    const resp = await this.props.app.license.key.getKeyConsumption(key);
    if (resp.statusCode === 200 && resp) {
      activations = resp.body.consumption;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return [];
    }
    this.setState({ activations, isLoading: false });
  }

  async _loadAssignments(key) {
    let assignments = [];
    if(!key) return;
    this.setState({ isLoading: true });
    const resp = await this.props.app.license.key.getKeyAssignments(key);
    if (resp.statusCode === 200 && resp) {
      assignments = resp.body.assignments;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return [];
    }
    this.setState({ assignments, isLoading: false });
   }
  
  async _loadReservations(key) {
    let reservations = [];
    if(!key) return;
    this.setState({ isLoading: true });
    const resp = await this.props.app.license.key.getKeyReservations(key);
    if (resp.statusCode === 200 && resp) {
      reservations = resp.body.reservations;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return [];
    }
    this.setState({ reservations, isLoading: false });
  }
}
