import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Table, Tooltip, Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

//props are: ids, orgs, onDelete, isLoading
export default class CommonOrgReferralTable extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: null };
  }

  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        sorter: (a, b) => {
          
          const orgA = this.props.orgs.find((o) => o.id == a);
          const orgB = this.props.orgs.find((o) => o.id == b);
          return (orgA?.name || '').localeCompare(orgB?.name || '');
        },
        render: (props) => {
          if (!this.props.orgs) return 'Loading...';
          const org = this.props.orgs.find((o) => o.id == props);
          return org?.name || '';
        },
        title: 'Name', key: 'name',
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order
      },
      ...(this.props.onDelete ? [{
        title: 'Actions', width: '150', key: 'Actions',
         render: props => {
          return (
            <span className='tableButtonContainer'>
              <Tooltip placement="bottomLeft" title="Delete">
                <Popconfirm placement="top" title="Are you sure that you want to delete this referral?"
                  onConfirm={() => this.props.onDelete(props)} okText="Yes" cancelText="No">
                  <Button variant="none" icon={<DeleteOutlined />} shape="circle" />
                </Popconfirm>
              </Tooltip>
            </span>
          );
        }
      }] : [])
    ];
    const props = {
      rowKey: (props) => props, loading: this.props.isLoading, onChange: this.handleFilterChange, locale: { emptyText: 'No referrals found!' },
      pagination: {pageSize: 10, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter']},
    };
    return (<Table className="commonOrgReferralTable" columns={columns} dataSource={this.props.ids} {...props}/>);
  }
}
