import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, message, Tabs, Table } from 'antd';
//
import CustomComponent from '../../../components/CustomComponent';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import CommonVoucherForm from '../../commonComponents/Forms/CommonVoucherForm';
import WhiteBox from '../../commonComponents/WhiteBox';
//
import config from '../../../config/config';
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
export default class AdminVoucherView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    const voucherID = this.props.match.params.id;
    this.state = {
      isLoading: false, voucherID: voucherID,
      isEdit: voucherID != Globals.URL_NewIndentifier,
      voucherObj: null, sortedInfo: null,
      selectedTab: 'details',
      isLoadingUsersInfo: false, usersInfo: [],
    };
  }

  //View Life Cycle
  async componentDidMount() {
    super.componentDidMount();
    if (!this.state.isLoading && this.state.isEdit) {
      await this.fetchData();
      const selectedTab = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_TabID);
      if (this.state.selectedTab != selectedTab && selectedTab) this.handleTabChange(selectedTab);
      else this.handleTabChange(this.state.selectedTab);
    }
  }

  async fetchData() {
    this.setState({ isLoading: true, isLoadingUsersInfo: true });
    const resp = await this._loadVoucher(this.state.voucherID);
    if (resp) {
      this.setState({ voucherObj: resp, isLoading: false }, () => {
        this.form.setFormData(resp);
      });
    } else this.setState({ isLoading: false, isLoadingUsersInfo: false });
  }

  //Actions
  async handleSubmit(data) {
    this.startLoading();
    if (this.state.isEdit) {
      const resp = await this._updateVoucher({ ...data, id: null }, this.state.voucherID, this.state.voucherObj);
      if (resp) await this.fetchData();
      this.stopLoading();
    } else {
      const resp = await this._createVoucher({ ...data, id: null }, data.id);
      if (resp) {
        this.props.app.urlManager.replacePage(config.ApplicationRoutes.voucher, null, resp);
        this.setState({ voucherID: resp, isEdit: true, isLoading: false }, this.fetchData);
      } else this.stopLoading();
    }
  }
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  handleTabChange(selectedTab) {
    this.setState({ selectedTab }, () => {
      this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_TabID, selectedTab);
    });
  }
  //UI
  render() {
    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className="pageHeader" title={(this.state.isEdit ? "Update Voucher" : "Create new Voucher")}
                        onBack={() => this.props.app.urlManager.pushBack()} />
            <WhiteBox>
              <Tabs activeKey={this.state.selectedTab} onChange={this.handleTabChange}>
                <Tabs.TabPane tab="Details" key="details">
                  <CommonVoucherForm
                    onSubmit={this.handleSubmit} isLoading={this.state.isLoading}
                    app={this.props.app}
                    editMode={this.state.isEdit} {...Utils.propagateRef(this, 'form')}
                  />
                </Tabs.TabPane>
                {this.state.isEdit && <Tabs.TabPane tab="Consumption" key="consumptions"> {this._renderConsumptionsTable()} </Tabs.TabPane>}
              </Tabs>
            </WhiteBox>
        </Layout.Content>
      </>
    );
  }

  /* private UI */
  _renderConsumptionsTable() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'Consumption Date', dataIndex: 'createdOn', key: 'createdOn', width: '13%',
        render: createdOn => createdOn ? Utils.getDateOnUIFormatByTimestamp(createdOn) : '',
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
      },
      {
        title: 'Product', dataIndex: 'productID', key: 'productID', width: '15%',
        sorter: (a, b) => a.productID.localeCompare(b.productID),
        sortOrder: sortedInfo.columnKey === 'productID' && sortedInfo.order,
        render: productID => {
          return this.props.app.sharedCache().getProductByID(productID).name;
        }
      },
      {
        title: 'Consumer', dataIndex: 'externalID', key: 'externalID', width: '15%',
        sorter: (a, b) => a.externalID.localeCompare(b.status),
        sortOrder: sortedInfo.columnKey === 'externalID' && sortedInfo.order,
        render: externalID => {
          if (this.state.isLoadingUsersInfo) { return 'Loading...'; }
          const user = this.state.usersInfo.find(user => user._source.id == externalID) || {};
          return user._source ? user._source.fullName : 'N/A';
        },
      },
      {
        title: 'Original value', key: 'valueOriginal', width: '5%', align: 'right',
        render: order => {
          return (order.valueOriginal != undefined ? `$${Utils.toCurrencyFormat(order.valueOriginal)}` : '');
        },
        sorter: (a, b) => a.valueOriginal - b.valueOriginal,
        sortOrder: sortedInfo.columnKey === 'valueOriginal' && sortedInfo.order,
      },
      {
        title: 'Quantity', key: 'quantity', width: '5%', align: 'right', dataIndex: 'quantity',
        sorter: (a, b) => a.quantity - b.quantity,
        sortOrder: sortedInfo.columnKey === 'quantity' && sortedInfo.order,
      },
      {
        title: 'Discount', key: 'valueDiscount', width: '5%', align: 'right',
        render: order => {
          return (order.valueDiscount != undefined ? `$${Utils.toCurrencyFormat(order.valueDiscount)}` : '');
        },
        sorter: (a, b) => a.valueOriginal - b.valueOriginal,
        sortOrder: sortedInfo.columnKey === 'valueOriginal' && sortedInfo.order,
      },
      {
        title: 'Total', key: 'valueTotal', width: '8%', align: 'right',
        render: order => {
          return (order.valueTotal != undefined ? `$${Utils.toCurrencyFormat(order.valueTotal - order.valueTax)}` : '');
        },
        sorter: (a, b) => (a.valueTotal - a.valueTax) - (b.valueTotal - b.valueTax),
        sortOrder: sortedInfo.columnKey === 'valueTotal' && sortedInfo.order,
      },
    ];

    const props = {
      rowKey: 'id', loading: this.state.isLoading, onChange: this.handleFilterChange,
      locale: { emptyText: 'No voucher consumptions!' },
      pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] }
    };
    return (
      <Table className="voucherConsumptionsTable"
        columns={columns} dataSource={this.state.voucherObj && this.state.voucherObj.consumptions ? this.state.voucherObj.consumptions : []} {...props} />
    );
  }
  /* private API calls */
  async _loadVoucher(voucherID) {
    const resp = await this.props.app.license.voucher.getVoucher(voucherID);
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body && resp.body.id) {
      resp.body.consumptions = resp.body.consumptions.map( c => {
        c.id = c.createdOn + c.externalID;
        return c;
      });
      if (resp.body.consumptions.length > 0) {
        const usersIDs = resp.body.consumptions.map(item => item.externalID);
        this._fetchUserNames(usersIDs);
      } return resp.body;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } return null;
  }
  async _createVoucher(data, optionaID) {
    const resp = await this.props.app.license.voucher.createVoucher({
      description: data.description,
      discAmount: data.discAmount,
      discPercent: data.discPercent,
      ...(data.expDate ? {expDate: data.expDate} : {}),
      maxActivations: data.maxActivations,
      maxQuantity: data.maxQuantity,
      maxDiscount: data.maxDiscount,
      productID: data.productID,
    }, optionaID);
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body && resp.body.voucherID) {
      message.success('Voucher created with success!');
      return resp.body.voucherID;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _updateVoucher(data, voucherID, oldObj) {
    const resp = await this.props.app.license.voucher.updateVoucher({
      description: data.description,
      discAmount: data.discAmount,
      discPercent: data.discPercent,
      ...(data.expDate ? { expDate: data.expDate } : {}),
      maxActivations: data.maxActivations,
      maxQuantity: data.maxQuantity,
      maxDiscount: data.maxDiscount,
      productID: data.productID,
      //no ui
      userIDRestriction: oldObj.userIDRestriction,
      isPrivate: oldObj.isPrivate,
    }, voucherID);
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200) {
      message.success('Voucher updated with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _fetchUserNames(usersIDs) {
    const resp = await this.props.app.api.user.searchUsersByIDs(usersIDs);
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body) {
      this.setState({ usersInfo: resp.body.users, isLoadingUsersInfo: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.setState({ isLoadingUsersInfo: false });
  }
}
