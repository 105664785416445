import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Tabs } from 'antd';
import scrollIntoView from 'scroll-into-view';
//
import config from '../../config/config';
import Globals from '../../config/Globals';
import Utils from '../../components/Utils';
//
import CustomComponent from '../../components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CommonNotificationsSettingsForm from '../commonComponents/Forms/CommonNotificationsSettingsForm';
//

export default class OrgManagersSettingsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false, selectedTab: 'main',
      //data
      org: null,
      orgMgrNotifications: null,
    };
  }
  
  //View Life Cycle
  async componentDidMount() {
    super.componentDidMount();
    //Tab
    const selectedTab = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_TabID);
    if (this.state.selectedTab != selectedTab && selectedTab) this.handleTabChange(selectedTab);
    else this.handleTabChange(this.state.selectedTab);
    //Load data
    await this._loadData();
    //Scroll
    this._scrollToTop();
  }

  //Actions
  handleTabChange(selectedTab) {
    this.setState({ selectedTab }, () => {
      this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_TabID, selectedTab);
    });
  }

  //UI
  render() {
    const orgID = this.state.org?.orgID;
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
        <PageHeader
          className="pageHeader"
          title={'Organization Settings'}
          onBack={() => this.props.app.urlManager.pushBack() || this.props.app.urlManager.pushPage(config.ApplicationRoutes.homepage)}
        />
        <Layout.Content>
          <Tabs activeKey={this.state.selectedTab} onChange={this.handleTabChange}>
            <Tabs.TabPane tab="Settings" key="main">
             <CommonNotificationsSettingsForm {...Utils.propagateRef(this, 'form')} app={this.props.app} orgID={orgID} data={this.state.orgMgrNotifications}/>
            </Tabs.TabPane>
          </Tabs>
        </Layout.Content>
      </Layout.Content>
    );
  }

  /* private helper */
  _scrollToTop() {
    const element = document.querySelector('.scrollHere');
    if (element) scrollIntoView(element, { debug: false, time: 500, align: { top: 0 } });
  }

  /* private */
  async _loadData() {
    const externalID = this.props.app.urlManager.selectedOrg;   
    await this._fetchData(externalID);
  }

  async _fetchData(externalID) {
    this.setState({ isLoading: true });
     let org = null;
    await Utils.execRequests([
      await this._fetchOrganization(externalID),
    ], null, (resp, index) => {
        org = resp.body;
    });
    this.setState({ isLoading: false, org})
  }

  async _fetchOrganization(orgID) {
    const resp = await this.props.app.organization.organizationApp.getOrganizationApp(orgID);
    if (resp.statusCode === 200 && resp.body) {
      const orgMgrNotifications = resp.body?.orgMgrNotifications;
      this.setState({ orgMgrNotifications });
      return resp;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }
}
