import React from 'react';
import moment from 'moment';
import { Col, Modal, Row, Form, Select, DatePicker, Button, Upload, message, Tooltip, Spin } from 'antd';
import { InboxOutlined, PaperClipOutlined, DeleteOutlined, RocketOutlined } from '@ant-design/icons';
import autoBind from 'react-autobind';
import ReactMarkdown from 'react-markdown';

import Utils from '../../../../../components/Utils';
import Globals from '../../../../../config/Globals';
import CustomComponent from '../../../../../components/CustomComponent';

export default class ApplicationItemUploadModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.initialState = {
      isLoading: false,
      isVisible: false,
      item: null,
      currentProvider: null,
      currentApplicationItem: null,
      fileList: [],
      uploadList: [],
      selectedProvider: null,
      issuedOn: null,
      expiresOn: null,
    };

    this.uploadListStatuses = {
      UPLOADING: 'UPLOADING',
      UPLOADED: 'UPLOADED',
      ERROR: 'ERROR',
    };

    this.state = { ...this.initialState };
  }

  open(item, currentProvider = null, currentApplicationItem = null) {
    this.setState({
      isVisible: true,
      item,
      currentProvider,
      currentApplicationItem,
      selectedProvider: (
        currentApplicationItem ? item.acceptedProviders.find((provider) => provider.id == currentApplicationItem.sourceID)
        : null
      ),
      uploadList: (
        currentApplicationItem ? currentApplicationItem.fileItems.map(({ fileID, fileName }) => ({ name: fileName, fileID, version: fileID, status: this.uploadListStatuses.UPLOADED }))
        : []
      ),
      issuedOn: currentApplicationItem?.issuedOn ? moment(new Date(currentApplicationItem.issuedOn)) : null,
      expiresOn: currentApplicationItem?.expiresOn ? moment(new Date(currentApplicationItem.expiresOn)) : null,
    });
  }

  close(forceClose = false) {
    if ((this.state.isLoading) && !forceClose) {
      return;
    }

    const isUploading = this._hasFilesUploading();
    if (isUploading) {
      return;
    }

    this.setState({ ...this.initialState });
  }

  handleSelectProvider(providerID) {
    const selectedProvider = this.state.item.acceptedProviders.find(
      (provider) => provider.id == providerID
    );

    this.setState({ selectedProvider });
  }

  handleLaunch() {
    this.props.app.urlManager.openExternalPage(this.state.selectedProvider.launchLink);
  }

  handleFileChange({ file }) {
    const { selectedProvider, fileList } = this.state;

    const allowedTypes = ['image/', 'pdf', 'doc', 'docx', 'xls', 'xlsx'];
    if (!allowedTypes.find(fileType => file.type?.includes(fileType))) {
      message.error('This type of file is not accepted.');
      return;
    }

    if (file.status == 'removed') {
      return;
    }

    if ((fileList.length + 1) > selectedProvider.maxFiles) {
      message.error(`This provider accepts only ${selectedProvider.maxFiles} file${selectedProvider.maxFiles > 1 ? 's' : ''}.`);
      return;
    }

    this._uploadFile(file);
    this.setState(prevState => ({
      ...prevState,
      fileList: [...prevState.fileList, file],
    }));
  }

  handleRemoveFile(fileID) {
    this.setState(prevState => ({
      ...prevState,
      fileList: prevState.fileList.filter(file => file.uid != fileID),
      uploadList: prevState.uploadList.filter(file => file.fileID != fileID),
    }));
  }

  async handleSubmit() {
    const payload = {
      ...(this.state.issuedOn ? { issuedOn: Utils.getTimestampFromMoment(this.state.issuedOn), } : {}),
      ...(this.state.expiresOn ? { expiresOn: Utils.getTimestampFromMoment(this.state.expiresOn) } : {}),
    };

    this._submitApplicationItem(payload);
  }

  handleChangeIssueDate(issuedOn) {
    if (issuedOn?.isAfter(new Date())) {
      message.error('Date cannot be in the future');
      return;
    }

    this.setState({ issuedOn });
  }

  render() {
    const { item, selectedProvider, fileList, uploadList } = this.state;

    const acceptedProviders = item?.acceptedProviders.filter((provider) => (provider.type == Globals.Providers_Types.FILE || provider.type == Globals.Providers_Types.INTERNAL)) || [];
    const providersWithLink = acceptedProviders.filter((provider) => provider.displayLink) || [];
    const showLinks = providersWithLink.length > 0;
    const showRightSection = (item?.detailedDescription || showLinks);

    if (acceptedProviders.length == 1 && !selectedProvider) {
      this.setState({ selectedProvider: acceptedProviders[0] });
    }

    const isUploading = this._hasFilesUploading();

    return (
      <Modal
        closable={false}
        visible={this.state.isVisible}
        title={item?.detailedName}
        className={`uploadModal ${!showRightSection ? 'noRightSection' : ''}`}
        okText="Done"
        footer={[
          <Button key="cancel" onClick={this.close}>
            Cancel
          </Button>,
          <Button
            type="primary"
            key="done"
            onClick={this.handleSubmit}
            loading={this.state.isLoading}
            disabled={isUploading}
          >
            Done
          </Button>,
        ]}
      >
        <Row gutter={32}>
          <Col span={showRightSection ? 12 : 24}>
            <Row>
              <Col span={24}>
                <h1>What we need to know</h1>
              </Col>
            </Row>

            <Form layout="vertical">
              <Row>
                <Col span={24}>
                  {acceptedProviders.length > 1 && (
                    <Form.Item label="Provider">
                      <Select onChange={this.handleSelectProvider} value={selectedProvider?.id} placeholder="Select a provider...">
                        {acceptedProviders.map((provider, i) => (
                          <Select.Option key={provider.id + i} value={provider.id}>
                            {provider.optionalName || this._getTrainingProviderByID(provider.trainingProviderID)?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}

                  {selectedProvider?.hasIssuedDate && (
                    <Form.Item label="Date issue">
                      <DatePicker style={{ width: '100%' }} value={this.state.issuedOn} onChange={this.handleChangeIssueDate} />
                    </Form.Item>
                  )}

                  {selectedProvider?.hasExpirationDate && (
                    <Form.Item label="Expiration date">
                      <DatePicker style={{ width: '100%' }} value={this.state.expiresOn} onChange={(expiresOn) => this.setState({ expiresOn })} />
                    </Form.Item>
                  )}

                  {(selectedProvider?.type == Globals.Providers_Types.INTERNAL) && (
                    <Button type="primary" onClick={this.handleLaunch} size="large" style={{ width: '100%' }}>
                      <RocketOutlined />
                      <span>Launch on {selectedProvider.optionalName || this._getTrainingProviderByID(selectedProvider.trainingProviderID)?.name}</span>
                    </Button>
                  )}

                  {(selectedProvider?.type == Globals.Providers_Types.FILE) && (
                    <>
                      {!(this._getUploadedFiles().length == selectedProvider.maxFiles) && (
                        <Form.Item label={`Select certificate file${selectedProvider.maxFiles > 1 ? 's': ''}`}>
                          <Upload.Dragger
                            multiple={false}
                            showUploadList={false}
                            beforeUpload={() => false}
                            fileList={fileList}
                            onChange={this.handleFileChange}
                            disabled={isUploading}
                            style={{ opacity: isUploading ? 0.5 : 1 }}
                          >
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                          </Upload.Dragger>
                        </Form.Item>
                      )}

                      {(selectedProvider?.type == Globals.Providers_Types.FILE && uploadList.length > 0) && (
                        <Form.Item label={`Uploaded file${uploadList.length > 1 ? 's': ''}`}>
                          <ul className="application-fileList">
                            {uploadList.map(this._renderFileItem)}
                          </ul>
                        </Form.Item>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>

          {showRightSection && (
            <Col span={12}>
              {item?.detailedDescription && (
                <>
                  <Row>
                    <Col span={24}>
                      <h1>Additional info</h1>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: 24 }}>
                    <Col span={24}>
                      <ReactMarkdown children={item?.detailedDescription} linkTarget='_blank'/>
                    </Col>
                  </Row>
                </>
              )}

              {showLinks && (
                <>
                  <Row>
                    <Col span={24}>
                      <h1>Providers</h1>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <ul>
                        {providersWithLink.map((provider, i) => (
                          <li key={provider.id + i}>
                            <a href={provider.optionalLink || this._getTrainingProviderByID(provider.trainingProviderID)?.url} target="_blank" rel="noopener">
                              {provider.optionalName || this._getTrainingProviderByID(provider.trainingProviderID)?.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          )}
        </Row>
      </Modal>
    );
  }

  // Private methods
  _hasFilesUploading() {
    return this.state.uploadList.some((file) => file.status == this.uploadListStatuses.UPLOADING);
  }

  _getTrainingProviderByID(trainingProviderID) {
    const { certificationSpecs } = this.props;

    return certificationSpecs.trainingProviders?.find(trainingProvider => (
      trainingProvider.id == trainingProviderID
    ));
  }

  _renderFileItem(uploadListItem, index) {
    const fileName = uploadListItem.name || this.state.fileList.find(fileListItem => fileListItem.uid = uploadListItem.fileID)?.name || `File #${(index < 10 ? `0${index + 1}` : index + 1)}`;

    return (
      <li className={uploadListItem.status == this.uploadListStatuses.ERROR ? 'error' : ''} key={uploadListItem.fileID}>
        <div>
          <PaperClipOutlined />
          <span className="file-name">{fileName}</span>
        </div>

        <div>
          {uploadListItem.status == this.uploadListStatuses.UPLOADING && <Spin />}

          {uploadListItem.status == this.uploadListStatuses.ERROR && (
            <Button type="primary" onClick={this._uploadFile.bind(this, uploadListItem.fileID, true)}>Retry</Button>
          )}

          {uploadListItem.status != this.uploadListStatuses.UPLOADING && (
            <Tooltip title="Delete file">
              <Button icon={<DeleteOutlined />} onClick={this.handleRemoveFile.bind(this, uploadListItem.fileID)} />
            </Tooltip>
          )}
        </div>
      </li>
    );
  }

  // API Requests
  async _uploadFile(file, isRetry = false) {
    const fileReader = new FileReader();

    if (isRetry) {
      file = this.state.fileList.find(fileListItem => fileListItem.uid = file);
    }

    fileReader.readAsDataURL(file);

    fileReader.onloadend = async (readerResp) => {
      if (!this._isMounted) return;

      this._setOrUpdateUploadListItemStatus({ fileID: file.uid, name: file.name }, this.uploadListStatuses.UPLOADING);

      file.file = readerResp.currentTarget.result;
      const version = new Date().getTime();
      const sourceID = this.state.selectedProvider.id;

      const resp = await this.props.app.api.applicationItemFile.upload(
        file,
        this.props.user.id,
        this.props.certificationProcess.id,
        sourceID,
        version,
      );
      if (!this._isMounted) return;

      if (resp && resp.statusCode == 200) {
        this._setOrUpdateUploadListItemStatus({ fileID: file.uid, name: file.name }, this.uploadListStatuses.UPLOADED, version);
      } else {
        this._setOrUpdateUploadListItemStatus({ fileID: file.uid, name: file.name }, this.uploadListStatuses.ERROR);
        this.props.app.alertController.showAPIErrorAlert(null, resp);
      }
    };
  }

  async _submitApplicationItem(formData)  {
    const { selectedProvider } = this.state;

    if (!selectedProvider || selectedProvider?.type == Globals.Providers_Types.INTERNAL) {
      this.close();
      this.props.onUpdate();

      return;
    }

    this.startLoading();

    const { currentApplicationItem, currentProvider } = this.state;
    const uploadedFiles = this._getUploadedFiles();

    if (currentApplicationItem && (uploadedFiles.length == 0 || currentProvider?.id !== selectedProvider.id)) {
      const resp = await this.props.app.api.applicationItem.delete(
        this.props.user.id,
        this.props.certificationProcess.id,
        this.props.applicationType,
        currentApplicationItem.sourceID,
      );

      if (resp.statusCode != 200) {
        this.props.app.alertController.showAPIErrorAlert(null, resp);
        return;
      }
    }

    if (uploadedFiles.length > 0) {
      const body = {
        additionalNotes: '',
        fileItems: uploadedFiles.map(file => ({
          fileID: String(file.version),
          fileName: file.name,
        })),
      };

      if (formData.issuedOn) {
        body.issuedOn = formData.issuedOn;
      }

      if (formData.expiresOn) {
        body.expiresOn = formData.expiresOn;
      }

      const resp = await this.props.app.api.applicationItem.create(
        this.props.user.id,
        this.props.certificationProcess.id,
        this.props.applicationType,
        selectedProvider.id,
        body,
      );
      if (!this._isMounted) return;

      if (resp.statusCode == 200) {
        this.props.onUpdate();
        this.close(true);
      } else {
        this.props.app.alertController.showAPIErrorAlert(null, resp);
      }

      this.stopLoading();
    } else {
      this.close(true);
      this.props.onUpdate();
    }
  }

  // Utils
  _getUploadedFiles() {
    return this.state.uploadList.filter((file) => file.status == this.uploadListStatuses.UPLOADED);
  }

  _setOrUpdateUploadListItemStatus({ fileID, name }, status, version) {
    const existingStatus = this.state.uploadList.find(
      (uploadListItem) => uploadListItem.fileID == fileID
    );

    this.setState(prevState => ({
      ...prevState,
      uploadList: (
        existingStatus
          ? prevState.uploadList.map((uploadListItem) => (
              uploadListItem.fileID == fileID
                  ? { fileID, name, status, version: version || null }
                  : uploadListItem
            ))
          : [...prevState.uploadList, { fileID, name, status, version: version || null }]
      ),
    }));
  }

  _removeFromUploadListStatus(fileID) {
    this.setState(prevState => ({
      ...prevState,
      uploadList: prevState.uploadList.filter(
        (uploadListItem) => uploadListItem.fileID != fileID
      ),
    }));
  }
}
