import React, { Fragment } from "react";
//
import { Row, Col, Descriptions } from 'antd';
import moment from 'moment';
import config from '../../../../config/config';
import Utils from '../../../../components/Utils';
import Globals from '../../../../config/Globals';
import CourseTabWrapper from './CourseTabWrapper';
export default class CourseTabSessionInfoTab extends CourseTabWrapper {
  _handleOpenSession = () => {
    const { app, session } = this.props;
    app.urlManager.openExternalPageWithParams(config.ApplicationRoutes.session, null, session.id);
  }

  render() {
    if (!this.props.isVisible) return (<></>);
    //
    const { currentCourse, app, session, venue, city } = this.props;
    const courseSpecs = app.sharedCache().getCourseByID(currentCourse.courseID);
    console.log('*-*- session', session);
    // create a variable defined by the 3r element of a string split using :: as the delimiter
    const enrolmentID = session.enrolment?.id.split('::')[2];
    return (
      <>
        <Row>
          <Col span={24}>
            <div className="data-card">
              <h2>{Utils.capitalizeString(courseSpecs.uiTerm)} information</h2>
              <Descriptions size='small' column={1} style={{ width: '440px' }}>
              {this.props.app.isAdmin() && (
                  <>
                    <Descriptions.Item label="Session ID"><a href="#" onClick={this._handleOpenSession}>{session.id}</a></Descriptions.Item>
                    <Descriptions.Item label="Enrolment ID">{enrolmentID}</Descriptions.Item>
                  </>
                )}
                <Descriptions.Item label="Course">{courseSpecs.description}</Descriptions.Item>
                <Descriptions.Item label="Type">{Globals.getTemplateTypeIcon(session?.type, true)}</Descriptions.Item>
                {venue && (
                  <Descriptions.Item label="Location">{venue.address?.street1}{city ? `, ${city.name}` : ''}</Descriptions.Item>
                )}
                {session?.startDate && (
                  <Descriptions.Item label="Dates">
                    {session.startDate.map((s, i) => (
                      <Fragment key={i}>
                        {moment(new Date(s)).format(`${Globals.DefaultUIBirthDateFormat} hh:mm A`)} - {moment(new Date(session.endDate[i])).format('hh:mm A')}
                        <br />
                      </Fragment>
                    ))}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
