import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Table, Tooltip, Tag, Button, DatePicker, Select, Row, Col } from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import * as ExcelJS from 'exceljs';
//
import CustomComponent from '../../../components/CustomComponent';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
//
import config from '../../../config/config';
import Globals from '../../../config/Globals';
import Utils from '../../../components/Utils';
//
import '../../../assets/stylesheets/AdminSearchSessionsView.less';
import '../../../assets/stylesheets/AdminReports.less';
//
export default class AdminSessionsListReportView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false, firstLoad: true,
      sessions: [], instructors: [],
      ...this._getInitialState(),
    };
  }

  //Life cycle
  componentDidMount() {
    super.componentDidMount();
    this._loadInstructors();
    // if (this.state.firstLoad) this.fetchData();
  }

  //API
  async fetchData() {
    this.setState({ sessions: [], total: 0, isLoading: true });
    this._setSearchQueryParams();
    //request
    const { from, to, state, courseID, instructorID } = this.state.filters;
    const resp = await this.props.app.classroom.report.sessionList(from, to, (state == 'ALL' ? null : state), (courseID == 'ALL' ? null : courseID), (instructorID == 'ALL' ? null : instructorID));
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body && resp.body.sessions) {
      const sessions = resp.body.sessions;
      this.setState({ sessions, total: resp.body.sessions.length, firstLoad: true, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }

  //Actions
    //Main actions
  async handleExportXLSX() {
    this.startLoading();
    // Starts XLSX
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Sheet1');
    // Generate XLSX header
    ws.addRow([ 'ID', 'Course', 'Name', 'Type', 'Instructor', 'Venue', 'State', 'Start date', 'End date' ]);
    // Generate XLSX rows
    this.state.sessions.forEach(session => {
      ws.addRow([
        session.id,
        this.props.app.sharedCache().getCourseByID(session.courseID).displayName,
        session.name,
        Globals.getTemplateTypeLabel(session.type),
        session.instructorName.join(', '),
        session.venueName,
        Globals.getTextBySessionState(session.state),
        session.startDate.map(t => (Utils.getDateOnUIFormatByTimestamp(t))).join(', '),
        session.endDate.map(t => (Utils.getDateOnUIFormatByTimestamp(t))).join(', ')
      ]);
    });
    const buffer = await wb.xlsx.writeBuffer();
    Utils.downloadArrayBuffer(buffer, `SessionList`, 'xlsx');
    this.stopLoading();
  }
  handleSearch() {
    if (this.state.filters.from || this.state.filters.from) {
      this.setState({ sortedInfo: null }, () => {
        this.fetchData();
      });
    }
  }
    //Table
  handleFilterChange(pagination, filters, sortedInfo) { this.setState({ sortedInfo }); }
  onRowSelection(val) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.sessionsHome, {}, val.id); } // TODO: Validate this behaviour
    //Custom filter
  handleChangeFilter = (type) => (value) => {
    // validating by type so we can set multiple filter types with only one state change
    // and encapsulate filters change logic in only one method
    // this is useful today only for registered date range
    const filter = !Array.isArray(value) && typeof value == 'object' ? value : { [type]: value };
    this.setState(prevState => ({
      filters: { ...prevState.filters, ...filter },
    }));
  };
  handleRangeDateChange(date) {
    let [from, to] = (date || []);
    from = (from ? from.format('YYYY-MM-DD') : null);
    to = (to ? to.format('YYYY-MM-DD') : null);

    this.setState({ momentRange: date }); // used to populate when state is cached
    this.handleChangeFilter(null)({ from, to });
  }
  handleSession(sessionID, tab = 'main') { 
    this.props.app.urlManager.pushPage(
      config.ApplicationRoutes.session, 
      { [Globals.URL_Path_TabID]: tab }, 
      sessionID
    ); 
  }
  //UI
  render() {
    const props = { rowKey: 'id', loading: this.state.isLoading, onChange: this.handleFilterChange, scroll: { x: true },
                    locale: {emptyText: (this.state.firstLoad ? 'Search sessions' : 'No sessions found!')},
                    pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] }
                  };
    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
            <PageHeader className="pageHeader" title="Sessions List" />
            <Layout.Content>
              {this._renderFilters()}
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="primary" icon={<DownloadOutlined />} onClick={this.handleExportXLSX}
                  disabled={this.state.sessions.length < 1} style={{ margin: '15px 0' }}> Export to xlsx </Button>
              </div>
              <Table className="adminSearchSessionsTable" onRow={this._onRow} columns={this._getTableColumns()} dataSource={this.state.sessions} {...props}/>
              <Row className="sessionSearchTotalRow" type="flex" align="center">
                <Tag className="sessionSearchTotalLabel" icon={<SearchOutlined/>}>{this.state.total} sessions found</Tag>
              </Row>
            </Layout.Content>
        </Layout.Content>
      </>
    );
  }

  /* UI privates renders */
  _renderFilters() {
    const instructors = this.state.instructors || [];
    return (
      <div className="sessionsFilters">
        <Row type='flex'>
          <Col className="filterItem">
            <strong>Course:</strong>
            <Select style={{ minWidth: 200 }} value={this.state.filters.courseID} size="large" onChange={this.handleChangeFilter('courseID')}>
              <Select.Option value="ALL">Any</Select.Option>
              {this.props.app.sharedCache().getAllUniqueCourses().map(course => (
                <Select.Option key={course.id} value={course.id}>{course.displayName}</Select.Option>
              ))}
            </Select>
          </Col>
          <Col className="filterItem">
            <strong>Instructor:</strong>
            <Select style={{ minWidth: 200 }} value={this.state.filters.instructorID} size="large"
              onChange={this.handleChangeFilter('instructorID')}>
              <Select.Option value="ALL">Any</Select.Option>
              {instructors.map(instructor => (
                <Select.Option key={instructor.id} value={instructor.id}>{instructor.firstName} {instructor.lastName}</Select.Option>
              ))}
            </Select>
          </Col>
          <Col className="filterItem">
            <strong>State:</strong>
            <Select style={{ minWidth: 200 }} value={this.state.filters.state} size="large"
              onChange={this.handleChangeFilter('state')}>
              <Select.Option value="ALL">Any</Select.Option>
              {Object.keys(Globals.Session_State).map(state => (
                <Select.Option key={state} value={state}>{Globals.getTextBySessionState(state)}</Select.Option>
              ))}
            </Select>
          </Col>
          <Col className="filterItem">
            <strong>Date Range:</strong>
            <DatePicker.RangePicker defaultValue={this.state.momentRange} size="large" onChange={this.handleRangeDateChange} />
          </Col>
          <Col>
            <Button type="primary" size="large" style={{ marginLeft: 4 }}
              onClick={this.handleSearch.bind(this)}>Filter</Button>
          </Col>
        </Row>
      </div>
    );
  }

  /* UI Helpers */
  _getTableColumns() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Course', key: 'courseID', width: '10%',
        render: session => this.props.app.sharedCache().getCourseByID(session.courseID).displayName,
        sorter: (a, b) => this.props.app.sharedCache().getCourseByID(a.courseID).displayName.localeCompare(this.props.app.sharedCache().getCourseByID(b.courseID).displayName),
        sortOrder: sortedInfo.columnKey === 'courseID' && sortedInfo.order,
      },
      {
        title: 'Name', key: 'name', width: '15%',
        render: session => session.name,
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      },
      {
        title: 'Type', key: 'type', width: '10%',
        render: session => Globals.getTemplateTypeLabel(session.type),
        sorter: (a, b) => Globals.getTemplateTypeLabel(a.type).localeCompare(Globals.getTemplateTypeLabel(b.type)),
        sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order,
      },
      {
        title: 'Instructor', key: 'instructorName', width: '15%',
        render: session => session.instructorName?.join(', '),
        sorter: (a, b) => a.instructorName?.join(', ').localeCompare(b.instructorName?.join(', ')),
        sortOrder: sortedInfo.columnKey === 'instructorName' && sortedInfo.order,
      },
      {
        title: 'Venue', key: 'venueName', width: '15%',
        render: session => session.venueName,
        sorter: (a, b) => a.venueName.localeCompare(b.venueName),
        sortOrder: sortedInfo.columnKey === 'venueName' && sortedInfo.order,
      },
      {
        title: 'State', key: 'state', width: '5%',
        render: session => Globals.getTextBySessionState(session.state),
        sorter: (a, b) => Globals.getTextBySessionState(a.state).localeCompare(Globals.getTextBySessionState(b.state)),
        sortOrder: sortedInfo.columnKey === 'state' && sortedInfo.order,
      },
      {
        title: 'Start Date', key: 'startDate', width: '15%',
        render: session => session.startDate.map(t => (Utils.getDateOnUIFormatByTimestamp(t))).join(', '),
        sorter: (a, b) => a.startDate[0] - b.startDate[0],
        sortOrder: sortedInfo.columnKey === 'startDate' && sortedInfo.order,
      },
      {
        title: 'End Date', key: 'endDate', width: '15%',
        render: session => session.endDate.map(t => (Utils.getDateOnUIFormatByTimestamp(t))).join(', '),
        sorter: (a, b) => a.endDate[0] - b.endDate[0],
        sortOrder: sortedInfo.columnKey === 'endDate' && sortedInfo.order,
      },
      {
        title: 'Actions', width: '5%', key: 'Actions',
         render: session => (
          <span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title="Session detail">
              <Button
                variant="none"
                icon={<SearchOutlined />}
                shape="circle"
                onClick={() => this.handleSession(session.id)}
              />
            </Tooltip>
          </span>
        ),
      },
    ];
    return columns;
  }
  _onRow(record) {
    return {
      onClick: (e) => {
        const elementsToPreventClick = ['svg', 'path', 'button'];
        if (elementsToPreventClick.includes(e.target.tagName.toLowerCase())) return;
        this.onRowSelection(record);
      }, // click row
      onDoubleClick: () => {
        this.onRowSelection(record);
      }, // double click row
    };
  };

  //Filters and URL support
  _getInitialState() {
    let pState = {};
    try { pState = JSON.parse(Buffer.from(this.props.app.idm.urlmanager.getParam(Globals.URL_Path_Filters), 'base64').toString('ascii') || '{}'); } catch (e) {}
    const from = pState?.filters?.from || null;
    const to = pState?.filters?.to || null;
    const courseID = pState?.filters?.courseID || 'ALL';
    const instructorID = pState?.filters?.instructorID || 'ALL';
    const state = pState?.filters?.state || 'ALL';

    const momentRange = [];
    if (from) { momentRange.push(moment(new Date(from))); }
    if (to) { momentRange.push(moment(new Date(to))); }

    return {
      momentRange,
      filters: {
        from: from || null,
        to: to || null,
        courseID,
        instructorID,
        state,
      },
      sortedInfo: null,
      total: 0
    };
  }
  _setSearchQueryParams() {
    const cleanState = this.state;
    delete cleanState.sessions;
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_Filters, Buffer.from(JSON.stringify(cleanState)).toString('base64'));
  }

  // Utils
  _getCertificationName(certID) {
    if (this.props.app.sharedCache().isMonoCertification()) return null;
    return `(${this.props.app.sharedCache().getCertificationByID(certID).description})`;
  }
  async _loadInstructors() {
    this.startLoading();
    const instructors = await this.props.app.sharedCache().getInstructors();
    this.setState({ instructors, isLoading: false });
  }
}
