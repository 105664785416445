import React from 'react';
import autoBind from 'react-autobind';
//Components
import { Form, Input, Row, Col, Popover, Typography } from 'antd';
//
import WhiteBox from '../WhiteBox';
import Utils from '../../../components/Utils';
//
export default class CommonInstructorProfileForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Public
  resetFields() { this.form.resetFields(); }
  setFieldsValue(data) { this.form.setFieldsValue({ ...data }); }
  async validateFields() {
    const formData = await this.form.validateFields();
    if (!formData) return false;
    return formData;
  }

  //UI
  render() {
    return (
      <WhiteBox>
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row>
            <Col span={12}>
              <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: 'This field is required!' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={11} offset={1}>
              <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: 'This field is required!' }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {this._wrapIntoEmailPopover(
                <Form.Item name="email" label="Email" rules={[
                  { required: true, message: 'Please, type your email!' },
                  { min: 2, max: 255, message: 'Email name must be between 2 and 255 characters' },
                  { type: 'email', message: 'Invalid email format' }
                ]}
                >
                  <Input />
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      </WhiteBox>
    );
  }

  /* helpers */ 
  _wrapIntoEmailPopover(toWrap) {
    return (<Popover className='emailChangeTooltip' getPopupContainer={triggerNode => triggerNode.parentNode}
      content={
        <div style={{ marginLeft: '16px', padding: '0px 10px 0px 10px', width: '400px' }}>
          <Typography.Title level={4}>Important notes for email change:</Typography.Title>
          <ul>
            <li>If wrong email is typed, email change can lock the user out of the account.</li>
            <li>Users will <b>not</b> be asked to confirm the new email.</li>
            <li>Change takes a minute to take effect on the whole system.</li>
            <li>In order to take effect <u>user must logout</u> after changing the email.</li>
          </ul>
        </div>
      }
    >{toWrap}</Popover>);
  }
}