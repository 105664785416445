import React from 'react';
import autoBind from 'react-autobind';
import { message, Row, Col, Button, Divider, Popconfirm, Alert, Tooltip, Popover, Typography } from 'antd';
import { KeyOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import '../../../../assets/stylesheets/ApplicationSubmissionStep.less';

import Globals from '../../../../config/Globals';
import Utils from '../../../../components/Utils';

import CommonLoadingView from '../../../commonComponents/CommonLoadingView';
import CustomComponent from '../../../../components/CustomComponent';
import CommonLicensePurchaseModal from '../../../commonComponents/Modals/CommonLicensePurchaseModal';
import CommonApplicationLicenseRedeemModal from '../../../commonComponents/Modals/CommonApplicationLicenseRedeemModal';

import ApplicationSectionsSubComponent from './ApplicationTabSubComponents/ApplicationSectionsSubComponent';
import ApplicationItemUploadModal from './ApplicationTabSubComponents/ApplicationItemUploadModal';
import ApplicationUtils from '../../../../components/UtilsApplication';

export default class ApplicationSubmissionView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.sections = [];

    this.state = {
      isLoading: false,
      allowSubmit: false,
      foreignItems: [],
      optionalTiles: [],
      isPurchaseModalVisible: false.length,
      pendingRequiredElectiveCount: 0,
      missingRequirements: [],
      missingCustomSectionsItems: [],
    };
  }

  async componentDidMount() {
    this._validateApplication();
  }

  startLoading() {
    this.setState({ isLoading: true });
  }

  stopLoading() {
    this.setState({ isLoading: false });
  }

  // Actions
  handleUpload(item) {
    const internalProviders = item.acceptedProviders.filter(
      (provider) => provider.type == Globals.Providers_Types.INTERNAL
    );
    const launchImmediately = internalProviders.length == 1 && item.acceptedProviders.length == 1;

    if (launchImmediately) {
      this.props.app.urlManager.openExternalPage(item.acceptedProviders[0].launchLink);
    } else {
      this.uploadModal.open(item);
    }
  }

  async handleUpdate() {
    await this.props.onUpdate();
    this._validateApplication();
  }

  handleEditTile(item, currentProvider, currentApplicationItem) {
    this.uploadModal.open(item, currentProvider, currentApplicationItem);
  }

  handleResubmit(item, currentProvider, currentApplicationItem) {
    this.uploadModal.open(item);
  }

  handleDelete(currentApplicationItem) {
    this._delete(
      this.props.user.id,
      this.props.certificationProcess.id,
      this.props.applicationType,
      currentApplicationItem.sourceID
    );
  }

  handleAddToBlacklist(currentApplicationItem) {
    this._addToBlacklist(
      this.props.user.id,
      this.props.certificationProcess.id,
      this.props.applicationType,
      currentApplicationItem.sourceID
    );
  }

  handlePreApproval() {
    this._submitPreApproval(this.props.user.id, this.props.certificationProcess.id, this.props.applicationType);
  }

  handleSubmitApplication() {
    if (!this.state.allowSubmit) return;
    const applicationProcess = this.props.applicationProcess;
    if (applicationProcess.licenseID) {
      this._submitApplication();
      return;
    }
    this._openPurchaseLicenseModal();
  }
  //Redeem modal
  handleRedeemApplication() {
    if (!this.state.allowSubmit) return;
    const applicationProcess = this.props.applicationProcess;
    if (applicationProcess.licenseID) {
      this._submitApplication();
      return;
    }
    this._openRedeemModal();
  }
  handleActivateApplication() {
    this._openRedeemModal();
  }
  handleChangeRedeemModal(isCancel) {
    if (!isCancel) this.props.onUpdate();
  }

  //UI
  render() {
    const applicationProcess = this.props.applicationProcess;
    return (
      <>
        <CommonLoadingView isLoading={this.state.isLoading} usePortal />
        {this._renderModals()}
        {this._renderPreApprovalAlert()}
        <ApplicationSectionsSubComponent
          optionalTiles={this.state.optionalTiles}
          sections={this.sections}
          certificationProcess={this.props.certificationProcess}
          certificationSpecs={this.props.certificationSpecs}
          applicationProcess={applicationProcess}
          foreignItems={this.props.foreignItems}
          actions={{
            onUpload: this.handleUpload,
            onResubmit: this.handleResubmit,
            onDownload: this.props.onDownloadItem,
            onDownloadApplication: this.props.onDownloadApplication,
            onPreview: this.props.onPreviewItem,
            onDelete: this.handleDelete,
            onEdit: this.handleEditTile,
            onDeleteForeignItem: this.handleAddToBlacklist,
          }}
        />
        {this._renderFooterSection()}
      </>
    );
  }

  // Private UI
  _renderPreApprovalAlert() {
    const applicationProcess = this.props.applicationProcess;
    const isPreApproval = applicationProcess.state == Globals.ApplicationState.PRE_APPROVAL;
    if (isPreApproval) {
      return (
        <Row style={{ marginBottom: 24 }}>
          <Col span={24}>
            <Alert type="warning" message="This application has been submitted for pre-approval." showIcon />
          </Col>
        </Row>
      );
    }
    return <></>;
  }
  _renderModals() {
    const applicationProcess = this.props.applicationProcess;
    return (
      <>
        <CommonLicensePurchaseModal
          {...Utils.propagateRef(this, 'purchaseModal')}
          user={this.props.user}
          isVisible={this.state.isPurchaseModalVisible}
          application={applicationProcess}
          app={this.props.app}
          onChange={this._openPurchaseLicenseModal}
          autoRedeem
          fixedQuantity
          onRequiresAttention={(modal) => {
            this.purchaseModal = modal;
            this._openPurchaseLicenseModal();
          }}
        />
        <CommonApplicationLicenseRedeemModal
          {...Utils.propagateRef(this, 'redeemModal')}
          app={this.props.app}
          isActivation={!!this.props.certificationProcess.waivedBy}
          application={applicationProcess}
          onChange={this.handleChangeRedeemModal}
        />
        <ApplicationItemUploadModal
          {...Utils.propagateRef(this, 'uploadModal')}
          certificationSpecs={this.props.certificationSpecs}
          onUpload={this._uploadFile}
          onUpdate={this.handleUpdate}
          user={this.props.user}
          certificationProcess={this.props.certificationProcess}
          app={this.props.app}
          applicationType={this.props.applicationType}
        />
      </>
    );
  }
  _renderFooterSection() {
    const applicationProcess = this.props.applicationProcess;
    const allowPreApplication = this.props.certificationSpecs.application.allowPreApplication;
    const isPreApproval = applicationProcess.state == Globals.ApplicationState.PRE_APPROVAL;
    const applicationSpecs =
      this.props.applicationType == Globals.ApplicationTypes.NEW
        ? this.props.certificationSpecs.application
        : this.props.certificationSpecs.renewal.application;
    const { termsLink } = applicationSpecs;
    let applicationMessage =
      'Once all the requirements for your application are met, the Submit button will become available to you so you can request approval of your application.';
    if (allowPreApplication) {
      applicationMessage +=
        ' If you are unsure as to whether a requirement is met or otherwise would like to ask for a review of the requirements you already met, you can use the Submit Pre-Approval button for that. When you request a pre-approval, the administrator will be notified and will review your process.';
    }

    return (
      <>
        <Divider />

        <Row type="flex" justify="space-between">
          <Col span={24} style={{ marginBottom: 24 }}>
            <Alert type="info" showIcon message={applicationMessage} />
          </Col>
          <Col>
            {termsLink && (
              <span style={{ fontSize: 18 }}>
                <InfoCircleOutlined style={{ marginRight: 8 }} />
                Click here to read{' '}
                <strong>
                  <a href={termsLink} target="_blank" rel="noopener">
                    our terms.
                  </a>
                </strong>
              </span>
            )}
          </Col>
          <Col>
            {allowPreApplication &&
              this._conditionalPopconfirmWrapper(termsLink, {
                title: (
                  <span>
                    This action will create a request for your application to be reviewed. <br />
                    You will still be able to make changes to your application. Proceed?
                  </span>
                ),
                onConfirm: this.handlePreApproval,
                okText: 'Yes',
                cancelText: 'No',
                disabled: isPreApproval,
              })((onConfirm) =>
                this._conditionalTooltipWrapper(
                  isPreApproval,
                  'This application is already in pre-approval.'
                )(
                  <Button type="primary" disabled={isPreApproval} onClick={onConfirm}>
                    Submit to pre-approval
                  </Button>
                )
              )}{' '}
            {this._conditionalTooltipWrapper(
              isPreApproval || !this.state.allowSubmit,
              isPreApproval
                ? 'This application is already in pre-approval.'
                : !this.state.allowSubmit
                ? this._getSubmissionDisabledReasonMessage()
                : ''
            )(
              !!this.props.certificationProcess.waivedBy && !applicationProcess.licenseID ? (
                <Button
                  type="primary"
                  disabled={isPreApproval || !this.state.allowSubmit}
                  onClick={this.handleActivateApplication}
                >
                  {' '}
                  Activate application{' '}
                </Button>
              ) : (
                <>
                  <Button
                    type="primary"
                    disabled={isPreApproval || !this.state.allowSubmit}
                    onClick={this.handleSubmitApplication}
                  >
                    {' '}
                    Submit application{' '}
                  </Button>
                  <Popover
                    placement="left"
                    content={
                      <div style={{ width: 300 }}>
                        <Typography.Text>
                          If you have received a <strong>license key</strong>, please click here to redeem it.
                        </Typography.Text>
                        <br></br>
                        <Row type="flex" justify="end">
                          {' '}
                          <Col>
                            <Button
                              type="primary"
                              disabled={isPreApproval || !this.state.allowSubmit}
                              onClick={this.handleRedeemApplication}
                            >
                              {' '}
                              Redeem license{' '}
                            </Button>
                          </Col>{' '}
                        </Row>
                      </div>
                    }
                  >
                    <KeyOutlined style={{ fontSize: 20, marginLeft: 8 }} />
                  </Popover>
                </>
              )
            )}
          </Col>
        </Row>
      </>
    );
  }
  _conditionalTooltipWrapper(condition, title) {
    return (component) => {
      if (condition) {
        return <Tooltip title={title}>{component}</Tooltip>;
      } else {
        return component;
      }
    };
  }
  _conditionalPopconfirmWrapper(condition, popconfirmProps) {
    return (fn) => {
      if (condition) {
        return <Popconfirm {...popconfirmProps}>{fn(() => {})}</Popconfirm>;
      } else {
        return fn(popconfirmProps.onConfirm);
      }
    };
  }

  // Private methods
  _getSubmissionDisabledReasonMessage() {
    const { missingRequirements, missingCustomSectionsItems, pendingRequiredElectiveCount } = this.state;

    const applicationProcess = this.props.applicationProcess;

    if (applicationProcess.state == Globals.ApplicationState.WAITING_APPROVAL) {
      return 'Application is already submitted.';
    }

    let message = 'Application is not available because is missing:';
    const reasons = [];

    if (missingRequirements.length > 0) {
      reasons.push(`${missingRequirements.length} requirement${missingRequirements.length > 1 ? 's' : ''}`);
    }

    if (pendingRequiredElectiveCount > 0) {
      reasons.push(
        `${pendingRequiredElectiveCount} elective requirement${pendingRequiredElectiveCount > 1 ? 's' : ''}`
      );
    }

    if (missingCustomSectionsItems.length > 0) {
      reasons.push(
        `${missingCustomSectionsItems.length} requirement${
          missingCustomSectionsItems.length > 1 ? 's' : ''
        } from latest sections`
      );
    }

    return `${message} ${reasons.join(', ')}.`;
  }
  _getApplicationItemsStatuses(applicationSpecs) {
    const foreignItems = this.props.foreignItems;
    const applicationProcess = this.props.applicationProcess;

    return ApplicationUtils.getApplicationItemsStatuses(applicationSpecs, foreignItems, applicationProcess);
  }
  _validateApplication() {
    const applicationProcess = this.props.applicationProcess;
    const applicationSpecs =
      this.props.applicationType == Globals.ApplicationTypes.NEW
        ? this.props.certificationSpecs.application
        : this.props.certificationSpecs.renewal.application;
    //
    const applicationItemsStatuses = this._getApplicationItemsStatuses(applicationSpecs);

    const { missingRequirements, missingCustomSectionsItems, pendingRequiredElectiveCount } =
      ApplicationUtils.validateApplication(
        this.props.certificationSpecs,
        applicationItemsStatuses,
        this.props.applicationType
      );

    this.sections = ApplicationUtils.getSections(applicationSpecs, {
      requirementsSection: {
        progress: {
          requiredCount: applicationSpecs.requirementsSection?.requirements?.length,
          missingCount: missingRequirements.length,
        },
      },
      electiveRequirementsSection: {
        progress: {
          requiredCount: applicationSpecs.electiveRequirementsSection?.requiredCount,
          missingCount: pendingRequiredElectiveCount,
        },
      },
    });

    // Allow submit only if application state is "PENDING",
    // if there's no missing requirements, missing items from custom sections
    // and if there are no pending elective items to be filled
    const allowSubmit =
      [Globals.ApplicationState.PENDING, Globals.ApplicationState.REJECTED].includes(applicationProcess.state) &&
      missingRequirements.length == 0 &&
      missingCustomSectionsItems.length == 0 &&
      pendingRequiredElectiveCount <= 0;

    const optionalTiles = applicationItemsStatuses.optionalItems.map((item) => ({
      id: item.id,
      ...(item.skippedBy ? { message: `This item is optional because you submitted ${item.skippedBy}.` } : {}),
    }));

    this.setState({
      allowSubmit,
      missingRequirements,
      missingCustomSectionsItems,
      pendingRequiredElectiveCount,
      optionalTiles,
    });
  }
  _openPurchaseLicenseModal() {
    if (!this.state.isPurchaseModalVisible) {
      //retrieve product ID for each type of application
      const productID =
        this.props.applicationType == Globals.ApplicationTypes.NEW
          ? this.props.certificationSpecs.application.productID
          : this.props.certificationSpecs.renewal.productID;
      let overrideValue = null;
      //Check for price overwrite if on renewal
      if (this.props.applicationType == Globals.ApplicationTypes.RENEWAL) {
        let renewalRules = this.props.certificationSpecs.renewal.rules;
        //Has rules?
        if (renewalRules && renewalRules.length > 0) {
          renewalRules = renewalRules.sort((a, b) => a.untilDaysAfterExpiration - b.untilDaysAfterExpiration);
          //get number of days after the expiration and select the renewal rule
          const diffDays = moment().diff(moment(new Date(this.props.certificationProcess.expiryDate)), 'days');
          const selectedRule =
            renewalRules.find((rule) => rule.untilDaysAfterExpiration >= diffDays) ||
            renewalRules[renewalRules.length - 1];
          //For each requirement that needs to be invalidated, invalidate it
          if (selectedRule && selectedRule.priceOverride != undefined) overrideValue = selectedRule.priceOverride;
        }
      }
      //load modal with info
      this.purchaseModal.loadModalInfo(productID, overrideValue);
    }
    this.setState(
      (prevState) => ({ isPurchaseModalVisible: !prevState.isPurchaseModalVisible }),
      () => {
        if (!this.state.isPurchaseModalVisible) this.props.onUpdate();
      }
    );
  }
  _openRedeemModal() {
    this.redeemModal.show();
  }

  // API Calls
  async _delete(userID, certProcID, applicationType, sourceID) {
    this.startLoading();

    const resp = await this.props.app.api.applicationItem.delete(userID, certProcID, applicationType, sourceID);

    if (resp.statusCode == 200) {
      message.success('Application item successfully deleted.');
      this.handleUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.stopLoading();
  }

  async _addToBlacklist(userID, certProcID, applicationType, sourceID) {
    this.startLoading();

    const resp = await this.props.app.api.applicationItem.addToBlacklist(userID, certProcID, applicationType, sourceID);

    if (resp.statusCode == 200) {
      this.handleUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.stopLoading();
  }

  async _submitPreApproval(userID, certProcID, applicationType) {
    this.startLoading();

    const resp = await this.props.app.api.application.submitPreApproval(userID, certProcID, applicationType);

    if (resp.statusCode == 200) {
      message.success('Pre application successfully submitted.');
      this.handleUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.stopLoading();
  }

  async _submitApplication() {
    // Start loading
    this.startLoading();

    // Make request
    const resp = await this.props.app.api.application.submit(
      this.props.user.id,
      this.props.certificationProcess.id,
      this.props.applicationType
    );
    if (!this._isMounted) return;

    // Response
    if (resp.statusCode == 200 && resp.body) {
      if (this.props.app.isAdmin()) {
        await this.props.app.reloadPendingApplications();
      }
      message.success('Application Submitted!');
      this.handleUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.stopLoading();
  }
}
