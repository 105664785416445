import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Table, Row, Col, Tooltip, Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
import CommonSessionInstructorModal from '../Modals/CommonSessionInstructorModal';
//props are: instructors, session, app, onDelete, onSelect
export default class CommonSessionInstructorsForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: null };
  }

  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'Name', key: 'name', sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        sorter: (a, b) => {
          const instructorA = this.props.instructors.find((i) => i.id == a.userID);
          const instructorB = this.props.instructors.find((i) => i.id == b.userID);
          return (`${instructorA.firstName} ${instructorA.lastName}`).localeCompare((`${instructorB.firstName} ${instructorB.lastName}`));
        },
        render: (props) => {
          const instructorA = this.props.instructors.find((i) => i.id == props.userID);
          return `${instructorA.firstName} ${instructorA.lastName}`;
        }
      },
      {
        title: 'Type', key: 'instructorType',
        render: props => Utils.capitalizeString(props.instructorType.toLowerCase()),
        sorter: (a, b) => a.instructorType.localeCompare(b.instructorType),
        sortOrder: sortedInfo.columnKey === 'instructorType' && sortedInfo.order
      },
      {
        title: 'Added On', key: 'createdOn', width: '15%',
        render: props => props.createdOn ? Utils.getDateOnUIFormatByTimestamp(props.createdOn) : 'N/A',
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order
      },
      {
        title: 'Actions', width: 'auto', key: 'Actions', width: '15%',
        render: props => {
          return (<span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title='Delete'>
              <Popconfirm placement="top" title={`Are you sure that you want to delete this instructor from this session?`}
                onConfirm={this.props.onDelete.bind(this, props.userID)} okText="Yes" cancelText="No" >
                <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle" />
              </Popconfirm>
            </Tooltip>
          </span>);
        }
      },
    ];

    const props = {
      rowKey: 'userID',
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No instructors assigned to this session!' },
      pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] },
    };

    return (
      <>
        <CommonSessionInstructorModal
          app={this.props.app}
          instructors={this.props.instructors}
          onSelect={this.props.onSelect}
          session={this.props.session}
          sessionInstructors={this.props.session?.instructors}
          {...Utils.propagateRef(this, 'sessionInstructorModal')}
        />

        <Row type='flex' justify='end'>
          <Col>
          <Button
            type="primary"
            style={{ marginBottom: 20 }}
            onClick={() => this.sessionInstructorModal.open()}
          >
            Add Instructor
          </Button>
            {/* <CommonSessionInstructorPopover app={this.props.app} onSelect={this.props.onSelect} instructors={this.props.instructors} sessionInstructors={this.props.session?.instructors}>
              <Button style={{ marginBottom: 20 }} type="primary"> Add Instructor </Button>
            </CommonSessionInstructorPopover> */}
          </Col>
        </Row>
        <Table className="commonInstructorCoursesTable" columns={columns} dataSource={this.props.session?.instructors || []} {...props} />
      </>
    );
  }
}
