import React from 'react';
import autoBind from 'react-autobind';
import { Input, Col, Row, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
//
import '../../assets/stylesheets/CommonSearchBar.less';
//
export default class CommonSearchBar extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { term: '' };
  }
  componentDidMount() {
    if (this.props.defaultValue) {
      this.setState({ term: this.props.defaultValue });
    }
  }
  //Actions
  handleChange(val) {
    this.setState({ term: val.target.value });

    if (this.props.onChange) {
      this.props.onChange(val.target.value);
    }
  }
  handleSearch() {
    this.props.handleSearch(this.state.term);
  }
  //UI
  render() {
    const disabled = (!this.props.alwaysEnabled && !(this.state.term && this.state.term.length > 0));

    return (
      <>
      <Row justify="start" align="middle" type='flex' className='usersSearchBar'>
        <Col span={2}>
          <SearchOutlined className="usersSearchBarIcon" />
        </Col><Col span={12}>
          <Input className="usersSearchBarInput" defaultValue={this.props.defaultValue || ''} placeholder="Search.."
                 onChange={this.handleChange} onPressEnter={this.handleSearch}/>
        </Col>
      </Row>
      <Row justify="end" align="middle" type='flex' className='usersSearchBarButtonRow'>
        <Col className='usersSearchBarButtonColumn'>
          <Button className='usersSearchBarButton' type='primary' disabled={disabled || this.props.disabled}
                  onClick={this.handleSearch}>Search</Button>
        </Col>
      </Row>
      </>
    );
  }
}
