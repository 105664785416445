import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Table, Button, Tooltip, Popconfirm, message } from 'antd';
import { EditOutlined, DeleteOutlined, FileDoneOutlined, UserOutlined } from '@ant-design/icons';
//
import CustomComponent from '../../components/CustomComponent';
//
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CommonSearchBar from '../commonComponents/CommonSearchBar';
//
import config from '../../config/config';
import Globals from '../../config/Globals';
//
import '../../assets/stylesheets/AdminSearchUsersView.less';
import Utils from '../../components/Utils';
//
export default class AdminOrganizationsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false,
      firstLoad: true,
      orgs: [], orgsTotal: 0,
      fullList: null,
      ...this._getInitialState(),
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    if (this.state.searchTerm) {
      this.fetchData();
    } else {
      this.fetchAll();
    }
  }

  //API
  async fetchAll() {
    this.startLoading();
    //request
    const resp = await this.props.app.organization.organizationApp.searchAllOrganizationsApps(this._getSearchFilter());
    if (resp.statusCode == 200 && resp.body && resp.body.orgs) {
      this.loadResponse(resp, true);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
    this._reloadURLParams();
  }

  async fetchData() {
    if (!this.state.searchTerm) return;
    this.setState({ orgs: [], isLoading: true });
    //request
    const resp = await this.props.app.organization.organizationApp.searchOrganizationsAppsByTerm(
      this.state.searchTerm, this._getSearchFilter()
    );
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body && resp.body.orgs) {
      this.loadResponse(resp);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
    this._reloadURLParams();
  }

  loadResponse(resp, setAsFullList = false) {
    const orgs = resp.body.orgs;
    this.setState({ orgs, orgsTotal: resp.body.total, ...(setAsFullList ? { fullList: orgs } : {}), firstLoad: false, isLoading: false });
  }

  handleSearchTermChange(value) {
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_SearchTerm, value);
    if (!value) {
      this.setState({ searchTerm: null, currentPage: 1, sortedInfo: null }, () => {
        this.fetchAll();
      });
    }
  }

  //Actions
  handleRegisterOrg() { this.props.app.urlManager.pushPage(config.ApplicationRoutes.registerOrg); }
  handleSearch(term) {
    this.setState({ searchTerm: term, currentPage: 1, sortedInfo: null }, () => {
      this.fetchData();
    });
  }
    //Table org actions
  handleEditPage(record, tab = 'details') {
    this.props.app.urlManager.pushPage(
      config.ApplicationRoutes.editOrg,
      { [Globals.URL_Path_TabID]: tab },
      record.orgID,
    );
  }
  handleViewLicenses(record) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.commonLicenses, { type: 'organization' }, record.orgID);
  }
  handleDeleteOrg(record) { this._deleteOrg(record.orgID); }
    //Table other actions
  handlePagination(currentPage) {
    this.setState({ currentPage }, () => {
      if (this.state.searchTerm) this.fetchData();
      else this.fetchAll();
    })
  }
  handleFilterChange(pagination, filters, sortedInfo) {
    this.setState({ sortedInfo }, () => {
      if (this.state.searchTerm) this.fetchData();
      else this.fetchAll();
    })
  }

  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const orgModEmployeeMode = this.props.app.sharedCache().getTenantConfig()?.orgModEmployeeMode;
    const columns = [
      { title: 'Name', key: 'name.keyword', dataIndex: 'name',  width: '15%', sorter: true },
      {
        title: 'Students', key: 'numMembers',  width: '15%',
        render: org => org.numMembers || 0, sorter: true,
      },
      {
        title: 'Updated on', key: 'updatedOn', width: '15%',
        render: props => props.updatedOn ? Utils.getDateOnUIFormatByTimestamp(props.updatedOn) : 'N/A',
        sorter: true,
      },
      {
        title: 'Actions', width: '10%', key: 'Actions',
         render: props => (
          <span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title="Edit">
              <Button
                variant="none"
                icon={<EditOutlined />}
                shape="circle"
                onClick={() => this.handleEditPage(props, 'details')}
              />
            </Tooltip>
            {' '}
            <Tooltip placement="bottomLeft" title="Licenses">
              <Button
                variant="none"
                icon={<FileDoneOutlined />}
                shape="circle"
                onClick={this.handleViewLicenses.bind(this, props)}
              />
            </Tooltip>
            {' '}
            <Tooltip placement="bottomLeft" title={orgModEmployeeMode ? "Employees" : "Students"}>
              <Button
                variant="none"
                icon={<UserOutlined />}
                shape="circle"
                onClick={() => this.handleEditPage(props, 'members')}
              />
            </Tooltip>
            {' '}
            {!orgModEmployeeMode && <Tooltip placement="bottomLeft" title={`Delete ${props.name}`}>
              <Popconfirm
                placement="top"
                title={`Are you sure that you want to delete org ${props.name}?`}
                onConfirm={this.handleDeleteOrg.bind(this, props)}
                okText="Yes"
                cancelText="No"
              >
              <Button
                variant="none"
                icon={<DeleteOutlined />}
                shape="circle"
              />
              </Popconfirm>
            </Tooltip>}
          </span>
        ),
       },
    ];
    const props = { rowKey: 'orgID', loading: this.state.isLoading, onChange: this.handleFilterChange,
                    locale: {emptyText: (this.state.firstLoad ? 'Search organizations' : 'No organizations found!')},
                    sortDirections: ['ascend', 'descend', 'ascend'],
                    pagination: {
                      pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: [ 'bottomCenter' ],
                      total: this.state.orgsTotal, onChange: this.handlePagination, current: this.state.currentPage,
                      showSizeChanger: false,
                    }};
    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
            <PageHeader className="pageHeader" title="Search Organizations" />
            <Layout.Content>
              <CommonSearchBar onChange={this.handleSearchTermChange}
                               defaultValue={this.state.searchTerm} handleSearch={this.handleSearch} />
              {!orgModEmployeeMode && <Button style={{ marginBottom: 20 }} type="primary" onClick={this.handleRegisterOrg}>
                Register new org
              </Button>}
              <Table className="adminSearchUsersTable" columns={columns} dataSource={this.state.orgs} {...props}/>
            </Layout.Content>
        </Layout.Content>
      </>
    );
  }

  /* private */
  //Filters and URL support
  _getInitialState() {
    const searchTerm = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_SearchTerm) || null;
    const currentPage = parseInt(this.props.app.idm.urlmanager.getParam(Globals.URL_Path_Page) || 1);
    const columnKey = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_SortField) || 'name.keyword';
    const order = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_SortOrder) || 'ascend';

    return {
      searchTerm,
      currentPage,
      sortedInfo: {
        columnKey,
        order,
      }
    };
  }

  _reloadURLParams() {
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_Page, this.state.currentPage);
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_SortField, (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.columnKey : null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_SortOrder, (this.state.sortedInfo ? this.state.sortedInfo.order : null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_SearchTerm, this.state.searchTerm);
  }
  _getSearchFilter() {
    const from = (Globals.Table_PagingItemsPerPage * (this.state.currentPage - 1));
    const sortField = (this.state.sortedInfo && this.state.sortedInfo.order && this.state.sortedInfo.columnKey ? this.state.sortedInfo.columnKey : null);
    const sortOrder = (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.order : null);
    return { from, sortField, sortOrder: (sortOrder == 'descend' ? 'desc' : 'asc') }
  }

  /* private API */
  async _deleteOrg(orgID) {
    if (!this._isMounted) return; //Important, check if is mounted

    this.startLoading();

    const resp = await this.props.app.api.organization.deleteOrganizationByID(orgID);

    if (resp.statusCode == 200) {
      message.success('Organization successfully deleted');
      this.setState(prevState => ({
        orgs: prevState.orgs.filter(org => org.orgID != orgID),
      }));
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.stopLoading();
  }

}
