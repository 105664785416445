import React from 'react';
import autoBind from 'react-autobind';
import { Typography, Select, Space, DatePicker } from 'antd';
import moment from "moment";
//
import '../../assets/stylesheets/CommonSearchBar.less';

export default class CommonActivationsSearchBar extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    const thirtyDaysAgo = moment().subtract(30, 'days');
    this.state = { products: props.app.sharedCache().products, product: null, from: thirtyDaysAgo.valueOf(), to: moment().valueOf() };
  }

  async handleSelectProduct(value) {
    const selectedProduct = this.state.products.find(product => product.id === value);
    this.setState({ product: selectedProduct }, () => {
      this.props.onProductFilter(selectedProduct); 
    });
  }

  handleDateChange = (dates) => {
    if (dates) { 
      const [from, to] = dates.map(date => moment(date).valueOf());
      this.setState({ from, to }, () => {
        this.props.onUpdateSearchObj({from, to}); 
      });
    }
  }
  
  // UI
  render() {
    return (
      <div className="commonSearchBar">
        {this._renderAdditionalFilters()}
      </div>
    );
  }

  /* sub renders */
  _renderAdditionalFilters() {
    const { from, to } = this.state;
    const defaultDate = [moment(from), moment(to)]; 
    return (
      <div className="searchFilterRow">
        <div style={{ marginBottom: '16px' }}>
          <Typography.Text strong>Date Range: </Typography.Text>
          <DatePicker.RangePicker onChange={this.handleDateChange} defaultValue={defaultDate} style={{ width: '400px' }}/>
        </div>
        <div>
          <Space>
            <Typography.Text strong>Filter by Product:</Typography.Text>
            <Select allowClear value={this.state.product?.id} onChange={this.handleSelectProduct} style={{ width: '360px' }}
              placeholder="Product">
                {this.state.products.map((product) => (
                  <Select.Option key={product.id} value={product.id}>
                    {product.description}
                  </Select.Option>
                ))}
            </Select>
          </Space>
        </div>
      </div>
    );
  }
}
