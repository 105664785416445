import React from "react";
import autoBind from 'react-autobind';
import { Form, Row, Col, Select, Input, Divider } from 'antd';
import Utils from "../../../components/Utils";
//
export default class CommonVenueForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Public
  resetFields() { this.form.resetFields(); }
  setFieldsValue(data) { 
    this.form.setFieldsValue({ ...data, street1: data?.address?.street1 || '', street2: data?.address?.street2 || '', postalCode: data?.address?.postalCode || '' });
  }
  async validateFields() {
    const formData = await this.form.validateFields();
    if (!formData) return;
    const selectedCity = (this.props.cities || []).find((c) => c.id == formData.cityID);
    return {
      ...formData, 
      street1: undefined, street2: undefined, postalCode: undefined,
      address: { street1: formData.street1, street2: formData.street2 || '', postalCode: formData.postalCode, province: selectedCity.province }
    }; 
  }

  //UI
  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Row>
          <Col span={24}>
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={14}>
            <Form.Item label="Address Street" name="street1" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input/>
            </Form.Item>
          </Col>
          <Col span={9} offset={1}>
            <Form.Item label="Address Street 2" name="street2" rules={[{ required: false, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={14}>
            <Form.Item label='City' name='cityID' rules={[{ required: true, message: `Please, select a city!` }]}>
              <Select mode='single' showSearch filterOption={(input, option) => {
                const city = this.props.cities.find((c) => option.value == c.id);
                const region = (this.props.regions || []).find((r) => r.id == city.regionID);
                return `${city.name}, ${city.province} (${region.name})`.toLowerCase().includes(input.toLowerCase());
              }}>
                {this.props.cities && this.props.cities.map((item) => {
                  const region = (this.props.regions || []).find((r) => r.id == item.regionID);
                  return <Select.Option value={item.id} key={item.id}>{`${item.name}, ${item.province} (${region.name})`}</Select.Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={9} offset={1}>
            <Form.Item label="Postal Code" name="postalCode" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Divider/>
        <Row>
          <Col span={24}>
            <Form.Item label="Comments" name="comments" rules={[{ required: false, message: 'This field is required!' }]}>
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}
