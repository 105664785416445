import React from 'react';
//
import { Tooltip, Popconfirm, message } from 'antd';
import { CloudUploadOutlined, CloseCircleFilled, DownloadOutlined, DeleteOutlined, CheckCircleFilled, PlusCircleOutlined, EyeOutlined, IssuesCloseOutlined } from '@ant-design/icons';

import '../../../../assets/stylesheets/CommonCourseTile.less';
//
import Globals from '../../../../config/Globals';
import Utils from '../../../../components/Utils';
//
import CourseTabWrapper from './CourseTabWrapper';
import CourseUploadModal from './CourseUpload/CourseUploadModal';
//props are: app, isVisible, collapsed, currentCourse, certificationProcess, user, onUpdate, canOpenUploadModal
export default class CourseTabUploadTab extends CourseTabWrapper {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  startLoading() {
    this.setState({ isLoading: true });
  }

  stopLoading() {
    this.setState({ isLoading: false });
  }

  // Actions
  handleUpload(item) {
    this.uploadModal.open(item);
  }

  async handleUpdate() {
    await this.props.onUpdate();
  }

  handleEdit(item) {
    this.uploadModal.open(item);
  }

  handleDelete(fileID) {
    this._delete(
      this.props.user.id,
      this.props.certificationProcess.id,
      this.props.currentCourse.id,
      fileID
    );
  }

  handleDownload(file) {
    this._download(
      this.props.user.id,
      this.props.certificationProcess.id,
      this.props.currentCourse.id,
      file
    );
  }

  handlePreviewItem(file) {
    this._previewItem(
      this.props.user.id,
      this.props.certificationProcess.id,
      this.props.currentCourse.id,
      file
    );
  }
  
  componentDidMount() {
   if (this.props.canOpenUploadModal) {
    this.handleUpload()
   }
    
  }
  
  render() {
    const { currentCourse } = this.props;
    const items = this.props.currentCourse?.submissionItems || [];
    const state = currentCourse?.approvalState || Globals.CourseApprovalState.PENDING;
    if (!this.props.isVisible) return (<></>);
    return (
      <>
      {this._renderModal()}
      <div className={`course-card-upload ${state}`}>
        <div className="info">
          <div className="title">
          {this._renderStatusIcon(state)}
            <strong>
              <span>Documents</span>
            </strong>
            <div className="actions">
              {!currentCourse?.approvalState && items.length > 0 && this._renderRequestReviewButton(state)}
              <button type="button" disabled={this.props.readOnly} onClick={() => this.handleEdit(items)}><PlusCircleOutlined /></button>
            </div>
          </div>
        </div>
        <div className="card-body">
          {this._renderBody(state, items)}
        </div>
      </div>
      </>
    );
  }

    // Private UI
  _renderModal() {
    return (
        <CourseUploadModal
          {...Utils.propagateRef(this, 'uploadModal')}
          onUpload={this._uploadFile}
          onUpdate={this.handleUpdate}
          app={this.props.app}
          currentCourse={this.props.currentCourse}
          certificationProcess={this.props.certificationProcess}
          user={this.props.user}
        />
      );
    }

  // Private method
  _renderStatusIcon(state) {
    if (state === Globals.CourseApprovalState.REJECTED) {
      return <CloseCircleFilled style={{ color: '#AF3947' }} />;
    }

    if (state === Globals.CourseApprovalState.APPROVED) {
      return <CheckCircleFilled style={{ color: '#39AF76' }} />;
    }

    if (state === Globals.CourseApprovalState.PENDING) {
      return (
        <div style={{ width: 22, height: 22, background: '#448EF7', borderRadius: '50%', display: 'flex', alignItems: 'center', marginRight: '2px'}}>
          <CloudUploadOutlined style={{ color: '#fff', fontSize: 14, marginLeft: 4}} />
        </div>
      );
    }
  }

  _renderBody(state, items) {
    return (
      <>
        <ul className="infos-list">
          {(items?.length > 0) && (
            <li>
              <small>Uploaded files</small>
              <ul style={{ listStyle: 'none', margin: '0', padding: '0' }}>
                <li>
                  {items.map(({ fileID, fileName }) => (
                    <div key={fileID} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 8 }}>
                      <Tooltip title={fileName}>
                        <span className="card-file-name" style={{ marginLeft: 8 }}>{fileName}</span>
                      </Tooltip>
                      <div className="course-file-actions-buttons">
                        <button className={state} type="button" onClick={() => this.handleDownload({ fileName, fileID })}><DownloadOutlined /></button>
                        {this._renderPreviewButton({ state, fileName, fileID })}
                        <Popconfirm
                          title="Are you sure to delete this item?"
                          onConfirm={() => this.handleDelete(fileID)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <button className={state} disabled={this.props.readOnly} type="button"><DeleteOutlined /></button>
                        </Popconfirm>
                      </div>
                    </div>
                  ))}
                </li>
              </ul>
            </li>
          )}
        </ul>
      </>
    );
  }

  _renderRequestReviewButton(state) {
    return(
      <Popconfirm
        title="Are you sure you want to request a review for these items? Files uploads and deletions will be disabled"
        onConfirm={() => this.props.onRequestApproval()}
        okText="Yes"
        cancelText="No"
      >
        <button className={state} disabled={this.props.readOnly} type="button"><IssuesCloseOutlined /></button>
      </Popconfirm>
    );
  }

  _renderPreviewButton(item) {
    const { state, fileName, fileID } = item;
    const { extension } = Utils.splitFilenameAndExtension(fileName);
    const disabled = !['png', 'jpeg', 'jpg', 'gif', 'pdf'].includes(extension?.toLowerCase());

    return (
      <button
        className={state}
        type="button"
        onClick={() => this.handlePreviewItem({ fileName, fileID })}
        disabled={disabled}
        style={{ opacity: disabled ? 0.5 : 1, cursor: disabled ? 'default' : 'pointer' }}
      >
        {!disabled && <EyeOutlined />}
        {disabled && (
          <Tooltip title="Preview is not available.">
            <EyeOutlined />
          </Tooltip>
        )}
      </button>
    );
  }

  // API Calls
  async _delete(userID, certProcID, courseID, fileID) {
    this.startLoading();
  
    const resp = await this.props.app.api.courseFile.deleteDocument(userID, certProcID, courseID, fileID);
  
    if (resp.statusCode == 200) {
      message.success('Course Document successfully deleted.');
      this.handleUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }  
      this.stopLoading();
  }

  async _download(userID, certProcID, courseID, file) {
    this.startLoading();
    const resp = await this.props.app.api.courseFile.downloadDocument(userID, certProcID, courseID, file.fileID);
  
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      const { filename, extension } = Utils.splitFilenameAndExtension(file.fileName);
      Utils.downloadBlob(resp.body, filename || file.fileName, extension || Utils.getFileExtensionFromMimeType(resp.body.type));
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
    }

    this.stopLoading();
  }

  async _previewItem(userID, certProcID, courseID, file) {
    this.startLoading();

    const resp = await this.props.app.api.courseFile.getSignURL(userID, certProcID, courseID, file.fileID)
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.stopLoading();
      Utils.openFileInNewTab(resp.body.fileURL, file.fileName);
    } else {
      this.stopLoading();
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
    }
  }

}