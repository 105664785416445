import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Table, Tooltip, Tag, Button, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
//
import CustomComponent from '../../../components/CustomComponent';
//
import CommonLoadingView from '../CommonLoadingView';
import CommonSearchBar from '../CommonSearchBar';
//
import Globals from '../../../config/Globals';
//
import '../../../assets/stylesheets/AdminSearchUsersView.less';
//
export default class CommonStudentSearchTable extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false, firstLoad: true,
      users: [],
      ...this._getInitialState(),
    };
  }

  //Life cycle
  componentDidMount() {
    super.componentDidMount();
    if (!this.props.loadOnSearchOnly && this.state.firstLoad) {
      this.fetchData();
    }
  }

  //API
  async fetchData() {
    this.setState({ users: [], total: 0, isLoading: true });
    this._setSearchQueryParams();
    //request
    const resp = await this.props.app.api.user.searchUsersByTerm(
      this.state.searchObj.term, this._getSearchFilter(),
    );
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body && resp.body.users) {
      const users = (this.props.app.isOrgManager()) ? resp.body.users?.map(user => user._source) : resp.body.users.map(user => user._source).filter(user => user.id != (this.props.student && this.props.student.userID)); //remove target student from replace list
      this.setState({ users, total: resp.body.total, firstLoad: true, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }

  //Actions
  //Main actions
  handleSearch(term) {
    this.setState({ searchObj: { term }, sortedInfo: null, currentPage: 1 }, () => {
      this.fetchData();
    });
  }
  //Table
  handleFilterChange(pagination, filters, sortedInfo) {
    this.setState({ sortedInfo }, () => {
      this.fetchData();
    });
  }
  handlePagination(currentPage) {
    this.setState({ currentPage }, () => {
      this.fetchData();
    })
  }
  //Table actions
  handleSelectStudent(student) {
    if (this.props.onSelect) {
      this.props.app.urlManager.clearQueryStringParam();
      this.props.onSelect(student);
    }
  }
  //UI
  render() {
    const props = {
      rowKey: 'id', loading: this.state.isLoading, onChange: this.handleFilterChange, scroll: { x: true },
      locale: { emptyText: (this.state.firstLoad ? 'Search users' : 'No users found!') },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage, showSizeChanger: false, hideOnSinglePage: true, position: ['bottomCenter'],
        total: this.state.total, onChange: this.handlePagination, current: this.state.currentPage
      }
    };
    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
          {!this.props.hideHeader && <PageHeader className="pageHeader" title="Search Users" />}
          <Layout.Content>
            <CommonSearchBar alwaysEnabled defaultValue={this.state.searchObj.term} handleSearch={this.handleSearch} />
            <Table className="adminSearchUsersTable"
              columns={this._getTableColumns()} dataSource={this.state.users} {...props}
            />
            <Row className="userSearchTotalRow" type="flex" align="center">
              <Tag className="userSearchTotalLabel" icon={<SearchOutlined />}>{this.state.total} users found</Tag>
            </Row>
          </Layout.Content>
        </Layout.Content>
      </>
    );
  }

  /* UI Helpers */
  _getTableColumns() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    console.log(this.state.users);
    const columns = [
      {
        title: 'Name', key: 'fullName.keyword', width: '50%', sorter: true,
        render: user => (<Tooltip title={user.email}> {user.fullName} </Tooltip>),
        sortOrder: sortedInfo.columnKey === 'fullName.keyword' && sortedInfo.order,
      },
      {
        title: 'Email', key: 'email', width: '40%', sorter: true,
        render: user => user.email,
        sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order,
      },
      {
        title: '', key: 'select', align: 'right',
        render: (user) => {
          return <Button type="primary" size="small" onClick={this.handleSelectStudent.bind(this, user)}>Select</Button>
        }
      }
    ].filter(item => !item.show);
    return columns;
  }
  //Filters and URL support
  _getInitialState() {
    let pState = {};
    try { pState = JSON.parse(Buffer.from(this.props.app.idm.urlmanager.getParam(Globals.URL_Path_Filters), 'base64').toString('ascii') || '{}'); } catch (e) { }
    // console.log(pState)
    const search = pState?.searchObj?.term || null;
    const currentPage = pState?.currentPage || 1;
    const columnKey = pState?.sortedInfo?.columnKey || 'createdOn';
    const order = pState?.sortedInfo?.order || 'descend';

    return {
      searchObj: { term: search || null },
      currentPage, sortedInfo: { columnKey, order },
      total: 0
    };
  }
  _setSearchQueryParams() {
    const cleanState = this.state;
    delete cleanState.users;
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_Filters, Buffer.from(JSON.stringify(cleanState)).toString('base64'));
  }
  _getSearchFilter() {
    //page
    const from = (Globals.Table_PagingItemsPerPage * (this.state.currentPage - 1));
    //org
    const organization = (this.props.app.isOrgManager()) ? this.props.app.urlManager.selectedOrg : null
    //sort
    const sortField = (this.state.sortedInfo && this.state.sortedInfo.order && this.state.sortedInfo.columnKey ? this.state.sortedInfo.columnKey : null);
    const sortOrder = (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.order : null);
    return { from, sortField, sortOrder: (sortOrder == 'descend' ? 'desc' : 'asc'), organization };
  }
}
