import React from 'react';
import { Row, Col, Button, Input, Modal, DatePicker, Popconfirm, Divider, Tooltip } from 'antd';
import { FileImageOutlined, EyeOutlined, DownloadOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import autoBind from 'react-autobind';

import CustomComponent from '../../../components/CustomComponent';

import '../../../assets/stylesheets/AdminReviewApplicationRequirementsSection.less';
import CustomCollapse from '../../commonComponents/CustomCollapse';
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';

export default class AdminReviewApplicationRequirementsSection extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    const itemsDetails = this._initializeItemsDetailsState();

    this.state = {
      itemsDetails,
      editingItem: null,
      editingCurrentApplicationItem: null,
    };
  }

  handleItemDetailChange(itemID, fieldName, value) {
    this.setState((prevState) => ({
      ...prevState,
      itemsDetails: {
        ...prevState.itemsDetails,
        [itemID]: {
          ...prevState.itemsDetails?.[itemID],
          [fieldName]: value,
        },
      },
    }));
  }

  handleApprove(isVisible, itemDetails, currentApplicationItem) {
    if (!isVisible) {
      Modal.confirm({
        title: 'Attention!',
        content: 'There are extra fields inside collapsed section and these fields will be sent with your feedback. Are you sure you want to continue?',
        cancelText: 'No, review fields',
        okText: 'Yes, approve',
        onOk: () => this.props.onApprove(itemDetails, currentApplicationItem),
        style: { width: 100 },
      });

      return;
    }

    this.props.onApprove(itemDetails, currentApplicationItem);
  }

  handleReject(isVisible, itemDetails, currentApplicationItem) {
    if (!isVisible) {
      Modal.confirm({
        title: 'Attention!',
        content: 'There are extra fields inside collapsed section and these fields will be sent with your feedback. Are you sure you want to continue?',
        cancelText: 'No, review fields',
        okText: 'Yes, reject',
        onOk: () => this.props.onReject(itemDetails, currentApplicationItem),
        style: { width: 100 },
      });

      return;
    }

    this.props.onReject(itemDetails, currentApplicationItem);
  }

  handleEditItem(itemDetailsID, itemDetails, currentApplicationItem, isApproved) {
    this.setState({
      editingItem: {
        itemDetailsID,
        itemDetails,
        currentApplicationItem,
        isApproved,
      },
    });
  }

  handleCancelEdit() {
    const { itemDetailsID, itemDetails } = this.state.editingItem;

    this.setState((prevState) => ({
      ...prevState,
      editingItem: null,
      itemsDetails: {
        ...prevState.itemsDetails,
        [itemDetailsID]: itemDetails,
      },
    }));
  }

  handleSubmitEdit() {
    const { isApproved, itemDetailsID, currentApplicationItem } = this.state.editingItem;

    if (isApproved) {
      this.handleApprove(true, this.state.itemsDetails[itemDetailsID], currentApplicationItem);
    } else {
      this.handleReject(true, this.state.itemsDetails[itemDetailsID], currentApplicationItem);
    }

    this.setState({ editingItem: null });
  }

  render() {
    const { sections, sectionsItemsStatuses } = this.props;

    return Object.entries(sections).map(([sectionKey, section]) => (
      <div className="application-requirements-wrapper" key={sectionKey}>
        <div className="application-requirements-section">
          <header>
            <h2>{section.name}</h2>
            <ReactMarkdown children={section.description} linkTarget='_blank'/>
          </header>

          {this._renderProgressBar(sectionsItemsStatuses[sectionKey], section.items)}

          <div className="body">
            {section.items.map((sectionItem) => this._renderSectionItemCollapse(sectionKey, sectionItem))}
          </div>
        </div>
      </div>
    ));
  }

  // Private methods
  _renderSectionItemCollapse(sectionKey, sectionItem) {
    const { currentApplicationItem, currentProvider, status } = this.props.getSectionItemData(sectionItem);

    const itemDetailsID = `${sectionKey}-${sectionItem.id}`;
    const itemDetails = this.state.itemsDetails[itemDetailsID];

    const mapStatusToCollapseTypes = {
      [Globals.ApplicationItemsStatus.APPROVED]: 'success',
      [Globals.ApplicationItemsStatus.REJECTED]: 'danger',
    };

    const isApproved = status == Globals.ApplicationItemsStatus.APPROVED;
    const isRejected = status == Globals.ApplicationItemsStatus.REJECTED;
    const reviewSubmissionAvailable = !(isApproved || isRejected);
    const isEditing = this.state.editingItem && (this.state.editingItem?.itemDetailsID == itemDetailsID);

    return (
      <CustomCollapse
        title={sectionItem.name}
        description={sectionItem.cardDescription}
        type={mapStatusToCollapseTypes[status] || null}
        extraActions={(isVisible) => (
          <div className="application-item-review-actions">
            <Popconfirm
              placement="top"
              title={`Are you sure you want to approve ${sectionItem.name}?`}
              onConfirm={() => this.handleApprove(isVisible, itemDetails, currentApplicationItem)}
              okText="Approve"
              cancelText="Cancel"
              disabled={isApproved}
            >
              <Button icon={<CheckCircleOutlined />} className={`approve ${isApproved ? 'is-active' : ''} ${isRejected ? 'is-available' : ''}`}>
                {isApproved ? 'Approved' : (isRejected ? 'Change to approved' : 'Approve')}
              </Button>
            </Popconfirm>
            <Popconfirm
              placement="top"
              title={`Are you sure you want to reject ${sectionItem.name}?`}
              onConfirm={() => this.handleReject(isVisible, itemDetails, currentApplicationItem)}
              okText="Reject"
              cancelText="Cancel"
              disabled={isRejected}
            >
              <Button icon={<CloseCircleOutlined />} type="primary" className={`${isRejected ? 'is-active' : ''} ${isApproved ? 'is-available' : ''}`}>
                {isRejected ? 'Rejected' : (isApproved ? 'Change to rejected' : 'Reject')}
              </Button>
            </Popconfirm>
          </div>
        )}
      >
        <div className="collapse-body large">
          <div className="collapse-data-section">
            <Row gutter={56}>
              <Col className="data-item">
                <small>Provider</small>
                <div className="value">
                  <span>{this._getTrainingProviderByID(currentProvider.trainingProviderID)?.name}</span>
                </div>
              </Col>

              {currentProvider?.hasIssuedDate && (
                <Col className="data-item">
                  <small>Issued on</small>
                  <div className="value">
                    {(reviewSubmissionAvailable || isEditing) && (
                      <DatePicker
                        value={itemDetails.issuedOn}
                        onChange={date => this.handleItemDetailChange(itemDetailsID, 'issuedOn', date)}
                      />
                    )}
                    {(!reviewSubmissionAvailable && !isEditing) && (
                      itemDetails.issuedOn ? itemDetails.issuedOn.format('yyyy-MM-DD') : '-'
                    )}
                  </div>
                </Col>
              )}
              {currentProvider?.hasExpirationDate && (
                <Col className="data-item">
                  <small>Expires on</small>
                  <div className="value">
                    {(reviewSubmissionAvailable || isEditing) && (
                      <DatePicker
                        value={itemDetails.expiresOn}
                        onChange={date => this.handleItemDetailChange(itemDetailsID, 'expiresOn', date)}
                      />
                    )}
                    {(!reviewSubmissionAvailable && !isEditing) && (
                      itemDetails.expiresOn ? itemDetails.expiresOn.format('yyyy-MM-DD') : '-'
                    )}
                  </div>
                </Col>
              )}
            </Row>
            <Row gutter={32} style={{ marginTop: 24 }}>
              <Col className="data-item" style={{ minWidth: 500 }}>
                <label>Notes</label>
                <div className="value">
                  {(reviewSubmissionAvailable || isEditing) && (
                    <Input.TextArea
                      value={itemDetails.additionalNotes}
                      style={{ minHeight: 150 }}
                      onChange={(event) => this.handleItemDetailChange(itemDetailsID, 'additionalNotes', event.target.value)}
                    />
                  )}

                  {(!reviewSubmissionAvailable && !isEditing) && (itemDetails.additionalNotes || '-')}
                </div>
              </Col>
            </Row>

            {!reviewSubmissionAvailable && (
              <>
                <Divider />
                <Row>
                  <Col>
                    {!isEditing && (
                      <Button type="primary" onClick={this.handleEditItem.bind(this, itemDetailsID, itemDetails, currentApplicationItem, isApproved)}>
                        Edit
                      </Button>
                    )}

                    {isEditing && (
                      <>
                        <Button onClick={this.handleCancelEdit}>
                          Cancel
                        </Button>
                        {' '}
                        <Button type="primary" onClick={this.handleSubmitEdit}>
                          OK
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </div>

          <div className="collapse-files-section">
            {currentApplicationItem.fileItems.map(({ fileID, fileName }) => (
              <Tooltip title={fileName} key={fileID}>
                <div className="file-card">
                  <div className="file-details">
                    <FileImageOutlined style={{ fontSize: 33 }} />
                    <span className="file-name">{fileName}</span>
                  </div>

                  <footer>
                    <Button type="link" icon={<EyeOutlined />} onClick={() => this.props.onPreview(currentApplicationItem, { fileID, fileName })} />
                    <Button type="link" icon={<DownloadOutlined />} onClick={() => this.props.onDownload(currentApplicationItem, { fileID, fileName })} />
                  </footer>
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
      </CustomCollapse>
    );
  }

  _renderProgressBar(progress, sectionItems) {
    const pending = progress[Globals.ApplicationItemsStatus.DEFAULT]?.length || 0;
    const approved = progress[Globals.ApplicationItemsStatus.APPROVED]?.length || 0;
    const rejected = progress[Globals.ApplicationItemsStatus.REJECTED]?.length || 0;

    const label = [];
    if (approved > 0) {
      label.push(`${approved} approved`);
    }

    if (rejected > 0) {
      label.push(`${rejected} rejected`);
    }

    if (pending > 0) {
      label.push(`${pending} pending review`);
    }

    const totalOfItems = sectionItems?.length || 0;
    const totalOfFilledItems = approved + rejected;
    const completedPercentage = (totalOfFilledItems * 100) / totalOfItems;

    return (
      <div className="progress-container">
        <span>{label.join(', ')}.</span>
        <div className="progress-bar">
          <div className="completed" style={{ width: `${completedPercentage}%` }} />
        </div>
      </div>
    );
  }

  _initializeItemsDetailsState() {
    const itemsDetails = {};

    Object.entries(this.props.sections).forEach(([sectionKey, section]) => {
      section.items.forEach((sectionItem) => {
        const { currentApplicationItem } = this.props.getSectionItemData(sectionItem);

        if (currentApplicationItem) {
          itemsDetails[`${sectionKey}-${sectionItem.id}`] = {
            issuedOn: (currentApplicationItem.issuedOn && currentApplicationItem.issuedOn != -1)? Utils.getDateOnMomentFormatByTimestamp(currentApplicationItem.issuedOn) : null,
            expiresOn: (currentApplicationItem.expiresOn && currentApplicationItem.expiresOn != -1)? Utils.getDateOnMomentFormatByTimestamp(currentApplicationItem.expiresOn) : null,
            additionalNotes: currentApplicationItem.additionalNotes || '',
          };
        }
      });
    });

    return itemsDetails;
  }

  _getTrainingProviderByID(trainingProviderID) {
    const { certificationSpecs } = this.props;

    return certificationSpecs.trainingProviders?.find(trainingProvider => (
      trainingProvider.id == trainingProviderID
    ));
  }
}
