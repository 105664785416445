import React from 'react';
import moment from 'moment';
import autoBind from 'react-autobind';
import { Result, Spin } from 'antd';
//
//props are: loadingType
export default class CommonLicensePurchaseModalStep_LoadingView extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //UI
  render() {
    let content = { status: 'info', title: 'Processing.', subTitle: 'Processing payment...', extra: <Spin spinning>{' '}</Spin> };
    if (this.props.loadingType == 1) content = { status: 'info', title: 'Processing.', subTitle: 'Generating License...', extra: <Spin spinning>{' '}</Spin> };
    return (
      <Result {...content} />
    );
  }
}
