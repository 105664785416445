import React from 'react';
import autoBind from 'react-autobind';
import { Table, Tooltip, Button, Popconfirm, message } from 'antd';
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
//
import Globals from '../../../../config/Globals';
import Utils from '../../../../components/Utils';
//
import CourseTabWrapper from './CourseTabWrapper';
import CommonResultCard from '../../../commonComponents/CommonResultCard';

//props are: app, currentCourse
export default class CourseTabResultsTab extends CourseTabWrapper {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  // Actions
  async handleUpdate() {
    await this.props.onUpdate();
  }

  handleDelete(row) {
    this._delete(row);
  }

  render() {
    //Check if not visible, don't render -- Please, change root element to
    //'display: none' if you need to retain any subcomponent
    if (!this.props.isVisible) return (<></>);
    //
    let courseSpecs = { passUiTerm: "Passed", failUiTerm: "Failed", revokedUiTerm: "Revoked" };
    if (this.props.currentCourse) {
      courseSpecs = this.props.app.sharedCache().getCourseByID(this.props.currentCourse.courseID);
    }
    console.log('*-*- rendering attemps courseSpecs', courseSpecs);
    const columns = [
      {
        title: 'Status', dataIndex: 'state',
        render: state => {
          const passed = state == Globals.Course_State.PASS;
          const revoked = state == Globals.Course_State.REVOKED;
          const statusText = passed ? (courseSpecs.passUiTerm || 'Passed') : revoked ? 'Revoked' : (courseSpecs.failUiTerm || 'Failed');

          return (
            <div className="value">
              <div style={{ display: 'inline-block' }} className={`status-dot ${passed ? 'green' : 'red'}`} />
              <span> {statusText} </span>
            </div>
          );
        }
      },
      { title: 'Grade', render: props => parseInt(props.courseResult?.resultGrade || props.courseResultV1?.grade || 0) },
      { title: 'Result Date', render: props => Utils.getDateAndTimeOnUIFormatByTimestamp(props.courseResult?.resultDate || props.courseResultV1?.resultDate), },
      {
        title: 'Actions',
        width: '10%',
        key: 'Actions',
        render: props => {
          console.log('*-*- rendering attempts props', props, courseSpecs);

          return (
            <span className='tableButtonContainer'>
              {props.courseResult?.resultStatus === 'PASS' && courseSpecs.courseCertificateType === Globals.CertificationProcess_CertificationPrintType.CERT && (
                <Tooltip placement="bottomLeft" title='Download a copy of the certificate'>
                  <Button variant="none" icon={<DownloadOutlined />} shape="circle" onClick={this.props.onDownload} />
                </Tooltip>
              )}
              {this.props.app.isAdmin() && (
                <Popconfirm placement="top" okText="Yes" cancelText="No" title={'Confirm removal of this attempt?'} onConfirm={() => this.handleDelete(props)}
                >
                  <Button style={{ marginLeft: '4px' }} variant="none" icon={<DeleteOutlined />} shape="circle" />
                </Popconfirm>
              )}
            </span>
          );
        }
      }
    ];

    const filteredRows = this.props.resultsHistory.filter(
      row => (row.state == Globals.Course_State.PASS || row.state == Globals.Course_State.FAIL || row.state == Globals.Course_State.REVOKED)
    );

    return super.render(
      <Table
        rowKey="id"
        columns={columns}
        dataSource={filteredRows}
        expandable={{ expandedRowRender: this._renderExpandedRow }}
        pagination={{ hideOnSinglePage: true, pageSize: 5 }}
        scroll={{ x: true }}
      />
    );
  }

  _renderExpandedRow(row) {
    return (
      <CommonResultCard app={this.props.app} currentCourse={row} />
    );
  }

  //private API
  async _delete(row) {
    this.startLoading();

    const { userID, certProcID, id: courseID } = row;
    const resp = await this.props.app.api.course.delete(userID, certProcID, courseID);

    if (resp.statusCode == 200) {
      message.success('Course successfully deleted.');
      this.handleUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.stopLoading();
  }
}
