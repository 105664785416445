import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, message, Row, Col, Button } from 'antd';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
import config from '../../../config/config';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
//
import CommonSessionDraftForm from '../../commonComponents/Forms/CommonSessionDraftForm';
//
const RequestMask = { SESSION: 2, VENUES: 4, CITIES: 8, ALL: 2 | 4 | 8 };
//
export default class AdminSessionEditView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    const sessionID = this.props.match.params.id;
    this.state = { isLoading: false, venues: null, courses: null, session: null, sessionID };
  }

  //View Life Cycle
  async componentDidMount() {
    super.componentDidMount();
    await this.fetchData(RequestMask.ALL);
  }

  async fetchData(mask) {
    this.startLoading();
    const resp = await this._loadData(mask);
    if (resp) {
      this.setState({ ...resp, isLoading: false }, () => {
        if (resp.session) this.sessionForm.setFieldsValue(resp.session);
      });
    } else this.stopLoading();
  }

  //Actions
  async handleUpdateSession() {
    const formData = await this.sessionForm.validateFields();
    if (!formData) return;
    this.startLoading();
    await this._updateSession(formData);
    this.stopLoading();
  }

  //UI
  render() {
    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className="pageHeader" title={'Update Session'} onBack={() => this.props.app.urlManager.pushBack()} />
          <Layout.Content>
            <Row type='flex' justify='end'> <Col> <Button style={{ marginBottom: 20 }} type="primary" onClick={this.handleUpdateSession}> Update Session</Button> </Col> </Row>
            <CommonSessionDraftForm {...Utils.propagateRef(this, 'sessionForm')} app={this.props.app} courses={this.state.courses} venues={this.state.venues} limitedEditMode={this.state.session?.state == Globals.Session_State.LOCKED} />
          </Layout.Content>
        </Layout.Content>
      </>
    );
  }

  /* private API calls */
  async _loadData(mask) {
    const resp = await Promise.all([
      (mask & RequestMask.SESSION) === RequestMask.SESSION ? this.props.app.classroom.session.getSession(this.state.sessionID) : null,
      (mask & RequestMask.VENUES) === RequestMask.VENUES ? this.props.app.sharedCache().getVenues() : null,
      (mask & RequestMask.CITIES) === RequestMask.CITIES ? this.props.app.sharedCache().getCities() : null,
    ]);
    if ((mask & RequestMask.SESSION) === RequestMask.SESSION && !(resp[0].statusCode == 200 && resp[0].body && resp[0].body.id)) {
      this.props.app.alertController.showAPIErrorAlert(null, resp[0]);
      return false;
    }
    if ((mask & RequestMask.VENUES) === RequestMask.VENUES && !resp[1]) {
      this.props.app.alertController.showErrorAlert(null, 'Could not load venues!');
      return false;
    }
    if ((mask & RequestMask.CITIES) === RequestMask.CITIES && !resp[2]) {
      this.props.app.alertController.showErrorAlert(null, 'Could not load cities!');
      return false;
    }
    const allowedEditingStates = [Globals.Session_State.DRAFT, Globals.Session_State.LOCKED];
    if (!allowedEditingStates.includes(resp[0].body.state)) {
      this.props.app.alertController.showErrorAlert('Opss..', 'This session does not allow editing. Only sessions on draft or locked can be modified!');
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.session, null, this.state.sessionID);
      return;
    }
    return {
      ...(mask & RequestMask.SESSION) === RequestMask.SESSION ? { session: resp[0].body } : {},
      ...(mask & RequestMask.VENUES) === RequestMask.VENUES ? { venues: resp[1] } : {},
      courses: this.props.app.sharedCache().getAllUniqueCourses()
    };
  }
  async _updateSession(sessionData) {
    if (!this._isMounted) return;
    const data = this.state.session;
    delete data.invitations;
    const resp = await this.props.app.classroom.session.updateSession(this.state.session.id, {
      ...data, ...sessionData,
      createdOn: undefined, createdBy: undefined, updatedOn: undefined, updatedBy: undefined,
      customerID: undefined, tenantID: undefined, courseID: undefined, id: undefined, templateID: undefined,
      state: undefined, events: undefined, isEventPublished: undefined, calendar: undefined,
      enrolments: undefined, attendances: undefined, results: undefined, transactionID: undefined,
      capacity: sessionData.capacity || -1, //auto-unlimited if not set
      isPrivate: !!sessionData.isPrivate,
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Session updated with success!');
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.session, null, this.state.sessionID);
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
}
