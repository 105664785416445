import React from "react";
import autoBind from 'react-autobind';
import { Alert, Button, Col, DatePicker, Dropdown, Input, Menu, Modal, Row, Select, Table, Tooltip, Typography, message } from 'antd';
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import EventWrapper from 'react-big-calendar/lib/addons/dragAndDrop/EventWrapper';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'moment/locale/en-ca';

import CustomComponent from '../../../components/CustomComponent';
import Globals from "../../../config/Globals";
import '../../../assets/stylesheets/InstructorCalendar.less';
import Utils from "../../../components/Utils";
import CommonLoadingView from "../CommonLoadingView";
import { CaretLeftOutlined, CaretRightOutlined, CloseCircleOutlined, CloseOutlined, SearchOutlined, SwapRightOutlined } from "@ant-design/icons";

const DragAndDropCalendar = withDragAndDrop(Calendar);
const CalendarLocalizer = momentLocalizer(moment);

export default class CommonSessionInstructorModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.startTime = Globals.CalendarViewStartTime;
    this.endTime = Globals.CalendarViewEndTime;
    this.hasWeekend = Globals.CalendarViewHasWeekends;
    this.handleSelectInstructors = Utils.debounce(this.handleSelectInstructors.bind(this), 500);

    this.state = {
      isOpened: false,
      selectedInstructors: [],
      eventsByInstructor: {},
      searchTerm: '',
      regions: [],
      selectedRegions: [],
      courses: [],
      selectedCourses: [],
      calendar: {
        from: moment().startOf('week').startOf('day'),
        to: moment().endOf('week').endOf('day'),
      },
    };
  }

  async componentDidMount() {
    this.startLoading();

    const regions = await this.props.app.sharedCache().getRegions();
    const courses = this.props.app.sharedCache().getAllUniqueCourses();
    this.setState({ regions, courses });

    this.stopLoading();
  }

  open() {
    let state = { isOpened: true };

    const startDate = this.props.session?.startDate?.[0];
    if (startDate) {
      state.calendar = {
        from: moment(startDate).startOf('week').startOf('day'),
        to: moment(startDate).endOf('week').endOf('day'),
      };
    }

    this.setState(state);
  }

  handleClose() {
    this.setState({
      isOpened: false,
      selectedInstructors: [],
      eventsByInstructor: {},
      searchTerm: '',
      selectedRegions: [],
      selectedCourses: [],
      calendar: {
        from: moment().startOf('week').startOf('day'),
        to: moment().endOf('week').endOf('day'),
      },
    });
  }

  handleChangeSearchTerm(e) {
    this.setState({ searchTerm: e.target.value });
  }

  handleChangeRegions(selectedRegions) {
    this.setState({ selectedRegions });
  }

  handleChangeCourses(selectedCourses) {
    this.setState({ selectedCourses });
  }

  handleDateStepChange(step) {
    let from = moment(this.state.calendar.from, Globals.DefaultDateFormat);

    if (step == -1) {
      from = from.subtract(1, 'weeks');
    } else {
      from = from.add(1, 'weeks')
    }

    this.setState({
      calendar: {
        from: from.clone().startOf('week').startOf('day'),
        to: from.clone().endOf('week').endOf('day')
      },
    }, this._loadInstructorsEvents);
  }

  handleRangeDateChange(value) {
    this.setState({
      calendar: {
        from: value.clone().startOf('week').startOf('day'),
        to: value.clone().endOf('week').endOf('day')
      },
    }, this._loadInstructorsEvents);
  }

  handleCalendarNavigate(date) {
    const momentDate = moment(date);

    this.setState({
      calendar: {
        from: momentDate.startOf('day'),
        to: momentDate.clone().endOf('week').endOf('day')
      },
    }, this._loadInstructorsEvents);
  }

  async handleSelectInstructors(selectedRowKeys, selectedRows) {
    const selectedInstructorsIds = selectedRows.map(instructor => instructor.id);
    const unselectedInstructors = this.state.selectedInstructors.filter(
      instructor => !selectedInstructorsIds.includes(instructor.id)
    );

    this.setState({ selectedInstructors: selectedRows }, async () => {
      await this._unloadInstructorsEvents(unselectedInstructors);
      this._loadInstructorsEvents();
    });
  }

  async handleAddInstructor(instructor, type) {
    this.startLoading();

    await this.props.onSelect(instructor, type);

    // We have a small delay until the event shows up on the instructor calendar
    // so we add this timer to wait this delay before try to reload the events
    setTimeout(() => {
      this._reloadEventsIfSelected(instructor);
    }, 1000);

    this.stopLoading();
  }

  render() {
    const columns = [
      {
        title: 'Name',
        key: 'name',
        render: (p) => (
          <Tooltip title={p.email}>
            {p.firstName} {p.lastName}
          </Tooltip>
        ),
      },
      {
        title: '',
        key: 'add',
        render: (instructor) => {
          const alreadyAdded = (this.props.sessionInstructors || []).find((i) => i.userID == instructor.id);

          return (
            <Dropdown.Button
              type="primary"
              size="small"
              onClick={this.handleAddInstructor.bind(this, instructor, 'PRIMARY')}
              disabled={alreadyAdded}
              overlay={(
                <Menu>
                  <Menu.Item key="1" onClick={this.handleAddInstructor.bind(this, instructor, 'PRIMARY')}>
                    Primary
                  </Menu.Item>
                  <Menu.Item key="2" onClick={this.handleAddInstructor.bind(this, instructor, 'SECONDARY')}>
                    Seconday
                  </Menu.Item>
                </Menu>
              )}
            >
              {alreadyAdded ? 'Already Added' : 'Add Instructor'}
            </Dropdown.Button>
          );
        }
      },
    ];
    const props = {
      rowKey: 'id',
      locale: { emptyText: 'Filter instructors' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, showSizeChanger: false },
    };

    return (
      <Modal
        destroyOnClose
        open={this.state.isOpened}
        onCancel={this.handleClose}
        footer={null}
        closeIcon={<CloseOutlined />}
      >
        <CommonLoadingView isLoading={this.state.isLoading} />

        <Row gutter={32} style={{ width: `1000px !important` }}>
          <Col span={8}>
            <Alert
              showIcon
              type="info"
              message="Select the instructors below to view their events in the calendar."
            />

            <div style={{ marginTop: 24 }}>
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search by name or e-mail..."
                value={this.state.searchTerm}
                onChange={this.handleChangeSearchTerm}
              />

              <Select
                mode="multiple"
                placeholder="Filter by regions"
                onChange={this.handleChangeRegions}
                style={{
                  width: '100%',
                  marginTop: 12,
                }}
                options={this.state.regions.map(region => ({
                  value: region.id,
                  label: region.name,
                }))}
              />

              <Select
                mode="multiple"
                placeholder="Filter by courses"
                onChange={this.handleChangeCourses}
                style={{
                  width: '100%',
                  marginTop: 12,
                }}
                options={this.state.courses.map(course => ({
                  value: course.id,
                  label: course.description,
                }))}
              />
            </div>

            <Table
              columns={columns}
              size="middle"
              dataSource={this._getFilteredInstructors()}
              style={{
                width: (window.screen.width / 2) + 200,
                marginTop: 16,
                position: 'sticky', top: 24
              }}
              rowSelection={{
                type: 'checkbox',
                onChange: this.handleSelectInstructors,
              }}
              {...props}
            />
          </Col>

          <Col span={16} className="instructorCalendarContainer">
            {this.renderTimeSelector()}

              <DragAndDropCalendar
                className="monthlyCalendar"
                localizer={CalendarLocalizer}
                events={this._getEvents()}
                draggableAccessor="dragEnabledNo"
                selectable={false}
                defaultView={(this.hasWeekend ? Views.WEEK : Views.WORK_WEEK)}
                popup
                onNavigate={this.handleCalendarNavigate}
                date={this.state.calendar.from.toDate()}
                eventPropGetter={(event) => ({
                  style: {
                    background: event.isUnavailabilityEvent ? '#c5303c' : '',
                    borderColor: event.isUnavailabilityEvent ? '#c5303c' : '',
                  }
                })}
                formats={{
                  timeGutterFormat: 'hh:mm A',
                  eventTimeRangeFormat: ({ start, end }, culture, localizer) => {
                    return localizer.format(start, 'hh:mm A') + ' - ' + localizer.format(end, 'hh:mm A');
                  }
                }}
                min={this.state.calendar.from.clone().hour(this.startTime.split(':')[0]).minute(this.startTime.split(':')[1]).toDate()}
                max={this.state.calendar.from.clone().hour(this.endTime.split(':')[0]).minute(this.endTime.split(':')[1]).toDate()}
              />
          </Col>
        </Row>
      </Modal>
    );
  }

  renderTimeSelector() {
    return (
      <Row type="flex" justify="center" align='middle' style={{ marginBottom: 22 }}>
        <Col>
          <Button
            type="link"
            onClick={this.handleDateStepChange.bind(this, -1)}
          >
            <CaretLeftOutlined style={{ color: 'grey', fontSize: '25px' }} />
          </Button>
        </Col>

        <Col>
          <DatePicker
            picker="week"
            onChange={this.handleRangeDateChange}
            bordered={false}
            allowClear={false}
            format={`${Globals.DefaultDateFormat}`}
            value={this.state.calendar.from}
            suffixIcon={
              <div style={{ width: '110px' }}>
                <SwapRightOutlined
                  style={{ color: 'black', display: 'inline', marginRight: '10px' }}
                />
                <Typography.Text>
                  {moment(this.state.calendar.to).format(Globals.DefaultDateFormat)}
                </Typography.Text>
              </div>
            }
          />
        </Col>

        <Col>
          <Button
            type="link"
            onClick={this.handleDateStepChange.bind(this, 1)}
          >
            <CaretRightOutlined style={{ color: 'grey', fontSize: '25px' }} />
          </Button>
        </Col>
      </Row>
    );
  }

  _getFilteredInstructors() {
    return this.props.instructors.filter(instructor => {
      const instructorName = `${instructor.firstName} ${instructor.lastName}`.toLowerCase();

      const matchName = instructorName.includes(this.state.searchTerm.toLocaleLowerCase());
      const matchEmail = instructor.email?.toLowerCase().includes(this.state.searchTerm.toLocaleLowerCase());
      const matchCourses = (
        this.state.selectedCourses.length > 0
        ? instructor.courses.some(course => this.state.selectedCourses.includes(course.courseID))
        : true
      );
      const matchRegions = (
        this.state.selectedRegions.length > 0
        ? instructor.regions.some(region => this.state.selectedRegions.includes(region.regionID))
        : true
      );

      return ((matchName || matchEmail) && matchCourses && matchRegions);
    });
  }

  _getEvents() {
    const mappedEvents = [];

    Object.entries(this.state.eventsByInstructor).forEach(([, { events, instructor }]) => {
      const instructorEvents = events.map((event) => {
        const course = this.props.app.sharedCache().getCourseByID(event.courseID);
        const eventName = !event.sessionID ? 'Unavailable' : course.description;

        return {
          id: `${event.startTime}${event.endTime}${event.sessionID || Date.now()}`,
          start: moment(Number(event.startTime)).toDate(event.startTime),
          end: moment(Number(event.endTime)).toDate(),
          title: `${instructor.firstName} - ${eventName}`,
          isUnavailabilityEvent: !event.sessionID,
        };
      });

      mappedEvents.push(...instructorEvents);
    });

    return mappedEvents;
  }

  _unloadInstructorsEvents(unselectedInstructors) {
    return new Promise((resolve) => {
      const eventsByInstructor = { ...this.state.eventsByInstructor };

      unselectedInstructors.forEach((instructor) => {
        Object.entries(this.state.eventsByInstructor).forEach(([key]) => {
          if (key.startsWith(instructor.id)) {
            delete eventsByInstructor[key];
          }
        });
      });

      this.setState({ eventsByInstructor }, resolve)
    });
  }

  async _reloadEventsIfSelected(instructor) {
    const isSelected = this.state.selectedInstructors.find(
      i => i.id == instructor.id
    );

    if (isSelected) {
      this.startLoading();
      await this._unloadInstructorsEvents([instructor]);
      await this._loadInstructorEvents(instructor, true);
      this.stopLoading();
    }
  }

  async _loadInstructorsEvents() {
    this.startLoading();
    await Promise.all(this.state.selectedInstructors.map(this._loadInstructorEvents));
    this.stopLoading();
  }

  async _loadInstructorEvents(instructor) {
    const { id } = instructor;
    const startTime = this.state.calendar.from.toDate().getTime();
    const endTime = this.state.calendar.to.toDate().getTime();

    const stateKey = `${id}::${startTime}`;

    // Use previously loaded data if exists
    if (this.state.eventsByInstructor[stateKey]) {
      return null;
    }

    const eventsResp = await this.props.app.classroom.instructor.getInstructorEvents(id, {
      startTime,
      endTime,
    });

    if (eventsResp?.statusCode == 200) {
      this.setState(prevState => ({
        ...prevState,
        eventsByInstructor: {
          ...prevState.eventsByInstructor,
          [stateKey]: {
            instructor,
            events: eventsResp.body.instructorCalendarEvents,
          },
        },
      }))
    } else {
      message.error(`Error while loading events for "${instructor.firstName} ${instructor.lastName ?? ''}"`);
    }
  }
}
