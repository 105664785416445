import React from 'react';
import autoBind from 'react-autobind';
import { Row, Col, Badge } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
//
import '../../../../../assets/stylesheets/OverallStatuses.less';
//
import Utils from '../../../../../components/Utils';
import Globals from '../../../../../config/Globals';
//
import CourseCardActions from '../Subcomponents/CourseCardActions';
import UtilsSession from '../../../../../components/UtilsSession';
import { CourseCardActionsHOC } from '../Subcomponents/CourseCardActionsContext';

class CourseAvailable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  componentDidMount() {
    const { currentCourse } = this.props;
    const courseID = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_CourseID);
    const autoLaunch = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Launch) == 'true';

    if (currentCourse.courseID == courseID && autoLaunch) {
      this.props.actions.launch();
    }
  }
  //UI
  render() {
    const { app, courseSpecs, session, certificationProcess, currentCourse } = this.props;
    return (
      <>
        {this._renderExpirationBadge()}
        <Row justify="center">
          <Col span={14} style={{ textAlign: 'center' }}>
            {courseSpecs.description} was made available on {Utils.getDateAndTimeOnUIFormatByTimestamp(session?.enrolments?.[0]?.createdOn)}.
          </Col>
        </Row>
        <CourseCardActions app={app} disableActions={this.props.disableActions} courseSpecs={courseSpecs} certificationProcess={certificationProcess}
          session={session}
          actionLaunch
          actionCheckResults={session?.type == Globals.Session_Type.ONLINE_EXT}
          actionAddResult
          actionCancelCourse
          actionUpload
          actionRequestApproval={currentCourse.submissionItems &&
                                 courseSpecs.courseType == Globals.Course_Types_Keys.ASSIGNMENT &&
                                 currentCourse.approvalState != Globals.CourseApprovalState.PENDING &&
                                 currentCourse.approvalState != Globals.CourseApprovalState.REJECTED ? true : false}
        />
      </>
    );
  }

  // Private methods
  _renderExpirationBadge() {
    const { courseSpecs, currentCourse, certificationProcess, session } = this.props;

    if (!currentCourse.invalidated && session
        && (session?.type == Globals.Session_Type.ONLINE_EXT || session?.type == Globals.Session_Type.SCHEDULED_ONLINE_EXT)
        && (session?.enrolments?.[0]?.accessRelease == Globals.SessionEnrolment_AccessRelease.RELEASED || (session?.enrolments?.[0]?.accessRelease == Globals.SessionEnrolment_AccessRelease.AUTO && UtilsSession.isOnSessionPeriods(session)))
        && session?.onlineAccessPeriod > 0 && session?.enrolments?.[0]?.accessReleasedOn > 0
        && currentCourse.state == Globals.Course_State.PENDING
        && certificationProcess.state == Globals.CertificationProcess_State.LICENSE_ACTIVATED
      ) {
      const preventLaunchAfter = Utils.timestampAfterMinutesFromDate(session?.onlineAccessPeriod, session?.enrolments?.[0]?.accessReleasedOn);
      const time = new Date(preventLaunchAfter);
      let daysDiff = (time.getTime() - Date.now()) / (1000 * 3600 * 24);

      if (parseInt(daysDiff) >= 0) {
        const displayDiff = (daysDiff + 1);

        return (<Badge className="available-badge" count={`Available for ${parseInt(displayDiff)} ${displayDiff == 1 ? 'day' : 'days'}`} />);
      } else if (parseInt(daysDiff) < 0) {
        daysDiff *= -1;

        return (<Badge className="available-badge" count={`Expired ${parseInt(daysDiff)} ${daysDiff == 1 ? 'day' : 'days'} ago`} />);
      }
    }
  }
}

CourseAvailable = CourseCardActionsHOC(CourseAvailable);
CourseAvailable.CardStatusHeader = function() {
  return <span className="warning"><InfoCircleFilled /> Available</span>;
}

export default CourseAvailable;
