import React from 'react';
import { Button, Popconfirm, Input, message } from 'antd';
import { FileImageOutlined, EyeOutlined, DownloadOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import autoBind from 'react-autobind';

import CustomComponent from '../../components/CustomComponent';

import CustomCollapse from '../commonComponents/CustomCollapse';
import Globals from '../../config/Globals';
import Utils from '../../components/Utils';
import config from '../../config/config';

export default class AdminReviewStudentsRequestsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isVisible: true,
      paItem: {},
      approvalNotes: '',
      isLoading: false,
      submissionItems: []
    };
  }

  async componentDidMount() {
    await this.fetch();
  }

  async fetch() {
    //Fetch PA
    const resp = await this.props.app.api.tenant.pendingApprovals();
    if (resp.statusCode !== 200 || !resp.body) {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return;
    }
    const paID = this.props.match.params.id;
    const paItem = resp.body.paItems.find((paItem) => paItem.id === paID);
    //Fetch Course Updated Submission Items
    const certificationProcess = await this.props.app.api.certification.getByUserIDAndCertID(paItem.userID, paItem.certProcID);
    if (certificationProcess.statusCode !== 200 || !certificationProcess.body) {
      this.props.app.alertController.showAPIErrorAlert(null, certificationProcess);
      return;
    }
    const submissionItems = certificationProcess.body.courses.find(c => c.id === paItem.courseID).submissionItems;
    //Set state
    this.setState({ certProc: certificationProcess.body, paItem, submissionItems });
  }

  handleReview(approved) {
    this._reviewCourseDocItem(approved);
  }

  handlePreviewItem(file) {
    this._preview(file);
  }

  handleDownloadItem(file) {
    this._download(file);
  }

  handleNote(approvalNotes) {
    this.setState({ approvalNotes });
  }

  render() {
    const { isLoading } = this.state;

    return (
      <div className="application-requirements-wrapper">
        <div className="application-requirements-section">
          <header>
            <h2>Pending Approvals</h2>
          </header>
          <div className="body">
            {!isLoading &&
              this._renderItemCollapse()
            }
          </div>
        </div>
      </div>
    );
  }

  // Private methods
  _renderItemCollapse() {
    const { status, userID, courseID, certProcID, displayName } = this.state.paItem;

    const isApproved = status === Globals.CourseApprovalState.APPROVED;
    const isRejected = status === Globals.CourseApprovalState.REJECTED;

    return (
      <CustomCollapse
        title={displayName}
        visible={this.state.isVisible}
        extraActions={() => (
          <div className="application-item-review-actions">
            <Popconfirm
              placement="top"
              onConfirm={() => this.handleReview(true)}
              title="Are you sure you want to approve?"
              okText="Approve"
              cancelText="Cancel"
              disabled={isApproved}
            >
              <Button
                icon={<CheckCircleOutlined />}
                className={`approve ${isApproved ? 'is-active' : ''} ${isRejected ? 'is-available' : ''}`}
              >
                {isApproved ? 'Approved' : isRejected ? 'Change to approved' : 'Approve'}
              </Button>
            </Popconfirm>
            <Popconfirm
              placement="top"
              onConfirm={() => this.handleReview(false)}
              title="Are you sure you want to reject?"
              okText="Reject"
              cancelText="Cancel"
              disabled={isRejected}
            >
              <Button
                icon={<CloseCircleOutlined />}
                type="primary"
                className={`${isRejected ? 'is-active' : ''} ${isApproved ? 'is-available' : ''}`}
              >
                {isRejected ? 'Rejected' : isApproved ? 'Change to rejected' : 'Reject'}
              </Button>
            </Popconfirm>
          </div>
        )}
      >
        <div className="collapse-body large">
          <div className="collapse-data-section">
            <label>Notes</label>
              <div className="value">
                  <Input.TextArea
                    value={this.state.approvalNotes}
                    style={{ minHeight: 150, minWidth: 500 }}
                  onChange={(event) => this.handleNote(event.target.value)}
                />
              </div>
            </div>
          <div className="collapse-files-section">
            {this.state.submissionItems?.map(({ fileID, fileName }) => (
              <div className="file-card" key={fileID}>
                <div className="file-details">
                  <FileImageOutlined style={{ fontSize: 33 }} />
                  <span className="file-name">{fileName}</span>
                  <footer>
                    <Button
                      type="link"
                      icon={<EyeOutlined />}
                      onClick={() => this.handlePreviewItem({ fileID, fileName,  userID, courseID, certProcID })}
                    />
                    <Button
                      type="link"
                      icon={<DownloadOutlined />}
                      onClick={() => this.handleDownloadItem({ fileID, fileName,  userID, courseID, certProcID })}
                    />
                  </footer>
                </div>
              </div>
            ))}
          </div>
        </div>
      </CustomCollapse>
    );
  }
  
  // API Calls

  async _preview(file) {
    this.startLoading();
    const resp = await this.props.app.api.courseFile.getSignURL(file.userID, file.certProcID, file.courseID, file.fileID)
    if (!this._isMounted) return;
    if (resp.statusCode === 200) {
      this.stopLoading();
      Utils.openFileInNewTab(resp.body.fileURL, file.fileName);
    } else {
      this.stopLoading();
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
    }
  }

  async _download(file) {
    this.startLoading();
    const resp = await this.props.app.api.courseFile.downloadDocument(file.userID, file.certProcID, file.courseID, file.fileID);
    if (!this._isMounted) return;
    if (resp.statusCode === 200) {
      const { filename, extension } = Utils.splitFilenameAndExtension(file.fileName);
      Utils.downloadBlob(resp.body, filename || file.fileName, extension || Utils.getFileExtensionFromMimeType(resp.body.type));
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
    }
    this.stopLoading();
  }

  async _reviewCourseDocItem(approved) {
    this.startLoading();
    const { userID, certProcID, courseID, id } = this.state.paItem;
    const body = {
      approvalNotes: this.state.approvalNotes,
      approved
    };
    const resp = await this.props.app.api.course.review(
      body,
      userID,
      certProcID,
      courseID,
      id
    );
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      await this.props.app.reloadPendingApprovals();
      message.success('Pending approval review successfully submitted!');
      this.props.app.urlManager.replacePage(config.ApplicationRoutes.studentsRequests);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
}


