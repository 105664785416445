import React from 'react';
import autoBind from 'react-autobind';
import { Alert, Form, Col, Row, Divider, Result, Modal, Button, Spin, Input, Steps } from 'antd';
import MaskedInput from 'antd-mask-input';
import { isMobile } from 'react-device-detect';
//
import Utils from '../../../components/Utils';
import config from '../../../config/config';
//
import CustomComponent from '../../../components/CustomComponent';
//
import '../../../assets/stylesheets/CommonCourseLicenseRedeemModal.less';
//
const STEPS = { FORM: 'FORM', LOADING: 'LOADING', RESULT: 'RESULT' };
const INITIAL_STATE = (visible) => {
  return {
    isVisible: visible || false, isLoading: false,
    stepper: {
      //stepper possible statuses - waiting, process, finish, error
      current: STEPS.FORM, status: 'waiting',
    },
  };
};
const LicenseInput = (props) => {
  const mask = React.useMemo(() => [ { mask: '******{-}*******{-}*******{-}*******', placeholderChar3: '#' } ], []);
  return (
    <MaskedInput
      {...props} mask={mask}
    />
  );
};
//props are: application, app, onChange, isActivation (optional)
export default class CommonApplicationLicenseRedeemModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = INITIAL_STATE();
  }
  /* Public */
  show() { 
    this.setState({ isVisible: true, stepper: { ...this.state.stepper, current: STEPS.FORM, status: 'waiting', error: null }, itaRequired: false, isLoading: false }, () => {
      if (this.props.isActivation) this.handleSubmit();
    });
  }
  goToInitialStep() { this.setState(INITIAL_STATE(true)); }
  
  //Actions
  async handleSubmit() {
    const resp = await this.currentChild.form.validateFields(this.props.isActivation ? ['itaNumber'] : ['licenseKey', 'itaNumber']);
    if (resp) {
      //Start loading indicator
      this.startLoading();
      //if ita is required, update user object and parts on API
      if (this.state.itaRequired && !(await this._addITANumber(resp.itaNumber))) {
        this.stopLoading();
        return;
      }
      //Redeem license
      await this._redeemLicense(resp.licenseKey);
    }
  }
    //Modal lifecycle
  handleCompleted() {
    if (this.currentChild && this.currentChild.form) this.currentChild.form.resetFields();
    this.setState({ isVisible: false }, () => { this.props.onChange(); })
  }
  handleCancel() {
    if (this.currentChild && this.currentChild.form) this.currentChild.form.resetFields();
    this.setState({ isVisible: false }, () => { this.props.onChange(true /* isCancel */); })
  }
  handleAfterClose() { this.setState(INITIAL_STATE()); }

  //UI
  render() {
    return (
      <Modal maskClosable={false} title={'Activate License Key - Application'} afterClose={this.handleAfterClose.bind(this)} className='redeemModal'
        visible={this.state.isVisible} confirmLoading={this.state.isLoading} closable={false} footer={null}>
        <Steps {... this.state.stepper} current={this._getStepIndex()} size='small' direction={(isMobile ? 'vertical' : 'horizontal')} className='paymentSteps'>
          {this._renderStepperSteps()}
        </Steps>
        {this._renderSelectedStep()}
      </Modal>
    );
  }
  /* Overwrites */
  _getStepIndex() {
    if (this.state.stepper.current == STEPS.FORM) return 0;
    else if (this.state.stepper.current == STEPS.LOADING) return 1;
    else if (this.state.stepper.current == STEPS.RESULT) return 2;
  }
  _renderStepperSteps() {
    return (
      <>
        <Steps.Step title="License Key" />
        <Steps.Step title="Activating" />
        <Steps.Step title={this.state?.stepper?.error ? "Failed" : "Completed"} />
      </>
    );
  }
  _renderSelectedStep() {
    if (this.state.stepper.current == STEPS.FORM) return this._renderLicenseFormStep();
    else if (this.state.stepper.current == STEPS.LOADING) return this._renderLoadingStep();
    else if (this.state.stepper.current == STEPS.RESULT) return this._renderResultStep();
  }
  /* Sub-steps */
  _renderLicenseFormStep() {
    return (<>
      {this._renderForm()}
      {this._renderButtonsFooter()}
    </>);
  }
  _renderLoadingStep() {
    return (<Result status='info' title='Processing.' subTitle={this.props.isActivation ? 'Activating license...' : 'Redeeming license..'} extra={<Spin spinning/>}/>);
  }
  _renderResultStep() {
    let result = null;
    if (this.state.stepper.error) {
      result = { 
        status: 'error', title: this.props.isActivation ? 'Error while activating application license.' : 'Error while redeeming application license.', subTitle: this._extractError(),
        extra: <Button type='primary' onClick={() => this.goToInitialStep()}>Retry</Button>
      };
    } else { 
      result = { 
        status: 'success', title: `Thank you!`, subTitle: 'Your application was submitted and will be evaluated.',
        extra: <Button type='primary' onClick={() => this.handleCompleted()}>Done</Button>
      }; 
    } return (<Result {...result}/>);
  }
  /* Form step */
  /* subforms */
  _renderForm() {
    return (
      <Form key='redeemForm' className='redeemModalForm' {...Utils.propagateRef(this, 'currentChild.form')}>
        <Divider orientation="left">{'License Key'}</Divider>
        {!this.props.isActivation && <Row type="flex" justify="center">
          <Col>
            <Form.Item name="licenseKey" rules={[{ min: 30, max: 30, message: 'Invalid license license key!' }]}>
              <LicenseInput className='licenseKeyInput' name="licenseKey" placeholder="######-########-########-#######" />
            </Form.Item>
          </Col>
        </Row>}
        {this.state.itaRequired && (
          <>
            <Divider orientation="left" />
            <Row type="flex" justify="center">
              <Col span={20}>
                <Form.Item name="itaNumber" label='ITA Number' rules={[{ required: true, min: 1, message: 'Invalid license key!' }]} validateStatus="error" help="The owner of this license is an organization that is on the apprenticeship program and you are required to have an ITA number to use it!">
                  <Input name="itaNumber" className='itaNumberInput' placeholder="e.g. A89BCD21" allowClear />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    );
  }
  _renderButtonsFooter() {
    if (!this.state.isVisible) return <></>;
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button disabled={this.state.isLoading} key="back" onClick={() => this.handleCancel()}> Cancel </Button>
        <Button disabled={this.state.isLoading} key="previous" onClick={() => this.goToInitialStep()} className='confirmButton'> Previous </Button>
        <Button key="submit" type="primary" loading={this.state.isLoading} onClick={this.handleSubmit} disabled={this.state.isLoading} className='confirmButton'> Submit </Button>
      </Row>
    )
  }

  /* API calls */
  async _redeemLicense(licenseKey) {
    //Start loading step
    this.setState({ stepper: { current: STEPS.LOADING, status: 'loading', error: null }, isLoading: true });
    //Make request
    //submit(userID, certProcID, applicationType, activationKey)
    const redeemResp = await this.props.app.api.application.submit(this.props.application?.userID, this.props.application?.certProcID, this.props.application?.type, licenseKey);
    if (!this._isMounted) return;
    console.debug('License redeem resp: ', redeemResp);
    if (redeemResp.statusCode == 200 && redeemResp.body) {
      this.setState({ stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'finish', error: null }, itaRequired: false, isLoading: false });
      return true;
    } else if (redeemResp.statusCode == 400 && redeemResp.body.errCode == 'ITA_REQUIRED') { /* This is currently not implemented on the API but we leave it here as a compatibility for future implementations */
      this.setState({ stepper: { ...this.state.stepper, current: STEPS.FORM, status: 'waiting', error: null }, itaRequired: true, isLoading: false });
    } else {
      this.setState({ stepper: { ...this.state.stepper, current: STEPS.RESULT, status: 'finish', error: redeemResp.body }, itaRequired: false, isLoading: false });
    } return false;
  }
  async _addITANumber(itaNumber) {
    let resp = await this._updateUserPartitions(itaNumber);
    if (resp) resp = await this._updateUser(itaNumber);
    if (resp) {
      this.props.app.alertController.showSuccessAlert('', 'ITA number saved!');
      return true;
    } else return false;
  }
  async _updateUserPartitions(itaNumber) {
    //Get part
    const parts = await this.props.app.idm.api.userPartition.readSome([config.IDMClientOptions.partitions.PROFESSIONAL], this.props.course?.userID);
    if (parts.statusCode != 200) {
      console.log('ERROR while reading user partitions');
      this.props.app.alertController.showAPIErrorAlert('Error', parts);
      return false;
    }
    const professional = parts.body.parts.find(obj => obj.partID == config.IDMClientOptions.partitions.PROFESSIONAL);
    //Build part -- suport for other parts in the future
    const partitionsValues = {
      [config.IDMClientOptions.partitions.PROFESSIONAL]: {
        value: { ...(professional && professional.value ? professional.value : {}), itaNumber },
      }
    };
    // Send API request to IDM, setting paritition for user X on partition Y with value Z
    const resp = await this.props.app.idm.api.userPartition.setMultiple(
      { parts: partitionsValues },
      /* userID */ this.props.course?.userID
    );

    //Invalidate old partition values if same user as logged
    const user = this.props.app.sharedCache().getProgramUser();
    if (user && this.props.course?.userID == user.id) await this.props.app.idm.session.invalidatePartitionByID(config.IDMClientOptions.partitions.PROFESSIONAL);; //reload user on cache

    // Simple 200 status means we got a update success
    if (resp.statusCode == 200) {
      console.log('update user partitions with success');
    } else {
      console.log('ERROR while updating user partitions');
      this.props.app.alertController.showAPIErrorAlert('Error', resp);
      return false;
    } return true;
  }
  async _updateUser(itaNumber) {
    //Get user
    let user = this.props.app.sharedCache().getProgramUser();
    if (!user || this.props.course?.userID != user.id) {
      user = await this.props.app.api.user.getByID(this.props.course?.userID);
      if (user.statusCode != 200) {
        console.log('ERROR while updating user object');
        this.props.app.alertController.showAPIErrorAlert('Error', user);
        return false;
      } else user = user.body;
    }
    //Build user
    user = { ...user, itaNumber };
    //Save partition on IDM
    const resp = await this.props.app.api.user.update(user);
    // Simple 200 status means we got a update success
    if (resp.statusCode == 200) {
      let puser = this.props.app.sharedCache().getProgramUser();
      if (puser && this.props.course?.userID == puser.id) await this.props.app.sharedCache().reloadUserObj(); //reload user on cache
      console.log('updated user with success');
    } else {
      console.log('ERROR while updating user');
      this.props.app.alertController.showAPIErrorAlert('Error', resp);
      return false;
    } return true;
  }
  _extractError() {
    if (this.state.stepper?.error && this.state.stepper?.error.err) return this.state.stepper?.error.err;
    //40x codes
    else if (this.state.stepper?.error && this.state.stepper?.error.message) return this.state.stepper?.error.message;
    return JSON.stringify(this.state.stepper?.error);
  }
}
