import React from 'react';
import { Switch } from 'react-router-dom';
import CustomRoute from './components/CustomRoute';

// Common
// import ErrorView from './views/ErrorView';
import CommonLicensesView from './views/commonSubviews/CommonLicensesView';
import CommonLicenseConsumptionView from './views/commonSubviews/CommonLicenseConsumptionView';
import CommonSearchUsersView from './views/commonSubviews/CommonSearchUsersView';
import CommonUserDashboardView from './views/commonSubviews/CommonUserDashboardView';
import CommonCertificationView from './views/commonSubviews/CommonCertificationView/CommonCertificationView';
import CommonRegisterOrgView from './views/commonSubviews/CommonRegisterOrgView';
import CommonFaqView from './views/commonSubviews/CommonFaqView';
// User
import UserDashboardView from './views/userSubviews/UserDashboardView';
// Admin
import AdminEditOrgView from './views/adminSubviews/AdminEditOrgView';
import AdminRevenuePerPeriodReportView from './views/adminSubviews/Reports/AdminRevenuePerPeriodReportView';
import AdminAffiliateComissionReportView from './views/adminSubviews/Reports/AdminAffiliateComissionReportView';
import AdminAchievedPerPeriodReportView from './views/adminSubviews/Reports/AdminAchievedPerPeriodReportView';
import AdminOrgLicensesReportView from './views/adminSubviews/Reports/AdminOrgLicensesReportView';
import AdminExpiredCertificationReportView from './views/adminSubviews/Reports/AdminExpiredCertificationReportView';
import AdminApplicationReportView from './views/adminSubviews/Reports/AdminApplicationReportView';
import AdminApplicationStatusView from './views/adminSubviews/Reports/AdminApplicationStatusView';
import AdminSessionsListReportView from './views/adminSubviews/Reports/AdminSessionsListReportView';
import AdminSessionResultsReportView from './views/adminSubviews/Reports/AdminSessionResultsReportView';
import AdminExamAnalysisReportView from './views/adminSubviews/Reports/AdminExamAnalysisReportView';
import AdminPendingOrdersView from './views/adminSubviews/AdminPendingOrdersView';
import AdminOrganizationsView from './views/adminSubviews/AdminOrganizationsView';
import AdminPendingApplicationsView from './views/adminSubviews/AdminPendingApplicationsView';
import AdminReviewApplicationView from './views/adminSubviews/AdminReviewApplicationView';
import AdminPendingStudentsRequestsView from './views/adminSubviews/AdminPendingStudentsRequestsView';
import AdminReviewStudentsRequestsView from './views/adminSubviews/AdminReviewStudentsRequestsView';
import AdminEmailCertificatesView from './views/adminSubviews/AdminEmailCertificatesView';
import AdminMergeStudentsView from './views/adminSubviews/AdminMergeStudentsView';
// Admin Settings
import AdminAppStatsView from './views/adminSubviews/AdminAppStatsView';
import AdminCertificationsView from './views/adminSubviews/SettingsViews/AdminCertificationsView';
import AdminCertificationView from './views/adminSubviews/SettingsViews/AdminCertificationView';
import AdminCourseProvidersView from './views/adminSubviews/SettingsViews/AdminCourseProvidersView';
import AdminCourseView from './views/adminSubviews/SettingsViews/AdminCourseView';
import AdminEquivalenciesView from './views/adminSubviews/SettingsViews/AdminEquivalenciesView';
import AdminInstructorsView from './views/adminSubviews/SettingsViews/AdminInstructorsView';
import AdminInstructorView from './views/adminSubviews/SettingsViews/AdminInstructorView';
import AdminLocationsView from './views/adminSubviews/SettingsViews/AdminLocationsView';
import AdminPricesView from './views/adminSubviews/SettingsViews/AdminPricesView';
import AdminProductsView from './views/adminSubviews/SettingsViews/AdminProductsView';
import AdminProductView from './views/adminSubviews/SettingsViews/AdminProductView';
import AdminRequirementsView from './views/adminSubviews/SettingsViews/AdminRequirementsView';
import AdminSessionCreateView from './views/adminSubviews/SettingsViews/AdminSessionCreateView';
import AdminSessionEditView from './views/adminSubviews/SettingsViews/AdminSessionEditView';
import AdminSessionsView, { AdminSessionsHomeView } from './views/adminSubviews/SettingsViews/AdminSessionsView';
import AdminSessionView from './views/adminSubviews/SettingsViews/AdminSessionView';
import AdminSettingsView from './views/adminSubviews/SettingsViews/AdminSettingsView';
import AdminTemplatesView from './views/adminSubviews/SettingsViews/AdminTemplatesView';
import AdminTemplateView from './views/adminSubviews/SettingsViews/AdminTemplateView';
import AdminTrainingProvidersView from './views/adminSubviews/SettingsViews/AdminTrainingProvidersView';
import AdminVenuesView from './views/adminSubviews/SettingsViews/AdminVenuesView';
import AdminVouchersView from './views/adminSubviews/SettingsViews/AdminVouchersView';
import AdminVoucherView from './views/adminSubviews/SettingsViews/AdminVoucherView';
import AdminFaqView from './views/adminSubviews/SettingsViews/AdminFaqView';
import AdminLicensesView from './views/adminSubviews/SettingsViews/AdminLicensesView';
// OrgManager
import OrgManagersInvitationsView from './views/orgmanagersSubviews/OrgManagersInvitationsView';
import OrgManagersInvitationHistoryView from './views/orgmanagersSubviews/OrgManagersInvitationHistoryView';
import OrgManagersSettingsView from './views/orgmanagersSubviews/OrgManagersSettingsView';
// Common
import CommonBillingView from './views/commonSubviews/CommonBillingView';
import CommonMyUpcomingCoursesView from './views/commonSubviews/CommonMyUpcomingCoursesView';
import CommonRegisterStudentsView from './views/commonSubviews/CommonRegisterStudentsView';
// Auth
import CommonUnauthorizedView from './views/authSubviews/CommonUnauthorizedView';
//
import config from './config/config';
import CommonCoursesView from './views/commonSubviews/CommonCoursesView';

export default class AppRoutes extends React.Component {
  _renderPrivilegedRoutes() {
    const tenantConfig = this.props.appRef.props.app.sharedCache().getTenantConfig();

    // not logged? - default landing page
    if (!this.props.appRef.props.app.idm.isLogged() || this.props.appRef.props.app.isAuthenticating) {
      // Login page will eventually be here
      return (
        <Switch>
          <CustomRoute component={CommonUnauthorizedView} appRef={this.props.appRef} />
        </Switch>
      );
    }
    //
    if (this.props.appRef.props.app.isAdmin()) {
      return (
        <Switch>
          <CustomRoute path={config.ApplicationRoutes.homepage} exact component={CommonSearchUsersView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.appStats} exact component={AdminAppStatsView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.adminLicensesView} exact component={AdminLicensesView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.userSearch} exact component={CommonSearchUsersView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.userDashboard} exact component={CommonUserDashboardView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.revenuePerPeriodReport} exact component={AdminRevenuePerPeriodReportView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.affiliateComissionReport} exact component={AdminAffiliateComissionReportView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.achievedCertificationsPerPeriodReport} exact component={AdminAchievedPerPeriodReportView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.expiredCertificationsReport} exact component={AdminExpiredCertificationReportView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.applicationReport} exact component={AdminApplicationReportView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.applicationStatus} exact component={AdminApplicationStatusView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.sessionsListReport} exact component={AdminSessionsListReportView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.sessionResultsReport} exact component={AdminSessionResultsReportView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.examAnalysisReport} exact component={AdminExamAnalysisReportView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.pendingOrders} exact component={AdminPendingOrdersView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.voucher} exact component={AdminVoucherView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.settings} exact component={AdminSettingsView} appRef={this.props.appRef} />
          {!tenantConfig.orgModDisabled && <CustomRoute path={config.ApplicationRoutes.organizations} exact component={AdminOrganizationsView} appRef={this.props.appRef} />}
          {tenantConfig.menuDisplayOptions.showEmailCertificates && <CustomRoute path={config.ApplicationRoutes.emailCertificates} exact component={AdminEmailCertificatesView} appRef={this.props.appRef} />}
          <CustomRoute path={config.ApplicationRoutes.commonLicenses} exact component={CommonLicensesView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.commonCourses} exact component={CommonCoursesView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.mergeStudents} exact component={AdminMergeStudentsView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.licenseConsumption} exact component={CommonLicenseConsumptionView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.registerOrg} exact component={CommonRegisterOrgView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.editOrg} exact component={AdminEditOrgView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.prices} exact component={AdminPricesView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.products} exact component={AdminProductsView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.product} exact component={AdminProductView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.vouchers} exact component={AdminVouchersView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.trainingProviders} exact component={AdminTrainingProvidersView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.courseProviders} exact component={AdminCourseProvidersView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.locations} exact component={AdminLocationsView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.requirements} exact component={AdminRequirementsView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.equivalencies} exact component={AdminEquivalenciesView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.venues} exact component={AdminVenuesView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.instructors} exact component={AdminInstructorsView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.instructor} exact component={AdminInstructorView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.certifications} exact component={AdminCertificationsView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.certification} exact component={AdminCertificationView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.course} exact component={AdminCourseView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.templates} exact component={AdminTemplatesView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.template} exact component={AdminTemplateView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.sessions} exact component={AdminSessionsView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.sessionCreate} exact component={AdminSessionCreateView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.sessionEdit} exact component={AdminSessionEditView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.session} exact component={AdminSessionView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.sessionsHome} exact component={AdminSessionsHomeView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.faqManagement} exact component={AdminFaqView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.pendingApplications} exact component={AdminPendingApplicationsView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.reviewApplication} exact component={AdminReviewApplicationView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.studentsRequests} exact component={AdminPendingStudentsRequestsView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.pendingApprovals} exact component={AdminReviewStudentsRequestsView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.myUpcomingCourses} exact component={CommonMyUpcomingCoursesView} appRef={this.props.appRef} />
          {tenantConfig.licModVaultingEnabled && <CustomRoute path={config.ApplicationRoutes.adminBillingView} exact component={CommonBillingView} appRef={this.props.appRef} />}
          <CustomRoute path={config.ApplicationRoutes.newAdminCertification} exact component={CommonCertificationView} appRef={this.props.appRef} />
          {!tenantConfig.orgModDisabled && <CustomRoute path={config.ApplicationRoutes.orgLicensesReport} exact
            component={AdminOrgLicensesReportView} appRef={this.props.appRef} />}
          <CustomRoute path={config.ApplicationRoutes.faq} exact component={CommonFaqView} appRef={this.props.appRef} />
          {(tenantConfig?.orgMgrCanEnrolBySession && this.props.appRef.props.app.isSysAdmin()) && (
            <CustomRoute path={config.ApplicationRoutes.registerStudents} exact component={CommonRegisterStudentsView} appRef={this.props.appRef} />
          )}
          <CustomRoute component={CommonSearchUsersView} appRef={this.props.appRef} />
        </Switch>
      );
    }
    //
    if (this.props.appRef.props.app.isOrgManager()) {
      return (
        <Switch>
          {/* Org manager */}
          <CustomRoute path={config.ApplicationRoutes.invitations} exact component={OrgManagersInvitationsView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.orgManagerSettings} exact component={OrgManagersSettingsView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.invitationHistory} exact component={OrgManagersInvitationHistoryView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.commonLicenses} exact component={CommonLicensesView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.commonCourses} exact component={CommonCoursesView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.licenseConsumption} exact component={CommonLicenseConsumptionView} appRef={this.props.appRef} />
          {tenantConfig.licModVaultingEnabled && <CustomRoute path={config.ApplicationRoutes.simpleBillingView} exact component={CommonBillingView} appRef={this.props.appRef} />}
          {tenantConfig.orgModAllowOrgSelfRegistration && <CustomRoute path={config.ApplicationRoutes.registerOrg} exact component={CommonRegisterOrgView} appRef={this.props.appRef} />}
          {tenantConfig?.orgMgrCanEnrolBySession && (
            <CustomRoute path={config.ApplicationRoutes.registerStudents} exact component={CommonRegisterStudentsView} appRef={this.props.appRef} />
          )}
          <CustomRoute path={config.ApplicationRoutes.myUpcomingCourses} exact component={CommonMyUpcomingCoursesView} appRef={this.props.appRef} />
          {/* Other users */}
          <CustomRoute path={config.ApplicationRoutes.userDashboard} exact component={CommonUserDashboardView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.newAdminCertification} exact component={CommonCertificationView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.orgManagerSearch} component={CommonSearchUsersView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.faq} exact component={CommonFaqView} appRef={this.props.appRef} />
        </Switch>
      );
    }
    //
    if (this.props.appRef.props.app.isUser()) {
      return (
        <Switch>
          <CustomRoute path={config.ApplicationRoutes.homepage} exact component={UserDashboardView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.commonLicenses} exact component={CommonLicensesView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.newUserCertification} appRef={this.props.appRef} exact component={CommonCertificationView} />
          <CustomRoute path={config.ApplicationRoutes.licenseConsumption} exact component={CommonLicenseConsumptionView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.sessionsHome} exact component={AdminSessionsHomeView} appRef={this.props.appRef} />
          {tenantConfig.orgModAllowOrgSelfRegistration && <CustomRoute path={config.ApplicationRoutes.registerOrg} exact component={CommonRegisterOrgView} appRef={this.props.appRef} />}
          {tenantConfig.licModVaultingEnabled && <CustomRoute path={config.ApplicationRoutes.simpleBillingView} exact component={CommonBillingView} appRef={this.props.appRef} />}
          <CustomRoute path={config.ApplicationRoutes.faq} exact component={CommonFaqView} appRef={this.props.appRef} />
          <CustomRoute path={config.ApplicationRoutes.myUpcomingCourses} exact component={CommonMyUpcomingCoursesView} appRef={this.props.appRef} />
          <CustomRoute component={UserDashboardView} appRef={this.props.appRef} />
        </Switch>
      );
    }
    return <Switch />;
  }

  render() {
    return this._renderPrivilegedRoutes();
  }
}
