import React from "react";
import autoBind from 'react-autobind';
import { Drawer, Form, Button, Input, Tag, Checkbox, message } from 'antd';
//
import Utils from "../../../components/Utils";
import CustomComponent from "../../../components/CustomComponent";
//props are: app, session, onInvite, failures
export default class CommonSessionInvitationDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isVisible: false, reservedSeat: false };
  }
  show() { this.setState({ isVisible: true }); }
  handleClose() { this.setState({ isVisible: false }); }
  async handleSubmit() {
    const formData = await this.form.validateFields();

    if (!formData.emails) {
      message.error('Please, type at least one e-mail address!');
      return;
    }

    const emails = formData.emails
      .replace(/\n/g, ',')
      .replace(' ', ',')
      .split(',');

    const invites = emails.map(email => ({ email }));
    if (invites.length > 30) {
      message.error('You can invite only 30 students at a time');
      return;
    }

    const invitations = {
      sessionID: this.props.session.id,
      courseID: this.props.session.courseID,
      isReservedSeat: this.state.reservedSeat,
      invites
    };

    await this.props.onInvite(invitations);
    if (this.props.failures && this.props.failures.length > 0) {
      const failedEmails = this.props.failures.map(item => item.email).join('\n');
      this.form.setFieldsValue({ emails: failedEmails });
    }
  }
  //UI
  render() {
    return (
      <Drawer
        placement="right"
        title="Send invitations"
        width={600}
        onClose={this.handleClose}
        open={this.state.isVisible}
        bodyStyle={{ paddingBottom: 20 }}
        footer={this._renderDrawerFooter()}
      >
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Form.Item
            name="emails"
            label="E-mails"
            help="Separate e-mails by line break, space or comma."
          >
            <Input.TextArea rows={7} />
          </Form.Item>

          <Checkbox
            id="reservedSeat"
            checked={this.state.reservedSeat}
            onChange={(e) => this.setState({ reservedSeat: e.target.checked })}
          />
          <strong style={{ marginLeft: 10 }}>Send invitation e-mails for reserved seats</strong>
        </Form>
        {(this.props.failures && this.props.failures.length > 0) && (
          <>
            <h1>These invitations were not sent:</h1>
            <ul>
              {this.props.failures.map((item) => (
                <li key={item.email} style={{ marginTop: 10 }}>
                  <strong>{item.email}</strong>: <br />
                  <Tag color="red">{`Status ${item.status} - ${item.errDesc}`}</Tag>
                </li>
              ))}
            </ul>
          </>
        )}
      </Drawer>
    );
  }
  _renderDrawerFooter() {
    return (
      <div style={{ textAlign: 'right' }}>
        <Button onClick={this.handleClose} style={{ marginRight: 8 }}>
          Cancel
        </Button>
        <Button onClick={this.handleSubmit} type="primary">
          Submit
        </Button>
      </div>
    );
  }
}
