import { List, Typography } from 'antd';
import { CalendarOutlined, ContactsOutlined } from '@ant-design/icons';
import { FaBuilding } from 'react-icons/fa';
import { GiVideoConference } from 'react-icons/gi';
import { HiOutlineStatusOnline } from 'react-icons/hi';
import { RiComputerLine } from 'react-icons/ri';
import Utils from '../../../components/Utils';

export default class CommonPopoversContent { }

/* Informational */
CommonPopoversContent.renderSessionTypesPopoverContent = function() {
  return (
    <div style={{ width: 600 }}>
      <List
        bordered
        dataSource={[
          { icon: <CalendarOutlined />, type: 'Online External (Scheduled) ', description: 'Online External (Scheduled) ', description: 'Scheduled online sessions are delivered over the Internet, offer integration with an external provider (such as D2L) but need be scheduled as a session with a specific date and time.' },
          { icon: <FaBuilding />, type: 'In class (Scheduled)', description: 'In class (Scheduled) sessions are sessions that occur on Venues on a specific date and time.' },
          { icon: <ContactsOutlined />, type: 'VILT', description: 'Scheduled online sessions led by an instructor.'},
          { icon: <GiVideoConference />, type: 'Webinar', description: 'Webinars are not implemented and will be better described in the future.' },
          { icon: <HiOutlineStatusOnline />, type: 'Online External', description: 'Online sessions w/ integration are delivered over the Internet, offer integration with an external provider (such as D2L) and become available to students as soon as they are purchased.' },
          { icon: <RiComputerLine />, type: 'Online', description: 'Online sessions are not implemented and will be better described in the future.' },
        ]}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta avatar={item.icon} title={item.type} description={item.description} />
          </List.Item>
        )}
      />
    </div>
  )
}
CommonPopoversContent.renderCapacityPopoverContent = function() {
  return (
    <div>
      <Typography.Text strong>What's the session capacity?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
        The <strong>maximum</strong> capacity (students) for sessions created using this template.
        <br></br>If blank or -1, means unlimited seats available.
        <br></br>You will be able to change this option when creating sessions from this template!
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderReservedCapacityPopoverContent = function() {
  return (
    <div style={{ maxWidth: 600 }}>
      <Typography.Text strong>What's the session reserved capacity?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
        The number of seats reserved from the <strong>capacity</strong> value. Students will require special invitations to enroll into a session
        and consume a reserved seat. For example, if you have <strong>10 seats available</strong> (capacity) and <strong>have 2 reserved seats</strong>.
        <strong> 8 Users</strong> will be able to enroll by going into the website and selecting the session while the 2 reserved seats
        will only be consumed when you generate a session invitation and give to a student so he can enroll into this session after paying the appropriate fees.
        <br></br><br></br>If blank or -1, means no reserved seats available and all the capacity can be consumed by students.
        <br></br>You will be able to change this option when creating sessions from this template!
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderMinimumCapacityPopoverContent = function() {
  return (
    <div style={{ maxWidth: 600 }}>
      <Typography.Text strong>What's the session minimum capacity?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
        The number of students that are required to be enrolled into sessions created from this template in order to make this session happen.
        If not reached, session will be auto-cancelled and users will be able to select other session from this course.
        <br></br><br></br>If blank or -1, means no minimum seats required to make this session happen.
        <br></br>You will be able to change this option when creating sessions from this template!
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderReleaseTypePopoverContent = function() {
  return (
    <div style={{ maxWidth: 600 }}>
      <Typography.Text strong>What's the session release type?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
        The session release type indicates when a session created using this template becomes available to students after they enroll in it.
        There are 3 types of session release types and they are available if the <strong>session type</strong> allows it.
        <br></br><br></br>
        <li>
          <ul><strong>•</strong><Typography.Text code>None</Typography.Text> Available only to sessions that are <strong>not scheduled</strong>. If selected, this type will make sessions available to students right after their enrolment.</ul>
          <ul><strong>•</strong><Typography.Text code>Manual</Typography.Text> Available to <strong>all session types</strong>. Requires that an administrator or instructor releases a session before it becomes available to students. Manual release can be done per student or per session.</ul>
          <ul><strong>•</strong><Typography.Text code>Auto</Typography.Text> Available only to <strong>scheduled</strong> sessions. Sessions will be released on demand at the time of the session and will close when the session is over.</ul>
        </li>
        <br></br>You will be able to change this option when creating sessions from this template!
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderHasMaterialPopoverContent = function() {
  return (
    <div style={{ maxWidth: 600 }}>
      <Typography.Text strong>What is course material ?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
        The attribute determines if the session has any course material and where it should be shipped to (students or venue).
        There are 3 values for course material:
        <br></br><br></br>
        <li>
          <ul><strong>•</strong><Typography.Text code>None</Typography.Text> Session does not have training material.</ul>
          <ul><strong>•</strong><Typography.Text code>Send to Student</Typography.Text> Session does have training material and it should be sent directly to students. If selected, the system will allow admins to enter training material delivery information for each student individually.</ul>
          <ul><strong>•</strong><Typography.Text code>Send to Venue</Typography.Text> Indicates the session does have training material and it should be sent to a single location (typically course venue). If selected the system will allow admins to enter training material delivery information for the session.</ul>
        </li>
        <br></br>You will be able to change this option when creating sessions from this template!
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderRequiresInstructorPopoverContent = function() {
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography.Text strong>What's 'Requires Instructor'?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
        Indicates if sessions created from this template require one or more instructors to be assigned before they become available to students.
        <br></br>You will be able to change this option when creating sessions from this template!
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderChangeForMaterialsPopoverContent = function() {
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography.Text strong>What's 'Charge for Materials'?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
        Indicates if sessions created from this template will charge for materials.
        <br></br>You will be able to change this option when creating sessions from this template!
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderCourseMaterialProductPopoverContent = function() {
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography.Text strong>What's 'Course Material Product'?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
        The product to be used to charge for shipped materials for the session.
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderIsCourseMaterialShippedPopoverContent = function() {
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography.Text strong>What's 'Course Material Shipped'?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
        If ON, it indicates if the course material was already shipped for the session.
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderCancelationPolicyPopoverContent = function() {
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography.Text strong>What's 'Cancelation Policy'?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
        Determines if students will be able to cancel enrolment to the session and what policy to use in that case.
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderCalendarColorPopoverContent = function() {
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography.Text strong>What's 'Calendar Color'?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
        Indicates which color should be used for this course events on the calendar (users and admins).
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderLockReasonPopoverContent = function() {
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography.Text strong>What's 'Reason for session lock'?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
        If a value is entered, it will be displayed in the sessions calendar view as a reason for a locked session.
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderRequiresAdminReviewPopoverContent = function() {
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography.Text strong>What's 'Requires Admin Review'?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
        Indicates if sessions created from this template require admin review after the instructor closes the course.
        <br></br>You will be able to change this option when creating sessions from this template!
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderAskForAffiliatesPopoverContent = function() {
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography.Text strong>What's 'Ask For Referrals'?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
        Indicates if sessions created from this template will prompt students with a <strong>How did you hear about this ? </strong> question.
        <br></br>You will be able to change this option when creating sessions from this template!
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderSessionPrivatePopoverContent = function() {
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography.Text strong>What's Private Sessions?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
        Private sessions are sessions that are not publicly available to students to enroll on the website. Students will require invitation codes in order to enroll.
        This option is similar to 'reserved seats' but you will have all the capacity automatically reserved and do not need to set a reserved capacity for this session.
        Private sessions will not be displayed in the public training calendar on the website.
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderConsiderFailuresPopoverContent = function() {
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography.Text strong>What's 'Single Quizz Attempt' of online sessions?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
        When enabled, it indicates that online <strong>failed</strong> results will not be ignored by the system and a failure in a quizz will cause the course or exam to fail.
        If disabled and used in conjunction with 'Online Access Period', students will have unlimited attempts until their access expires or they pass the course/exam (SitereadyBC, for example).
        <br></br>You will be able to change this option when creating sessions from this template!
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderOnlineAccessPeriodPopoverContent = function() {
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography.Text strong>What's 'Online Access Period' of online sessions?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
        When this value is larger than 0, it will indicate how long a session be available for students to launch, starting from when the session license was purchased/activated or from when it was released (if session is not auto released).
        A value of 0 or -1 will determine that a session will always be available whereas a value of 30 days will determine that after 30 days from purchase/activation, a student will no longer be able to launch the session.
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderChargeDeferredPopoverContent = function() {
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography.Text strong>What's 'Charge Deferred'?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
        If false (default) indicates that course should be charged upon registration if true, charges will be done at course conclusion.
        <br></br>You will be able to change this option when creating sessions from this template!
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderIsReferralPercentagePopoverContent = function() {
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography.Text strong>What's 'Is Referral Amount Percentage of the purchase value'?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
        if true comission paid for referral is a percentage defined at referral amount. (1.00 means 100% of the purchased value)
        <br></br>You will be able to change this option when creating sessions from this template!
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderIsEligibleForNoCostPopoverContent = function() {
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography.Text strong>What's 'Eligible for No Cost'?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
        If enabled, sessions created from this template will be eligible for No Cost Training by default.
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderConfirmInfoOnEnrolmentPopoverContent = function() {
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography.Text strong>What's 'Confirm info at enrolment'?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
      If this switch is enabled, users will be prompted to confirm their personal and shipping information during the enrolment into this session.
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderClassificationUnitsPopoverContent = function() {
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography.Text strong>What's 'Classification Units'?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
      If no cost purchase is enabled for a session, students whose employers have the selected CUs will be eligible for discount.
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderDiscountPercentagePopoverContent = function() {
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography.Text strong>What's 'Discount %'?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
      This is the percentual value used as discount for students whose employer have the selected CUs.
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderCancelPolicyDescriptionPopoverContent = function(cancelPolicy) {
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography.Text strong>Cancel policy:</Typography.Text>
      {cancelPolicy.cancelPolicy.rules.map(cp => {
        return (
          <Typography.Text stype='secondary' key={cp.untilDaysBefore}>
            <br/>
            Until {cp.untilDaysBefore} {cancelPolicy.cancelPolicy.isBusinessDays ? 'business ' : ''}days the refund amount is {cp.percentageRefunded == -1 ? '100' : cp.percentageRefunded}% of the value paid{cp.amountCharged ? ` minus a flat charge of ${cp.amountCharged}.` : '.'}
            <br/>
          </Typography.Text>
        );
      }).concat([
        <Typography.Text stype='secondary' key='z'>
          <br/>
          Original product price: ${Utils.toCurrencyFormat(cancelPolicy.charges.productPrice)}<br/>
          Price paid: ${Utils.toCurrencyFormat(cancelPolicy.charges.valuePaid)}<br/>
          Shipping charges: ${Utils.toCurrencyFormat(cancelPolicy.charges.shippingCharges)}<br/>
          Total amount charged on cancelation, shipping included: ${Utils.toCurrencyFormat(cancelPolicy.charges.cancelationCharge)}<br/>
          Refund Amount: ${Utils.toCurrencyFormat(cancelPolicy.charges.refundAmount)}<br/>
          <br/>
        </Typography.Text>
      ]).reduce((prev, curr) => [prev, '', curr])}
    </div>
  );
}
CommonPopoversContent.renderConfirmInfoAutoEnrolmentPopoverContent = function() {
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography.Text strong>What's 'Optional Auto Enrol In Session'?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
      If provided, this field should contain the ID of a session. When students enrol in the current session, they will also be automatically enrolled into the session defined by this ID. The use of this feature will allow pre-requisites to be overridden.
      </Typography.Text>
    </div>
  );
}
CommonPopoversContent.renderGradeCheckDelayPopoverContent = function() {
  return (
    <div style={{ maxWidth: 600 }}>
      <Typography.Text strong>What's the 'Grade Check Delay'?</Typography.Text><br></br><br></br>
      <Typography.Text stype='secondary'>
      Sessions with this attribute set will have grade results obtained from the trainer provider ignored unless the number of minutes given by this attribute is past since the last update to the grades inside the provider. This attribute introduces a delay when retrieving grades. 
      </Typography.Text>
    </div>
  );
}
