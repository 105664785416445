import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Table, Row, Col, Tooltip, Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
import CommonInstructorRegionPopover from '../Popovers/CommonInstructorRegionPopover';
//props are: instructorRegions, regions, app, onDelete, onAdd
export default class CommonInstructorRegionsForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: null };
  }

  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'Region', key: 'name',
        sorter: (a, b) => (this.props.regions.find((r) => r.id == a.regionID)?.name || '').localeCompare((this.props.regions.find((r) => r.id == b.regionID)?.name || '')),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        render: (props) => this.props.regions.find((r) => r.id == props.regionID)?.name
      },
      {
        title: 'Added On', key: 'createdOn', width: '15%',
        render: props => props.createdOn ? Utils.getDateOnUIFormatByTimestamp(props.createdOn) : 'N/A',
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order
      },
      {
        title: 'Actions', width: 'auto', key: 'Actions', width: '15%',
        render: props => {
          return (<span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title='Delete'>
              <Popconfirm placement="top" title={`Are you sure that you want to delete the region '${this.props.regions.find((r) => r.id == props.regionID)?.name}' from this instructor?`}
                onConfirm={this.props.onDelete.bind(this, props.regionID)} okText="Yes" cancelText="No" >
                <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle" />
              </Popconfirm>
            </Tooltip>
          </span>);
        }
      },
    ];

    const props = {
      rowKey: 'regionID',
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No regions related to this instructor!' },
      pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] },
    };

    return (
      <>
        <Row type='flex' justify='end'>
          <Col> 
            <CommonInstructorRegionPopover app={this.props.app} onSelect={this.props.onSelect} regions={this.props.regions} instructorRegions={this.props.instructorRegions}>
              <Button style={{ marginBottom: 20 }} type="primary"> Add Region </Button>
            </CommonInstructorRegionPopover>
          </Col>
        </Row>
        <Table className="commonInstructorRegionsTable" columns={columns} dataSource={this.props.instructorRegions || []} {...props} />
      </>
    );
  }
}
