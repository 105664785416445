import React, { createContext } from 'react';

const CourseCardActionsContext = createContext();

export function CourseCardActionsProvider({ value, children }) {
  return (
    <CourseCardActionsContext.Provider value={value}>
      {children}
    </CourseCardActionsContext.Provider>
  );
}

export function CourseCardActionsHOC(Component) {
  return props => {
    return (
      <CourseCardActionsContext.Consumer>
        {actions => (
          <Component actions={actions} {...props} />
        )}
      </CourseCardActionsContext.Consumer>
    );
  };
}
