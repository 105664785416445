import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Select, Row, Col, Button, Table } from 'antd';
import * as ExcelJS from 'exceljs';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import CustomComponent from '../../../components/CustomComponent';
import DateRangeFilter from '../../../components/DateRangeFilter';
import { DownloadOutlined } from '@ant-design/icons';
//
import config from '../../../config/config';
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
export default class AdminApplicationStatusView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      applications: [],
      disabled: true,
      certificationID: this.props.app.sharedCache().getTenantConfig().certifications[0].id,
    };
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  async handleSearch() {
    const certID = this.state.certificationID;
    if (!certID) {
      this.setState({ disabled: true });
      return;
    }
    //Make request
    const resp = await this.props.app.api.application.getApplicationsStatusReport(certID);
    this.setState({ applications: resp.body.applications });
  }

  async generateReportXLSX() {
    const { applications } = this.state;

    if (applications.length < 1) {
      return;
    }

    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Sheet1');

    const app = applications[0];

    const header = [
      'Name',
      'Email',
      'Last Update',
      `${app.courses.name} (${app.courses.total})`,
      `${app.electives.name} (${app.electives.total})`,
    ];

    app.custom.forEach((customReq) => {
      header.push(`${customReq.name} (${customReq.total})`);
    });

    header.push(`Total Completed (${app.total.total})`);

    ws.addRow(header);

    applications.forEach((app) => {
      const row = [
        app.name,
        app.email,
        app.lastUpdate ? Utils.getDateOnUIFormatByTimestamp(app.lastUpdate) : '',
        app.courses.achieved,
        app.electives.achieved,
      ];

      app.custom.forEach((customReq) => {
        row.push(customReq.achieved);
      });

      row.push(app.total.achieved);

      ws.addRow(row);
    });

    const buffer = await wb.xlsx.writeBuffer();
    Utils.downloadArrayBuffer(buffer, `ApplicationStatus`, 'xlsx');
  }

  handleChangeCertification(certificationID) {
    this.setState({ certificationID });
  }

  render() {
    const applications = this.state.applications;

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Email',
        dataIndex: 'email',
      },
      {
        title: 'Last Update',
        dataIndex: 'lastUpdate',
        render: (date) => (date ? Utils.getDateOnUIFormatByTimestamp(date) : '-'),
      },
      {
        title: applications.length > 0
          ? `${applications[0].courses.name} (${applications[0].courses.total})`
          : 'Requirements',
        dataIndex: ['courses', 'achieved'],
        width: '10%',
      },
      {
        title: applications.length > 0
          ? `${applications[0].electives.name} (${applications[0].electives.total})`
          : 'Elective Requirements',
        dataIndex: ['electives', 'achieved'],
        width: '10%',
      },
      ...(applications.length > 0
        ? applications[0].custom.map((custom, index) => ({
          title: `${custom.name} (${custom.total})`,
          dataIndex: ['custom', index, 'achieved'],
          width: '10%',
        }))
        : [
          {
            title: 'Custom Requirements',
            dataIndex: ['custom', 0, 'achieved'],
            width: '10%',
          },
        ]),
      {
        title: applications.length > 0
          ? `Total Completed (${applications[0].total.total})`
          : 'Total Completed',
        dataIndex: ['total', 'achieved'],
        width: '10%',
      },
    ];


    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className="pageHeader" title="Application Status" />
          <Layout.Content>
            <div className="usersFilters">
              <Row justify="space-between">
                <Col className="filterItem">
                  <strong>Certification:</strong>
                  <Select
                    style={{ minWidth: 200, marginRight: 8 }}
                    value={this.state.certificationID}
                    onChange={this.handleChangeCertification}
                  >
                    {this.props.app
                      .sharedCache()
                      .getTenantConfig()
                      .certifications.map((certification) => (
                        <Select.Option key={certification.id} value={certification.id}>
                          {certification.description}
                        </Select.Option>
                      ))}
                  </Select>
                  <Button type="primary" disabled={this.disabled} onClick={this.handleSearch}>
                    Search
                  </Button>
                </Col>
                <Col>
                  <Button type="primary" disabled={this.disabled} onClick={this.generateReportXLSX}>
                    Export to XLSX
                  </Button>
                </Col>
              </Row>
            </div>
            <br />
            <Table
              rowKey="email"
              dataSource={this.state.applications}
              loading={this.state.isLoading}
              columns={columns}
              pagination={{
                hideOnSinglePage: true,
              }}
            />
          </Layout.Content>
        </Layout.Content>
      </>
    );
  }
}
