import React from "react";
import autoBind from 'react-autobind';
import { Popover, Row, Col, Result, Input, Button, Divider, Table } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
//
import Utils from "../../../components/Utils";
//props are: app, onSelect, courses, instructorCourses
export default class CommonInstructorCoursePopover extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isVisible: false, searchTerm: '' };
  }
  //Actions
    //Search actions
  handleChange(e) { this.setState({ searchTerm: e.target.value }); }
    //Table actions
  handleSelectItem(item) { 
    this.popover.onClick(); //hacky way of dismissing non controller popovers.. Blame Gabriel :p
    if (this.props.onSelect) this.props.onSelect(item); 
  }
  //UI
  render() {
    const columns = [
      { title: 'Course', key: 'description', dataIndex: 'description' },
      { title: '', key: 'select', align: 'right',
        render: (item) => (
          <Button type="primary" size="small" onClick={this.handleSelectItem.bind(this, item)}
                  disabled={(this.props.instructorCourses || []).find((i) => i.courseID == item.id)}>
            Add to Instructor
          </Button>
        )
      }
    ];
    const props = {
      rowKey: 'id', locale: { emptyText: 'No courses found!' },
      pagination: { pageSize: 8, showSizeChanger: false, hideOnSinglePage: true, position: 'bottom' }
    };
    return (
      <Popover title="Filter and select a course for this instructor" trigger={['click']} placement='bottomRight' 
               {...Utils.propagateRef(this, 'popover')} content={
          <div style={{ minWidth: '600px', maxWidth: '1200px' }}>
            <Row>
              <Col span={24}>
                <Input prefix={<SearchOutlined />} size="large" placeholder="Filter..." value={this.state.searchTerm} onChange={this.handleChange} autoFocus/>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                {this.props.courses && <Table columns={columns} dataSource={this.props.courses.filter((c) => c.description.toLowerCase().includes((this.state.searchTerm || '').toLowerCase()))} {...props}/>}
              </Col>
            </Row>
          </div>
        }
      >
        {this.props.children}
      </Popover>
    )
  }
}
