import React from 'react';
import autoBind from 'react-autobind';
import { PageHeader, message, Layout, Row, Col, Table, Button, Tooltip, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import CustomComponent from '../../../components/CustomComponent';
import CommonRequirementDrawer from '../../commonComponents/Drawers/CommonRequirementDrawer';
//
import Globals from '../../../config/Globals';
import config from '../../../config/config';
import Utils from '../../../components/Utils';
//
export default class AdminRequirementsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      requirements: [], trainingProviders: [], sortedInfo: { columnKey: 'internalName', order: 'ascend' },
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    this.fetchData();
  }

  async fetchData() {
    this.startLoading();
    const resp = await this._loadAll();
    if (resp) this.setState({ ...resp, isLoading: false });
    else this.stopLoading();
  }

  //Actions
  handleCreateRequirement() { this.requirementDrawer.show(null, this.state.trainingProviders, this.state.requirements); }
    //Table
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
    //Table row actions
  handleEditRequirement(requirementID) { this.requirementDrawer.show(requirementID, this.state.trainingProviders, this.state.requirements); }
  async handleDeleteRequirement(requirementID) {
    this.startLoading();
    const resp = await this._deleteRequirement(requirementID);
    if (resp) await this.fetchData();
    this.stopLoading();
  }
  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader className="pageHeader" title="Requirements" onBack={() => this.props.app.urlManager.pushBack()} />
        <CommonRequirementDrawer {...Utils.propagateRef(this, 'requirementDrawer')} onUpdate={this.fetchData} app={this.props.app} />
        <Layout.Content>
          <Row type='flex' justify='end'>
            <Col>
              <Button style={{ marginBottom: 20 }} type="primary" onClick={this.handleCreateRequirement}> Create new Requirement </Button>
            </Col>
          </Row>
          {this._renderRequirementsTable()}
        </Layout.Content>
      </Layout.Content>
    );
  }

  /* private UI */
  _renderRequirementsTable() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Name (internal)', key: 'internalName', dataIndex: 'internalName',
        sorter: (a, b) => a.internalName.localeCompare(b.internalName),
        sortOrder: sortedInfo.columnKey === 'internalName' && sortedInfo.order
      },
      {
        title: 'Display Name', key: 'name', dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order
      },
      {
        title: 'Card Description', key: 'cardDescription', dataIndex: 'cardDescription',
        sorter: (a, b) => a.cardDescription.localeCompare(b.cardDescription),
        render: (e) => <div style={{ marginTop: '1em' }}><ReactMarkdown children={e || ''} linkTarget='_blank'/></div>,
        sortOrder: sortedInfo.columnKey === 'cardDescription' && sortedInfo.order
      },
      {
        title: '# of Providers', key: 'numbOfProviders',
        render: (e) => `${(e.acceptedProviders || []).length} Providers`,
        sorter: (a, b) => ((a.acceptedProviders || []).length) - ((b.acceptedProviders || []).length),
        sortOrder: sortedInfo.columnKey === 'numbOfProviders' && sortedInfo.order
      },
      {
        title: 'Created On', key: 'createdOn',
        render: props => props.createdOn ? Utils.getDateOnUIFormatByTimestamp(props.createdOn) : 'N/A',
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order
      },
      {
        title: 'Actions', width: 'auto', key: 'Actions',
        render: props => {
          return (<span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title='Edit'>
              <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.handleEditRequirement.bind(this, props.id)} />
            </Tooltip>
            <Tooltip placement="bottomLeft" title='Delete'>
              <Popconfirm placement="top" title={`Are you sure that you want to delete the Requirement '${props.name}'?`} 
                          onConfirm={this.handleDeleteRequirement.bind(this, props.id)} okText="Yes" cancelText="No" >
                <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle"/>
              </Popconfirm>
            </Tooltip>
          </span>);
        }
      },
    ];
    const props = {
      rowKey: 'id', loading: this.state.isLoading, onChange: this.handleFilterChange,
      locale: { emptyText: 'No requirements found!' },
      pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] }
    };
    return (
      <Layout.Content>
        <Table className="adminRequirementsTable" columns={columns} dataSource={this.state.requirements} {...props} />
      </Layout.Content>
    );
  }

  /* private API */
  async _loadAll() {
    const resp = await Promise.all([
      this.props.app.config.requirement.getAllRequirements(),
      this.props.app.config.trainingProvider.getAllProviders()
    ]);
    if (!(resp[0].statusCode == 200 && resp[0].body && resp[0].body.requirements)) {
      this.props.app.alertController.showAPIErrorAlert(null, resp[0]);
      return false;
    } 
    if (!(resp[1].statusCode == 200 && resp[1].body && resp[1].body.trainingProviders)) {
      this.props.app.alertController.showAPIErrorAlert(null, resp[1]);
      return false;
    } return { trainingProviders: resp[1].body.trainingProviders, requirements: resp[0].body.requirements };
  }
  async _deleteRequirement(requirementID) {
    const resp = await this.props.app.config.requirement.deleteRequirement(requirementID);
    if (resp.statusCode == 200) {
      message.success('Requirement deleted with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
}
