import React from 'react';
import autoBind from 'react-autobind';
import { message } from 'antd';

import CustomComponent from '../../components/CustomComponent';
//
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CommonCertificationCollectionView from '../commonSubviews/CommonCertificationCollectionView';
import CommonCertificationViewUserHeader from './CommonCertificationView/CommonCertificationViewUserHeader';

//
import config from '../../config/config';
import Globals from '../../config/Globals';
import DataUtils from '../../components/UtilsCertification';
//
import '../../assets/stylesheets/AdminUserDashboardView.less';
//

export default class CommonUserDashboardView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false, firstLoad: true, isMonoCertification: props.app.sharedCache().isMonoCertification(),
      certifications: [], selectingCertSpecsID: null, user: null
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    this.userID = this.props.match.params[Globals.URL_Path_ID_Placeholder];
    if (this.state.firstLoad && this.userID) this.fetchData();
  }

  //API
  async fetchData() {
    this.startLoading();
    const userID = this.userID;
    const user = await this.props.app.api.user.getByID(userID);
    const userCertsResp = await this.props.app.api.certification.getByUserID(userID);
    this.loadData(user, userCertsResp);
  }
  async loadData(userResp, userCerts) {
    if (userResp.statusCode == 200 && userResp.body &&
        userCerts.statusCode == 200 && userCerts.body && userCerts.body.procs) {
          //Process certifications
      const tenantConfig = this.props.app.sharedCache().getTenantConfig();
      let certifications = DataUtils.processUserCertifications(userCerts.body.procs, tenantConfig.certifications);
      const user = userResp.body;
      //Auto Select certification
      if (this.state.selectingCertSpecsID) {
        const cert = certifications.filter(item => item.certificationID == this.state.selectingCertSpecsID);
        if (cert) this.props.app.urlManager.pushPage(config.ApplicationRoutes.newAdminCertification, {}, cert[0].id, user.id);
      } else {
        //Mono certification mode: Check for auto init or autoselect when number of certifications specs on the system is 1
        if (this.state.isMonoCertification) {
          //Auto select if exists, or create a new one and select if doesn't exists
          if (userCerts.body.procs.length > 0) {
            this.props.app.urlManager.replacePage(config.ApplicationRoutes.newAdminCertification, {}, certifications[0].id, user.id);
            return; //avoid further processing
          } else {
            this.handleStartCertification(tenantConfig.certifications[0].id);
            return;
          }
        }
        //Set state
        this.setState({ user, isLoading: false, firstLoad: false, selectingCertSpecsID: null, certifications });
      }
    } else {
      console.debug(userResp, userCerts);
      this.props.app.alertController.showAPIErrorAlert(null, (userResp.statusCode == 200 ? userCerts : userResp));
      this.setState({ firstLoad: false, isLoading: false, selectingCertSpecsID: null, user: null, certifications: [] })
    }
  }
  //Actions
  async handleStartCertification(certSpecsID) {
    if (!this.state.user) return;
    this.startLoading();
    //Start cert
    const certStarted = await this.props.app.api.certification.createByUserIDAndCertSpecsID(this.state.user.id, certSpecsID);
    if (!this._isMounted) return;
    const certSpecs = this.props.app.sharedCache().getCertificationByID(certSpecsID);
    //Check answer
    if (certStarted.statusCode == 200 && certStarted.body) {
      if (!this.state.isMonoCertification) message.success(`${certSpecs.uiTerm} initiated.`);
      //Flag cert id to be selected
      this.setState({ selectingCertSpecsID: certSpecsID }, this.fetchData);
    } else {
      //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, certStarted);
      this.stopLoading();
    }
  }
  //UI
  render() {
    return (
      <>
        <CommonLoadingView isLoading={this.state.isLoading} />
          {!this.state.isMonoCertification &&
            <div className="application-wrapper">
             {!this.state.isMonoCertification &&
                <CommonCertificationViewUserHeader app={this.props.app} user={this.state.user} showBackButton={false} />   }
            </div> }
            {this.state.certifications.length > 0 && (
              <CommonCertificationCollectionView certifications={this.state.certifications} app={this.props.app}
                                                 customUser={this.state.user} handleStartCertification={this.handleStartCertification}/>
            )}
      </>
    );
  }
}
