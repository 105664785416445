import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Table, Tooltip, Tag, Button, DatePicker, Select, Row, Col } from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import * as ExcelJS from 'exceljs';
//
import CustomComponent from '../../../components/CustomComponent';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
//
import config from '../../../config/config';
import Globals from '../../../config/Globals';
import Utils from '../../../components/Utils';
//
import '../../../assets/stylesheets/AdminSearchUsersView.less';
//
export default class AdminExpiredCertificationReportView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false, isSetPassModalVisible: false, firstLoad: true,
      users: [], selectedEmployer: null,
      ...this._getInitialState(),
    };
  }

  //Life cycle
  componentDidMount() {
    super.componentDidMount();
    // if (this.state.firstLoad) this.fetchData();
  }

  //API
  async fetchData() {
    this.setState({ users: [], total: 0, isLoading: true });
    this._setSearchQueryParams();
    //request
    const resp = await this.props.app.api.user.searchUsersByTerm(
      null, this._getSearchFilter(),
    );
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body && resp.body.users) {
      const users = resp.body.users.map(user => user._source);
      this.setState({ users, total: resp.body.total, firstLoad: true, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }

  //Actions
    //Main actions
  async handleExportXLSX() {
    this.startLoading();
    //Make request
    const resp = await this.props.app.api.user.printSearchUsersByTerm(
      null, this._getSearchFilter(),
    );
    if (resp.statusCode == 200) {
      Utils.downloadBlob(resp.body, `certificateExpiration`, 'xlsx');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading();
  }
  handleSearch() {
    this.setState({ sortedInfo: null, currentPage: 1 }, () => {
      this.fetchData();
    });
  }
    //Table
  handleFilterChange(pagination, filters, sortedInfo) {
    this.setState({ sortedInfo }, () => {
      this.fetchData();
    });
  }
  onRowSelection(val) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.userDashboard, {}, val.id); }
  handlePagination(currentPage) {
    this.setState({ currentPage }, () => {
      this.fetchData();
    })
  }
    //Custom filter
  handleChangeFilter = (type) => (value) => {
    // validating by type so we can set multiple filter types with only one state change
    // and encapsulate filters change logic in only one method
    // this is useful today only for registered date range
    const filter = !Array.isArray(value) && typeof value == 'object' ? value : { [type]: value };
    this.setState(prevState => ({
      filters: { ...prevState.filters, ...filter },
    }));
  };
  handleRangeDateChange(date) {
    let [certExpDate1, certExpDate2] = (date || []);
    certExpDate1 = (certExpDate1 ? certExpDate1.format('YYYY-MM-DD') : null);
    certExpDate2 = (certExpDate2 ? certExpDate2.format('YYYY-MM-DD') : null);

    this.setState({ momentRange: date }); // used to populate when state is cached
    this.handleChangeFilter(null)({ certExpDate1, certExpDate2 });
  }
  //UI
  render() {
    const props = { rowKey: 'id', loading: this.state.isLoading, onChange: this.handleFilterChange, scroll: { x: true },
                    locale: {emptyText: (this.state.firstLoad ? 'Search users' : 'No users found!')},
                    pagination: {
                      pageSize: Globals.Table_PagingItemsPerPage, showSizeChanger: false, hideOnSinglePage: true, position: ['bottomCenter'],
                      total: this.state.total, onChange: this.handlePagination, current: this.state.currentPage
                    }
                  };
    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
            <PageHeader className="pageHeader" title="Expired Certifications" />
            <Layout.Content>
              {this._renderFilters()}
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="primary" icon={<DownloadOutlined />} onClick={this.handleExportXLSX}
                  disabled={this.state.users.length < 1} style={{ margin: '15px 0' }}> Export to xlsx </Button>
              </div>
              <Table className="adminSearchUsersTable" onRow={this._onRow} columns={this._getTableColumns()} dataSource={this.state.users} {...props}/>
              <Row className="userSearchTotalRow" type="flex" align="center">
                <Tag className="userSearchTotalLabel" icon={<SearchOutlined/>}>{this.state.total} users found</Tag>
              </Row>
            </Layout.Content>
        </Layout.Content>
      </>
    );
  }

  /* UI privates renders */
  _renderFilters() {
    return (
      <div className="usersFilters">
        <Row type='flex'>
          <Col className="filterItem">
            <strong>Certification:</strong>
            <Select style={{ minWidth: 200 }} value={this.state.filters.certification} size="large"
              onChange={this.handleChangeFilter('certification')}>
              <Select.Option value="ALL">All</Select.Option>
              {this.props.app.sharedCache().getTenantConfig().certifications.map(certification => (
                <Select.Option key={certification.id} value={certification.id}>{certification.description}</Select.Option>
              ))}
            </Select>
          </Col>
          <Col className="filterItem">
            <strong>Expiry Date:</strong>
            <DatePicker.RangePicker defaultValue={this.state.momentRange} size="large" onChange={this.handleRangeDateChange} />
          </Col>
          <Col>
            <Button type="primary" size="large" style={{ marginLeft: 4 }}
              onClick={this.handleSearch.bind(this)}>Filter</Button>
          </Col>
        </Row>
      </div>
    );
  }

  /* UI Helpers */
  _getTableColumns() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Name', key: 'fullName.keyword', width: '20%', sorter: true,
        render: user => ( <Tooltip title={user.email}> {user.fullName} </Tooltip> ),
        sortOrder: sortedInfo.columnKey === 'fullName.keyword' && sortedInfo.order,
      },
      {
        title: 'Email', key: 'email.keyword', width: '20%', sorter: true,
        render: user => user.email,
        sortOrder: sortedInfo.columnKey === 'email.keyword' && sortedInfo.order,
      },
      {
        title: 'Certificate number', dataIndex: 'memberNumber', key: `memberNumber.${this.state.filters.certification}.keyword`, width: '20%', sorter: this.state.filters.certification !== 'ALL',
        render: (memberNumber) => !memberNumber ? '-' : Object.entries(memberNumber).map(([certID, number]) => (
          (this.state.filters.certification === certID || this.state.filters.certification === 'ALL') && <div key={certID}>{number} {this._getCertificationName(certID)}</div>
        )),
        sortOrder: sortedInfo.columnKey === `memberNumber.${this.state.filters.certification}.keyword` && sortedInfo.order,
      },
      {
        title: 'Completion date', dataIndex: 'certificationDate', key: `certificationDate.${this.state.filters.certification}`, width: '20%', sorter: this.state.filters.certification !== 'ALL',
        render: (certificationDate) => (
          !certificationDate
            ? 'N/A'
            : Object.entries(certificationDate).map(([certID, timestamp]) => (
              (this.state.filters.certification === certID || this.state.filters.certification === 'ALL') && <div key={certID}>{Utils.getDateOnUIFormatByTimestamp(timestamp)} {this._getCertificationName(certID)}</div>
            ))
        ),
        sortOrder: sortedInfo.columnKey === `certificationDate.${this.state.filters.certification}` && sortedInfo.order,
      },
      {
        title: 'Expiration Date', dataIndex: 'certificationExpirationDate', key: `certificationExpirationDate.${this.state.filters.certification}`, width: '20%', sorter: this.state.filters.certification !== 'ALL',
        render: (certificationExpirationDate) => (
          !certificationExpirationDate
            ? 'N/A'
            : Object.entries(certificationExpirationDate).map(([certID, timestamp]) => (
              (this.state.filters.certification === certID || this.state.filters.certification === 'ALL') && <div key={certID}>{Utils.getDateOnUIFormatByTimestamp(timestamp)} {this._getCertificationName(certID)}</div>
            ))
        ),
        sortOrder: sortedInfo.columnKey === `certificationExpirationDate.${this.state.filters.certification}` && sortedInfo.order,
      },
    ];
    return columns;
  }
  _onRow(record) {
    return {
      onClick: (e) => {
        const elementsToPreventClick = ['svg', 'path', 'button'];
        if (elementsToPreventClick.includes(e.target.tagName.toLowerCase())) return;
        this.onRowSelection(record);
      }, // click row
      onDoubleClick: () => {
        this.onRowSelection(record);
      }, // double click row
    };
  };

  //Filters and URL support
  _getInitialState() {
    let pState = {};
    try { pState = JSON.parse(Buffer.from(this.props.app.idm.urlmanager.getParam(Globals.URL_Path_Filters), 'base64').toString('ascii') || '{}'); } catch (e) {}
    const certExpDate1 = pState?.filters?.certExpDate1 || null;
    const certExpDate2 = pState?.filters?.certExpDate2 || null;
    const currentPage = pState?.currentPage || 1;
    const columnKey = pState?.sortedInfo?.columnKey || 'createdOn';
    const order = pState?.sortedInfo?.order  || 'descend';
    const certification = pState?.filters?.certification || 'ALL';
    const courseSpecsID = pState?.filters?.courseSpecsID || [];
    
    const momentRange = [];
    if (certExpDate1) { momentRange.push(moment(new Date(certExpDate1))); }
    if (certExpDate2) { momentRange.push(moment(new Date(certExpDate2))); }

    return {
      momentRange,
      filters: {
        courseSpecsID,
        certExpDate1: certExpDate1 || null,
        certExpDate2: certExpDate2 || null,
        certification,
      },
      currentPage, sortedInfo: { columnKey, order },
      total: 0
    };
  }
  _setSearchQueryParams() {
    const cleanState = this.state;
    delete cleanState.users;
    this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_Filters, Buffer.from(JSON.stringify(cleanState)).toString('base64'));
  }
  _getSearchFilter() {
    const filters = { ...this.state.filters };
    //cert
    filters.certSpecsID = filters.certification;
    delete filters.certification;
    if ((!filters.certSpecsID || filters.certSpecsID == 'ALL') || this.props.app.sharedCache().isMonoCertification()) delete filters.certSpecsID;
    //page
    const from = (Globals.Table_PagingItemsPerPage * (this.state.currentPage - 1));
    //sort
    const sortField = (this.state.sortedInfo && this.state.sortedInfo.order && this.state.sortedInfo.columnKey ? this.state.sortedInfo.columnKey : null);
    const sortOrder = (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.order : null);
    return { from, sortField, sortOrder: (sortOrder == 'descend' ? 'desc' : 'asc'), ...filters };
  }

  // Utils
  _getCertificationName(certID) {
    if (this.props.app.sharedCache().isMonoCertification()) return null;
    return `(${this.props.app.sharedCache().getCertificationByID(certID).description})`;
  }
}
