import React from 'react';
import autoBind from 'react-autobind';

import Utils from '../../../../../components/Utils';
import CommonSessionResultModal from '../../../../commonComponents/Modals/CommonSessionResultModal';
import CommonCoursePurchaseModal from '../../../../commonComponents/Modals/CommonCoursePurchaseModal';
import CommonCourseLicenseRedeemModal from '../../../../commonComponents/Modals/CommonCourseLicenseRedeemModal';
import CommonCourseScheduleModal from '../../../../commonComponents/Modals/CommonCourseScheduleModal';

export default class CourseTabViewModals extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isPurchaseModalVisible: false, currentCourse: null, isActivation: false };
  }
  /***** Public *****/
  openPurchaseLicenseModal(courseSpecs, sessionID) {
    if (!this.state.isPurchaseModalVisible) this.purchaseModal.loadModalInfoWithCourseSpec(courseSpecs);
    if (sessionID) this.purchaseModal.handleSessionSelection(sessionID);
    this.setState(prevState => ({ isPurchaseModalVisible: !prevState.isPurchaseModalVisible }), () => {
      if (!this.state.isPurchaseModalVisible) this.props.onUpdate();
    });
  }
  openAddResultModal(currentCourse) {
    this.setState({ currentCourse }, () => {
      this.resultModal.show(currentCourse.sessionID, currentCourse.courseID, this.props.user);
    });
  }
  openRedeemModal(currentCourse, isActivation) {
    this.setState({ currentCourse, isActivation }, () => this.redeemModal.show());
  }
  openScheduleModal(currentCourse) {
    this.setState({ currentCourse }, () => this.scheduleModal.show());
  }
  /***** Actions *****/
  handleChangeResultModal(isCancel) {
    this.setState({ currentCourse: null });
    if (!isCancel) this.props.onUpdate();
  }
  handleChangeRedeemModal(isCancel) {
    this.setState({ currentCourse: null, isActivation: false });
    if (!isCancel) this.props.onUpdate();
  }
  handleChangeScheduleModal(isCancel) {
    this.setState({ currentCourse: null });
    if (!isCancel) this.props.onUpdate();
  }

  //UI
  render() {
    return (
      <>
        <CommonCourseLicenseRedeemModal
          {...Utils.propagateRef(this, 'redeemModal')}
          app={this.props.app}
          isActivation={this.state.isActivation}
          course={this.state.currentCourse}
          onChange={this.handleChangeRedeemModal}
          user={this.props.user}
        />
        <CommonCoursePurchaseModal
          {...Utils.propagateRef(this, 'purchaseModal')}
          user={this.props.user}
          certification={this.props.certificationProcess}
          isVisible={this.state.isPurchaseModalVisible}
          autoRedeem
          fixedQuantity
          invitationCode={this.props.invitationCode}
          onChange={this.openPurchaseLicenseModal}
          app={this.props.app}
          onRequiresAttention={modal => {
            this.purchaseModal = modal;
            this.openPurchaseLicenseModal();
          }}
        />
        <CommonSessionResultModal
          {...Utils.propagateRef(this, 'resultModal')}
          app={this.props.app}
          certification={this.props.certificationProcess}
          course={this.state.currentCourse}
          onChange={this.handleChangeResultModal}
        />
        <CommonCourseScheduleModal
          {...Utils.propagateRef(this, 'scheduleModal')}
          app={this.props.app}
          course={this.state.currentCourse}
          onChange={this.handleChangeScheduleModal}
          user={this.props.user}
        />
      </>
    )
  }
}
