import React from 'react';
import autoBind from 'react-autobind';
import { Form, Input, Divider, Col, Row, Alert, InputNumber, Button, Tooltip, Typography, Spin } from 'antd';
import { isMobile } from 'react-device-detect';
//
import { PAYMENT_TYPE } from './CommonLicensePurchaseModalStep_PaymentView_Federated';
import Utils from '../../../../components/Utils';
//
//props are: app, user, onCancel, onNext, product, gatewayURL, selectedSession
//chargingAmount, quantity, taxAmount, totalAmount, productObject, autoRedeem
const styleHidden = { style: { opacity: 0 } };
export default class CommonLicensePurchaseModalStep_SummaryView_Braintree extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.paymentProcessorInstance = null;
    this.state = {
      firstName: '', lastName: '', purchaserName: 'loading...', 
      purchaseMethod: '',  paymentMethod: PAYMENT_TYPE.CARD,
      loadingBraintree: false
    };
  }
  //Life cycle
  componentWillUnmount() {
    this._releaseDropInListeners();
    if (this.paymentProcessorInstance) this.paymentProcessorInstance.teardown();
  }
  //
  setSensitiveInfo(data) {
    this.paymentProcessorInstance = null;
    this.setState({
      purchaserName: `${data.firstName} ${data.lastName}`,
      firstName: data.firstName, lastName: data.lastName, paymentMethod: data.paymentMethod,
      purchaseMethod: (data.paymentMethod == PAYMENT_TYPE.CARD ? '(Credit Card)' : 'Invoice'),
      loadingBraintree: (data.paymentMethod == PAYMENT_TYPE.CARD)
    }, () => {
      //load braintree
      if (data.paymentMethod == PAYMENT_TYPE.CARD) this._loadBraintreeSDK();
    });
  }
  //Validation
  async validateFields() { return this.form.validateFields(); }
  //Actions
  async handleSubmit() {
    if (this.state.paymentMethod == PAYMENT_TYPE.CARD) {
      //save current changes com bt component
      const nonce = await this.paymentProcessorInstance.requestPaymentMethod();
      if (!nonce) return;
    }
    //
    await this.props.onNext(this.state);
    //
    if (this.state.paymentMethod == PAYMENT_TYPE.CARD) this._releaseDropInListeners();
  }
  async handlePreviousAction() {
    const resp = await this.form.validateFields();
    if (this.state.paymentMethod == PAYMENT_TYPE.CARD) this._releaseDropInListeners();
    if (resp) this.props.onPrevious({ ...resp, ...this.state});
  }
  //UI
  render() {
    return (
      <Form {...Utils.propagateRef(this, 'form')} key='summary' id='summary'>
        {/* show expiration alert */ 
          this.props.autoRedeem && this.props.selectedSession && this.props.selectedSession.onlineAccessPeriod > 0 &&
          <Alert style={{ marginTop: 10 }} type="warning" showIcon 
                 message={`Once you complete this operation, you will have ${Utils.getIntervalString(this.props.selectedSession.onlineAccessPeriod)} to complete ${this.props.productObject.description}.
                           Your license will expire on ${Utils.getDateAndTimeOnPrintFormatByTimestamp(Utils.timestampAfterMinutesAtEOD(this.props.selectedSession.onlineAccessPeriod))}.`}/>
        }
        {this.state.paymentMethod == PAYMENT_TYPE.CARD ? this._renderBraintreeCheckout() : this._renderInvoiceSummary()}
        {this._renderButtonsFooter()}
      </Form>
    );
  }
  /* Private UI */
  _renderBraintreeCheckout() {
    return (
      <Row type='flex' align='center'>
        <div style={{ width: '90%', height: (this.state.loadingBraintree ? 250 : 450), marginTop: 20 }}>
          {this.state.loadingBraintree && <Row type='flex' justify='center' style={{ marginTop: '130px' }} >
            <Col> <Spin spinning size="large"></Spin> </Col>
          </Row>}
          <div {...(this.state.loadingBraintree ? styleHidden : { style: { opacity: 1 } })} id="paymentProcessorContainer"></div>
        </div >
      </Row>
    );
  }
  _renderInvoiceSummary() {
    const discount = (this.props.discountAmount > 0 ? this.props.discountAmount * this.props.quantity * -1 : 0);
    return (
      <>
        <Divider orientation="left">Order Summary</Divider>
        <Row type="flex" justify="start">
          <Typography.Text strong>Please, confirm the order information carefully:</Typography.Text>
        </Row>
        <Divider orientation="left" className='subdividers'>Order</Divider>
        <Row type="flex" justify="center">
          <Col span={(isMobile ? 24 : 8)}>
            <div className='purchaseModalOverviewTableHeader'>
              <Typography.Text> Product </Typography.Text>
            </div>
            <Form.Item>
              <Input value={this.props.product.name} disabled className="purchaseModalInputBoderless" />
            </Form.Item>
          </Col>
          <Col span={(isMobile ? 24 : 3)}>
            <div className='purchaseModalOverviewTableHeader'>
              <Typography.Text> Price </Typography.Text>
            </div>
            <Form.Item>
              <Tooltip title={"The original price of the product."} placement="topLeft">
                <InputNumber name='amount' value={this.props.chargingAmount} decimalSeparator="." precision={2} disabled
                  formatter={Utils.defaultCurrenyInputFormatter} parser={Utils.defaultCurrentInputParser} className='purchaseModalInputBoderless' />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={(isMobile ? 0 : 2)}>
            <div className='purchaseModalOverviewTableHeader'> &nbsp; </div>
          </Col>
          <Col span={(isMobile ? 24 : 2)}>
            <div className='purchaseModalOverviewTableHeader'>
              <Typography.Text> Quantity </Typography.Text>
            </div>
            <Form.Item name='quantity'>
              <Tooltip title={"The number of licenses (activations) you are purchasing."} placement="topLeft">
                <InputNumber precision={0} step={1} min={0} value={this.props.quantity} disabled className='purchaseModalInputBoderless' />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={(isMobile ? 0 : 6)}>
            <div className='purchaseModalOverviewTableHeader'> &nbsp; </div>
          </Col>
          <Col span={(isMobile ? 24 : 3)}>
            <div className='purchaseModalOverviewTableHeader'>
              <Typography.Text> Subtotal </Typography.Text>
            </div>
            <Form.Item>
              <InputNumber disabled precision={2} decimalSeparator="." value={this.props.chargingAmount * this.props.quantity}
                formatter={Utils.defaultCurrenyInputFormatter} parser={Utils.defaultCurrentInputParser} className="purchaseModalInputBoderless" />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={(isMobile ? 12 : 2)} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Discount </Typography.Text>
          </Col>
          <Col span={(isMobile ? 12 : 3)}>
            <Form.Item>
              <Tooltip title={"Discounts can be applied by using cupom codes."} placement="topLeft">
                <InputNumber precision={2} decimalSeparator="." disabled formatter={Utils.defaultCurrenyInputFormatter}
                  parser={Utils.defaultCurrentInputParser} value={discount} className="purchaseModalInputBoderless" />
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={(isMobile ? 12 : 2)} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Tax </Typography.Text>
          </Col>
          <Col span={(isMobile ? 12 : 3)}>
            <Form.Item>
              <InputNumber precision={2} decimalSeparator="." disabled formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser} value={this.props.taxAmount} className="purchaseModalInputBoderless" />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={(isMobile ? 12 : 2)} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Total </Typography.Text>
          </Col>
          <Col span={(isMobile ? 12 : 3)}>
            <Form.Item>
              <InputNumber precision={2} decimalSeparator="." disabled formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser} className="purchaseModalInputBoderless" value={this.props.totalAmount} />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left" className='subdividers'>Billing</Divider>
        <Row type="flex" justify="start">
          <Col span={(isMobile ? 24 : 12)}>
            <Typography.Text>Payment method: {this.state.purchaseMethod}</Typography.Text>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={(isMobile ? 24 : 12)}>
            <Typography.Text>Billing name: {this.state.purchaserName}</Typography.Text>
          </Col>
        </Row>
      </>
    );
  }
  _renderButtonsFooter() {
    const isCard = (this.state.paymentMethod == PAYMENT_TYPE.CARD);
    const hasPaymentMethod = (this.paymentProcessorInstance ? this.paymentProcessorInstance.isPaymentMethodRequestable() : false);
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button className="purchaseModalCancelButton" disabled={this.props.isLoading} key="back" onClick={this.props.onCancel}> Cancel </Button>
        <Button disabled={this.props.isLoading} onClick={this.handlePreviousAction}> Previous </Button>
        {(!isCard || hasPaymentMethod) && 
          <Button key="submit" type="primary" loading={this.props.isLoading} disabled={this.props.isLoading}
                  onClick={this.handleSubmit} className='purchaseModalConfirmationButton'> Submit </Button>
        }
      </Row>
    );
  }
  /* Private Braintree */
  async _loadBraintreeSDK() {
    console.log('load', this.props.user)
    const { email, firstName, lastName } = this.props.user;
    const nonce = await this.props.app.sharedCache().getVaultPaymentNonceByExternalID(this.props.user.id, { email, firstName, lastName });
    this.paymentProcessorInstance = await this.props.app.paymentManager.initializePaymentFlowSDKOnContainer('#paymentProcessorContainer', nonce);
    this._setupDropInListeners();
    this.setState({ loadingBraintree: false });
  }
    //Listeners
  _setupDropInListeners() {
    if (!this.paymentProcessorInstance) return;
    this.paymentProcessorInstance.on('paymentMethodRequestable', this._onPaymentMethodRequestable);
    this.paymentProcessorInstance.on('noPaymentMethodRequestable', this._onNoPaymentMethodRequestable);
  }
  _releaseDropInListeners() {
    if (!this.paymentProcessorInstance) return;
    this.paymentProcessorInstance.off('paymentMethodRequestable', this._onPaymentMethodRequestable);
    this.paymentProcessorInstance.off('noPaymentMethodRequestable', this._onNoPaymentMethodRequestable);
  }
  _onPaymentMethodRequestable(event) { this.forceUpdate(); }
  _onNoPaymentMethodRequestable() { this.forceUpdate(); }
}
