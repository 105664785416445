import React from "react";
import autoBind from 'react-autobind';
import { Layout, Row, Col, Switch, InputNumber, Select, Form, Input, Divider } from 'antd';
//
import Utils from "../../../components/Utils";

export default class CommonProductForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Public
  resetFields() { this.form.resetFields(); }
  setFieldsValue(data) { this.form.setFieldsValue({ ...data }); }
  async validateFields() {
    const formData = await this.form.validateFields();
    if (!formData) return false;
    return formData;
  }

  //Life cycle
  componentDidUpdate(prevProps) {
    const data = this.props.product;
    if (prevProps.product != data && data) this.setFieldsValue(data);
  }
  //Actions

  //UI
  render() {
    return (
      <Layout.Content>
        {/* Form */}
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')} initialValues={{ expirationMode: 2, unitOfMeasure: 'EACH', allowedProviders: 'BRAINTREE', chargeGST: true, }}>
          <Row type='flex'>
            <Col span={8} offset={1}>
              <Form.Item name="id" label="Product ID" rules={[
                { required: true, message: 'Please, type the product ID!' },
              ]}>
                <Input disabled={this.props.isEdit} />
              </Form.Item>
            </Col>
            <Col span={10} offset={3}>
              <Form.Item name="name" label="Name" rules={[
                { required: true, message: 'Please, type the product name!' },
              ]}>
                <Input disabled={this.props.isEdit} />
              </Form.Item>
            </Col>
          </Row>
          {/* Product details informations */}
          {this._renderProductDetailsSubsection()}
          {/* Payment Provider */}
          {this._renderPaymentProviderSubsection()}
        </Form>
      </Layout.Content>
    );
  }
  /* subsections */
  _renderProductDetailsSubsection() {
    const tax = this.props.app.sharedCache().getTenantConfig().customer.salesTaxLabel;

    return (
      <>
        <Divider orientation="left">Product Details</Divider>
        <Row type='flex'>
          <Col span={8} offset={1}>
            <Form.Item name="shortName" label="Short Description" rules={[{ required: true, message: 'Short description is required!' }]} extra='Short description for product.'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} offset={3}>
            <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Description is required!' }]} extra='Full product description.'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={4} offset={1}>
            <Form.Item name="validityPeriod" label="Validity (Years)" extra='Number of years the license from this product is valid for - Takes precende over validityPeriodInMonths and validityPeriodInDays'>
              <InputNumber min={0} style={{ width: 200 }} />
            </Form.Item>
          </Col>
          <Col span={4} offset={2}>
            <Form.Item name="validityPeriodInMonths" label="Validity (Months)" extra='Number of months the license from this product is valid for - Takes precende over validityPeriodInDays'>
              <InputNumber min={0} style={{ width: 200 }} />
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item name="validityPeriodInDays" label="Validity (Days)" extra='Number of days the license from this product is valid for'>
              <InputNumber min={0} style={{ width: 200 }} />
            </Form.Item>
          </Col>
          <Col span={4} offset={4}>
            <Form.Item name="hideInPurchaseDialog" valuePropName='checked' label="Prevent purchase" extra='If enabled, product will not be offered to users when purchasing licenses.'>
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={5} offset={1}>
            <Form.Item name="expirationMode" label="Valid from" rules={[{ required: true, message: 'Valid from is required!' }]}>
              <Select mode='single' style={{ width: '100%' }}>
                <Select.Option value={1} key={1}>Expires from purchase date</Select.Option>
                <Select.Option value={2} key={2}>Expires from license activation date</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item name="chargeGST" valuePropName='checked' label={tax} extra={`If enabled, ${tax} will be charged during purchases.`}>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item name="generateIndividualKeys" valuePropName='checked' label="Single license key" extra='If enabled, one individual license key will be generated for each license of the product when more than 1 license is purchased in a single order.'>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item name="invoiceIsDisabled" valuePropName='checked' label="Disable Invoice" extra='When enabled, users will not be offered the option to use invoice purchasing.'>
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }
  _renderPaymentProviderSubsection() {
    return (
      <>
        <Divider orientation="left">Payment Provider</Divider>
        <Row type='flex'>
          <Col span={4} offset={1}>
            <Form.Item name="price" label="Price" rules={[{ required: true, message: 'Price is required!' }]}>
              <InputNumber min={0} style={{ width: 200 }} />
            </Form.Item>
          </Col>
          <Col span={6} offset={3}>
            <Form.Item name="productCode" label="Provider product code" rules={[{ required: true, message: 'Provider product code is required!' }]} extra='Product code on payment provider'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6} offset={4}>
            <Form.Item name="productDescription" label="Provider description" rules={[{ required: true, message: 'Provider description is required!' }]} extra='Description of the product used on payment provider'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={6} offset={1}>
            <Form.Item name="commodityCode" label="Commodity Code" rules={[{ required: true, message: 'Commodity Code is required!' }]} extra='Payment providers use this attribute as a classification for products. It can be used for grouping in reports.'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item name="unitOfMeasure" label="Unit of measure" rules={[{ required: true, message: 'Unit of measure is required!' }]} extra='Required by payment providers.'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item name="alternateTaxID" label="Alternate tax ID" extra='Optional alternate tax id used on payment provider.'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item name="productURL" label="Product URL" rules={[{ required: true, message: 'Product URL is required!' }]} extra='Link to a page that describes the product or is related to it.'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={4} offset={1}>
            <Form.Item name="allowedProviders" label="Payment providers" rules={[{ required: true, message: 'Payment providers is required!' }]}>
              <Select mode='single'>
                <Select.Option value={'BRAINTREE'} key={'BRAINTREE'}>Braintree</Select.Option>
                <Select.Option value={'MONERIS'} key={'MONERIS'}>Moneris</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
}
