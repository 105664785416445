import React from 'react';
import autoBind from 'react-autobind';
import { Row, Col, Card, Button, Divider, Tag, Typography, Descriptions } from 'antd';
import Utils from '../../../../components/Utils';
import { MdComputer } from 'react-icons/md';
//props are: onSelect, onCancel, courseSpecs, sessions, app
export default class CommonCoursePurchaseModalSubStep_OnlineSessionSelection extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //UI
  render() {
    const uiTerm = this.props?.courseSpecs?.uiTerm?.toLowerCase() || '';
    return (
      <div>
        <Divider orientation='left'>Select a session:</Divider>
        <Row type='flex' justify='center' align='middle'>
          {this.props.sessions?.map((session, i) => {
            return (
              <Col offset={i != 0 ? 1 : 2} key={i}>
                <Card hoverable style={{ width: 300, marginBottom: 20, marginTop: 20 }} onClick={() => this.props.onSelect(session.id)}>
                  <Row type='flex' justify='start' align='middle' style={{ height: 110 }}>
                    <Col span={6}> <MdComputer size={40} /> </Col>
                    <Col span={15}>
                      <Typography.Text strong>{`Online ${Utils.capitalizeString(uiTerm)}`}</Typography.Text><br />
                      <Descriptions size='small' column={2}>
                        <Descriptions.Item span={2} label="Name">{session?.name}</Descriptions.Item>
                      </Descriptions>
                    </Col>
                  </Row>
                  <Row type='flex' justify='center' style={{ marginTop: 10 }}> <Tag color={this.props.app.themeManager.primaryColor}> Select </Tag> </Row>
                </Card>
              </Col>
            );
          })}
        </Row>
        {this._renderButtonsFooter()}
      </div>
    );
  }
  _renderButtonsFooter() {
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button form="summary" htmlType="submit" key="submit" type="secondary" onClick={this.props.onCancel}> Cancel </Button>
      </Row>
    );
  }
}
