import React from 'react';
import autoBind from 'react-autobind';
import { Typography, Select, Checkbox, Space } from 'antd';
//
import '../../assets/stylesheets/CommonSearchBar.less';

export default class CommonAssignedLicenseSearchBar extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { products: props.app.sharedCache().products, product: null, consumed: false };
  }

  async handleSelectProduct(value) {
    const selectedProduct = this.state.products.find(product => product.id === value);
    this.setState({ selectedProduct }, () => {
      this.props.onUpdateSearchObj({ product: selectedProduct });
    });
  }

  async handleConsumedChange(event) {
    const consumed = event.target.checked;
    this.setState({ consumed }, () => {
      this.props.onUpdateSearchObj({ consumed });
    });
  }

  // UI
  render() {
    return (
      <div className="commonSearchBar">
        {this._renderAdditionalFilters()}
      </div>
    );
  }

  /* sub renders */
  _renderAdditionalFilters() {
    return (
      <div className="searchFilterRow">
        <Space>
          <Typography.Text strong>Product:</Typography.Text>
          <Select allowClear value={this.state.selectedProduct?.id} onChange={this.handleSelectProduct} style={{width: '400px' }}
            placeholder="Filter by product">
              {this.state.products.map((product) => (
                <Select.Option key={product.id} value={product.id}>
                  {product.description}
                </Select.Option>
              ))}
          </Select>
        </Space>
        <Space>
          <Typography.Text strong style={{ marginLeft: 16 }}>Show activated licenses:</Typography.Text>
          <Checkbox onChange={this.handleConsumedChange}/>
        </Space>
      </div>
    );
  }

  /* Event handlers */
  _handleProductChange(value) {
    this.setState({ product: value }, () => {
      this.props.onUpdateSearchObj(this.state);
    });
  }

  _handleSearch() {
    const { product, consumed } = this.state;
    const searchObj = {
      product: product,
      consumed: consumed,
    };
    this.props.onSearch(searchObj);
  }
}
