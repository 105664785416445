import React from 'react';
import { Row, Col } from 'antd';
import { CalendarFilled } from '@ant-design/icons';
//
import CourseCardActions from '../Subcomponents/CourseCardActions';
//
export default class ReadyToSchedule extends React.Component {
  render() {
    const { courseSpecs, app, certificationProcess, session } = this.props;
    return (
      <>
        <Row justify="center">
          <Col span={12} style={{ textAlign: 'center' }}>
            Click the schedule button to book or select your {courseSpecs.description} session.
          </Col>
        </Row>
        <CourseCardActions app={app} disableActions={this.props.disableActions} courseSpecs={courseSpecs} certificationProcess={certificationProcess} session={session} actionSchedule/>
      </>
    );
  }
}
ReadyToSchedule.CardStatusHeader = function({ courseSpecs }) {
  return <span className="warning"><CalendarFilled /> Schedule your {courseSpecs.uiTerm?.toLowerCase()}</span>;
}
