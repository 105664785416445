import React from 'react';
import { Button, Col, Dropdown, Row, Menu, Tag, Tooltip } from 'antd';
import { CaretDownFilled, DownloadOutlined } from '@ant-design/icons';
import autoBind from 'react-autobind';
//
import CustomComponent from '../../../components/CustomComponent';
import '../../../assets/stylesheets/AdminReviewApplicationHeader.less';
import Globals from '../../../config/Globals';
import Utils from '../../../components/Utils';

export default class AdminReviewApplicationHeader extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      shrink: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    document.getElementById('main-content').addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    document.getElementById('main-content').removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const element = document.getElementById('main-content');

    if (element.scrollTop >= 115) {
      this.setState({ shrink: true });
    } else {
      this.setState({ shrink: false });
    }
  }

  render() {
    const { certificationProcess, applicationProcess } = this.props;

    if (!certificationProcess) {
      return null;
    }

    const isPreApproval = applicationProcess.state == Globals.ApplicationState.PRE_APPROVAL;
    const isWaitingApproval = applicationProcess.state == Globals.ApplicationState.WAITING_APPROVAL;

    const isApplicationApproved = !!applicationProcess.approvedBy;
    const isApplicationRejected = !!applicationProcess.rejectedBy;

    return (
      <Row className={`application-review-header ${this.state.shrink ? 'shrink' : ''}`} justify="space-between">
        <Col className="certification-details">
          {this._renderCertificationDetails(isPreApproval)}

        </Col>
        <Row align='middle'>
          <Tooltip title='Download Application files'>
            <Button key='downloadapplication' type="primary" icon={<DownloadOutlined />} onClick={this.props.onDownloadApplication}/>
          </Tooltip>
          <div className="certification-submission">
            {isPreApproval && (
              <Button type="primary" onClick={this.props.onPreApprove}>
                Submit Review
              </Button>
            )}
            {isWaitingApproval && this._renderSubmitReviewDropdown()}
            {(applicationProcess.reviewedOn && applicationProcess.reviewedOn != -1) && (
              <strong>
                {
                  isApplicationApproved ? 'Approved on '
                    : (
                      isApplicationRejected ? 'Rejected on '
                        : 'Last update on '
                    )
                }
                {Utils.getDateAndTimeOnUIFormatByTimestamp(applicationProcess.reviewedOn)}
              </strong>
            )}
          </div>
        </Row>
      </Row>
    );
  }

  // Private methods
  _getCertificationFillData() {
    const { sections, sectionsItemsStatuses } = this.props;

    const totalOfItems = Object.entries(sections).reduce(
      (accumulator, [, section]) => accumulator + section.items?.length || 0,
      0,
    );
    const { pending, approved, rejected } = Object.entries(sectionsItemsStatuses).reduce(
      (accumulator, [, section]) => ({
        pending: accumulator.pending + section[Globals.ApplicationItemsStatus.DEFAULT]?.length || 0,
        approved: accumulator.approved + section[Globals.ApplicationItemsStatus.APPROVED]?.length || 0,
        rejected: accumulator.rejected + section[Globals.ApplicationItemsStatus.REJECTED]?.length || 0,
      }),
      { pending: 0, approved: 0, rejected: 0 },
    );

    const totalOfFilledItems = approved + rejected;

    return { totalOfItems, pending, approved, rejected, totalOfFilledItems };
  }

  _renderSubmitReviewDropdown() {
    const { totalOfItems, approved, rejected } = this._getCertificationFillData();

    const enableApprove = approved == totalOfItems;
    const enableReject = rejected > 0;

    return (
      <Dropdown
        type="primary"
        icon={<CaretDownFilled />}
        disabled={(!enableApprove && !enableReject)}
        overlay={(
          <Menu>
            <Menu.Item disabled={!enableApprove} onClick={this.props.onApprove}>
              Approve
            </Menu.Item>
            <Menu.Item disabled={!enableReject} onClick={this.props.onReject}>
              Reject
            </Menu.Item>
          </Menu>
        )}
      >
        <Button type="primary">Submit Review <CaretDownFilled /></Button>
      </Dropdown>
    );
  }

  _renderCertificationDetails(isPreApproval) {
    const { certificationProcess, sections } = this.props;
    const { totalOfItems, pending, approved, rejected, totalOfFilledItems} = this._getCertificationFillData();

    const hasSections = Object.keys(sections).length > 0;

    const label = [];
    if (approved > 0) {
      label.push(`${approved} approved`);
    }

    if (rejected > 0) {
      label.push(`${rejected} rejected`);
    }

    if (pending > 0) {
      label.push(`${pending} pending review`);
    }

    const completedPercentage = (totalOfFilledItems * 100) / totalOfItems;

    const certificationName = this.props.app.sharedCache().getCertificationByID(certificationProcess.certificationID)?.description || '-';

    return (
      <div className="certification-info">
        <div className="certification-info-header">
          <h1>{certificationName}</h1>
          <Tag color="blue">
            {this.props.applicationType == Globals.ApplicationTypes.NEW ? 'New application' : 'Application renewal'}
          </Tag>
          {isPreApproval && (
            <Tag color="blue">
              Pre approval
            </Tag>
          )}
        </div>
        {hasSections && (
          <div className="progress">
            <div className="progress-bar">
              <div className="completed" style={{ width: `${completedPercentage}%` }} />
            </div>
            <span>{label.join(', ')}.</span>
          </div>
        )}
      </div>
    );
  }
}
