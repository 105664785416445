import React from 'react';
import autoBind from 'react-autobind';
import { Form, Input, Divider, Col, Row, Select, InputNumber, Button, Tooltip, Typography, Alert, Popover } from 'antd';
import { isMobile } from 'react-device-detect';
import { InfoCircleFilled, QuestionCircleFilled } from '@ant-design/icons';
import CommonPopoversContent from "../../../commonComponents/Forms/_CommonPopoversContent";
//
import Utils from '../../../../components/Utils';
//
const styleHidden = { style: { display: 'none' } };
//props are: app, isLoading, onChange, onCancel, onNext, onVoucherApply, fixedQuantity,
//product, productObjects, selectedObjectIndex, quantity, autoRedeem,
//taxAmount, totalAmount, chargingAmount
//onPrevious, hasPrevious, hasSessionID
export default class CommonLicensePurchaseModalStep_OverviewView extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { referralOrgs: [], selectedReferral: null };
  }
  componentDidMount() {
    this._loadReferralNames();
  }
  handleChangeReferral(value) {
    const org = this.state.referralOrgs.find(org => org.id == value);

    if (!org) return;

    this.props.onChange({ id: org.id, name: org.name }, 'selectedReferral')
  }
  //Public
  async validateFields() { return this.form.validateFields(); }
  //Actions
  async handleVoucherApply() {
    const resp = await this.form.validateFields(['voucherCode']);
    if (resp) this.props.onVoucherApply(resp.voucherCode);
  }
  //UI
  render() {
    const discount = (this.props.discountAmount > 0 ? this.props.discountAmount * this.props.quantity * -1 : 0);
    const productLabel = Utils.capitalizeString(this.props.productObjects && this.props.productObjects[0] ? //you can hate Gabriel for that :p
      this.props.productObjects.reduce((p, c) => {
        const term = (c.isExternal ? 'Product' : (c.isApplication ? 'Application' : (c.isRenewal ? 'Renewal' : c.uiTerm)));
        if (this.props.productObjects?.length == 1) return term;
        return p != term ? p : 'Product';
      }, this.props.productObjects[0].uiTerm) : `Product`);
    return (
      <Form key='product' {... Utils.propagateRef(this, 'form')}>
        <Divider orientation="left">License Details</Divider>
        <Row type="flex" justify="center">
          <Col span={14}>
            <Form.Item label={productLabel} rules={[{ required: true, message: `Please, select an option!` }]}>
              <Select onChange={(val) => this.props.onChange(val, 'object')} value={this.props.selectedObjectIndex} disabled={this.props.hasSessionID}>
                {this.props.productObjects && this.props.productObjects.map((item, index) => {
                  const productSpecs = this.props.app.sharedCache().getProductByProductRelatedObject(item);
                  return <Select.Option value={index} key={index}>{productSpecs.name}</Select.Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}/>
        </Row>
        <Row type="flex" justify="center">
          <Col span={(isMobile ? 24 : 8)}>
            <div className='purchaseModalOverviewTableHeader'>
              <Typography.Text> Product </Typography.Text>
            </div>
            <Form.Item>
              <Input value={this.props.product?.name} disabled className="purchaseModalInputBoderless"/>
            </Form.Item>
          </Col>
          <Col span={(isMobile ? 24 : 3)}>
            <div className='purchaseModalOverviewTableHeader'>
              <Typography.Text> Price </Typography.Text>
            </div>
            <Form.Item>
              <Tooltip title={"The original price of the product."} placement="topLeft">
                <InputNumber value={this.props.chargingAmount} decimalSeparator="." precision={2} disabled={!this.props.app.isAdmin()} className={(!this.props.app.isAdmin() ? "purchaseModalInputBoderless" : "")}
                             formatter={Utils.defaultCurrenyInputFormatter} parser={Utils.defaultCurrentInputParser} onChange={(val) => this.props.onChange(val, 'amount')}/>
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={(isMobile ? 0 : 2)}>
            <div className='purchaseModalOverviewTableHeader'> &nbsp; </div>
              {this.props.cancelPolicy && <Popover title='Charge Value' content={CommonPopoversContent.renderCancelPolicyDescriptionPopoverContent(this.props.cancelPolicy)}>
              <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 8, marginTop: 6 }} />
              </Popover>}
          </Col>
          <Col span={(isMobile ? 24 : 2)}>
            <div className='purchaseModalOverviewTableHeader'>
              <Typography.Text> Quantity </Typography.Text>
            </div>
            <Form.Item name='quantity' rules={[{ min: 1, message: 'Please, inform the desired quantity amount!' }]}>
              <Tooltip title={"The number of licenses (activations) you are purchasing."} placement="topLeft">
                <InputNumber precision={0} step={1} min={1} value={this.props.quantity} disabled={this.props.fixedQuantity}
                  onChange={(val) => this.props.onChange(val, 'quantity')}
                  max={this.props.validatedVoucher && this.props.validatedVoucher.maxQuantity > 0 ? this.props.validatedVoucher.maxQuantity : 99999}/>
              </Tooltip>
            </Form.Item>
          </Col>
          <Col span={(isMobile ? 0 : 6)}>
            <div className='purchaseModalOverviewTableHeader'> &nbsp; </div>
          </Col>
          <Col span={(isMobile ? 24 : 3)}>
            <div className='purchaseModalOverviewTableHeader'>
              <Typography.Text> Subtotal </Typography.Text>
            </div>
            <Form.Item>
              <InputNumber disabled precision={2} decimalSeparator="." value={this.props.chargingAmount * (this.props.quantity || 0)}
                formatter={Utils.defaultCurrenyInputFormatter} parser={Utils.defaultCurrentInputParser} className="purchaseModalInputBoderless" />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={(isMobile ? 12 : 2)} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Discount </Typography.Text>
          </Col>
          <Col span={(isMobile ? 12 : 3)}>
            <Form.Item>
              <Tooltip title={"Discounts can be applied by using cupom codes."} placement="topLeft">
                <InputNumber precision={2} decimalSeparator="." disabled formatter={Utils.defaultCurrenyInputFormatter}
                  parser={Utils.defaultCurrentInputParser} className="purchaseModalInputBoderless" value={discount} />
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={(isMobile ? 12 : 2)} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Tax </Typography.Text>
          </Col>
          <Col span={(isMobile ? 12 : 3)}>
            <Form.Item>
              <InputNumber precision={2} decimalSeparator="." disabled formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser} value={this.props.taxAmount} className="purchaseModalInputBoderless" />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end" className="purchaseModalOverviewTotalContainerRow">
          <Col span={(isMobile ? 12 : 2)} className="purchaseModalOverviewTotalContainer">
            <Typography.Text className="purchaseModalOverviewTotal"> Total </Typography.Text>
          </Col>
          <Col span={(isMobile ? 12 : 3)}>
            <Form.Item>
              <InputNumber precision={2} decimalSeparator="." disabled formatter={Utils.defaultCurrenyInputFormatter}
                parser={Utils.defaultCurrentInputParser} className="purchaseModalInputBoderless" value={this.props.totalAmount}/>
            </Form.Item>
          </Col>
        </Row>
        {/*this.state.originalValue != this.state.transactionValue && (!this.state.validatedVoucher || !this.state.validatedVoucher.id) && (
          <Row type="flex" justify="center">
            <Col span={22}>
              <Form.Item label={`${Globals.LABEL_COMMENTS}`}>
                {getFieldDecorator('comments', {
                  rules: [{ required: true, message: 'Please, justify why the amount has changed!' }],
                })(<Input.TextArea rows={4} />)}
              </Form.Item>
            </Col>
          </Row>
              )*/}
        {this._renderVoucherRow()}
        {this._renderButtonsFooter()}
      </Form>
    );
  }

  /* Private UI */
  _renderVoucherRow() {
    //isHiddenVoucher validatedVoucher applingVoucher
    //{valid discPercent discAmount}
    let validationStatus = null;
    if (this.props.applingVoucher) validationStatus = 'validating';
    if (this.props.validatedVoucher) {
      if (this.props.validatedVoucher.valid == true) validationStatus = 'success';
      else validationStatus = 'error';
    }
    //
    return (
      <Row type="flex" justify="end" {...(this.props.isHiddenVoucher ? styleHidden : {})}>
        <Col span={7}>
          <Form.Item label='Voucher Code' name="voucherCode" {...(validationStatus ? { validateStatus: validationStatus, hasFeedback: true } : {})}>
            <Input id="voucherCode" allowClear/>
          </Form.Item>
        </Col>
        <Col>
          <Button className='fixedInputButton_Voucher' loading={this.props.applingVoucher || this.props.isLoading} onClick={this.handleVoucherApply}
                  disabled={this.props.applingVoucher || this.props.isLoading} type="primary">Apply</Button>
        </Col>
      </Row>
    );
  }
  _renderButtonsFooter() {
    return (
      <>
        <Row type="flex" justify="end">
          <Divider/>
          <Col span={24}>
            {this.props.autoRedeem && <Alert
              type="warning"
              showIcon
              message={(
                <>
                  <strong>ATTENTION:</strong> Do not proceed if you do not intend to take this course or exam yourself. A license purchased here cannot be used by another person.
                  <Popover
                    trigger="click"
                    content={<div style={{ width: 300 }}>By continuing you will be purchasing a license and activating it at the same time. If you wish to buy extra licenses to be used by other users of the portal, please click the Cancel button, select the My Licenses menu option and purchase your licenses there.</div>}
                  >
                    <InfoCircleFilled style={{ marginLeft: 8, color: '#1990FF', cursor: 'pointer' }} />
                  </Popover>
                </>
              )}
              style={{ marginBottom: 24 }}
            />}
          </Col>
          {(this.props.selectedSession?.askForReferrals && this.props.selectedSession?.possibleReferrals.length > 0) && (
            <Col span={24} style={{ marginBottom: 26 }}>
              <Row justify="end">
                <Col span={8}>
                  <Form layout="vertical">
                    <Form.Item label="Where did you hear about this course?">
                      <Select placeholder="Select..." onChange={this.handleChangeReferral} value={this.props.selectedReferral.id}>
                        {this.state.referralOrgs.map((org) => (
                          <Select.Option key={org.id} value={org.id}>{org.name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Col>
          )}
          <Button className="purchaseModalCancelButton" disabled={this.props.isLoading || this.props.applingVoucher} key="back" onClick={this.props.onCancel}> Cancel </Button>
          {this.props.hasPrevious && <Button disabled={this.props.isLoading} onClick={this.props.onPrevious}> Previous </Button>}
          <Button key="submit" type="primary" loading={this.props.isLoading || this.props.applingVoucher}
                  onClick={this.props.onNext} disabled={this.props.isLoading || this.props.applingVoucher || !this.props.quantity}
                  className='purchaseModalConfirmationButton'> Next </Button>
        </Row>
      </>
    )
  }

  async _loadReferralNames() {
    const referralsIDs = (this.props.selectedSession?.possibleReferrals || []);

    if (referralsIDs.length === 0) {
      return;
    }

    const resp = await this.props.app.organization.organization.searchOrganizationsByIDs({ orgIDs: referralsIDs });

    if (resp.statusCode == 200 && resp.body?.orgs) {
      this.setState({ referralOrgs: resp.body.orgs });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }
}
