import React from "react";
import autoBind from 'react-autobind';
import { Drawer, Row, Form, InputNumber, Input, Col, Button } from 'antd';
//
import Utils from "../../../components/Utils";
import CustomComponent from "../../../components/CustomComponent";
//
//props are: onUpdate
export default class CommonCertificationExpirationWarningsDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isVisible: false, warning: null, index: -1 };
  }
  //Public
  show(warning, index) { 
    this.setState({ isVisible: true, warning, index: index != undefined ? index : -1 }, () => {
      if (warning) this.form.setFieldsValue({ ...warning });
    });
  }
  //Private actions
  handleClose() {
    this.form.resetFields();
    this.setState({ isVisible: false, warning: null, index: -1 });
  }
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (!formData) return;
    this.props.onUpdate(formData, this.state.index);
    this.handleClose();
  }

  //UI
  render() {
    return (
      <Drawer visible={this.state.isVisible} title={`${this.state.warning ? 'Edit' : 'Create'} Expiration Warning`} placement="right" onClose={this.handleClose} width={800}>
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row>
            <Col span={12}>
              <Form.Item name="template" label="Email Template" extra='Email template to be used to send renewal warnings'
                rules={[{ required: true, message: 'This field is required!' }]}> 
                <Input/>
              </Form.Item>
            </Col>
            <Col span={10} offset={1}>
              <Form.Item name="period" label="Period" extra='Number of days ahead certification expiration when emails should be sent.'> 
                <InputNumber style={{width: '100%'}} min={-1} />
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' justify='end'>
            <Col> <Button type='primary' onClick={this.handleSubmit}>Done</Button> </Col>
          </Row>
        </Form>
      </Drawer>
    );
  }
}
