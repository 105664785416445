import React from 'react';
import autoBind from 'react-autobind';
import { Layout, Row, Col, Empty } from 'antd';

import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import AdminAppStatsFilters from './AdminAppStatsFilters';
import WhiteBox from '../../commonComponents/WhiteBox';

import Charts from './Charts';

export default class AdminAppStatsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false,
      data: null,
    };
  }

  handleFilter({ fromDate, toDate, programID }) {
    this._fetch({ fromDate, toDate, programID });
  }

  render() {
    const { data } = this.state;

    return (
      <Layout.Content className="pageContent" style={{ marginTop: 24 }}>
        <CommonLoadingView isLoading={this.state.isLoading} />

        <AdminAppStatsFilters onFilter={this.handleFilter} app={this.props.app} />

        {!this.state.data && (
          <Row justify="center" style={{ marginTop: 50 }}>
            <Col>
              <Empty description="Select the program and the period to generate reports." />
            </Col>
          </Row>
        )}

        {this.state.data && (
          <Layout.Content style={{ marginTop: 24 }}>
            <Row gutter={24}>
              <Col span={7}>
                <WhiteBox title="Applications" style={{ height: 330 }}>
                  <Charts.Applications
                    submitted={data.application?.submitted}
                    started={data.application?.started}
                    renewalSubmitted={data.renewalApplications?.submitted}
                    renewalStarted={data.renewalApplications?.started}
                  />
                </WhiteBox>
              </Col>
              <Col span={9}>
                <WhiteBox title="Exams" style={{ height: 330 }}>
                  <Charts.Exams exams={data.exams} />
                </WhiteBox>
              </Col>
              <Col span={8}>
                <WhiteBox title="Rewrites" style={{ height: 330 }}>
                  <Charts.Rewrites exams={data.exams} />
                </WhiteBox>
              </Col>
            </Row>
            <Row gutter={40}>
              <Col span={12}>
                <WhiteBox title="Certifications" style={{ height: 330 }}>
                  <Charts.Certifications
                    issued={data.certifications?.issued}
                    recertifications={data.certifications?.recertifications}
                    isV2={data.renewalApplications ? true : false}
                  />
                </WhiteBox>
              </Col>
              <Col span={12}>
                <WhiteBox title="Pass-fail rate" style={{ height: 330 }}>
                  <Charts.PassFailRate exams={data.exams} />
                </WhiteBox>
              </Col>
            </Row>
          </Layout.Content>
        )}
      </Layout.Content>
    );
  }

  // Private
  async _fetch({ programID, fromDate, toDate }) {
    this.setState({ isLoading: true });

    const resp = await this.props.app.api.dashboard.getStatsV2({ programID, fromDate, toDate });
    if (resp.statusCode == 200 && resp.body) {
      const data = resp.body;

      data.exams = Object.entries(data.exams).map(([examID, value]) => ({
        exam: this.props.app.sharedCache().getCourseByID(examID),
        ...value,
      }));

      this.setState({ isLoading: false, data });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isLoading: false });
    }
  }
}
