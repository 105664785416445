import React from 'react';
import autoBind from 'react-autobind';
import { Layout, Popconfirm, Upload, message, Spin, Tooltip } from 'antd';
//
import { FaDownload, FaEye, FaFile, FaFileCsv, FaFileExcel, FaFileImage, FaFilePdf, FaFileWord, FaTrash, FaUpload } from "react-icons/fa";
//
import Utils from '../../../components/Utils';
//
import '../../../assets/stylesheets/CommonSessionDocumentsForm.less';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from "../../commonComponents/CommonLoadingView";
//
//props are: documents, session, app, onDelete, onInvite, failures
export default class CommonSessionDocumentsForm extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.sessionID = props.session.id;
    this.state = {
      isLoading: false,
      session: null,
      isUploading: false,
    };
  }
  componentDidMount() {
    this._fetchSession();
  }
    // API Calls
  async _fetchSession() {
    this.setState({ isLoading: true });
    const resp = await this.props.app.classroom.session.getSession(this.sessionID, false);
    if (resp.statusCode == 200) {
      const session = resp.body;
      this.setState({
        session: {
          ...session
        }
      });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.setState({ isLoading: false });
  }

  render() {
    const { session } = this.state;
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed/>

        {session && (
          <>
              <h1 style={{ fontSize: 24, marginTop: 48 }}>Session Documents</h1>
              <div className="uploads-container">
                {(session.documents || []).map(file => (
                  <div className="upload-item" key={file.fileID}>
                    {this._getFileIcon(file.fileName)}
                    <span>{file.fileName}</span>

                    <div className="actions">
                      {this._conditionalTooltipWrapper(
                        !this._hasPreview(file.fileName),
                        'Preview is not available for this file',
                      )(
                        <button
                          type="button"
                          onClick={this.handlePreview.bind(this, file)}
                          disabled={!this._hasPreview(file.fileName)}
                        >
                          <FaEye />
                        </button>
                      )}
                      <button type="button" onClick={this.handleDownloadFile.bind(this, file)}>
                        <FaDownload />
                      </button>
                      <Popconfirm
                        title={`Are you sure you want to delete file ${file.fileName}?`}
                        onConfirm={this.handleDeleteFile.bind(this, file.fileID)}
                      >
                        <button type="button"><FaTrash /></button>
                      </Popconfirm>
                    </div>
                  </div>
                ))}

                <Upload
                  style={{
                    width: '100%',
                    minHeight: '220px',
                    opacity: this.state.isUploading ? 0.5 : 1,
                  }}
                  multiple={false}
                  showUploadList={false}
                  beforeUpload={() => false}
                  onChange={this.handleFileChange}
                  disabled={this.state.isUploading}
                  className="upload-item new-upload"
                >
                  <button className="upload-button">
                    {!this.state.isUploading && <><FaUpload /> New document</>}
                    {this.state.isUploading && <><Spin /> Uploading...</>}
                  </button>
                </Upload>
              </div>
          </>
        )}
      </Layout.Content>
    );
  }

  _getFileIcon(fileName) {
    const { extension } = Utils.splitFilenameAndExtension(fileName);

    const excel = ['xlsx', 'xls'];
    const word = ['doc', 'docx'];
    const image = ['png', 'jpg', 'jpeg', 'gif'];
    const csv = ['csv'];
    const pdf = ['csv'];

    const iconSize = 32;

    if (excel.includes(extension)) {
      return <FaFileExcel size={iconSize} />;
    }

    if (word.includes(extension)) {
      return <FaFileWord size={iconSize} />;
    }

    if (image.includes(extension)) {
      return <FaFileImage size={iconSize} />;
    }

    if (csv.includes(extension)) {
      return <FaFileCsv size={iconSize} />;
    }

    if (pdf.includes(extension)) {
      return <FaFilePdf size={iconSize} />;
    }

    return <FaFile size={iconSize} />;
  }
  _hasPreview(fileName) {
    const { extension } = Utils.splitFilenameAndExtension(fileName);
    return ['png', 'jpeg', 'jpg', 'gif', 'pdf'].includes(extension?.toLowerCase());
  }
  handleFileChange({ file }) {
    const allowedTypes = ['image/', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv'];
    if (!allowedTypes.find(fileType => file.type?.includes(fileType))) {
      message.error('This type of file is not accepted.');
      return;
    }

    if (file.status == 'removed') {
      return;
    }

    this._uploadFile(file);
  }

  handleDeleteFile(fileID) {
    this._deleteFile(fileID);
  }

  handleDownloadFile(file) {
    this._downloadFile(file);
  }

  handlePreview(file) {
    this._previewFile(file);
  }

  // Upload api calls
  async _uploadFile(file) {
    this.setState({ isUploading: true });

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onloadend = async (readerResp) => {
      if (!this._isMounted) return;

      file.file = readerResp.currentTarget.result;
      const resp = await this.props.app.classroom.sessionFile.uploadAndCreateDocument(
        this.sessionID,
        file
      );

      if (resp && resp.statusCode == 200) {
        await this._fetchSession();
        message.success('File successfully uploaded!');
      } else {
        this.props.app.alertController.showAPIErrorAlert(null, resp);
      }

      this.setState({ isUploading: false });
    };
  }

  async _previewFile(file) {
    this.startLoading();

    const resp = await this.props.app.classroom.sessionFile.getSignURL(this.sessionID, file.fileID);

    if (!this._isMounted) return;

    if (resp.statusCode == 200) {
      this.stopLoading();
      Utils.openFileInNewTab(resp.body.fileURL, file.fileName);
    } else {
      this.stopLoading();
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
    }
  }

  async _downloadFile(file) {
    this.startLoading();

    const resp = await this.props.app.classroom.sessionFile.download(this.sessionID, file.fileID);

    if (!this._isMounted) return;

    if (resp.statusCode == 200) {
      const { filename, extension } = Utils.splitFilenameAndExtension(file.fileName);
      Utils.downloadBlob(resp.body, filename || file.fileName, extension || Utils.getFileExtensionFromMimeType(resp.body.type));
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
    }

    this.stopLoading();
  }

  async _deleteFile(fileID) {
    this.startLoading();

    const resp = await this.props.app.classroom.sessionFile.delete(this.sessionID, fileID);

    if (resp.statusCode == 200) {
      await this._fetchSession();
      message.success('File successfully deleted!');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.stopLoading();
  }

  _conditionalTooltipWrapper(condition, title) {
    return (component) => {
      if (condition) {
        return (
          <Tooltip title={title}>{component}</Tooltip>
        );
      } else {
        return component;
      }
    };
  }
}
