import React from 'react';
import autoBind from 'react-autobind';
import { PageHeader, message, Layout, Row, Col, Table, Button, Tooltip, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import CustomComponent from '../../../components/CustomComponent';
import CommonCourseProviderDrawer from '../../commonComponents/Drawers/CommonCourseProviderDrawer';
//
import Globals from '../../../config/Globals';
import config from '../../../config/config';
import Utils from '../../../components/Utils';
//
export default class AdminCourseProvidersView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      providers: [], sortedInfo: { columnKey: 'name', order: 'ascend' },
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    this.fetchData();
  }

  async fetchData() {
    this.startLoading();
    const resp = await this._loadProviders();
    if (resp) {
      this.setState({ providers: resp });
      this.stopLoading();
    } else this.stopLoading();
  }

  //Actions
  handleCreateProvider() { this.courseProviderDrawer.show(); }
    //Table
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
    //Table row actions
  handleEditProvider(providerID) { this.courseProviderDrawer.show(providerID); }
  async handleDeleteProvider(providerID) {
    this.startLoading();
    const resp = await this._deleteCourseProvider(providerID);
    if (resp) await this.fetchData();
    this.stopLoading();
  }
  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader className="pageHeader" title="Course Providers" onBack={() => this.props.app.urlManager.pushBack()} />
        <CommonCourseProviderDrawer {...Utils.propagateRef(this, 'courseProviderDrawer')} onUpdate={this.fetchData} app={this.props.app}/>
        <Layout.Content>
          <Row type='flex' justify='end'>
            <Col>
              <Button style={{ marginBottom: 20 }} type="primary" onClick={this.handleCreateProvider}> Create new course provider </Button>
            </Col>
          </Row>
          {this._renderProvidersTable()}
        </Layout.Content>
      </Layout.Content>
    );
  }

  /* private UI */
  _renderProvidersTable() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'ID', key: 'id', dataIndex: 'id', width: '30%',
        sorter: (a, b) => a.id.localeCompare(b.id),
        sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order
      },
      {
        title: 'Type', key: 'type', dataIndex: 'type',
        sorter: (a, b) => (a.type || '').localeCompare(b.type || ''),
        sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order
      },
      {
        title: 'Endpoint', key: 'endpoint',
        sorter: (a, b) => (a.provider.APIEndpoint || '').localeCompare(b.provider.APIEndpoint || ''),
        sortOrder: sortedInfo.columnKey === 'endpoint' && sortedInfo.order,
        render: (props) => props?.provider?.APIEndpoint || ''
      },
      {
        title: 'Created On', key: 'createdOn', width: '15%',
        render: props => props.createdOn ? Utils.getDateOnUIFormatByTimestamp(props.createdOn) : 'N/A',
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order
      },
      {
        title: 'Actions', width: 'auto', key: 'Actions', width: '15%',
        render: props => {
          return (<span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title='Edit'>
              <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.handleEditProvider.bind(this, props.id)} />
            </Tooltip>
            <Tooltip placement="bottomLeft" title='Delete'>
              <Popconfirm placement="top" title={`Are you sure that you want to delete the course provider '${props.id}'?`} 
                          onConfirm={this.handleDeleteProvider.bind(this, props.id)} okText="Yes" cancelText="No" >
                <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle"/>
              </Popconfirm>
            </Tooltip>
          </span>);
        }
      },
    ];
    const props = {
      rowKey: 'id', loading: this.state.isLoading, onChange: this.handleFilterChange,
      locale: { emptyText: 'No course providers found!' },
      pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] }
    };
    return (
      <Layout.Content>
        <Table className="adminCourseProvidersTable" columns={columns} dataSource={this.state.providers} {...props} />
      </Layout.Content>
    );
  }

  /* private API */
  async _loadProviders() {
    const resp = await this.props.app.config.courseProvider.getAllProviders();
    if (resp.statusCode == 200 && resp.body && resp.body.courseProviders) {
      return resp.body.courseProviders;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _deleteCourseProvider(providerID) {
    const resp = await this.props.app.config.courseProvider.deleteCourseProvider(providerID);
    if (resp.statusCode == 200) {
      message.success('Course provider deleted with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
}
