import React from 'react';
import { Row, Col } from 'antd';
import { ReconciliationOutlined } from '@ant-design/icons';
//
export default class WaitingResultRelease extends React.Component {
  render() {
    const { courseSpecs, app, certificationProcess } = this.props;
    return (
      <>
        <Row justify="center">
          <Col span={14} style={{ textAlign: 'center' }}>
            Your result has been received by the administrator and is waiting to be processed.
            <br></br>
            Please, allow some time for us to process and review it.
          </Col>
        </Row>
      </>
    );
  }
  
}
WaitingResultRelease.CardStatusHeader = function() {
  return <span className="warning"><ReconciliationOutlined /> Processing Result </span>;
}
