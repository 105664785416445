import { Modal, Steps } from 'antd';
import React from 'react';
import autoBind from 'react-autobind';
import { isMobile } from 'react-device-detect';
//
import CustomComponent from '../../../components/CustomComponent';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
import CommonCoursePurchaseModalSubStep_CalendarSessionSelection from './CommonCoursePurchaseModal_CalendarSubSteps/CommonCoursePurchaseModalSubStep_CalendarSessionSelection';
import CommonCoursePurchaseModalSubStep_LoadingView from './CommonCoursePurchaseModal_CalendarSubSteps/CommonCoursePurchaseModalSubStep_LoadingView';
import CommonCoursePurchaseModalSubStep_NoSessionsAvailable from './CommonCoursePurchaseModal_CalendarSubSteps/CommonCoursePurchaseModalSubStep_NoSessionsAvailable';
import CommonCoursePurchaseModalSubStep_OnlineSessionSelection from './CommonCoursePurchaseModal_CalendarSubSteps/CommonCoursePurchaseModalSubStep_OnlineSessionSelection';
import CommonCoursePurchaseModalSubStep_SessionTypeSelection from './CommonCoursePurchaseModal_CalendarSubSteps/CommonCoursePurchaseModalSubStep_SessionTypeSelection';
//Using same css class of purchase modal :/
import '../../../assets/stylesheets/CommonLicensePurchaseModal.less';
//
export const STEPS = { CALENDAR: 'CALENDAR' };
const SessionTypes = { NONSCHEDULABLE: 1, SCHEDULABLE: 2 };
//State management
const INITIAL_STATE = (visible) => {
  return {
    isVisible: visible || false, isLoading: false,
    stepper: {
      //stepper possible statuses - waiting, process, finish, error
      current: STEPS.CALENDAR, status: 'waiting',
    },
    //Main product stuff
    // selectedObjectIndex: 0, selectedProduct: null,
    // productObjects: null,
    //Session selection
    sessions: null, calendars: null, selectedSessionType: null,
    sessionID: null, session: null,
    //Meta
    session: null
  };
};

//constructor accepts second param so subclasses can specify primary step!
//required props are: app, course, onChange
export default class _CommonSessionSelectionModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = INITIAL_STATE();
  }
  /* Public */
  show() { this.setState({ isVisible: true }); }
  /* Protected interface */
  getCurrentStep() { return this.state.stepper.current; }
  goToInitialStep() {
    this.__sessionsFirstLoad = false;
    this.setState(INITIAL_STATE(true));
  }

  //Actions
  handleSessionSelection(sessionID) {
    const session = this.state.sessions?.find((s) => s.id == sessionID);
    //we dont set the state here because childs will set their state causing
    //double refresh and some delay on the screen.
    this._advanceAfterSessionSelection(sessionID, session);
  }
  handleSessionTypeSelection(type) {
    this.setState({ selectedSessionType: type }, () => {
      if (type == SessionTypes.NONSCHEDULABLE) {
        const onlineSessions = (this.state.sessions?.filter((s) => (s.type == Globals.Session_Type.ONLINE || s.type == Globals.Session_Type.ONLINE_EXT)) || []);
        if (onlineSessions.length == 1) this.handleSessionSelection(onlineSessions[0].id);
      }
    });
  }
    //Modal lifecycle
  handleCompleted() {
    if (this.currentChild && this.currentChild.form) this.currentChild.form.resetFields();
    this.setState({ isVisible: false }, () => { this.props.onChange(); })
  }
  handleCancel() {
    if (this.currentChild && this.currentChild.form) this.currentChild.form.resetFields();
    this.setState({ isVisible: false }, () => { this.props.onChange(true /* isCancel */); })
  };
  handleAfterClose() {
    this.setState(INITIAL_STATE(), () => {
      this.__sessionsFirstLoad = false;
    });
  };
  //UI
  render() {
    return (
      <Modal maskClosable={false} title={this._getTitle()} afterClose={this.handleAfterClose.bind(this)} className='sessionSelectionModal'
             open={this.state.isVisible} confirmLoading={this.state.isLoading} closable={false} footer={null}>
        <Steps {...this.state.stepper} current={this._getStepIndex()} size='small' direction={(isMobile ? 'vertical' : 'horizontal')} className='paymentSteps'>
          {this._renderStepperSteps()}
        </Steps>
        {this._renderSelectedStep()}
      </Modal>
    );
  }

  /* protected - this is what should be overwritten when extending this modal */
  _advanceAfterSessionSelection(sessionID, session) {  }
  _getTitle() {
    const courseSpecs = this.props.app.sharedCache().getCourseByID(this.props.course?.courseID);
    return `Scheduled ${Utils.capitalizeString(courseSpecs?.uiTerm || '')}`;
  }
  _getStepIndex() { return 0; }
  _renderStepperSteps() { return (<Steps.Step title="Sessions" />); }
  _renderSelectedStep() { return this._renderStepSelection(); }

  /* private render */
  _renderStepSelection() {
    const courseSpecs = this.props.app.sharedCache().getCourseByID(this.props.course?.courseID);
    if (this.state.isLoading || !this.state.sessions) {
      this._loadOnDemand();
      return (<CommonCoursePurchaseModalSubStep_LoadingView />);
    } else if (!this.state.selectedSessionType) {
      if (!this.state.sessions || this.state.sessions.length <= 0) {
        return (<CommonCoursePurchaseModalSubStep_NoSessionsAvailable onCancel={this.handleCancel.bind(this)} courseSpecs={courseSpecs} />);
      } else {
        return (<CommonCoursePurchaseModalSubStep_SessionTypeSelection onSelect={this.handleSessionTypeSelection.bind(this)} onCancel={this.handleCancel.bind(this)}
          courseSpecs={courseSpecs} app={this.props.app} sessions={this.state.sessions} />);
      }
    } else if (this.state.sessions && this.state.selectedSessionType) {
      if (this.state.selectedSessionType == SessionTypes.NONSCHEDULABLE) {
        const onlineSessions = (this.state.sessions?.filter((s) => (s.type == Globals.Session_Type.ONLINE || s.type == Globals.Session_Type.ONLINE_EXT)) || []);
        return (<CommonCoursePurchaseModalSubStep_OnlineSessionSelection onSelect={this.handleSessionSelection.bind(this)} onCancel={this.handleCancel.bind(this)}
          courseSpecs={courseSpecs} sessions={onlineSessions} app={this.props.app} />);
      } else {
        const schedulableSessions = (this.state.sessions?.filter((s) => (s.type != Globals.Session_Type.ONLINE && s.type != Globals.Session_Type.ONLINE_EXT)) || []);
        return (<CommonCoursePurchaseModalSubStep_CalendarSessionSelection onSelect={this.handleSessionSelection.bind(this)} onCancel={this.handleCancel.bind(this)}
          calendars={this.state.calendars} sessions={schedulableSessions} app={this.props.app} />);
      }
    } return (<></>);
  }

  /* multi user support */
  _getCurrentUser(isOrgMngr, org) {
    //props needed: id, firstName, lastName, email
    if (this.props.user && !isOrgMngr) return this.props.user;
    else if (this.props.org) {
      const usr = this.props.app.sharedCache().getProgramUser();
      return { id: this.props.org.orgID, firstName: this.props.org.name, lastName: `${(usr ? `- ${usr.firstName} ` : '')}${(usr ? usr.lastName : '')}`, email: ( this.props.org.managers ? this.props.org.managers.map( m => m.email ) : [] ) }
    } else if (isOrgMngr && org) {
      const usr = this.props.app.sharedCache().getProgramUser();
      return { id: org.orgID, firstName: org.name, lastName: `${(usr ? `- ${usr.firstName} ` : '')}${(usr ? usr.lastName : '')}`, email: ( org.managers ? org.managers.map( m => m.email ) : [] ) }
    } else return this.props.app.sharedCache().getProgramUser();
  }

  /* API calls */
  _loadOnDemand() {
    if (!this.state.sessions && !this.__sessionsFirstLoad && this.state.isVisible) {
      this.__sessionsFirstLoad = true;
      setTimeout(() => this._loadSessions(), 100); //this is done to avoid set state on render cycle!
    }
  }
  async _loadSessions() {
    this.startLoading();
    //Make request
    const sessionsResp = await this.props.app.classroom.session.getSessionList(Date.now(), Utils.timestampAfterYears(1), this.props.course?.courseID);
    if (!this._isMounted) return;
    //Response
    if (sessionsResp.statusCode == 200 && sessionsResp.body) {
      const { sessions, calendars } = sessionsResp.body;
      //Filter for usable sessions
      const usableSessions = sessions.filter((session) => {
        return !((session.state != Globals.Session_State.AVAILABLE && session.state != Globals.Session_State.LOCKED) ||
          (session.capacity > 0 && session.capacity - (session.enrolmentCount || 0) - session.reservedCapacity <= 0));
      });
      const onlineCount = (usableSessions?.filter((s) => (s.type == Globals.Session_Type.ONLINE || s.type == Globals.Session_Type.ONLINE_EXT)) || []).length;
      const schedulable = (usableSessions?.filter((s) => (s.type != Globals.Session_Type.ONLINE && s.type != Globals.Session_Type.ONLINE_EXT)) || []).length;
      //Check if need to preselect type
      this.setState({ sessions: usableSessions, calendars, isLoading: false }, () => {
        const selectedSessionType = ((onlineCount == 0 && schedulable != 0) ? SessionTypes.SCHEDULABLE : ((onlineCount != 0 && schedulable == 0) ? SessionTypes.NONSCHEDULABLE : null));
        if (selectedSessionType) this.handleSessionTypeSelection(selectedSessionType);
      });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, sessionsResp);
      this.closeModal();
    }
  }
}
