import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Table, Tag, Tooltip, Button, Popconfirm } from 'antd';
import { CloseCircleOutlined, FileAddOutlined } from '@ant-design/icons';
//
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';

export default class CommonLicensesTable extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: null };
  }

  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'Date', dataIndex: 'createdOn', key: 'createdOn', width: '10%',
        render: createdOn => createdOn ? Utils.getDateOnUIFormatByTimestamp(createdOn) : '--',
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order
      },
      // {
      //   title: 'License ID', dataIndex: 'id', key: 'id', width: '15%',
      //   sorter: (a, b) => a.id.localeCompare(b.id),
      //   sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order
      // },
      {
        title: 'Product', dataIndex: 'productName', key: 'productName', width: '10%',
        sorter: (a, b) => a.productName.localeCompare(b.productName),
        sortOrder: sortedInfo.columnKey === 'productName' && sortedInfo.order
      },
      {
        title: 'License ID', dataIndex: 'id', key: 'id', width: '10%',
        sorter: (a, b) => a.id.localeCompare(b.id),
        sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order
      },
      {
        title: 'Number of licenses', dataIndex: 'licNumber', key: 'licNumber', width: '10%',
        sorter: (a, b) => a.licNumber - b.licNumber,
        sortOrder: sortedInfo.columnKey === 'licNumber' && sortedInfo.order
      },
      {
        title: 'Activations', dataIndex: 'licActivated', key: 'licActivated', width: '10%',
        sorter: (a, b) => a.licActivated - b.licActivated,
        sortOrder: sortedInfo.columnKey === 'licActivated' && sortedInfo.order
      },
      {
        title: 'Assignments', dataIndex: 'assignCount', key: 'assignCount', width: '10%',
        sorter: (a, b) => a.assignCount - b.assignCount,
        sortOrder: sortedInfo.columnKey === 'assignCount' && sortedInfo.order
      },
      {
        title: 'Reservations', dataIndex: 'reservedCount', key: 'reservedCount', width: '10%',
        sorter: (a, b) => a.assignCount - b.assignCount,
        sortOrder: sortedInfo.columnKey === 'assignCount' && sortedInfo.order
      },
      {
        title: 'Status', dataIndex: 'status', key: 'status', width: '10%',
        sorter: (a, b) => a.status.localeCompare(b.status),
        sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order,
        render: status => {
          return (<Tag color={Globals.getColorByLicenseStatus(status)}>{status}</Tag>);
        }
      },
      {
        title: 'Actions', width: '10%', key: 'Actions',
        render: props => {
          return (
            <span className='tableButtonContainer'>
              {this.props.isAdmin && props.status != Globals.License_State.REVOKED &&
                <Tooltip placement="bottomLeft" title="Revoke">
                  <Popconfirm
                    placement="top"
                    title="Are you sure that you want to revoke this license?"
                    onConfirm={() => this.props.onRevoke(props)}
                    okText="Yes"
                    cancelText="No"
                    disabled={props.status == Globals.License_State.REVOKED}
                  >
                    <Button
                      variant="none"
                      icon={<CloseCircleOutlined />}
                      shape="circle"
                      disabled={props.status == Globals.License_State.REVOKED}
                    />
                  </Popconfirm>
                </Tooltip>
              }
              {(props.licNumber > props.licActivated + (props.assignCount || 0) + (props.reservedCount || 0) && !props.isAssignment && props.status == Globals.License_State.ACTIVE && (this.props.isAdmin || this.props.isOrgManager)) &&
                <Tooltip placement="bottomLeft" title="Assign License">
                  <Button variant="none" icon={<FileAddOutlined />} shape="circle" style={{ marginLeft: '8px'}} onClick={() => this.props.onAssign(props)}/>
                </Tooltip>
              }
            </span>
          );
         }
       },
    ];

    const props = {
      rowKey: 'id',
      loading: this.props.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No licenses found!' },
      pagination: {pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter']},
    };

    return (
      <Table
        className="adminSearchUsersTable"
        onRow={this._onRow}
        columns={columns}
        dataSource={this.props.licenses}
        scroll={{ x: true }}
        sticky
        {...props}
      />
    );
  }

  _onRow(record) {
    return {
      onClick: (e) => {
        const elementsToPreventClick = ['svg', 'path', 'button', 'span'];
        if (elementsToPreventClick.includes(e.target.tagName.toLowerCase())) return;
        if (this.props.onSelectRow) this.props.onSelectRow(record);
      },
      onDoubleClick: (e) => {
        const elementsToPreventClick = ['svg', 'path', 'button'];
        if (elementsToPreventClick.includes(e.target.tagName.toLowerCase())) return;
        if (this.props.onSelectRow) this.props.onSelectRow(record);
      },
    };
  }
}
