import React from 'react';
import { Row, Col } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
//
export default class AccessDenied extends React.Component {
  render() {
    const { courseSpecs, app, certificationProcess, session } = this.props;
    return (
      <Row justify="center">
        <Col span={14} style={{ textAlign: 'center' }}>
          The {courseSpecs.description} is not available to you, it can only be accessed by the student enrolled in it.
        </Col>
      </Row>
    );
  }
}
AccessDenied.CardStatusHeader = function() {
  return <span className="danger"><CloseCircleFilled /> Access Denied</span>;
}
