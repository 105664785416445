import React from 'react';
import { Result, Button } from 'antd';
//
import CustomComponent from '../../../../components/CustomComponent';

export default class ApplicationApprovedSubComponent extends CustomComponent {
  render() {
    const { alreadyAcknowledge, reviewNotes } = this.props;

    const adminNotes = reviewNotes ? <><strong>Admin notes:</strong> {reviewNotes}</> : null;

    return (
      <Result
        status="success"
        title={`Your application has met all the requirements for ${this.props.certificationProcess.certificationDescription} and was approved.`}
        subTitle={alreadyAcknowledge ? adminNotes : <>{reviewNotes && <>{adminNotes}<br /></>}Press the Ok button to continue with your process.</>}
        extra={[
          ...(alreadyAcknowledge ? [] : [<Button type="primary" key="ok" onClick={this.props.submitAcknowledge}>OK</Button>]),
          <Button key='downloadapplication' onClick={this.props.onDownloadApplication}>
            Download Application
          </Button>
        ]}
      />
    );
  }
}
