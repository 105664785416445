import React from 'react';
import autoBind from 'react-autobind';
import { Tabs, Layout, PageHeader, Button, message, Modal } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
//
import CustomComponent from '../../components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
//
import CommonLicensesTable from '../commonComponents/CommonLicensesTable';
import CommonLicenseOrdersTable from '../commonComponents/CommonLicenseOrdersTable';
import CommonAssignedLicensesView from '../commonComponents/CommonAssignedLicensesView';
import CommonActivationsView from '../commonComponents/CommonActivationsView';
//
import CommonLicenseRefundModal from '../commonComponents/Modals/CommonLicenseRefundModal';
import CommonLicensePurchaseModal from '../commonComponents/Modals/CommonLicensePurchaseModal';
//
import CommonAssignLicenseDrawer from '../commonComponents/Drawers/CommonAssignLicenseDrawer';
//
import Utils from '../../components/Utils';
import config from '../../config/config';
import Globals from '../../config/Globals';
//
import "./../../assets/stylesheets/CommonLicensesView.less";
//
const { TabPane } = Tabs;

export default class CommonLicensesView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    const type = this.props.app.idm.urlmanager.getParam('type');
    this.isOrg = type === 'organization';
    this.state = {
      isLoading: true, pageTitle: 'Licenses', selectedTab: 'licenses',
      //data
      licenses: [], orders: [],
      user: null, org: null, selectedLicense: null,
      //modals
      isVisiblePurchase: false, purchaseModalRequiresAttention: false,
      isVisibleRefund: false, isPendingOrderModalVisible: false,
      //drawer
      isAssignLicenseDrawerVisible: false,
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    //Set title properly
    let pageTitle = 'Licenses';
    const externalID = this.props.match.params.id;
    if (this.isOrg && (this.props.app.isAdmin() || this.props.app.isOrgManager())) pageTitle = 'Org Licenses';
    else if (!this.isOrg && this.props.app.isAdmin()) pageTitle = 'User Licenses';
    else if (this.props.app.isOrgManager() && externalID != this.props.app.getAuthorizedUserID()) pageTitle = 'User Licenses';
    else pageTitle = 'My Licenses';
    this.setState({ pageTitle });
    //Load data
    await this._loadData();
    //Change tab
    const selectedTab = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_TabID);
    if (this.state.selectedTab != selectedTab && selectedTab) this.handleTabChange(selectedTab);
    else this.handleTabChange(this.state.selectedTab);
  }

  //Actions
    //Tab
  handleTabChange(selectedTab) {
    this.setState({ selectedTab }, () => {
      this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_TabID, selectedTab);
    });
  }
    //General
  handleBuyMore() {
    if (!this.state.isVisiblePurchase) this.purchaseModal.loadModalInfo();
    this.setState({ isVisiblePurchase: !this.state.isVisiblePurchase, isGrant: this.props.app.isAdmin() }, () => {
      if (!this.state.isVisiblePurchase) this._loadData();
    });
  }
    //Orders
  handleCancelOrder(order) { this._cancelOrder(order.id, order.externalID, order.productID); }
  handleRefundOrder(order) {
    if (!this.state.isVisibleRefund) this.refundModal.loadModalInfo(order);
    this.setState({ isVisibleRefund: !this.state.isVisibleRefund }, () => {
      if (!this.state.isVisibleRefund) this._loadData();
    });
  }
    //Licenses
  handleSelectLicenseRow(record) {
    const licenseID = record.id;
    let externalID = this.props.match.params.id;

    this.props.app.urlManager.pushPage(
      config.ApplicationRoutes.licenseConsumption, null, externalID, licenseID,
    );
  }

  handleAssignLicenseDrawerClose() { this.setState({ isAssignLicenseDrawerVisible: false }, () => {
      if (!this.state.isAssignLicenseDrawerVisible) this._loadData();
    })
  };
  handleAssignLicense(record) { this.setState({ isAssignLicenseDrawerVisible: true,  selectedLicense: { record } }) };

  handleSelectOrderRow(record) {
    if (record.status.toLowerCase() == Globals.Course_State.PENDING.toLowerCase()) {
      this.setState({ isPendingOrderModalVisible: true });
      return;
    }

    const licenseID = record.id;
    let externalID = this.props.match.params.id;

    this.props.app.urlManager.pushPage(
      config.ApplicationRoutes.licenseConsumption, null, externalID, licenseID,
    );
  }
  handleRevokeLicense(record) { this._revokeLicense(record.id); }
  handleReceiptDownload(record) { this._downloadReceipt(record.externalID, record.id); }
  handleBackPage() {
    if (document.referrer && document.referrer.includes('org/licenses')) {
      window.history.go(-2);
      return;
    }
    this.props.app.urlManager.pushBack();
  }

  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
          {this._renderPurchaseModal()}
          {this._renderRefundModal()}
          {this._renderPendingOrderModal()}
          { this.isOrg && this._renderAssignLicenseDrawer() }
          <PageHeader className="pageHeader" title={this.state.pageTitle} onBack={this.handleBackPage}/>
          <Layout.Content>
            <div className="buyMoreButtonContainer">
              <Button type="primary" onClick={this.handleBuyMore} className="buyMoreButton">
                {this.props.app.isAdmin() ? 'Grant Licenses' : 'Buy More'}
                <ShoppingCartOutlined />
              </Button>
            </div>
            <Tabs activeKey={this.state.selectedTab} onChange={this.handleTabChange}>
              <TabPane tab="Licenses" key="licenses">
                <CommonLicensesTable isLoading={this.state.isLoading} licenses={this.state.licenses}
                                     onSelectRow={this.handleSelectLicenseRow} isAdmin={this.props.app.isAdmin()} isOrgManager={this.props.app.isOrgManager()}
                                     onRevoke={this.handleRevokeLicense} onAssign={this.handleAssignLicense}/>
              </TabPane>
              { this.isOrg &&
                <Tabs.TabPane tab="Assigned Licenses" key="assignedLicenses">
                  <CommonAssignedLicensesView app={this.props.app} orgID={this.props.match.params.id}/>
                </Tabs.TabPane>
              }
              <TabPane tab="Orders" key="orders">
                <CommonLicenseOrdersTable isLoading={this.state.isLoading} orders={this.state.orders} app={this.props.app}
                  onSelectRow={this.handleSelectOrderRow} onApproval={this._loadData}
                  onCancel={this.handleCancelOrder} onRefund={this.handleRefundOrder}
                  onReceiptDownload={this.handleReceiptDownload}/>
              </TabPane>
              <TabPane tab="Activations" key="activations">
                <CommonActivationsView app={this.props.app} orgID={this.props.match.params.id}/>
              </TabPane>
            </Tabs>
          </Layout.Content>
      </Layout.Content>
    );
  }

  /* private ui */
  _renderPendingOrderModal() {
    return (
      <Modal
        closable
        visible={this.state.isPendingOrderModalVisible}
        title="Attention!"
        onCancel={() => this.setState({ isPendingOrderModalVisible: false })}
        footer={
          <Button onClick={() => this.setState({ isPendingOrderModalVisible: false })} type="primary">
            OK
          </Button>
        }
      >
        This license is pending approval, no keys or consumption are available
      </Modal>
    );
  }
  _renderPurchaseModal() {
    return (
      <CommonLicensePurchaseModal user={this.state.user}
        isVisible={this.state.isVisiblePurchase} {...Utils.propagateRef(this, 'purchaseModal')}
        isGrant={this.props.app.isAdmin()}
        onChange={this.handleBuyMore} app={this.props.app} org={this.state.org}
        onRequiresAttention={modal => {
          this.purchaseModal = modal;
          if (this.state.isLoading) this.setState({purchaseModalRequiresAttention: true});
          else this.handleBuyMore();
        }}/>
    );
  }
  _renderRefundModal() {
    return (
      <CommonLicenseRefundModal isVisible={this.state.isVisibleRefund}
        app={this.props.app} {...Utils.propagateRef(this, 'refundModal')} onChange={this.handleRefundOrder}/>
    );
  }
  _renderAssignLicenseDrawer() {
    return (
      <CommonAssignLicenseDrawer shouldShowUserSelect={true} isVisible={this.state.isAssignLicenseDrawerVisible} product={this.state.selectedLicense} onClose={this.handleAssignLicenseDrawerClose} app={this.props.app} orgID={this.props.match.params.id}/>
    )
  }

  /* private */
  async _loadData() {
    let externalID = '';
    const needsUserFetch = (this.props.app.isAdmin());
    //Choose what value to use as user ID
    if (this.props.app.isOrgManager() && this.isOrg) {
      externalID = this.props.app.urlManager.selectedOrg;
    } else if (this.props.app.isAdmin()) {
      externalID = this.props.match.params.id;
    } else {
      externalID = this.props.app.idm.session.authorization.getUserID();
    }
    //Request data
    await this._fetchData(externalID, needsUserFetch);
  }
  async _fetchData(externalID, needsUserFetch) {
    this.setState({ isLoading: true });
    let licenses = null, orders = null, user = null, org = null;
    //Load licenses, orders and user in parallel
    await Utils.execRequests([
      await this._fetchLicenses(externalID),
      await this._fetchOrders(externalID),
      await (this.isOrg ? this._fetchOrganization(externalID) : this._fetchUser(externalID, needsUserFetch))
    ], null, (resp, index) => {
        if (index == 0) { //Licenses
          licenses = (resp?.body?.licenses || []).map((license) => ({
            ...license,
            productName: this.props.app.sharedCache().getProductByID(license.productID).name,
          })).sort((a, b) => b.createdOn - a.createdOn);
        } else if (index == 1) { //orders
          orders = (resp?.body?.orders || []).sort((a, b) => b.createdOn - a.createdOn);
        } else if (index == 2) { //user
          if (this.isOrg) org = resp.body;
          else user = resp.body;
        }
    });
    //Set state but also check if purchase modal came back as is waiting data to be loaded to show up
    const purchaseModalRequiredAttention = this.state.purchaseModalRequiresAttention;
    this.setState({ isLoading: false, licenses, orders, user, org, purchaseModalRequiresAttention: false }, () => {
      if (purchaseModalRequiredAttention) this.handleBuyMore();
    });
  }
    //License API calls
  async _fetchLicenses(externalID) {
    const resp = await this.props.app.license.license.getLicensesByExternalID(externalID);
    if (resp.statusCode != 200 || !resp.body) {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } return resp;
  }
  async _fetchOrders(externalID) {
    const resp = await this.props.app.license.order.getOrdersByExternalID(externalID);
    if (resp.statusCode != 200 || !resp.body) {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } return resp;
  }
  async _cancelOrder(orderID, extID, productID) {
    this.startLoading();
    const productSpecs = this.props.app.sharedCache().getProductByID(productID);
    const provider = this.props.app.license.order.getProviderFromProduct(productSpecs);
    const resp = await this.props.app.license.order.cancelOrder({
      user: { id: extID },
      orderID
    }, provider);
    if (resp.statusCode == 200) {
      message.success('Order cancelled!');
      this._loadData();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  async _revokeLicense(licenseID) {
    this.startLoading();
    const resp = await this.props.app.license.license.revokeLicense(licenseID);
    if (resp.statusCode == 200) {
      message.success('License revoked!');
      this._loadData();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
    //Purchase modal support
  async _fetchUser(externalID, needsUserFetch) {
    if (needsUserFetch) {
      const resp = await this.props.app.api.user.getByID(externalID);
      if (resp.statusCode != 200 || !resp.body) {
        this.props.app.alertController.showAPIErrorAlert(null, resp);
      } return resp;
    } else {
      return { statusCode: 200, body: this.props.app.sharedCache().getProgramUser() };
    }
  }
  async _fetchOrganization(orgID) {
    const resp = await this.props.app.organization.organizationApp.getOrganizationApp(orgID);
    if (resp.statusCode != 200 || !resp.body) {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } return resp;
  }
  async _downloadReceipt(externalID, licenseID) {
    this.startLoading();
    const resp = await this.props.app.license.order.getOrderReceipt(externalID, licenseID);
    if (resp.statusCode == 200) Utils.downloadArrayBuffer(resp.body.data, `receipt-${licenseID}`, 'pdf');
    else message.error(`Error while download order receipt. Please, try again!`);
    this.stopLoading();
  }
}
