import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Row, Col, Button, message } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
//
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import '../../assets/stylesheets/WhiteBox.less';
//
// import config from '../../config/config';
import Globals from '../../config/Globals';
//
const styleHidden = {style: { display: 'none' }};
// This view can be called in 4 different ways:
//    - /billing/:type/:externalID
//      Where type can be 'user' or 'org' and externalID being optional
//    - Same param {type} & {externalID} to embeed this view somewhere else
//props can be: orgID or userID, aside with: app
export default class CommonBillingView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { 
      isLoading: true, orgMode: (this.props.match?.params?.[Globals.URL_Path_ID_Placeholder] || this.props.type).indexOf('org') != -1,
      externalID: this.props.match?.params?.[Globals.URL_Path_ID2_Placeholder] || this.props.externalID || null,
      newPaymentMethodAvailable: false
    };
    console.log(this.state)
  }

  //Life cycle
  async componentDidMount() {
    super.componentDidMount();
    await this._loadBraintreeSDK();
    this.stopLoading();
  }
  componentWillUnmount() { 
    this._releaseDropInListeners();
    if (this.paymentProcessorInstance) this.paymentProcessorInstance.teardown();
  }

  //Actions
  async handleAddPaymentAction() {
    const nonce = await this.paymentProcessorInstance.requestPaymentMethod();
  }

  //UI
  render() {
    const isEmbeeded = !!this.props.type;
    const { isLoading } = this.state;
    return (
      <>
        <Layout.Content className="pageContent">  
          <CommonLoadingView isLoading={isLoading} />
          {!isEmbeeded && <PageHeader className="pageHeader" onBack={() => { this.props.app.urlManager.pushBack(); }} title="Billing"/>}
          <Layout.Content type='flex' align='center' className='whiteBox'>
            <Row type='flex' justify='middle' style={{maxWidth: 600, marginLeft: 'auto', marginRight: 'auto'}}>
                <div {...(isLoading ? styleHidden : { style: { opacity: 1, width: '100%' }})} id="paymentProcessorContainer"></div>
              </Row>
              <Row type='flex' justify='end' style={{marginTop: 20}}>
                <Col>
                  <Button type="primary" loading={isLoading} disabled={isLoading || !this.state.newPaymentMethodAvailable} onClick={this.handleAddPaymentAction}>Add Payment method</Button>
                </Col>
              </Row>
          </Layout.Content>
        </Layout.Content>
      </>
    );
  }

  // Utils
  _getScopedExternalID() {
    if (this.state.externalID) return this.state.externalID;
    if (this.state.orgMode) return this.props.app.urlManager.selectedOrg;
    else return this.props.app.getAuthorizedUserID();
  }
  async _getScopedUserInformation() {
    if (this.state.externalID && !this.state.orgMode) { //get user info
      const user = await this.props.app.api.user.getByID(this.state.externalID);
      if (user.statusCode != 200) this.props.app.alertController.showAPIErrorAlert(null, user);
      else {
        const { lastName, firstName, email } = user.body;
        return { firstName, lastName, email };
      }
    } else if (this.state.orgMode) { //get org info
      const orgID = this.state.externalID || this.props.app.urlManager.selectedOrg;
      const orgResp = await this.props.app.organization.organizationApp.getOrganizationApp(orgID);
      const usr = this.props.app.sharedCache().getProgramUser();
      if (orgResp.statusCode == 200 && orgResp.body) return { firstName: orgResp.name, lastName: `${(usr ? `- ${usr.firstName} ` : '')}${(usr ? usr.lastName : '')}`, email: ( orgResp.managers ? orgResp.managers.map( m => m.email ) : [] ) };
    } 
    const { lastName, firstName, email } = this.props.app.sharedCache().getProgramUser();
    return { firstName, lastName, email };
  }

  /* Private Braintree */
  async _loadBraintreeSDK() {
    const info = (await this._getScopedUserInformation());
    console.debug(info);
    const nonce = await this.props.app.sharedCache().getVaultPaymentNonceByExternalID(this._getScopedExternalID(), info);
    this.paymentProcessorInstance = await this.props.app.paymentManager.initializePaymentFlowSDKOnContainer('#paymentProcessorContainer', nonce);
    this._setupDropInListeners();
  }
  /* API operations */
  async _setCurrentPaymentMethodAsDefault(paymentMethodAlreadyExists) {
    try {
      //Ask for payment method nonce
      //this operation will add the payment method if it's on the 'ADD' form
      const nonce = await this.paymentProcessorInstance.requestPaymentMethod();
      //If already existing payment method, we need to set as default via API
      if (paymentMethodAlreadyExists) {
        const resp = await this.props.app.license.vault.setVaultDefaultPaymentMethod(nonce.nonce, this._getScopedExternalID());
        if (resp.statusCode != 200) {
          this.paymentProcessorInstance.clearSelectedPaymentMethod();
          message.error('Error while setting up default payment method. Please, try again!');
        }
      } else message.success({ content: 'New payment method added with success!' });
    } catch (e) {
      console.error(e);
      message.error({ content: e.message });
    }
  }
    //Listeners
  _setupDropInListeners() {
    if (!this.paymentProcessorInstance) return;
    this.paymentProcessorInstance.on('paymentMethodRequestable', this._onPaymentMethodRequestable);
    this.paymentProcessorInstance.on('noPaymentMethodRequestable', this._onNoPaymentMethodRequestable);
  }
  _releaseDropInListeners() {
    if (!this.paymentProcessorInstance) return;
    this.paymentProcessorInstance.off('paymentMethodRequestable', this._onPaymentMethodRequestable);
    this.paymentProcessorInstance.off('noPaymentMethodRequestable', this._onNoPaymentMethodRequestable);
  }
  async _onPaymentMethodRequestable(event) { 
    this.setState({ newPaymentMethodAvailable: !event.paymentMethodIsSelected });
    if (event.paymentMethodIsSelected) await this._setCurrentPaymentMethodAsDefault(true);
  }
  _onNoPaymentMethodRequestable() {
    this.setState({ newPaymentMethodAvailable: false });
  } 
}
