import React from 'react';
import { Row, Col, Button } from 'antd';
import { CalendarFilled, ReloadOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import Countdown from 'react-countdown';
//
import Globals from '../../../../../config/Globals';
import { CourseCardActionsHOC } from '../Subcomponents/CourseCardActionsContext';
import UtilsSession from '../../../../../components/UtilsSession';
import CourseCardActions from '../Subcomponents/CourseCardActions';
//
class Scheduled extends React.Component {
  constructor(props) {
    super(props);

    this.interval = null;

    this.state = {
      triedToAutoReload: false,
      refreshTimer: 0,
    };
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  //Countdown support
  reload = async () => {
    this.setState({ triedToAutoReload: true, refreshTimer: 10 });

    await this.props.actions.onUpdate();

    this.interval = setInterval(() => {
      if (this.state.refreshTimer > 0) {
        this.setState(prevState => ({
          ...prevState,
          refreshTimer: prevState.refreshTimer - 1,
        }));
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  //UI
  render() {
    const { app, courseSpecs, session, certificationProcess } = this.props;
    const { uiTerm } = courseSpecs;
    const { timezone } = this.props.app.sharedCache().getTenantConfig();
    const isPresential = (this.props.session.type == Globals.Session_Type.SCHEDULED_PRESENTIAL);

    const sortedLaunchSchedule = [...session?.launchSchedule || []].sort((a, b) => a.dayOfWeek - b.dayOfWeek);
    const hasLaunchSchedule = [...session?.launchSchedule || []].length > 0;

    return (
      <>
        <Row justify="center">
          <Col span={24} style={{ textAlign: 'center' }}>
            The {courseSpecs.description} will be available on
            {(session?.startDate?.length > 1 || hasLaunchSchedule) ?
              ` the following dates:` :
              <>
                {` ${moment(new Date(session?.startDate?.[0])).format(`${Globals.DefaultDateFormat} hh:mm A`)}.`}
                <br></br>

                {!isPresential && (
                <>
                  A LAUNCH button will appear below, allowing you to begin your {uiTerm}.
                </>
                )}
              </>
            }
          </Col>
        </Row>
        {(session?.startDate?.length > 1 || hasLaunchSchedule) && (
          <Row justify="center">
            <Col>
              <ul>
                {!hasLaunchSchedule && (session?.startDate || []).map((s, i) => {
                  return <li key={i}> {moment(new Date(s)).format(`${Globals.DefaultDateFormat} hh:mm A`)} to {moment(new Date(session?.endDate?.[i])).format('hh:mm A')}</li>;
                })}
                {hasLaunchSchedule && (sortedLaunchSchedule || []).map((schedule, i) => {
                  const [startHours, startMinutes] = schedule.startTime.split(':');
                  const startTime = moment().tz(timezone).hours(startHours).minutes(startMinutes);
                  const startTimeLocalTz = moment(startTime.toDate()).format('hh:mm A');
                  const [endHours, endMinutes] = schedule.endTime.split(':');
                  const endTime = moment().tz(timezone).hours(endHours).minutes(endMinutes);
                  const endTimeLocalTz = moment(endTime.toDate()).format('hh:mm A');

                  return <li key={i}>
                    {/* TODO: Convert to local timezone */}
                    {Globals.WeekDays[schedule.dayOfWeek]} from {startTimeLocalTz} to {endTimeLocalTz}
                  </li>;
                })}
              </ul>
              <br></br>
                {!isPresential && (
                  <>
                   A LAUNCH button will appear below, allowing you to begin your {uiTerm}.
                  </>
                )}
            </Col>
          </Row>
        )}

        {this._renderCountdownRow(session, uiTerm)}
        <CourseCardActions app={app} disableActions={this.props.disableActions} courseSpecs={courseSpecs} certificationProcess={certificationProcess} session={session} actionCancelCourse/>
      </>
    );
  }

  /* private UI */
  _renderCountdownRow(session, uiTerm) {
    let date = session?.startDate?.[0];

    if (session?.type == Globals.Session_Type.ONLINE_EXT && session.launchSchedule) {
      const { timezone } = this.props.app.sharedCache().getTenantConfig();

      const { isToday, session: sessionData } = UtilsSession.getTodaysClosestSession(session.launchSchedule, timezone);
      const [hours, minutes] = sessionData.startTime.split(':');

      if (isToday) {
        date = moment().tz(timezone).hours(hours).minutes(minutes).unix() * 1000;
    } else {
        const todayWeekDay = moment().tz(timezone).isoWeekday();

        if (sessionData.dayOfWeek > todayWeekDay) {
          date = moment()
            .isoWeekday(sessionData.dayOfWeek)
            .hours(0)
            .minutes(0)
            .tz(timezone)
            .unix() * 1000;
        } else {
          date = moment()
            .add(1, 'weeks')
            .isoWeekday(sessionData.dayOfWeek)
            .hours(0)
            .minutes(0)
            .tz(timezone)
            .unix() * 1000;
        }
      }
    }

    return (
      <Row style={{ marginTop: 10, fontSize: 28 }} justify="center">
        <Col>
          <Countdown
            date={date + (30 * 1000)} // add 30 extra seconds
            renderer={({ days, hours, minutes, seconds, completed }) => {
              if (completed) {
                if (!this.state.triedToAutoReload) {
                  this.reload();
                }

                return (
                  <div style={{ marginTop: 16 }}>
                    <Row justify="center">
                      <Button type="primary" onClick={this.reload} icon={<ReloadOutlined />} disabled={this.state.refreshTimer > 0}>
                        Refresh {this.state.refreshTimer > 0 ? `(${this.state.refreshTimer})` : ''}
                      </Button>
                    </Row>
                    <Row justify="center" style={{ maxWidth: 500, fontSize: 12, textAlign: 'center', marginTop: 8, opacity: 0.6 }}>
                      Your {uiTerm} is about to start but your access to it was not enabled yet.
                      <br />
                      Please click the Refresh button to check if your {uiTerm} is already available.
                    </Row>
                  </div>
                );
              }

              if (days > 0) {
                return <span>Starts in <strong>{days} days</strong></span>;
              }

              return <span>Starts in <strong>{this._prefixZero(hours)}:{this._prefixZero(minutes)}:{this._prefixZero(seconds)}</strong></span>;
            }}
          />
        </Col>
      </Row>
    );
  }

  /* private */
  _prefixZero(number) {
    return number < 10 ? `0${number}` : number;
  }
}

Scheduled = CourseCardActionsHOC(Scheduled);
Scheduled.CardStatusHeader = function() {
  return <span className="info"><CalendarFilled /> Scheduled</span>;
};

export default Scheduled;
