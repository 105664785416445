import React from 'react';
import { Badge } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import moment from 'moment';
//
import CommonResultCard from '../../../../commonComponents/CommonResultCard';
import Globals from '../../../../../config/Globals';
import CourseCardActions from '../Subcomponents/CourseCardActions';
import { CourseCardActionsHOC } from '../Subcomponents/CourseCardActionsContext';

class Completed extends React.Component {
  constructor(props) {
    super(props);

    this.courseID = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_CourseID);
    this.autoLaunch = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Launch) == 'true';
    this.alreadyAutoLaunched = false;
  }

  render() {
    const { courseSpecs, currentCourse, app } = this.props;

    return (
      <>
        {this._renderExpirationBadge()}
        <CommonResultCard label={`Passed on ${courseSpecs.description}.`} app={app} currentCourse={currentCourse}/>
        {this._renderActions()}
      </>
    );
  }
  _renderActions() {
    const { courseSpecs, session, currentCourse } = this.props;

    let actionLaunch = true;

    if (courseSpecs.preventLaunchIfCompleted) {
      actionLaunch = false;
    }

    const enrollment = session?.enrolments ? session?.enrolments[0] : null;
    let date = null;
    if (enrollment && session?.onlineAccessPeriod && session.onlineAccessPeriod >= 1) {
      date = moment(enrollment.accessReleasedOn).add(session.onlineAccessPeriod, 'minutes');
    }
    
    if(moment(new Date()).isBefore(date)) {
      if (currentCourse.courseID == this.courseID && this.autoLaunch && !this.alreadyAutoLaunched) {
        this.alreadyAutoLaunched = true;
        this.props.actions.launch();
      }
    }
  
    return (
      <>
        <CourseCardActions
          app={this.props.app}
          courseSpecs={this.props.courseSpecs}
          session={session}
          actionLaunch={actionLaunch}
          actionRetake
          actionRevoke
        />
      </>
     );
  }
  _renderExpirationBadge() {
    const { courseSpecs, currentCourse, certificationProcess } = this.props;

    if (
      !currentCourse.invalidated
      && courseSpecs.validityPeriod
      && certificationProcess.expiryDate
      && certificationProcess.expiryDate != '-1'
      && currentCourse.state == Globals.Course_State.PASS
      && certificationProcess.state != Globals.CertificationProcess_State.COMPLETED
    ) {
        const time = new Date(certificationProcess.expiryDate);
        let daysDiff = (time.getTime() - Date.now())/ (1000 * 3600 * 24);

        if (parseInt(daysDiff) >= 0) {
          const displayDiff = (daysDiff + 1);

          return (
            <Badge className="available-badge" count={`Expires in ${parseInt(displayDiff)} ${displayDiff == 1 ? 'day' : 'days'}`}/>
          );
        } else if (parseInt(daysDiff) < 0) {
          daysDiff *= -1;

          return (
            <Badge className="available-badge" count={`Expired ${parseInt(daysDiff)} ${daysDiff == 1 ? 'day' : 'days'} ago`}/>
          );
        }
    }
  }
}

Completed = CourseCardActionsHOC(Completed);
Completed.CardStatusHeader = function(courseSpecs) {
  return <span className="success"><CheckCircleFilled /> {courseSpecs.passUiTerm ? courseSpecs.passUiTerm : 'Passed'} </span>;
}

export default Completed;
