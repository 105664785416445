import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Table, Tooltip, Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
//
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
//props are: courses, onDelete, onEdit, isLoading
export default class CommonSettingsCoursesTable extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: null };
  }

  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'Name', dataIndex: 'displayName', key: 'displayName', width: '15%',
        sorter: (a, b) => a.displayName - b.displayName,
        sortOrder: sortedInfo.columnKey === 'displayName' && sortedInfo.order
      },
      {
        title: 'Description', dataIndex: 'description', key: 'description', width: '15%',
        sorter: (a, b) => a.description - b.description,
        sortOrder: sortedInfo.columnKey === 'description' && sortedInfo.order
      },
      {
        title: 'Created On', dataIndex: 'createdOn', key: 'createdOn', width: '15%',
        render: createdOn => createdOn ? Utils.getDateOnUIFormatByTimestamp(createdOn) : '--',
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order
      },
      {
        title: 'Actions', width: '10%', key: 'Actions',
         render: props => {
          return (
            <span className='tableButtonContainer'>
              <Tooltip placement="bottomLeft" title="Edit">
                <Button variant="none" icon={<EditOutlined />} style={{marginLeft: 10 }} onClick={() => this.props.onEdit(props)} shape="circle" />
              </Tooltip>
              <Tooltip placement="bottomLeft" title="Delete">
                <Popconfirm placement="top" title="Are you sure that you want to delete this course?"
                  onConfirm={() => this.props.onDelete(props)} okText="Yes" cancelText="No">
                  <Button variant="none" icon={<DeleteOutlined />} shape="circle" />
                </Popconfirm>
              </Tooltip>
            </span>
          );
        }
      }
    ];
    const props = {
      rowKey: 'id', loading: this.props.isLoading, onChange: this.handleFilterChange, locale: { emptyText: 'No courses found!' },
      pagination: {pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter']},
    };
    return (<Table className="adminSettingsCoursesTable" columns={columns} dataSource={this.props.courses} {...props}/>);
  }
}
