import React from 'react';
import { Row, Col } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
//
import Globals from '../../../../../config/Globals';
import Utils from '../../../../../components/Utils';
//
import CourseCardActions from '../Subcomponents/CourseCardActions';

export default class Cooldown extends React.Component {
  render() {
    const { app, courseSpecs, currentCourse, certificationProcess, session } = this.props;

    const time = new Date(currentCourse?.courseResult?.resultDate);
    time.setDate(time.getDate() + (this._getCooldownPeriod() || 0));

    const hasCooldownFinished = (
      currentCourse.cooldownOverwrittenOn > currentCourse?.courseResult?.resultDate
      || time.getTime() <= Date.now() && certificationProcess.state != Globals.CertificationProcess_State.COOLDOWN
    );
    return (
      <>
        <Row justify="center">
          <Col span={24} style={{ textAlign: 'center' }}>
            {
              hasCooldownFinished
                ? `Cooldown period ended on ${Utils.getDateOnUIFormatCoolDown(time)}. You are now able to purchase a new license and take the ${courseSpecs.uiTerm.toLowerCase()}.`
                : `Cooldown period is in progress, you must wait until ${Utils.getDateOnUIFormatCoolDown(time)} to retake the ${courseSpecs.description}.`
            }
          </Col>
        </Row>

        {(!hasCooldownFinished && this.props.app.isAdmin()) && 
          <CourseCardActions app={app} disableActions={this.props.disableActions} courseSpecs={courseSpecs} certificationProcess={certificationProcess} session={session} actionEndCooldown/>
        }
      </>
    );
  }
  //Private
  _getCooldownPeriod() {
    const { courseSpecs, certificationProcess } = this.props;
    //Count retries
    let retries = 0;
    for (let course of certificationProcess.courses) {
      if (course.courseID == courseSpecs.id && !course.invalidated && course.state == Globals.Course_State.FAIL) {
        console.log(course)
        retries++;
      }
    }
    return (courseSpecs.coolDownPeriod && courseSpecs.coolDownPeriod != '-1' ? Array.isArray(courseSpecs.coolDownPeriod) ? courseSpecs.coolDownPeriod[retries - 1] || courseSpecs.coolDownPeriod[courseSpecs.coolDownPeriod.length - 1] : courseSpecs.coolDownPeriod : null)
  }
}
Cooldown.CardStatusHeader = function() {
  return <span className="info"><InfoCircleFilled /> Cooldown</span>;
}
