import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, message, Tabs, Row, Col, Button } from 'antd';
//
import config from '../../../config/config';
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
//
import CommonInstructorRegionsForm from '../../commonComponents/FakeForms/CommonInstructorRegionsForm';
import CommonInstructorCoursesForm from '../../commonComponents/FakeForms/CommonInstructorCoursesForm';
import CommonInstructorProfileForm from '../../commonComponents/Forms/CommonInstructorProfileForm';
import AdminInstructorCalendarView from '../../adminSubviews/SettingsViews/AdminInstructorCalendarView';
//
const RequestMask = { USER: 2, IREGIONS: 4, ICOURSES: 8, REGIONS: 16, ALL: 2 | 4 | 8 | 16 };
//
export default class AdminInstructorView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    const instructorID = this.props.match.params.id;
    this.state = {
      isLoading: false, instructorID: instructorID,
      instructor: null, instructorRegions: null, instructorCourses: null, regions: null,
      selectedTab: 'profile',
    };
  }

  //View Life Cycle
  async componentDidMount() {
    super.componentDidMount();
    await this.fetchData(RequestMask.ALL);
    const selectedTab = this.props.app.idm.urlmanager.getParam(Globals.URL_Path_TabID);
    if (this.state.selectedTab != selectedTab && selectedTab) this.handleTabChange(selectedTab);
    else this.handleTabChange(this.state.selectedTab);
  }

  async fetchData(mask) {
    this.startLoading();
    const resp = await this._loadData(mask);
    if (resp) {
      this.setState({ ...resp, isLoading: false }, () => {
        this.profileForm.setFieldsValue(this.state.instructor);
      });
    } else this.stopLoading();
  }

  //Actions
  handleTabChange(selectedTab) {
    this.setState({ selectedTab }, () => {
      this.props.app.urlManager.updateQueryStringParam(Globals.URL_Path_TabID, selectedTab);
    });
  }
  //Real forms actions
  async handleInstructorUpdate() {
    let resp = await this.profileForm.validateFields();
    if (resp) {
      //Check if changing password, if yes, prompt
      if (this.state.instructor?.email != resp.email) {
        const confirmation = await this.props.app.alertController.showPromptAlert('Attention!', `Instructor email will be updated to "${resp.email}". Are you sure you want to update it? (type the new email address to confirm)`);
        if (confirmation != resp.email) return;
      }
      //Continue update!
      this.startLoading();
      resp = await this._updateInstructorInfo(resp);
    }
    if (resp) await this.fetchData(RequestMask.USER);
    this.stopLoading();
  }
  //Fake forms actions
  async handleInstructionRegionAdd(regionID) {
    this.startLoading();
    const resp = await this._addInstructorRegion(regionID);
    if (resp) await this.fetchData(RequestMask.IREGIONS);
    this.stopLoading();
  }
  async handleInstructionRegionDelete(regionID) {
    this.startLoading();
    const resp = await this._deleteInstructorRegion(regionID);
    if (resp) await this.fetchData(RequestMask.IREGIONS);
    this.stopLoading();
  }
  async handleInstructionCourseAdd(courseID) {
    this.startLoading();
    const resp = await this._addInstructorCourse(courseID);
    if (resp) await this.fetchData(RequestMask.ICOURSES);
    this.stopLoading();
  }
  async handleInstructionCourseDelete(courseID) {
    this.startLoading();
    const resp = await this._deleteInstructorCourse(courseID);
    if (resp) await this.fetchData(RequestMask.ICOURSES);
    this.stopLoading();
  }
  //UI
  render() {
    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className="pageHeader" title={'Instructor'} onBack={() => this.props.app.urlManager.pushBack()} />
            <Layout.Content>
              <Tabs activeKey={this.state.selectedTab} onChange={this.handleTabChange}>
                <Tabs.TabPane tab="Profile" key="profile">
                  <Row type='flex' justify='end'>
                    <Col>
                      <Button style={{ marginBottom: 20 }} type="primary" onClick={this.handleInstructorUpdate}> Update Profile </Button>
                    </Col>
                  </Row>
                  <CommonInstructorProfileForm
                    app={this.props.app}
                    {...Utils.propagateRef(this, 'profileForm')}
                  />
                  <AdminInstructorCalendarView
                    app={this.props.app}
                    userID={this.state.instructorID}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Courses" key="courses">
                  <CommonInstructorCoursesForm app={this.props.app} courses={this.props.app.sharedCache().getAllUniqueCourses()}
                                               instructorCourses={this.state.tabInstructorCourses}
                                               onSelect={this.handleInstructionCourseAdd} onDelete={this.handleInstructionCourseDelete} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Regions" key="regions">
                  <CommonInstructorRegionsForm app={this.props.app} regions={this.state.regions} instructorRegions={this.state.instructorRegions}
                                              onSelect={this.handleInstructionRegionAdd} onDelete={this.handleInstructionRegionDelete}/>
                </Tabs.TabPane>
                {/* {this.state.isEdit && <Tabs.TabPane tab="Consumption" key="consumptions"> {this._renderConsumptionsTable()} </Tabs.TabPane>} */}
              </Tabs>
            </Layout.Content>
        </Layout.Content>
      </>
    );
  }

  /* private API calls */
  async _loadData(mask) {
    const resp = await Promise.all([
      (mask & RequestMask.USER) === RequestMask.USER ? this.props.app.idm.api.user.read(this.state.instructorID) : Promise.resolve(),
      (mask & RequestMask.IREGIONS) === RequestMask.IREGIONS ? this.props.app.classroom.instructor.getInstructorRegions(this.state.instructorID) : Promise.resolve(),
      (mask & RequestMask.ICOURSES) === RequestMask.ICOURSES ? this.props.app.classroom.instructor.getInstructorCourses(this.state.instructorID) : Promise.resolve(),
      (mask & RequestMask.REGIONS) === RequestMask.REGIONS ? this.props.app.sharedCache().getRegions() : Promise.resolve(),
    ]);
    if ((mask & RequestMask.USER) === RequestMask.USER && !(resp[0].statusCode == 200 && resp[0].body && resp[0].body.id)) {
      this.props.app.alertController.showAPIErrorAlert(null, resp[0]);
      return false;
    }
    if ((mask & RequestMask.IREGIONS) === RequestMask.IREGIONS && !(resp[1].statusCode == 200 && resp[1].body && resp[1].body.instructorRegions)) {
      this.props.app.alertController.showAPIErrorAlert(null, resp[1]);
      return false;
    }
    if ((mask & RequestMask.ICOURSES) === RequestMask.ICOURSES && !(resp[2].statusCode == 200 && resp[2].body && resp[2].body.instructorCourses)) {
      this.props.app.alertController.showAPIErrorAlert(null, resp[2]);
      return false;
    }
    if ((mask & RequestMask.REGIONS) === RequestMask.REGIONS && !resp[3]) {
      this.props.app.alertController.showErrorAlert(null, 'Error while loading regions!');
      return false;
    }
    return {
      ...(mask & RequestMask.USER) === RequestMask.USER ? { instructor: resp[0].body } : {},
      ...(mask & RequestMask.IREGIONS) === RequestMask.IREGIONS ? { instructorRegions: resp[1].body.instructorRegions } : {},
      ...(mask & RequestMask.ICOURSES) === RequestMask.ICOURSES ? { instructorCourses: resp[2].body.instructorCourses } : {},
      ...(mask & RequestMask.ICOURSES) === RequestMask.ICOURSES ? { tabInstructorCourses: resp[2].body.instructorCourses.filter(ic => this.props.app.sharedCache().getCourseByID(ic.courseID)) } : {},
      ...(mask & RequestMask.REGIONS) === RequestMask.REGIONS ? { regions: resp[3] } : {},
    };
  }
  //Instructor region
  async _addInstructorRegion(region) {
    //Append new region
    let iRegions = this.state.instructorRegions?.map((r) => r.regionID);
    if (!iRegions) iRegions = [];
    iRegions.push(region.id);
    //
    const resp = await this.props.app.classroom.instructor.upsertInstructorRegions(this.state.instructorID, iRegions);
    if (resp.statusCode == 200) {
      message.success('Region added to instructor with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _deleteInstructorRegion(regionID) {
    //Append new region
    let iRegions = this.state.instructorRegions?.map((r) => r.regionID);
    if (!iRegions) iRegions = [];
    iRegions.splice(iRegions.indexOf(regionID), 1);
    //
    const resp = await this.props.app.classroom.instructor.upsertInstructorRegions(this.state.instructorID, iRegions);
    if (resp.statusCode == 200) {
      message.success('Region removed to instructor with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  //Instructor course
  async _addInstructorCourse(course) {
    //Append new course
    let iCourses = this.state.instructorCourses?.map((r) => r.courseID);
    if (!iCourses) iCourses = [];
    iCourses.push(course.id);
    //
    const resp = await this.props.app.classroom.instructor.upsertInstructorCourses(this.state.instructorID, iCourses);
    if (resp.statusCode == 200) {
      message.success('Course added to instructor with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _deleteInstructorCourse(courseID) {
    //Append new course
    let iCourses = this.state.instructorCourses?.map((r) => r.courseID);
    if (!iCourses) iCourses = [];
    iCourses.splice(iCourses.indexOf(courseID), 1);
    //
    const resp = await this.props.app.classroom.instructor.upsertInstructorCourses(this.state.instructorID, iCourses);
    if (resp.statusCode == 200) {
      message.success('Course removed to instructor with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  //IDM Instructor
  async _updateInstructorInfo(data) {
    const user = { ...this.state.instructor, firstName: data.firstName, lastName: data.lastName, email: data.email };
    const updateUserResp = await this.props.app.idm.api.user.update(user);
    if (updateUserResp.statusCode == 200) {
      message.success('Instructor profile updated with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
}
