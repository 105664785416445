import React from 'react';
import ReactMarkdown from 'react-markdown';
//
import CourseTabWrapper from './CourseTabWrapper';

//props are: app, currentCourse
export default class CourseTabResourcesTab extends CourseTabWrapper {
  render() {
    //Check if not visible, don't render -- Please, change root element to
    //'display: none' if you need to retain any subcomponent
    if (!this.props.isVisible) return (<></>);
    //
    return super.render(<ReactMarkdown children={this.props.resources} linkTarget='_blank' />);
  }
}
