import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, message, Row, Col, Button } from 'antd';
//
import config from '../../../config/config';
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import WhiteBox from '../../commonComponents/WhiteBox';
//
import CommonProductForm from '../../commonComponents/Forms/CommonProductForm'
//
export default class AdminProductView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    const productID = this.props.match.params.id;
    this.state = {
      isLoading: false, productID: (productID == Globals.URL_NewIndentifier ? null : productID),
      product: null,
    };
  }

  //View Life Cycle
  async componentDidMount() {
    super.componentDidMount();
    //Load data
    await this.fetchData((this.state.productID));
  }

  async fetchData(productID) {
    this.startLoading();
    const resp = await this._loadData(productID);
    if (resp) {
      this.setState({ ...resp, isLoading: false }, () => {
        if (this.state.productID && this.state.product) this.productForm.setFieldsValue(this.state.product);
      });
    } else this.stopLoading();
  }

  //Actions
  async handleProductSubmit() {
    const formData = await this.productForm.validateFields();
    if (!formData) return;
    this.startLoading();
    let productDataToUpdate = formData;
    if (this.state.product) {
      productDataToUpdate = { ...this.state.product, ...formData };
    }
  
    productDataToUpdate.allowedProviders = Array.isArray(productDataToUpdate.allowedProviders) ? productDataToUpdate.allowedProviders : [productDataToUpdate.allowedProviders];
  
    if (this.state.productID) await this._updateProduct(productDataToUpdate);
    else await this._createProduct(formData);
    this.stopLoading();
  }
  
  //UI
  render() {
    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className="pageHeader" title={'Product'} onBack={() => this.props.app.urlManager.pushBack()} />
            <WhiteBox>
              <Row type='flex' justify='end'> <Col> <Button style={{ marginBottom: 20 }} type="primary" onClick={this.handleProductSubmit}> {this.state.productID ? 'Update' : 'Create'} Product </Button> </Col> </Row>
              <CommonProductForm {...Utils.propagateRef(this, 'productForm')} app={this.props.app} isEdit={!!this.state.productID} />
            </WhiteBox>
        </Layout.Content>
      </>
    );
  }

  /* private API calls */
  async _loadData(productID) {
    const resp = await this.props.app.sharedCache().getProductByID(productID);
    if (resp) {
      return { product: resp };
    } else {
      return false;
    }
  }
  async _createProduct(productObj) {
    if (!this._isMounted) return;
    const resp = await this.props.app.license.product.createProduct(
      productObj
    );
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Product created with success!');
      //
      this.props.app.urlManager.replacePage(config.ApplicationRoutes.products);
      this.setState({ productID: resp.body.id });
      await this.fetchData(resp.body.id);
      //
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _updateProduct(productObj) {
    const resp = await this.props.app.license.product.updateProduct(
      productObj
    );
    if (resp.statusCode == 200) {
      message.success('Product updated with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
}
