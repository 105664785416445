import React from 'react';
import autoBind from 'react-autobind';
import { Layout, Button } from 'antd';
import moment from "moment";
//
import CustomComponent from '../../components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CommonActivationsTable from '../commonComponents/CommonActivationsTable';
import CommonActivationsSearchBar from '../commonComponents/CommonActivationsSearchBar';
//
import config from '../../config/config';

export default class CommonActivationsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    const thirtyDaysAgo = moment().subtract(30, 'days');
    this.state = {
      isLoading: true,
      searchObj: { from: thirtyDaysAgo.valueOf(), to: moment().valueOf() },
      consumptions: [],
      productFilter: null,
      orgID: null
    };
    this._handleSearch = this._handleSearch.bind(this);
  }

  async componentDidMount() {
    await this.setOrgID();
    this._handleSearch(this.state.searchObj);
  }

  _handleProductFilter(product) {
    this.setState({ productFilter: product }); 
  }

  // Event handler to update searchObj
  _updateSearchObj(searchObjUpdates) {
    this.setState(prevState => ({
      searchObj: {
        ...prevState.searchObj,
        ...searchObjUpdates
      }
    }));
  }

  // UI
  render() {
    const { isLoading, consumptions, searchObj, productFilter } = this.state;
    return (
      <>
        <Layout.Content >
          <CommonLoadingView isLoading={isLoading} />
            <div style={{ display: 'flex',  marginBottom: '10px' }}>
              <CommonActivationsSearchBar
                onSearch={this._search}
                onUpdateSearchObj={this._updateSearchObj}
                searchObj={searchObj}
                app={this.props.app}
                onProductFilter={this._handleProductFilter}
                orgID={this.props.orgID}
              />
              <Button style={{ marginLeft: 16 }} key="1" type='primary' onClick={() => this._handleSearch(this.state.searchObj)}>Search</Button>
            </div>
            <CommonActivationsTable 
              consumptions={consumptions?.consumptions} 
              isLoading={isLoading}
              productFilter={productFilter}
              onRow={this.handleSelectLicenseRow}
            />
        </Layout.Content>
      </>
    );
  }

  /* Private methods */
  async _handleSearch(searchObj) {
    this.setState({ isLoading: true });
    try {
      await this._search(searchObj);
    } catch (error) {
      console.error("Error occurred while searching:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async setOrgID() {
    let orgID = null;
    if (this.props.app.isOrgManager()) {
      orgID = this.props.app.urlManager.selectedOrg;
    } else {
      orgID = this.props.orgID;
    }
   this.setState({ orgID }); 
  }

  async handleSelectLicenseRow(record) {
    await this.setOrgID();
    const { orgID } = this.state;
    const licenseID = record.licenseID;

    this.props.app.urlManager.pushPage(
      config.ApplicationRoutes.licenseConsumption, null, orgID, licenseID,
    );
  }

  async _search(searchObj) {
    await this.setOrgID();
    const { orgID } = this.state;
    const { from, to } = searchObj;

    this.setState({ isLoading: true });
    try {
      const resp = await this.props.app.license.licenseConsumption.searchLicenseConsumption(
        orgID, from, to
      );
      if (resp.statusCode === 200 && resp.body) {
        this.setState({ consumptions: resp.body });
      } else {
        console.error("Error occurred while fetching data:", resp.error);
      }
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  }
}
