import React from 'react';
import autoBind from 'react-autobind';
import { Form, Row, Col, Input, InputNumber, Typography, Checkbox, Button, message } from 'antd';
import { CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
//
import CustomComponent from '../../../components/CustomComponent';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
import CommonOrganizationSelectionInput from '../OrganizationSelection/CommonOrganizationSelectionInput';
//
export default class CommonOrgForm extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      selectedOrg: null,
      affiliateID: null,
      affiliateURLCopied: false,
      isAffiliate: false,
      metadata: null
    };
  }

  //Actions
  handleCreateNewOrg() { this.props.app.urlManager.redirectToPortal(Globals.PortalFEPath_OrgCreate); }
  handleSelectOrg(org) { this.setState({ selectedOrg: org }, this.form.validateFields); }
  handleCopyAffiliateURL() {
    this.setState({ affiliateURLCopied: true }, () => {
      message.success('Copied to clipboard');
      setTimeout(() => {
        if (this._isMounted) this.setState({ affiliateURLCopied: false });
      }, 3500);
    });
  }
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (!formData || !this.state.selectedOrg) return;
    //
    this.props.onSubmit({
      metadata: {
        apprenticeshipProgram: !!formData.apprenticeshipProgram,
        ...(formData.discPercent && formData.discPercent > 0 ?
          { discPercent: formData.discPercent / 100, discAmount: 0 } :
          { discPercent: 0, discAmount: formData.discAmount }
        ),
        voucherID: this.state.selectedOrg.metadata?.voucherID || null
      },
      selectedOrg: this.state.selectedOrg,
      isAffiliate: !!formData.isAffiliate,
    });
    //
    return false;
  }
  //
  setFormData(data) {
    this.setState({ affiliateID: data.affiliateID || null, isAffiliate: data.isAffiliate, metadata: data.metadata, selectedOrg: data });
    this.form.setFieldsValue({
      ...(data.metadata && data.metadata.discPercent && data.metadata.discPercent > 0 ?
        { discPercent: data.metadata.discPercent * 100, discAmount: 0 } :
        { discPercent: 0, discAmount: data.metadata?.discAmount || 0 }
      ),
      apprenticeshipProgram: data.metadata ? (data.metadata.apprenticeshipProgram) : false,
      isAffiliate: data.isAffiliate,
    });
  }

  //UI
  render() {
    const layout = { labelCol: { span: 4 }, wrapperCol: { span: 10 } };
    const isFullForm = (!this.props.app.isUser() && !this.props.app.isOrgManager());
    return (
      <Form {...layout} {...Utils.propagateRef(this, 'form')}>
        <Row type='flex'>
          <Col span={18} offset={1}>
            <CommonOrganizationSelectionInput app={this.props.app} isEdit={!this.props.editMode} selectedOrg={this.state.selectedOrg} isAdmin={this.props.app.isAdmin()} 
              input={{ id: 'orgID', label: 'Organization Name', required: true, placeholder: this.props.editMode ? null : 'Please, selected an organization' }} 
              onSelection={this.handleSelectOrg}/>
          </Col>
          {this.props.app.isOrgAdmin() && <Col style={{marginLeft: -450}}> <Button onClick={this.handleCreateNewOrg} type='primary'> + Create New </Button> </Col>}
        </Row>
        {isFullForm && <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 8 }} extra="*Discount per license, percentage amount takes precedence." label="Discount">
          <Form.Item extra="Fixed Amount" initialValue={0} name="discAmount" style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}>
            <InputNumber formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')} />
          </Form.Item>
          <Typography.Text className='discountDivider'>OR</Typography.Text>
          <Form.Item extra="Percentage" initialValue={0} name="discPercent" style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}>
            <InputNumber formatter={value => `${value}%`} parser={value => value.replace('%', '')} min={0} max={100} />
          </Form.Item>
        </Form.Item>}
        {isFullForm && <Form.Item label="Is Affiliate?" name="isAffiliate" colon={false} valuePropName="checked">
          <Checkbox id="isAffiliate" />
        </Form.Item>}
        {this.state.isAffiliate && (
          <Form.Item label="Affiliate ID">
            <Input type="text" value={this.state.affiliateID} disabled suffix={
                <CopyToClipboard text={this.props.app.urlManager.getRegisterPageByAffiliateID(this.state.affiliateID)} onCopy={this.handleCopyAffiliateURL}>
                  <Button> Copy Link
                    {!this.state.affiliateURLCopied && <CopyOutlined />}
                    {this.state.affiliateURLCopied && <CheckOutlined />}
                  </Button>
                </CopyToClipboard>}/>
          </Form.Item>
        )}
        {isFullForm && <Form.Item label="Apprenticeship Program?" name="apprenticeshipProgram" colon={false} valuePropName="checked">
          <Checkbox id="apprenticeshipProgram" />
        </Form.Item>}
        <Form.Item label=" " colon={false}>
          <Button style={{ border: 0 }} type="primary" onClick={this.handleSubmit} loading={this.props.isLoading} disabled={this.props.isLoading}>
            {this.props.editMode ? 'Save' : 'Register'}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
