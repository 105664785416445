import React from "react";
import autoBind from 'react-autobind';
import { Form, Row, Col, Input } from 'antd';
import Utils from "../../../components/Utils";
//
export default class CommonCityForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Public
  resetFields() { this.form.resetFields(); }
  setFieldsValue(data) { this.form.setFieldsValue({ ...data }); }
  async validateFields() {
    const formData = await this.form.validateFields();
    if (!formData) return false;
    return formData;
  }

  //UI
  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Row>
          <Col span={14}>
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input/>
            </Form.Item>
          </Col>
          <Col span={9} offset={1}>
            <Form.Item label="Province" name="province" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}
