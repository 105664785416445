export default {
  applicationName: 'NCSO / NHSA',
  organizationName: 'NCSO / NHSA',
  websiteLabel: 'bccsa.ca',
  websiteLink: 'https://www.bccsa.ca/',
  imagesSizes: {
    logoCenter: {
      height: 85,
    },
  },
};
