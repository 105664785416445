import React from 'react';
import autoBind from 'react-autobind';
import { Row, Col, Alert, Button, Progress } from 'antd';

import ApplicationItemTileSubComponent from './ApplicationItemTileSubComponent';
import CustomCollapse from '../../../../commonComponents/CustomCollapse';

export default class ApplicationSectionsSubComponent extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {};
  }

  render() {
    const { sections } = this.props;

    return (
      <>
        {sections.flat().map((section, i) => {
          if (section.tiles?.length < 1) {
            return null;
          }

          let progressPercentage = null;
          let filledItems = 0;
          if (section.metadata?.progress) {
            filledItems = section.metadata.progress.requiredCount - section.metadata.progress.missingCount;
            progressPercentage = Math.floor((filledItems * 100) / section.metadata.progress.requiredCount);
          }

          return (
            <CustomCollapse
              visible key={i}
              title={section.title}
              description={section.description}
              extraActions={() => (
                <>
                  {i == 0
                    ? (
                      <Button key='downloadapplication' type="primary" onClick={this.props.actions.onDownloadApplication}>
                        Download Application
                      </Button>
                    )
                    : null
                  }

                  {(progressPercentage != null) && (
                    <Progress
                      type="circle"
                      width={70}
                      style={{ marginLeft: 10 }}
                      percent={progressPercentage}
                      format={() => `${filledItems}/${section.metadata.progress.requiredCount}`}
                    />
                  )}
                </>
              )}
            >
              {this._renderAlert(section)}
              {this._renderSectionTiles(section)}
            </CustomCollapse>
          );
        })}
      </>
    );
  }

  // Private methods
  _renderAlert(section) {
    const { alert } = section;

    if (!alert) {
      return null;
    }

    return (
      <Alert
        style={{ marginBottom: 16 }}
        message={alert.title}
        description={alert.description}
        type={alert.type}
      />
    );
  }

  _renderSectionTiles(section) {
    const { optionalTiles } = this.props;

    return this._separateTilesByRows(section.tiles).map((tiles, index) => (
      <Row gutter={24} key={String(index)} style={{ marginBottom: 24 }}>
        {tiles.map(tile => {
          let disabled = tile.disabled;
          let tooltip = tile.tooltip;

          const isTileSkipped = optionalTiles?.find((skippedTile) => skippedTile.id == tile.id);
          if (isTileSkipped) {
            tooltip = isTileSkipped.message;
          }

          return (
            <Col xs={24} sm={12} md={12} lg={6} key={tile.id} className="tile-column">
              <ApplicationItemTileSubComponent
                tile={tile}
                disabled={disabled}
                skip={isTileSkipped}
                tooltip={tooltip}
                onUpload={() => this.props.actions.onUpload(tile)}
                onResubmit={this.props.actions.onResubmit}
                onDelete={this.props.actions.onDelete}
                onDownload={this.props.actions.onDownload}
                onPreview={this.props.actions.onPreview}
                onEdit={this.props.actions.onEdit}
                onDeleteForeignItem={this.props.actions.onDeleteForeignItem}
                certificationProcess={this.props.certificationProcess}
                applicationProcess={this.props.applicationProcess}
                foreignItems={this.props.foreignItems}
                certificationSpecs={this.props.certificationSpecs}
              />
            </Col>
          );
        })}
      </Row>
    ));
  }

  _separateTilesByRows(tiles) {
    const rows = [];

    tiles.forEach((tile, index) => {
      const row = Math.floor(index / 4);
      if (!rows[row]) rows[row] = [];

      rows[row].push(tile);
    });

    return rows;
  }
}
