import React from 'react';
import autoBind from 'react-autobind';
import { PageHeader, message, Layout, Row, Col, Table, Button, Tooltip, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import CustomComponent from '../../../components/CustomComponent';
import CommonLocationDrawer from '../../commonComponents/Drawers/CommonLocationDrawer';
//
import Globals from '../../../config/Globals';
import config from '../../../config/config';
import Utils from '../../../components/Utils';
//
export default class AdminLocationsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      cities: [], regions: [], sortedInfo: { columnKey: 'name', order: 'ascend' },
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    this.fetchData();
  }

  async fetchData() {
    this.startLoading();
    const resp = await this._loadLocations();
    if (resp) this.setState({ ...resp, isLoading: false });
    else this.stopLoading();
  }

  //Actions
  handleCreateLocation() { this.locationDrawer.show(); }
    //Table
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
    //Table row actions
  handleEditLocation(regionID) { this.locationDrawer.show(regionID, this.state.cities);  }
  async handleDeleteRegion(regionID) {
    this.startLoading();
    const resp = await this._deleteRegion(regionID);
    if (resp) await this.fetchData();
    this.stopLoading();
  }
  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader className="pageHeader" title="Locations" onBack={() => this.props.app.urlManager.pushBack()} />
        <CommonLocationDrawer {...Utils.propagateRef(this, 'locationDrawer')} onUpdate={this.fetchData} app={this.props.app} />
        <Layout.Content>
          <Row type='flex' justify='end'>
            <Col>
              <Button style={{ marginBottom: 20 }} type="primary" onClick={this.handleCreateLocation}> Create new Location </Button>
            </Col>
          </Row>
          {this._renderRegionsTable()}
        </Layout.Content>
      </Layout.Content>
    );
  }

  /* private UI */
  _renderRegionsTable() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Name', key: 'name', dataIndex: 'name', width: '30%',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order
      },
      {
        title: 'Cities', key: 'cities',
        render: (e) => `${(this.state.cities || []).filter((c) => c.regionID == e.id).length} Cities` || '-'
      },
      {
        title: 'Created On', key: 'createdOn', width: '15%',
        render: props => props.createdOn ? Utils.getDateOnUIFormatByTimestamp(props.createdOn) : 'N/A',
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order
      },
      {
        title: 'Actions', width: 'auto', key: 'Actions', width: '15%',
        render: props => {
          return (<span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title='Edit'>
              <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.handleEditLocation.bind(this, props.id)} />
            </Tooltip>
            <Tooltip placement="bottomLeft" title='Delete'>
              <Popconfirm placement="top" title={`Are you sure that you want to delete the region '${props.name}'?`} 
                          onConfirm={this.handleDeleteRegion.bind(this, props.id)} okText="Yes" cancelText="No" >
                <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle"/>
              </Popconfirm>
            </Tooltip>
          </span>);
        }
      },
    ];
    const props = {
      rowKey: 'id', loading: this.state.isLoading, onChange: this.handleFilterChange,
      locale: { emptyText: 'No locations found!' },
      pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] }
    };
    return (
      <Layout.Content>
        <Table className="adminLocationsTable" columns={columns} dataSource={this.state.regions} {...props} />
      </Layout.Content>
    );
  }

  /* private API */
  async _loadLocations() {
    const resp = await Promise.all([
      this.props.app.sharedCache().getRegions(),
      this.props.app.sharedCache().getCities()
    ]);
    if (!resp[0]) {
      this.props.app.alertController.showErrorAlert(null, 'Could not load regions!');
      return false;
    } 
    if (!resp[1]) {
      this.props.app.alertController.showErrorAlert(null, 'Could not load cities!');
      return false;
    } return { cities: resp[1], regions: resp[0] };
  }
  async _deleteRegion(regionID) {
    this.props.app.sharedCache().invalidateRegions();
    const resp = await this.props.app.classroom.region.deleteRegion(regionID);
    if (resp.statusCode == 200) {
      message.success('Region deleted with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
}
