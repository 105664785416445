import React, { Fragment } from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
//Components
import { Form, Input, Card, Row, Col, Checkbox, Divider, Select, DatePicker } from 'antd';
//
import Utils from '../../../components/Utils';
//props are: app
export default class CommonOrganizationMemberProfileForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Public
  async setFields(memberObj) {
    try {
      const { organizationMemberProfile } = this.props.app.sharedCache().getTenantConfig();
      if (!organizationMemberProfile) return;
      const { fields } = organizationMemberProfile;
      //Buildup form data
      const formData = {};
      for (let fieldKey of Object.keys(organizationMemberProfile.fields)) {
        const field = fields[fieldKey];
        formData[fieldKey] = Utils.getNestedObject(memberObj.metadata || {}, fieldKey);
        if (formData[fieldKey] && field.type == 'date') formData[fieldKey] = moment(formData[fieldKey]);
        if (!formData[fieldKey]) { formData[fieldKey] = ''; /* unknown source */ }
        if (field.type == 'boolean') formData[fieldKey] = !!formData[fieldKey];
      }
      //Set form data
      this.form.setFieldsValue({ 
        ...formData, email: memberObj.email
      });
    } catch (err) {
      console.error(err);
    }
  }
  validateFields() { return this.form.validateFields(); }

  //Actions
  handleSubmit(e) { e.preventDefault(); }
  //UI
  render() {
    return (
      <Card>
        <Form layout="vertical" onSubmit={this.handleSubmit} {...Utils.propagateRef(this, 'form')}>
          {this._renderPersonalSection()}
          {this._renderDynamicInformation()}
        </Form>
      </Card>
    );
  }

  //Sections
  _renderPersonalSection() {
    return (
      <>
        <Row>
          <Col span={24}>
            <Form.Item name='email' label="Email"> 
              <Input disabled/>
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  /* Dynamic form support */
  _renderDynamicInformation() {
    const { organizationMemberProfile } = this.props.app.sharedCache().getTenantConfig();
    if (!organizationMemberProfile) return;
    const { fields, sections } = organizationMemberProfile;
    if (!fields || Object.keys(fields).length <= 0) return (<></>);
    //order by row priority and group by row priority with array of the fields key
    const orderedRows = Object.keys(fields).sort((a, b) => fields[a].row - fields[b].row).reduce((prev, curr) => {
      if (!prev[fields[curr].row]) prev[fields[curr].row] = [];
      prev[fields[curr].row].push(curr);
      return prev;
    }, {})
    //
    return (
      <>
        {Object.keys(orderedRows).map((row) => {
          const section = (sections ? sections.find((s) => s.beforeRow == row) : null);
          return (
            <Fragment key={row}>
              {section && <Divider orientation="left">{section.name}</Divider>}
              <Row gutter={24}>
                {orderedRows[row].map((fieldKey, index) => (
                  <Fragment key={fieldKey + index}>
                    <Col md={parseInt(fields[fieldKey].widthPercentage ? 24 * (fields[fieldKey].widthPercentage / 100) : 24 / orderedRows[row].length)} xs={24}>
                      {this._renderDynamicFormItem(fields[fieldKey], fieldKey)}
                    </Col>
                  </Fragment>
                ))}
              </Row>
            </Fragment>
          )
        })}
      </>
    );
  }
  _renderDynamicFormItem(input, inputKey) {
    const rules = [];
    if (input.required) rules.push({ required: true, message: 'This field is required!' });
    if (input?.customProps?.maxLength) rules.push({ max: input.customProps.maxLength, message: `Must be lower than ${input.customProps.maxLength}` });
    if (input?.customProps?.pattern) {
      const type = typeof input.customProps.pattern;
      const pattern = type == 'object' ? input.customProps.pattern.regex : input.customProps.pattern;
      const message = type == 'object' ? input.customProps.pattern.message : 'Invalid format';
      rules.push({ pattern, message });
    }
    return (
      <Fragment key={input}>
        <Form.Item name={inputKey} label={input.label} rules={rules} {...(input.type == 'boolean' ? { valuePropName: 'checked' } : {})}
                   extra={input?.customProps?.extra || ''}>
          {input.type === 'date' ?
            (<DatePicker onChange={this.validateFields} placeholder={input.placeholder || ''} format={input.format} style={{ width: '100%' }} />) :
            input.type === 'boolean' ?
              (<Checkbox onChange={this.validateFields} style={{ display: 'block', marginLeft: 20 }} />) :
            input.type === 'list' ?
              (<Select showSearch options={input.customProps.values} onChange={this.validateFields} />) :
              (<Input type={input.type} placeholder={input.placeholder || ''} onChange={this.validateFields} allowClear />)
          }
        </Form.Item>
      </Fragment>
    );
  }
}
