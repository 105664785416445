import React from 'react';
import autoBind from 'react-autobind';
import { PageHeader, Layout, Row, Col, Table, Button, Tooltip } from 'antd';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import CustomComponent from '../../../components/CustomComponent';
//
import Globals from '../../../config/Globals';
import Utils from '../../../components/Utils';
import config from '../../../config/config';
//
export default class AdminProductsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      products: [], sortedInfo: { columnKey: 'name', order: 'ascend' },
    };
  }
  // Life cycle
  async componentDidMount() {
    super.componentDidMount();
    this.fetchData();
  }
  // Remote
  async fetchData() {
    this.startLoading();
    const resp = await this._loadCertifications();
    if (resp) this.setState({ ...resp, isLoading: false });
    else this.stopLoading();
  }

  //Actions
    //Product Management
  handleAddProduct() { this.props.app.urlManager.pushPage(config.ApplicationRoutes.product, null, Globals.URL_NewIndentifier); }
    //Table
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  handleEditProduct(productID) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.product, null, productID); }

  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader className="pageHeader" title="Products" onBack={() => this.props.app.urlManager.pushBack()} />
        <Layout.Content>
          <Row type='flex' justify='end'>
            <Col> <Button style={{ marginBottom: 20 }} type="primary" onClick={this.handleAddProduct}> Create new Product </Button> </Col>
          </Row>
          {this._renderCertificationsTable()}
        </Layout.Content>
      </Layout.Content>
    );
  }

  /* private UI */
  _renderCertificationsTable() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'ID', key: 'id', dataIndex: 'id',
        sorter: (a, b) => a.id.localeCompare(b.id),
        sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order
      },
      {
        title: 'Name', key: 'name', dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order
      },
      {
        title: 'Created On', key: 'createdOn', width: '10%',
        render: props => props.createdOn ? Utils.getDateOnUIFormatByTimestamp(props.createdOn) : 'N/A',
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order
      },
      {
        title: 'Prevent Purchase', key: 'hideInPurchaseDialog', width: '10%',
        render: props => (props.hideInPurchaseDialog) === true ? <CheckOutlined/> : '',
      },
      {
        title: 'Actions', width: 'auto', key: 'Actions', width: '15%',
        render: props => {
          return (<span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title='Edit'>
              <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.handleEditProduct.bind(this, props.id)} />
            </Tooltip>
          </span>);
        }
      },
    ];
    const props = {
      rowKey: 'id', loading: this.state.isLoading, onChange: this.handleFilterChange,
      locale: { emptyText: 'No products found!' },
      pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] }
    };
    return (
      <Layout.Content>
        <Table className="adminCertificationsTable" columns={columns} dataSource={this.state.products} {...props} />
      </Layout.Content>
    );
  }

  /* private API */
  async _loadCertifications() {
    const resp = await this.props.app.sharedCache().getProducts();
    if (resp) {
      return { products: resp };
    } else {
      this.props.app.alertController.showAPIErrorAlert('Could not load products!', resp);
      return false;
    }
  }   
}
