import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Table, Tag, Typography, Button, Tooltip } from 'antd';
import * as ExcelJS from 'exceljs';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import CustomComponent from '../../../components/CustomComponent';
import DateRangeFilter from '../../../components/DateRangeFilter';
import { DownloadOutlined } from '@ant-design/icons';
//
import config from '../../../config/config';
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
export default class AdminAchievedPerPeriodReportView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false, isDownloading: false, reportDates: [],
      data: [], sortedInfo: null, firstLoad: true,
    };
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  //Actions
  onRowSelection(val) { }

  async handleFilter(dates) {
    let [from, to] = dates || [];
    if (!from || !to) {
      this.setState({ data: [] });
      return;
    };
    //Start loading
    to = to.toDate().getTime();
    from = from.toDate().getTime();
    this.setState({ data: [], isLoading: true, reportDates: [from, to] });
    //Make request
    const resp = await this.props.app.api.tenant.getAchievedCertificationsReport(from, to, true);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.data) {
      this.setState({ data: resp.body.data, isLoading: false, firstLoad: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ data: [], isLoading: false, firstLoad: false });
    }
  }

  async generateReportXLSX() {
    if (this.state.data.length < 1) {
      return;
    }
    this.setState({ isDownloading: true });
    const resp = await this.props.app.api.tenant.getAchievedCertificationsReport(this.state.reportDates[0], this.state.reportDates[1], false);

    if (resp.statusCode == 200) {
      Utils.downloadBlob(resp.body, 'achieved-report', 'xlsx');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.setState({ isDownloading: false });
  }

  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: 'Name', key: 'name',
        render: prop => {
          return <Tooltip title={prop.email}>{`${prop.firstName} ${prop.lastName}`}</Tooltip>
        },
        sorter: (a, b) => {
          return (`${a.firstName} ${a.lastName}`).localeCompare(`${b.firstName} ${b.lastName}`);
        },
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      },
      {
        title: 'Certification', key: 'certification',
        sorter: (a, b) => a.certificationID.localeCompare(b.certificationID),
        render: prop => {
          const certSpecs = this.props.app.sharedCache().getCertificationByID(prop.certificationID);
          return certSpecs.description;
        },
        sortOrder: sortedInfo.columnKey === 'certification' && sortedInfo.order,
      },
      {
        title: 'Application Started', key: 'applicationDate',
        render: props => props.applicationDate,
        sorter: (a, b) => (a.applicationDate || '').localeCompare(b.applicationDate || ''),
        sortOrder: sortedInfo.columnKey === 'applicationDate' && sortedInfo.order,
      },
      {
        title: 'Completion Date', key: 'completionDate',
        render: props => props.completionDate,
        sorter: (a, b) => (a.completionDate || '').localeCompare(b.completionDate || ''),
        sortOrder: sortedInfo.columnKey === 'completionDate' && sortedInfo.order,
      },
      {
        title: 'Expiration Date', key: 'expirationDate',
        render: props => props.expirationDate,
        sorter: (a, b) => (a.expirationDate || '').localeCompare(b.expirationDate || ''),
        sortOrder: sortedInfo.columnKey === 'expirationDate' && sortedInfo.order,
      },
      {
        title: 'System Completion Date', key: 'systemCompletionDate',
        render: props => props.systemCompletionDate,
        sorter: (a, b) => (a.systemCompletionDate || '').localeCompare(b.systemCompletionDate || ''),
        sortOrder: sortedInfo.columnKey === 'systemCompletionDate' && sortedInfo.order,
      },
    ];

    const props = { rowKey: (props) => `${props.userID}${props.certificationID}`, loading: this.state.isLoading, onChange: this.handleFilterChange,
                    locale: {emptyText: (this.state.firstLoad ? 'Search certifications' : 'No certifications found!')},
                    pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter']}};

    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
            <PageHeader className="pageHeader" title="Achieved per Period" />
            <Layout.Content>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <DateRangeFilter buttonLabel="Run Report" onFilter={this.handleFilter} />
              <Button type="primary" style={{ marginLeft: 4 }} icon={<DownloadOutlined />} onClick={this.generateReportXLSX} 
                disabled={(this.state.data.length < 1 || this.state.isDownloading)} loading={this.state.isDownloading}>
                Export to xlsx
              </Button>
            </div>
            <Table className="adminSearchUsersTable" columns={columns} dataSource={this.state.data} {...props}/>
            </Layout.Content>
        </Layout.Content>
      </>
    );
  }
}
