import React from 'react';
import autoBind from 'react-autobind';
//
import { Menu, Avatar, Dropdown, Tag, Typography } from 'antd';
import { UserOutlined, ExportOutlined, PlusOutlined } from '@ant-design/icons';
//
export default class IDMUserBadge extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Actions
  handleSelect(i) {
    if (i.key == 'changepassword') this.props.onChangePassword();
    else if (i.key == 'logout') this.props.onLogout();
    else if (i.key == 'register-org') this.props.onRegisterOrg();
    else if (i.key == 'profile') this.props.onProfile();
  }
  //UI
  render() {
    return (
      <Dropdown overlay={this._renderDropdownMenu()} className="-leave">
        <Tag className="idmBadgeContainer">
          <Avatar justify="end" size={36} className="idmBadgeUserIcon">
            <Typography.Text className="idmBadgeUserText">{this.props.user?.firstName?.substr(0, 1)}</Typography.Text>
          </Avatar>
        </Tag>
      </Dropdown>
    );
  }
  _renderDropdownMenu() {
    //determine if should show register org item
    const { orgModAllowOrgSelfRegistration } = this.props.app.sharedCache().getTenantConfig();
    const shouldShowRegisterOrg = orgModAllowOrgSelfRegistration && (this.props.app.isUser() && !this.props.app.isOrgAdmin());
    return (
      <Menu className="userPopoverList" onClick={this.handleSelect} items={[
        { disabled: true, label: this.props.user?.firstName + ' ' + this.props.user?.lastName, key: 'name', icon: <UserOutlined/> },
        ...(shouldShowRegisterOrg ? [
          { type: 'divider' },
          { key: 'register-org', label: 'Register an organization', icon: <PlusOutlined/> },
          { type: 'divider' },
        ] : []),
        ...(!this.props.app.isUser() ? [ { label: 'Profile', key: 'profile' } ] : []),
        { label: 'Change Password', key: 'changepassword' },
        { label: 'Logout', key: 'logout', icon: <ExportOutlined/> }
      ]}/>
    );
  }
}
