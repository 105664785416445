import React from 'react';
import { Row, Col } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
//
import Globals from '../../../../../config/Globals';
import CourseCardActions from '../Subcomponents/CourseCardActions';

export default class Expired extends React.Component {
  render() {
    const { courseSpecs, app, certificationProcess, session } = this.props;
    const isWaived = !!certificationProcess.waivedBy;
    return (
      <>
        <Row justify="center">
          <Col span={24} style={{ textAlign: 'center' }}>
            {`The ${courseSpecs.uiTerm.toLowerCase()} ${courseSpecs.description} is expired and needs to be retaken.`}
          </Col>
        </Row>

        {courseSpecs.courseType === Globals.Course_Types_Keys.EXTERNALREQUIREMENT && (
          <CourseCardActions
            app={app}
            courseSpecs={courseSpecs}
            certificationProcess={certificationProcess}
            session={session}
            actionForceCheck
          />
        )}

        {courseSpecs.courseType !== Globals.Course_Types_Keys.EXTERNALREQUIREMENT && (
         <CourseCardActions
            app={app}
            courseSpecs={courseSpecs}
            certificationProcess={certificationProcess}
            availableKeys={this.props.availableKeys}
            session={session}
            actionPurchase
            actionRedeem
            actionActivate
            actionUpload
            disableActions={this.props.disableActions}
            message={isWaived ?
              `To take the ${this.props.courseSpecs.description} you need to activate this ${this.props.courseSpecs.uiTerm}. Please click continue the activation.` :
              `To take the ${this.props.courseSpecs.description} please click the buttons below to make a purchase or enter a license key.`
            }/>
         )}
      </>
    );
  }
}
Expired.CardStatusHeader = function() {
  return <span className="danger"><ExclamationCircleFilled /> Expired</span>;
}
