import React from "react";
import autoBind from 'react-autobind';
import { Drawer, Row, Form, Input, Space, Divider, Col, Button } from 'antd';
//
import Utils from "../../../components/Utils";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import CustomComponent from "../../../components/CustomComponent";
//
//props are: onUpdate
export default class CommonOrgLocationDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isVisible: false, description: null, index: -1 };
  }
  //Public
  show(location) {
    this.setState({ isVisible: true, location }, () => {
      if (location) this.form.setFieldsValue({ ...location?.address || {}, ...location });
    });
  }
  //Private actions
  handleClose() {
    this.form.resetFields();
    this.setState({ isVisible: false, location: null });
  }
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (!formData) return;
    this.props.onUpdate({
      ...this.state.location || {},
      phone: formData.phone || [],
      description: formData.description,
      address: {
        street: (formData.street || []), city: formData.city || '',
        postalCode: formData.postalCode || '', province: formData.province || '',
        country: formData.country || '',
      }
    });
    this.handleClose();
  }

  //UI
  render() {
    return (
      <Drawer visible={this.state.isVisible} title={`${this.state.warning ? 'Edit' : 'Create'} a Organization Operating Location`}
        placement="right" onClose={this.handleClose} width={800}>
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row type='flex'>
            <Col span={24}>
              <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please, type the location description!' }]}>
                <Input.TextArea rows={2}/>
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">Address</Divider>
          <Row type='flex'>
            <Col span={12}>
              <Form.List name="street">
                {(fields, { add, remove }) => (
                  <Form.Item label='Address'>
                    {fields.map(({ key, name, ...restField }, i) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Form.Item style={{ width: 500 }} {...restField} name={[name]}
                          rules={[{ required: true, message: 'Please, type the an address or remove the line!' }]}>
                            <Input/>
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}> Add new line </Button>
                    </Form.Item>
                  </Form.Item>
                )}
              </Form.List>
            </Col>
          </Row>
          <Row type='flex'>
            <Col span={12}>
              <Form.Item name="city" label="City">
                <Input/>
              </Form.Item>
            </Col>
            <Col span={11} offset={1}>
              <Form.Item name="province" label="Province">
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex'>
            <Col span={12}>
              <Form.Item name="postalCode" label="Postal Code">
                <Input/>
              </Form.Item>
            </Col>
            <Col span={11} offset={1}>
              <Form.Item name="country" label="Country">
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">Contact</Divider>
          <Row type='flex'>
            <Col span={12}>
              <Form.List name="phone">
                {(fields, { add, remove }) => (
                  <Form.Item label='Phone(s)'>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                          <Form.Item style={{ width: 150 }} {...restField} name={[name, 'label']} label='Label'
                            rules={[{ required: true, message: 'Please, type the label or remove the empty line!' }]}>
                            <Input/>
                          </Form.Item>
                          <Form.Item style={{ width: 350 }} {...restField} name={[name, 'number']} label='Number'
                            rules={[{ required: true, message: 'Please, type the phone or remove the empty line!' }]}>
                            <Input/>
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}> Add phone </Button>
                    </Form.Item>
                  </Form.Item>
                )}
              </Form.List>
            </Col>
          </Row>
          <Row type='flex' justify='end'>
            <Col> <Button type='primary' onClick={this.handleSubmit}>Done</Button> </Col>
          </Row>
        </Form>
      </Drawer>
    );
  }
}
