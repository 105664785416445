import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Select, Row, Col } from 'antd';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import CustomComponent from '../../../components/CustomComponent';
import DateRangeFilter from '../../../components/DateRangeFilter';
import { DownloadOutlined } from '@ant-design/icons';
//
import config from '../../../config/config';
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
export default class AdminApplicationReportView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isLoading: false, isDownloading: false, reportDates: [],
      disabled: true, certificationID: this.props.app.sharedCache().getTenantConfig().certifications[0].id
    };
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  async handleFilter(dates) {
    let [from, to] = dates || [];
    if (!from || !to) {
      this.setState({ disabled: true });
      return;
    }
    //Start loading
    const fileTo = to.format("YYYY-MM-DD");
    const fileFrom = from.format("YYYY-MM-DD");
    to = to.toDate().getTime();
    from = from.toDate().getTime();

    //Make request
    this.setState({ isDownloading: true });
    console.log(this.state);
    const resp = await this.props.app.api.application.getApplicationsReport(from, to, this.props.app.sharedCache().getTenantConfig().id, this.state.certificationID);

    if (resp.statusCode == 200) {
      Utils.downloadBlob(resp.body, `Applications-${fileFrom}-${fileTo}`, 'xlsx');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.setState({ isDownloading: false });
  }

  //Custom filter
  handleChangeCertification(certificationID) {
    this.setState({ certificationID })
  };

  render() {
    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
            <PageHeader className="pageHeader" title="Applications Report" />
            <Layout.Content>
              <div className="usersFilters">
                <Row type='flex'>
                  <Col className="filterItem">
                    <strong>Certification:</strong>
                    <Select style={{ minWidth: 200 }} value={this.state.certificationID} size="large"
                      onChange={this.handleChangeCertification}>
                      {this.props.app.sharedCache().getTenantConfig().certifications.map(certification => (
                        <Select.Option key={certification.id} value={certification.id}>{certification.description}</Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col className="filterItem">
                    <DateRangeFilter buttonLabel="Export to xlsx" onFilter={this.handleFilter} buttonDisabled={(this.disabled || this.state.isDownloading)} buttonLoading={this.state.isDownloading} />
                  </Col>
                </Row>
              </div>
            </Layout.Content>
        </Layout.Content>
      </>
    );
  }
}
