import React from "react";
import autoBind from 'react-autobind';
import { Select, Form, Divider, Row, Col, Input, InputNumber } from 'antd';
import MarkdownEditor from '@ikonintegration/react-rte';
import Utils from "../../../components/Utils";
//
//props: app, isEdit
export default class CommonEquivalencyForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      cardDescription: MarkdownEditor.createEmptyValue(),
      detailedName: MarkdownEditor.createEmptyValue(),
      detailedDescription: MarkdownEditor.createEmptyValue(),
    };
  }
  //Public
  resetFields() { 
    this.setState({ 
      cardDescription: MarkdownEditor.createEmptyValue(),
      detailedName: MarkdownEditor.createEmptyValue(),
      detailedDescription: MarkdownEditor.createEmptyValue(),
    })
    this.form.resetFields(); 
  }
  setFieldsValue(data) { 
    this.setState({
      cardDescription: MarkdownEditor.createValueFromString(data.cardDescription || '', 'markdown'),
      detailedName: MarkdownEditor.createValueFromString(data.detailedName || '', 'markdown'),
      detailedDescription: MarkdownEditor.createValueFromString(data.detailedDescription || '', 'markdown'),
    }, () => this.form.setFieldsValue(data));
  }
  async validateFields() {
    const formData = await this.form.validateFields();
    if (!formData) return false;
    return { 
      ...formData, cardDescription: (this.state.cardDescription ? this.state.cardDescription.toString('markdown') : ''),
      detailedName: (this.state.detailedName ? this.state.detailedName.toString('markdown') : ''),
      detailedDescription: (this.state.detailedDescription ? this.state.detailedDescription.toString('markdown') : '')
    };
  }

  //Actions
  handleMarkdownChange(key, value) { 
    this.setState({ [key]: value }); 
    this.form.validateFields();
  }

  //UI
  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Row>
          {this.props.app.isSysAdmin() && <Col span={11}>
            <Form.Item label="Unique ID" name="id"  rules={[{validator: Utils.validateUniqueID}, {required: false}]} extra='This field is available to sysadmins only and will be auto generated for normal admins or if not specified!'>
              <Input disabled={this.props.isEdit}/>
            </Form.Item>
          </Col>}
          <Col span={(!this.props.app.isSysAdmin() ? 24 : 12)} offset={(!this.props.app.isSysAdmin() ? 0 : 1)}>
            <Form.Item label="Internal name" name="internalName" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Divider>Information</Divider>
        <Row>
          <Col span={20}>
            <Form.Item label="Display Name" name="name" rules={[{ required: true, message: 'This field is required!' }]} extra={'This field will be displayed to users on their equivalency card title.'}>
              <Input/>
            </Form.Item>
          </Col>
          <Col span={3} offset={1}>
            <Form.Item name='order' label={'Order'} initialValue={0} rules={[{ required: true, message: 'This field is required!' }]}>
              <InputNumber style={{ maxWidth: 100 }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Card Description" name="cardDescription" extra={'This field will be displayed to users on their equivalency card description. Try to briefly describe the equivalency. A more complex description can be added on the detailed description.'}
                       valuePropName='none' rules={[Utils.markdownEditorValidationObject(this.state.cardDescription)]}>
              <MarkdownEditor toolbarConfig={Utils.markdownToolbarItems()} value={this.state.cardDescription} onChange={this.handleMarkdownChange.bind(this, 'cardDescription')}/>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item label="Detailed Name" name="detailedName" extra={'This field will be displayed to users on their upload modal. If not specified it will use the display name.'} valuePropName='none'>
              <MarkdownEditor toolbarConfig={Utils.markdownToolbarItems(true)} value={this.state.detailedName} onChange={this.handleMarkdownChange.bind(this, 'detailedName')} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Detailed Description" name="detailedDescription" valuePropName='none'
                       extra={'This field will be displayed to users on their equivalency upload modal. Carefully explain to users what they need to upload and accomplish this equivalency. If not specified, it will use the card description.'}>
              <MarkdownEditor toolbarConfig={Utils.markdownToolbarItems()} value={this.state.detailedDescription} onChange={this.handleMarkdownChange.bind(this, 'detailedDescription')} />
            </Form.Item>
          </Col>
        </Row>
        <Divider>Rules</Divider>
        <Row>
          <Col span={24}>
            <Form.Item name='matches' label={'What other requirements this equivalency is equivalent to?'} rules={[{ required: true, message: 'This field is required!' }]}>
              <Select mode='multiple'>
                {this.props.requirements.map((r) => {
                  return <Select.Option value={r.id} key={r.id}>{r.internalName}</Select.Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <Form.Item name='matchesElectiveCount' label={'Number of Elective requirements this equivalency is equivalent to:'} initialValue={0} rules={[{ required: true, message: 'This field is required!' }]}>
              <InputNumber style={{maxWidth: 100}}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}
