import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, message, Tabs, Row, Col, Button, Divider } from 'antd';
//
import config from '../../../config/config';
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
import CustomComponent from '../../../components/CustomComponent';
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import WhiteBox from '../../commonComponents/WhiteBox';
//
import CommonCourseForm from '../../commonComponents/Forms/CommonCourseForm';
//
const RequestMask = { COURSE: 2, ALL: 2 };
//
export default class AdminCourseView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    const certificationID = this.props.match.params.id;
    const courseID = this.props.match.params.id2;
    this.state = {
      isLoading: false, isNew: courseID == Globals.URL_NewIndentifier,
      courseID: (courseID == Globals.URL_NewIndentifier ? null : courseID), certificationID,
      course: null
    };
  }

  //View Life Cycle
  async componentDidMount() {
    super.componentDidMount();
    if (this.state.courseID) await this.fetchData(RequestMask.ALL);
  }

  async fetchData(mask) {
    this.startLoading();
    const resp = await this._loadData(mask);
    if (resp) {
      this.setState({ ...resp, isLoading: false }, () => {
        if (this.state.course) {
          //this.courseForm.setFieldsValue({ ...this.state.course, calendarColor: resp.calendarColor });
          this.courseForm.setFieldsValue({ ...this.state.course });
        }
      });
    } else this.stopLoading();
  }

  //actions
  async handleSubmit() {
    //validate forms
    const formData = await this.courseForm.validateFields();
    if (!formData) return;
    //
    this.startLoading();
    await Promise.all([
      (this.state.courseID ? await this._updateCourse({ ...formData }) : await this._createCourse({ ...formData })),
      this._upsertCourseCalendar(formData.calendarColor),
    ]);
    this.stopLoading();
  }

  //UI
  render() {
    return (
      <>
        <Layout.Content className="pageContent">
          <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className="pageHeader" title={'Course'} onBack={() => this.props.app.urlManager.pushBack()} />
            <WhiteBox>
              <Row type='flex' justify='end'> <Col> <Button style={{ marginBottom: 20 }} type="primary" onClick={this.handleSubmit}> {this.state.courseID ? 'Update' : 'Create'} Course </Button> </Col> </Row>
              <CommonCourseForm {...Utils.propagateRef(this, 'courseForm')} isEdit={!!this.state.courseID} app={this.props.app}/>
            </WhiteBox>
        </Layout.Content>
      </>
    );
  }

  /* private API calls */
  async _loadData(mask) {
    const resp = await Promise.all([
      // removed extra call to course calendar, we will be using the value in the config. the course color will still be pushed to the course calendar for compatibility
      (mask & RequestMask.COURSE) === RequestMask.COURSE ? this.props.app.config.course.getCourse(this.state.certificationID, this.state.courseID) : null,
      //(mask & RequestMask.COURSE) === RequestMask.COURSE ? this.props.app.classroom.courseCalendar.getCourseCalendar(this.state.courseID) : null,
    ]);
    if ((mask & RequestMask.COURSE) === RequestMask.COURSE && !(resp[0].statusCode == 200 && resp[0].body && resp[0].body.id)) {
      this.props.app.alertController.showAPIErrorAlert('Could not load course!', resp[0]);
      return false;
    }
    return {
      ...(mask & RequestMask.COURSE) === RequestMask.COURSE ? { course: resp[0].body } : {},
      //...(mask & RequestMask.COURSE) === RequestMask.COURSE ? { calendarColor: resp[1]?.body?.calendarColor } : {},
    };
  }
  async _createCourse(courseData) {
    if (!this._isMounted) return;
    // removed the deletion, we actually want the color in the config, it will also be saved in the calendar record for the course
    // delete courseData.calendarColor;
    const resp = await this.props.app.config.course.createCourse(this.state.certificationID, {
      ...courseData,
      ...(this.props.app.isSysAdmin() && courseData.id ? { id: courseData.id } : {
        id: `Course-${this.props.app.sharedCache().getCustomerID()}-${this.props.app.sharedCache().getTenantConfig().id}-${config.Stage}-${courseData.displayName.toLowerCase().replace(/[ ?&/]/g, '')}-${Date.now()}`
      }),
      certificationID: this.state.certificationID,
      tenantID: this.props.app.sharedCache().getTenantConfig().id,
      customerID: this.props.app.sharedCache().getTenantConfig().customerID,
    });
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      message.success('Course created with success!');
      //
      this.props.app.urlManager.replacePage(config.ApplicationRoutes.certification, null, this.state.certificationID);
      //
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _updateCourse(courseData) {
    // removed the deletion, we actually want the color in the config, it will also be saved in the calendar record for the course
    // delete courseData.calendarColor;
    const resp = await this.props.app.config.course.updateCourse(this.state.certificationID, {
      ...courseData,
      id: this.state.courseID, certificationID: this.state.certificationID,
      tenantID: this.props.app.sharedCache().getTenantConfig().id,
      customerID: this.props.app.sharedCache().getTenantConfig().customerID,
    });
    if (resp.statusCode == 200) {
      message.success('Course updated with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _upsertCourseCalendar(color) {
    const resp = await this.props.app.classroom.courseCalendar.upsertCourseCalendar(this.state.courseID, color);
    if (resp.statusCode == 200) {
      message.success('Course calendar updated with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
}
