import React from 'react';

import { Col, Modal, Row, Form,  Button, Upload, message, Tooltip, Spin } from 'antd';
import { InboxOutlined, PaperClipOutlined, DeleteOutlined,  } from '@ant-design/icons';
import autoBind from 'react-autobind';

import Globals from '../../../../../config/Globals';

import CustomComponent from '../../../../../components/CustomComponent'; 

export default class CourseUploadModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.initialState = {
      isLoading: false,
      isVisible: false,
      item: null,
      fileList: [],
      uploadList: [],
      currentDocItem: null,
    };

    this.submissionItems = null;

    this.uploadListStatuses = {
      UPLOADING: 'UPLOADING',
      UPLOADED: 'UPLOADED',
      ERROR: 'ERROR',
    };

    this.state = { ...this.initialState };
  }

  open(item, currentDocItem = null) {
    this.setState({
      isVisible: true,
      item,
      currentDocItem,
      uploadList: (
        currentDocItem ? currentDocItem.fileItems.map(({ fileID, fileName }) => ({ name: fileName, fileID, status: this.uploadListStatuses.UPLOADED }))
        : []
      ),
    });
  }

  close(forceClose = false) {
    if ((this.state.isLoading) && !forceClose) {
      return;
    }

    const isUploading = this._hasFilesUploading();
    if (isUploading) {
      return;
    }

    this.setState({ ...this.initialState });
  }

  handleFileChange({ file }) {

    const allowedTypes = ['image/', 'pdf', 'doc', 'docx', 'xls', 'xlsx'];
    if (!allowedTypes.find(fileType => file.type.includes(fileType))) {
      message.error('This type of file is not accepted.');
      return;
    }

    if (file.status == 'removed') {
      return;
    }

    this._uploadFile(file);
    this.setState(prevState => ({
      ...prevState,
      fileList: [...prevState.fileList, file],
    }));
  }

  handleRemoveFile(fileID) {
    this.setState(prevState => ({
      ...prevState,
      fileList: prevState.fileList.filter(file => file.uid !== fileID),
      uploadList: prevState.uploadList.filter(file => file.fileID !== fileID),
    }));
  }

  handleCloseUploadModal() {
    this.props.onUpdate();
    this.close(true);
  }

  render() {
    const { item, fileList, uploadList } = this.state;

    const isUploading = this._hasFilesUploading();

    return (
      <Modal
        closable={false}
        open={this.state.isVisible}
        className={`uploadModal`}
        okText="Done"
        footer={[
          <Button key="cancel" onClick={this.close}>
            Cancel
          </Button>,
          <Button
            type="primary"
            key="done"
            onClick={this.handleCloseUploadModal}
            loading={this.state.isLoading}
            disabled={isUploading}
          >
            Done
          </Button>,
        ]}
      >
        <Row gutter={32}>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <h1>Documents</h1>
              </Col>
            </Row>

            <Form layout="vertical">
              <Row>
                <Col span={24}>
                   <Form.Item label={'Select document file'}>
                     <Upload.Dragger
                        multiple={false}
                        showUploadList={false}
                        beforeUpload={() => false}
                        fileList={fileList}
                        onChange={this.handleFileChange}
                        disabled={isUploading}
                        style={{ opacity: isUploading ? 0.5 : 1 }}
                        >
                     <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                     </p>
                     <p className="ant-upload-text">Click or drag file to this area to upload</p>
                     </Upload.Dragger>
                    </Form.Item>

                    <Form.Item label={`Uploaded file${uploadList.length > 1 ? 's': ''}`}>
                      <ul className="application-fileList">
                        {uploadList.map(this._renderFileItem)}
                      </ul>
                    </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    );
  }

  // Private methods
  _hasFilesUploading() {
    return this.state.uploadList.some((file) => file.status == this.uploadListStatuses.UPLOADING);
  }

  _renderFileItem(uploadListItem, index) {
    const fileName = uploadListItem.name || this.state.fileList.find(fileListItem => fileListItem.uid = uploadListItem.fileID)?.name || `File #${(index < 10 ? `0${index + 1}` : index + 1)}`;

    return (
      <li className={uploadListItem.status == this.uploadListStatuses.ERROR ? 'error' : ''} key={uploadListItem.fileID}>
        <div>
          <PaperClipOutlined />
          <span className="file-name">{fileName}</span>
        </div>

        <div>
          {uploadListItem.status == this.uploadListStatuses.UPLOADING && <Spin />}

          {uploadListItem.status == this.uploadListStatuses.ERROR && (
            <Button type="primary" onClick={this._uploadFile.bind(this, uploadListItem.fileID, true)}>Retry</Button>
          )}

          {uploadListItem.status != this.uploadListStatuses.UPLOADING && (
            <Tooltip title="Delete file">
              <Button icon={<DeleteOutlined />} onClick={this.handleRemoveFile.bind(this, uploadListItem.fileID)} />
            </Tooltip>
          )}
        </div>
      </li>
    );
  }

  // API Requests
  async _uploadFile(file, isRetry = false) {
    const fileReader = new FileReader();

    if (isRetry) {
      file = this.state.fileList.find(fileListItem => fileListItem.uid = file);
    }

    fileReader.readAsDataURL(file);
    fileReader.onloadend = async (readerResp) => {
      if (!this._isMounted) return;

      this._setOrUpdateUploadListItemStatus({ fileID: file.uid, name: file.name }, this.uploadListStatuses.UPLOADING);

      file.file = readerResp.currentTarget.result;
      const resp = await this.props.app.api.courseFile.uploadAndCreateDocument(
        this.props.user.id,
        this.props.certificationProcess.id,
        this.props.currentCourse?.id,
        file,
      );

      this.submissionItems = resp.body.submissionItems;

      if (!this._isMounted) return;

      if (resp && resp.statusCode == 200) {
        this._setOrUpdateUploadListItemStatus({ fileID: file.uid, name: file.name }, this.uploadListStatuses.UPLOADED);
      } else {
        this._setOrUpdateUploadListItemStatus({ fileID: file.uid, name: file.name }, this.uploadListStatuses.ERROR);
        this.props.app.alertController.showAPIErrorAlert(null, resp);
      }
    };
  }

  // Utils
  _getUploadedFiles() {
    return this.state.uploadList.filter((file) => file.status == this.uploadListStatuses.UPLOADED);
  }

  _setOrUpdateUploadListItemStatus({ fileID, name }, status) {
    const existingStatus = this.state.uploadList.find(
      (uploadListItem) => uploadListItem.fileID == fileID
    );

    this.setState(prevState => ({
      ...prevState,
      uploadList: (
        existingStatus
          ? prevState.uploadList.map((uploadListItem) => (
              uploadListItem.fileID == fileID
                  ? { fileID, name, status}
                  : uploadListItem
            ))
          : [...prevState.uploadList, { fileID, name, status }]
      ),
    }));
  }

  _removeFromUploadListStatus(fileID) {
    this.setState(prevState => ({
      ...prevState,
      uploadList: prevState.uploadList.filter(
        (uploadListItem) => uploadListItem.fileID != fileID
      ),
    }));
  }
}

