import React from 'react';
import moment from 'moment';
import autoBind from 'react-autobind';
import { Form, Input, Divider, Col, Row, Select, Button, DatePicker, Alert } from 'antd';
import Cards from 'react-credit-cards';
import { isMobile } from 'react-device-detect';
//
import mastercard from '../../../../assets/images/mastercard.svg';
import visa from '../../../../assets/images/visa.svg';
//
import Utils from '../../../../components/Utils';
//
import 'react-credit-cards/lib/styles.scss';
export const PAYMENT_TYPE = {
  CARD: 0,
  INVOICE: 1
};
//props are: app, user, onCancel, onNext, onPrevious, isLoading, totalAmount
// isNormalUser, product
export default class CommonLicensePurchaseModalStep_PaymentView_Federated extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    const paymentType = ((this.props.app.isAdmin() && !this.props.isNormalUser && !props.product.invoiceIsDisabled) ? PAYMENT_TYPE.INVOICE : PAYMENT_TYPE.CARD);
    this.state = {
      paymentMethod: paymentType,
      cvc: '', expiration: '', name: (paymentType == PAYMENT_TYPE.CARD ? '' : `${this.props.user.firstName} ${this.props.user.lastName}`),
      ccNumber: '', focused: 'name'
    }
  }
  //Validation
  async validateFields() {
    const resp = await this.form.validateFields();
    return (this.state.paymentMethod == PAYMENT_TYPE.CARD ? {
      ...resp, paymentMethod: this.state.paymentMethod,
      ...(resp ? { firstName: resp.name.split(' ')[0].trim(), lastName: resp.name.replace(resp.name.split(' ')[0], '').trim() } : {})
    } : {
      name: this.state.name, paymentMethod: this.state.paymentMethod,
      firstName: this.state.name.split(' ')[0].trim(), lastName: this.state.name.replace(this.state.name.split(' ')[0].trim(), '')
    });
  }
  //Public
  setInfo(data) {
    const pData = {
      ccNumber: data['billing-cc-number'] || '',
      name: (data['billing-first-name'] ? `${data['billing-first-name']} ${data['billing-last-name']}` : data.purchaserName),
      expiration: data['billing-cc-exp'] || '', cvc: data['cvv'] || '',
      paymentMethod: data.paymentMethod
    };
    this.setState(pData, () => {
      this.form.setFieldsValue({
        ...pData,
        ...(data['billing-cc-exp'] ? { expiration: moment(data['billing-cc-exp'], 'MMYY') } : {}),
      });
    });
  }
  //Actions
  handlePaymentTypeChange(type) {
    this.setState({ paymentMethod: type, name: (type == PAYMENT_TYPE.CARD ? '' : `${this.props.user.firstName} ${this.props.user.lastName}`)});
  }
  handleChange(val, id) {
    let focused = 'name';
    let setValue = val;
    if (id == 'cvc') {
      focused = id;
      setValue = setValue.replace(/\D/g, "")
    } else if (id == 'ccNumber') {
      setValue = setValue.replace(/\D/g, "")
    } else if (id == 'expiration') {
      setValue = Utils.getCardExpirationFromMoment(setValue);
      if (!val) val = '';
    }
    this.setState({ [id]: (!setValue ? "" : setValue), focused}, () => {
      if (val != setValue && id != 'expiration') this.form.setFieldsValue({[id]: setValue});
    });
  }
  //UI
  render() {
    return (
      <Form key='product' {...Utils.propagateRef(this, 'form')} data-private>
        <Divider orientation="left">Payment Details</Divider>
        <Row type="flex" justify="center">
          <Col span={(isMobile ? 24 : 12)}>
            <Form.Item name='paymentMethod' label={'Payment method'} rules={[{ required: true, message: 'Please, select a payment method!' }]} initialValue={this.state.paymentMethod}>
              <Select onChange={(val) => this.handlePaymentTypeChange(val)}>
                {(this.props.app.isOrgManager() || this.props.isNormalUser || this.props.product.invoiceIsDisabled) && <Select.Option value={PAYMENT_TYPE.CARD} key={PAYMENT_TYPE.CARD} disabled={(this.props.totalAmount <= 0)}>Credit Card</Select.Option>}
                {((this.props.app.isAdmin() && !this.props.isNormalUser && !this.props.product.invoiceIsDisabled) || this.props.app.isOrgManager()) && <Select.Option value={PAYMENT_TYPE.INVOICE} key={PAYMENT_TYPE.INVOICE}>Invoice</Select.Option>}
              </Select>
            </Form.Item>
          </Col>
          {this.state.paymentMethod === PAYMENT_TYPE.CARD ? (
            <Col style={{ marginLeft: 15 }} span={(isMobile ? 24 : 11)}>
              <img src={mastercard} width="40" alt="mastercard" />
              <img src={visa} width="40" alt="visa" style={{ marginLeft: 10 }} />
            </Col>
          ) : <Col span={11} />}
        </Row>
        <Divider/>
        {this.state.paymentMethod == PAYMENT_TYPE.CARD ? this._renderCardRow() : this._renderInvoiceRow()}
        {this._renderButtonsFooter()}
      </Form>
    );
  }
  _renderButtonsFooter() {
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button className="purchaseModalCancelButton" disabled={this.props.isLoading} key="back" onClick={this.props.onCancel}> Cancel </Button>
        <Button disabled={this.props.isLoading} onClick={this.props.onPrevious}> Previous </Button>
        <Button key="submit" type="primary" loading={this.props.isLoading}
          onClick={this.props.onNext} disabled={this.props.isLoading}
          className='purchaseModalConfirmationButton'> Next </Button>
      </Row>
    )
  }
  //Card
  _renderCardRow() {
    return (
      <Row type="flex" justify="center">
        <Col span={(isMobile ? 24 : 8)}>
          <Cards cvc={this.state.cvc} expiry={this.state.expiration} name={this.state.name} number={this.state.ccNumber} focused={this.state.focused} />
        </Col>
        <Col span={(isMobile ? 24 : 16)}>
          <Row type="flex" justify="center">
            <Col span={(isMobile ? 24 : 22)}>
              <Form.Item label='Card number' name="ccNumber" rules={[{ required: true, message: 'Please, type the card number!' }]}>
                <Input data-dd-privacy="mask" allowClear onChange={(val) => this.handleChange(val.target.value, 'ccNumber')} value={this.state.ccNumber} />
              </Form.Item>
            </Col>
          </Row><Row type="flex" justify="center">
            <Col span={(isMobile ? 24 : 22)}>
              <Form.Item name="name" label='Cardholder Fullname' initialValue={this.state.name} rules={[{ required: true, message: 'Please, type the cardholder\'s first name!' }]}>
                <Input data-dd-privacy="mask" allowClear id="name" onChange={(val) => this.handleChange(val.target.value, 'name')} />
              </Form.Item>
            </Col>
          </Row><Row type="flex" justify="start">
            <Col span={(isMobile ? 24 : 10)} offset={(isMobile ? 0 : 1)}>
              <Form.Item label='Expiration Date' name="expiration" rules={[{ required: true, message: 'Please, select the card expiration date on format MMYY!' }]}>
                <DatePicker.MonthPicker data-dd-privacy="mask" format="MMYY" allowClear value={(this.state.expiration ? moment(this.state.expiration, 'MMYY') : null)} onChange={(val) => this.handleChange(val, 'expiration')} />
              </Form.Item>
            </Col><Col span={(isMobile ? 24 : 6)} offset={(isMobile ? 0 : 6)}>
              <Form.Item label='CVV' name="cvc" rules={[{ required: true, message: 'Please, type the card cvc!' }]}>
                <Input data-dd-privacy="mask" allowClear value={this.state.cvc} onChange={(val) => this.handleChange(val.target.value, 'cvc')} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
  _renderInvoiceRow() {
    const text = `When you complete this transaction, {ORG_NAME}’s training department will be notified and will issue your invoice.
    Your license(s) will become available once {ORG_NAME} has received payment and the {APP_NAME} administrator approves your order.`;

    return (
      <Row type="flex" justify="center">
        <Alert message="Invoice order details"
          description={Utils.replacePlaceholders(text, this.props.app.themeManager.theme)} type="info" showIcon />
      </Row>
    );
  }
}
