import React from 'react';
import { Row, Col } from 'antd';
import { IssuesCloseOutlined } from '@ant-design/icons';

export default class MissingRequirements extends React.Component {
  render() {
    const { courseSpecs, app, certificationProcess } = this.props;
    
    const requirementDescriptions = courseSpecs.requirementsIDs
      .map((r) => app.sharedCache().getCourseByID(r)?.description || null)
      .filter((t) => !!t);

    const messagePrefix = courseSpecs.allowIfAnyRequirementMet ? 'Please, complete any of: ' : 'Please, complete: ';
    
    return (
      <Row justify="center">
        <Col span={14} style={{ textAlign: 'center' }}>
          {messagePrefix}
          {requirementDescriptions.join(', ')} before proceeding to {courseSpecs.description}.
        </Col>
      </Row>
    );
  }
}

MissingRequirements.CardStatusHeader = function() {
  return <span className="danger"><IssuesCloseOutlined /> Missing Requirements</span>;
}
