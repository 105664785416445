import React from 'react';
import autoBind from 'react-autobind';
import { PageHeader, Layout, Button, Row, Col, Tooltip, Popconfirm, Empty } from 'antd';
import ReactMarkdown from 'react-markdown';
//
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CustomCollapse from '../commonComponents/CustomCollapse';
import CustomComponent from '../../components/CustomComponent';
//
import '../../assets/stylesheets/AdminFaq.less';
//
import WhiteBox from '../commonComponents/WhiteBox';
import { AiOutlineEdit, AiOutlinePlusCircle } from 'react-icons/ai';
import { DeleteOutlined } from '@ant-design/icons';
//
export default class CommonFaqView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      faqs: [],
      isLoading: false,
    };
  }

  async componentDidMount() {
    this._fetchFaqs();
  }

  reloadFaqs() {
    this._fetchFaqs();
  }

  //UI
  render() {
    const {
      isAdminView,
      onAddNewQuestion,
      onUpdateQuestion,
      onDeleteQuestion,
    } = this.props;

    const { isLoading } = this.state;

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader className="pageHeader" title="FAQ" onBack={() => this.props.app.urlManager.pushBack()} />

        {isAdminView && (
          <Row type='flex' justify='end'>
            <Col>
              <Button
                style={{ marginBottom: 20 }}
                type="primary"
                onClick={onAddNewQuestion}
              >
                Add FAQ
              </Button>
            </Col>
          </Row>
        )}

        <Layout.Content>
          <WhiteBox title="Frequently Asked Questions">
            {(this.state.faqs.length === 0 && !isLoading) && (
              <Empty
                description="No FAQs were found."
                style={{ marginTop: 56 }}
              />
            )}

            <div className="questions">
              {this.state.faqs.map((question) => (
                <CustomCollapse
                  key={question.id}
                  title={question.question}
                  extraActions={() => (
                    isAdminView
                      ? (
                        <>
                          <Button
                            variant="none"
                            icon={<AiOutlineEdit style={{ marginRight: 8 }} />}
                            shape="round"
                            style={{ marginRight: 16 }}
                            onClick={() => onUpdateQuestion(question)}
                          >
                            Edit
                          </Button>

                          <Tooltip placement="bottomLeft" title="Delete question">
                            <Popconfirm
                              placement="top"
                              okText="Yes"
                              cancelText="No"
                              title="Are you sure you want to delete this question?"
                              onConfirm={() => onDeleteQuestion(question.id)}
                            >
                              <Button variant="none" icon={<DeleteOutlined />} shape="circle" />
                            </Popconfirm>
                          </Tooltip>
                        </>
                      ) : null
                  )}
                >
                  <div className="questions__answer-wrapper">
                    <ReactMarkdown children={question.answer} linkTarget="_blank" />
                  </div>
                </CustomCollapse>
              ))}
            </div>

            {(isAdminView && !isLoading) && (
              <div className="add-new-question">
                <button onClick={onAddNewQuestion}>
                  <AiOutlinePlusCircle size={20} />
                  <span>
                  {this.state.faqs.length === 0 ? 'Create the first one' : 'Add new FAQ'}
                  </span>
                </button>
              </div>
            )}
          </WhiteBox>
        </Layout.Content>
      </Layout.Content>
    );
  }

  async _fetchFaqs() {
    this.startLoading();

    const resp = await this.props.app.config.faq.getAllQuestions();
    if (resp.statusCode == 200 && resp.body) {
      const faqs = resp.body.faqs;
      this.setState({ faqs, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isLoading: false });
    }
  }
}
