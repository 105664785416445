import React from "react";
import autoBind from 'react-autobind';
import { Switch, Row, Col, Popover, Input, Button, Form, Typography, Tooltip, message, Spin } from 'antd';
import { UserOutlined, CalendarOutlined, CreditCardOutlined, FileDoneOutlined, InfoCircleTwoTone, LeftOutlined, FileOutlined, QuestionCircleOutlined, ScheduleOutlined, SnippetsOutlined } from '@ant-design/icons';

import config from '../../../config/config';
import Utils from "../../../components/Utils";
import CustomComponent from '../../../components/CustomComponent';
import  '../../../assets/stylesheets/CommonCertificationUserHeader.less';
import Globals from "../../../config/Globals";
//props: app, user, certificationProcess, onWaiveToogle, showBackButton
export default class CommonCertificationViewUserHeader extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      waivePopoverVisible: false,
      notesPopoverVisible: false,
      notesModalVisible: false,
      userNotes: '',
      originalNotes: null,
    };
  }

  // Actions
  _handleWaiveAutoUpdate() {
    const { certificationProcess } = this.props;
    if (!!certificationProcess.waivedBy) {
      this.props.onWaiveToogle(false);
    } else this.setState({ waivePopoverVisible: true });
  }

  async _handleWaiveEnable() {
    const resp = await this.waiveForm.validateFields(); //Get notes
    await this.props.onWaiveToogle(true, resp?.notes || '');
    this.setState({ waivePopoverVisible: false });
  }

  _handleWaivePopoverVisibleChange(visible) { this.setState({ waivePopoverVisible: visible }); }

  async _handleNotesPopoverVisibleChange(visible) {
    this._fetchUserNotes();
    this.setState({ notesModalVisible: visible });
  }

  _handleCloseNotes() {
    this.setState({ notesModalVisible: false });
  }

  _handleSaveNotes() {
    this._updateUserNotes();
  }

  _handleNavigateToFaq() {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.faq);
  }

  _handleNavigateToMyUpcomingCourses() {
    let userID = null;
    if (this.props.app.isAdmin()) {
      userID = this.props.user.id;
    } else {
      userID = this.props.app.getAuthorizedUserID();
    }
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.myUpcomingCourses, null, userID);
  }

  _handleNavigateToProfile() {
    if (this.props.app.isAdmin()) this.props.app.urlManager.redirectToPortal(Globals.PortalFEPath_CustomUser + this.props.user.id);
    else this.props.app.urlManager.redirectToPortal(Globals.PortalFEPath_UserProfile);
  }

  _handleNavigateToBilling() {
    if (this.props.app.isAdmin()) {
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.adminBillingView, null, 'user', this.props.user.id);
      return;
    }
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.simpleBillingView, null, 'user');
  }

  _handleNavigateToLicenses() {
    let userID = null;
    if (this.props.app.isAdmin()) {
      userID = this.props.user.id;
    } else {
      userID = this.props.app.getAuthorizedUserID();
    }
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.commonLicenses, null, userID);
  }

  _handleNavigateToApplication() {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.newAdminCertification, null, this.props.certificationProcess.id, this.props.user.id);
  }

  handleOpenCalendar() {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.sessionsHome);
  }

  _handleBackButton() {
    this.props.app.urlManager.pushBack();
    // if (this.props.app.isAdmin()) {
    //   if (this.props.app.sharedCache().isMonoCertification()) this.props.app.urlManager.pushPage(config.ApplicationRoutes.userSearch);
    //   else this.props.app.urlManager.pushBack(config.ApplicationRoutes.userDashboard, null, this.props.user.id);
    // } else this.props.app.urlManager.pushPage(config.ApplicationRoutes.homepage);
  }

  _handleNoteChange(e) {
    this.setState({ userNotes: e.target.value });
  }

  // UI
  render() {
    const { user, app, showApplicationIcon, showBackButton } = this.props;
    const tenantConfig = this.props.app.sharedCache().getTenantConfig();
    const isAdmin = app.isAdmin();
    const isOrgManager = (!isAdmin && user?.id != this.props.app.getAuthorizedUserID());
    if (!user) { return null; }
    const sessionPage = this.props.app.idm.urlmanager.getParam(Globals.URLQueryParam_From) !== null;
    return (
      <Row className="certification-user-header" gutter={32}>
        {(this.props.app.isAdmin() || !this.props.app.sharedCache().isMonoCertification() && (showBackButton)) && <Col className="back-section">
          <Button type='link' onClick={this._handleBackButton}><LeftOutlined style={{ fontSize: 24 }}/></Button>
        </Col>}
        <Col className="user-info">
          <strong>{user.firstName} {user.lastName}</strong>
          <span>{user.email}</span>
          <small>Terms agreed on <strong>{Utils.getDateOnUIFormatByTimestamp(user.agreedOnTerms)}</strong></small>
        </Col>
        {this._renderWaiveSwitch()}
        {this._renderApplicationTitle()}
        <Col className="user-links">
          {tenantConfig.settingsDisplayOptions.showFAQ && (
            <Button type='link' onClick={this._handleNavigateToFaq} disabled={isOrgManager} style={{ height: 60, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <Row type='flex' justify='center' style={{ marginTop: -10, width: '100%' }}> <QuestionCircleOutlined style={{ fontSize: '22px' }} /> </Row>
              <Row type='flex' justify='center'> <span>FAQ</span> </Row>
            </Button>
          )}
          <Button type='link' onClick={this._handleNavigateToProfile} disabled={isOrgManager} style={{ height: 60, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <Row type='flex' justify='center' style={{ marginTop: -10, width: '100%' }}> <UserOutlined style={{ fontSize: '22px' }} /> </Row>
            <Row type='flex' justify='center'> <span>{isAdmin ? 'User' : 'My'} profile</span> </Row>
          </Button>
          {isAdmin && (
            <Popover
              content={this._renderNotes()}
              title="Student Notes"
              trigger="click"
              visible={this.state.notesModalVisible}
              onVisibleChange={this._handleNotesPopoverVisibleChange}
            >
              <Button type='link' style={{ height: 60, display: 'flex', justifyContent: 'center', flexDirection: 'column' }} onClick={this._handleNotesPopoverVisibleChange}>
                <Row type='flex' justify='center' style={{ marginTop: -10, width: '100%' }}>
                  <SnippetsOutlined style={{ fontSize: '22px' }} />
                </Row>
                <Row type='flex' justify='center'>
                  <span>Notes</span>
                </Row>
              </Button>
            </Popover>
          )}
          {tenantConfig.licModVaultingEnabled && <Button type='link' onClick={this._handleNavigateToBilling} disabled={isOrgManager} style={{ height: 60, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <Row type='flex' justify='center' style={{ marginTop: -10, width: '100%' }}> <CreditCardOutlined style={{ fontSize: '22px' }} /> </Row>
            <Row type='flex' justify='center'> <span>Billing</span> </Row>
          </Button>}
          <Button type='link' onClick={this._handleNavigateToLicenses} disabled={isOrgManager} style={{ height: 60, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Row type='flex' justify='center' style={{ marginTop: -10, width: '100%' }}> <FileDoneOutlined style={{ fontSize: '22px' }} /> </Row>
            <Row type='flex' justify='center'> <span>{isAdmin ? 'User' : 'My'} licenses</span> </Row>
          </Button>
          {(isAdmin && showApplicationIcon) && (
            <Button type='link' onClick={this._handleNavigateToApplication}>
              <Row type='flex' justify='center' style={{ marginTop: -10 }}> <FileOutlined style={{ fontSize: '22px' }} /> </Row>
              <Row type='flex' justify='center'> <span>User application</span> </Row>
            </Button>
          )}
          {tenantConfig.menuDisplayOptions.showSessionsToUsers && (
            <Button type='link' onClick={this._handleNavigateToMyUpcomingCourses} disabled={isOrgManager} style={{ height: 60, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <Row type='flex' justify='center' style={{ marginTop: -10, width: '100%' }}> <ScheduleOutlined style={{ fontSize: '22px' }} /> </Row>
              <Row type='flex' justify='center'> <span>Coming up</span> </Row>
            </Button>
          )}
          {!sessionPage &&(
            <Button type="link" onClick={this.handleOpenCalendar} style={{ height: 60, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <Row type='flex' justify='center' style={{ marginTop: -10, width: '100%' }}> <CalendarOutlined style={{ fontSize: '22px' }} /> </Row>
              <Row type='flex' justify='center'> <span>Calendar</span> </Row>
            </Button>
          )}
        </Col>
      </Row>
    );
  }

  /* Private UI */
  _renderApplicationTitle() {
    const sessionPage = this.props.app.idm.urlmanager.getParam(Globals.URLQueryParam_From) !== null;

    if (!this.props.certificationProcess?.certificationDescription && !sessionPage)   {
      return (
        <Col className="certification-name">
          <h1>Training and Certifications</h1>
        </Col>
      );
    } else if (this.props.certificationProcess?.certificationDescription) {
      return (
        <Col className="certification-name">
          <h1>{this.props.certificationProcess.certificationDescription}</h1>
        </Col>
      );
    }
    return (
      <Col className="certification-name">
          <h1>Training Calendar</h1>
      </Col>
    );
  }

  _renderWaiveSwitch() {
    const { app, certificationProcess, certificationSpecs } = this.props;
    const isAdmin = app.isAdmin();
    const certSpecs = app.sharedCache().getCertificationByID(certificationProcess?.certificationID);
    if (!(isAdmin && certSpecs?.ui?.showWaiveFeature)) return (<></>);
    //
    return (
      <Col className="waive">
        <div>
          <Popover trigger={!!certificationProcess.waivedBy ? 'none' : 'click'} title={`Enable waive for this ${certificationSpecs.uiTerm}?`}
                   destroyTooltipOnHide visible={this.state.waivePopoverVisible} onVisibleChange={this._handleWaivePopoverVisibleChange} content={
            <div style={{ width: 400 }}>
              <Typography.Text>Optional waive notes (visible for admins only):</Typography.Text>
              <Form {...Utils.propagateRef(this, 'waiveForm')}>
                <Form.Item name="notes">
                  <Input.TextArea rows={3} />
                </Form.Item>
              </Form>
              <Row type='flex' justify='end'>
                <Col>
                  <Button type='primary' onClick={this._handleWaiveEnable}> Enable </Button>
                </Col>
              </Row>
            </div>
          }>
            <Switch checked={!!certificationProcess.waivedBy} onChange={this._handleWaiveAutoUpdate} />
            {!!certificationProcess.waivedBy && certificationProcess.waivedComments && certificationProcess.waivedComments.length > 0 &&
              <Tooltip trigger='hover' title={`Admin notes: ${certificationProcess.waivedComments}`}>
                <InfoCircleTwoTone twoToneColor={app.themeManager.primaryColor} style={{marginLeft: 10}} />
              </Tooltip>}
          </Popover>
        </div>
        <strong>WAIVE PAYMENTS</strong>
      </Col>
    );
  }

  _renderNotes() {
    const { originalNotes, userNotes } = this.state;
    const isLoading = originalNotes === null;

    return (
      <div style={{ width: 400 }}>
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 160 }}>
            <Spin />
          </div>
        ) : (
          <>
            <Typography.Text>Notes:</Typography.Text>
            <Input.TextArea rows={4} value={userNotes} onChange={this._handleNoteChange} />
            <Row type='flex' justify='end' gutter={8} style={{ marginTop: 8 }}>
              <Col>
                <Button type='secondary' onClick={this._handleCloseNotes}>Cancel</Button>
              </Col>
              <Col>
                <Button type='primary' onClick={this._handleSaveNotes}>Save</Button>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }


  // API
  async _fetchUserNotes() {
    const { user } = this.props;
    let originalNotes = '';

    const userResp = await this.props.app.idm.api.user.read(user?.id);
    if (userResp.statusCode == 200 && userResp.body) {
      originalNotes = userResp.body?.notes || '';
    }
    this.setState({ originalNotes, userNotes: originalNotes });
  }

  async _updateUserNotes() {
    const { userNotes, originalNotes } = this.state;
    if (userNotes === originalNotes) {
      this.setState({ notesModalVisible: false });
      return;
    }
    const { user } = this.props;
    const notesResp = await this.props.app.idm.api.user.simpleUpdate({ id: user?.id, notes: userNotes });
    if (notesResp.statusCode == 200) {
      message.success('Notes updated successfully');
      this.setState({ notesModalVisible: false, originalNotes: userNotes });
    } else {
      message.error('Failed to update notes');
    }
  }
}
