import React from 'react';
import autoBind from 'react-autobind';
import { Button } from 'antd';
import moment from 'moment';

import CommonModal from './CommonModal';

import UserProfileStep from './UserRegistrationModal_steps/UserProfileStep';
import UserAgreementStep from './UserRegistrationModal_steps/UserAgreementStep';
import Utils from '../../../components/Utils';
import CommonLoadingView from '../CommonLoadingView';
import Globals from '../../../config/Globals';

class UserRegistrationModal extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false,
      isVisible: false,
      registrationSteps: [],
      isFormValidProfile: false,
      isFormValidAgreement: false,
      tenantConfig: {},
    };
  }
  needsToAppear() {
    const tenantConfig = this.props.app.sharedCache().getTenantConfig();
    return (tenantConfig.organizationRegText?.length > 0 || this.props.app.sharedCache().getTenantRegistrationConfig());
  }
  //Life cycle
  componentDidMount = async () => {
    const tenantConfig = this.props.app.sharedCache().getTenantConfig();
    this.setState({
      tenantConfig,
      registrationSteps: [
        {
          title: 'Personal Information',
          content: (
            <UserProfileStep onChange={this.handleValidation} app={this.props.app}
                             {...Utils.propagateRef(this, 'profileStep')}/>
          ),
        },
        ...(tenantConfig.organizationRegText?.length > 0 ? [{
          title: 'Agreement',
          content: (
            <UserAgreementStep onChange={this.handleValidation} app={this.props.app}
                               {...Utils.propagateRef(this, 'agreementStep')}/>
          ),
        }] : []),
      ],
    });
  };
  //Actions
  handleNextStep() { this.modal.next(); }
  handleShow() { this.setState({ isLoading: true, isVisible: true }); }
  handleValidation(data) { this.setState({ ...data }); }
  handleCompleteUserRegistration = async () => {
    this.setState({ isLoading: true });
    //Validate
    const respProfile = await this.profileStep.handleSubmit();
    const respAgreement = (this.state.tenantConfig.organizationRegText ? await this.agreementStep.handleSubmit() : {});
    //profile errors?
    if (!respProfile.errorFields || !respAgreement.errorFields) {
      //build data
      let data = { ...respProfile, ...respAgreement };
      //Update parts
      let resp = await this.updateUserPartitions(data);
      //Update employeement if field is specified
      const { fields } = this.props.app.sharedCache().getTenantRegistrationConfig();
      const userObj = await this.props.app.idm.session.data.getUserObject();
      if (Object.values(fields || {}).find((f) => f.type == Globals.RegOrgEmploymentSelectionType)) {
        let employmentResp = (await this.props.app.organization.employee.upsertEmployee(respProfile?.selectedOrg?.id || '', userObj.id, {
          email: userObj.email, firstName: userObj.firstName, lastName: userObj.lastName, hashedWorksafeID: respProfile?.selectedOrg?.hashedWorksafeID || ''
        }));
        if (employmentResp.statusCode != 200 && employmentResp.statusCode != 204) {
          this.props.app.alertController.showAPIErrorAlert(null, employmentResp);
          this.setState({ isLoading: false });
          return;
        }
        //save on program user as well
        data.employerID = respProfile?.selectedOrg?.id;
      }
      //Create user
      if (resp) resp = await this.createuser(data); //only continue to save if saved previous with success
      if (resp) {
        await this.props.app.reloadCache(true); //reload user object
        this.props.app.alertController.showSuccessAlert('', 'Registration completed!');
        this.setState({ isLoading: false, isVisible: false });
        if (this.props.completionHandler) this.props.completionHandler();
      } else {
        this.setState({ isLoading: false });
      }
    } else {
      this.modal.prev();
      this.setState({ isLoading: false });
    }
  };
  //UI
  render() {
    const { tenantConfig } = this.state;
    return (
      <>
        {this.state.isVisible && <CommonLoadingView isLoading={this.state.isLoading} />}
        {this.state.isVisible && (
          <CommonModal {...Utils.propagateRef(this, 'modal')} title={`Complete ${tenantConfig.organizationName} Registration`}
            isVisible={this.state.isVisible} isLoading={this.state.isLoading} steps={this.state.registrationSteps}
            modalButtons={null} nextButton={
              <Button className="primary solid" key="submit" type="primary" disabled={!this.state.isFormValidProfile}
              loading={this.state.isLoading} onClick={() => this.handleNextStep()}> Next </Button>}
            submitButton={
              <Button className="primary solid" key="submit" type="primary"
              disabled={(!this.state.isFormValidAgreement && this.state.tenantConfig.organizationRegText) || !this.state.isFormValidProfile}
              loading={this.state.isLoading} onClick={() => this.handleCompleteUserRegistration()}> Submit </Button>}
          />
        )}
      </>
    );
  }

  //API
  updateUserPartitions = async data => {
    //Get all unique partitions in parallel
    const { fields } = this.props.app.sharedCache().getTenantRegistrationConfig();
    const uniqueValidParts = Object.keys(fields || {}).filter((f) => fields[f].partitionID && fields[f].partitionID.length > 0).reduce((acc, curr) => {
      if (acc.indexOf(fields[curr].partitionID) == -1) acc.push(fields[curr].partitionID);
      return acc;
    }, []);
    const partsPool = await Promise.all(uniqueValidParts.map((p) => this.props.app.idm.session.getPartitionByID(p, true)));
    //Buildup part data
    const partData = {};
    //For each unique part
    for (let partitionID of uniqueValidParts) {
      let partition = partsPool.find((p) => p && p.partID == partitionID);
      if (!partition || !partition.partID) partition = { partID: partitionID };
      //Get fields that needs to be saved on this partition
      const fieldsData = {};
      for (let fieldKey of Object.keys(fields || {})) {
        const field = fields[fieldKey];
        if (field.partitionID && field.partitionID.length > 0 && field.partitionID == partition.partID) {
          if (data[field.id] && field.type == 'date') {
            Utils.setNestedObject(fieldsData, field.id, moment(data[field.id]).format(field.format));
          } else {
            Utils.setNestedObject(fieldsData, field.id, data[field.id]);
          }
        }
      }
      //Build part from old values + overrides
      partData[partition.partID] = { value: { ...partition.value || {}, ...fieldsData } };
    }
    // Get current logged user ID from session authorization
    const userID = await this.props.app.idm.session.authorization.getUserID();
    // Send API request to IDM, setting paritition for user X on partition Y with value Z
    const resp = await this.props.app.idm.api.userPartition.setMultiple({ parts: partData }, userID);
    //Invalidate old partition values
    await Promise.all(uniqueValidParts.map((p) => this.props.app.idm.session.invalidatePartitionByID(p)));
    // Simple 200 status means we got a update success
    if (resp.statusCode == 200) {
      console.log('update user partitions with success');
    } else {
      // errors can come with 2 status code 400 (bad request) or 401 (unauthorized) -
      // Other status codes are not expected, 500 means de role API is down and it should be
      // handled as a unknown error for it rarity
      console.log('ERROR while updating user partitions');
      this.props.app.alertController.showAPIErrorAlert('Error', resp);
      return false;
    }
    return true;
  };
  createuser = async data => {
    //Get fields that needs to be saved on the user object partition
    const { fields } = this.props.app.sharedCache().getTenantRegistrationConfig() || {};
    const fieldsData = { employerID: data.employerID };
    for (let fieldKey of Object.keys(fields || {})) {
      const field = fields[fieldKey];
      if (field.saveOnUser) {
        if (data[field.id] && field.type == 'date') {
          Utils.setNestedObject(fieldsData, field.id, moment(data[field.id]).format(field.format));
        } else if (field.type == 'switch' || field.type == 'boolean') {
          data[field.id] = !!data[field.id];
        } else {
          Utils.setNestedObject(fieldsData, field.id, data[field.id]);
        }
      }
    }
    //Get current IDM user and merge with new values + agreedOnTerms
    const userObj = await this.props.app.idm.session.data.getUserObject();
    const userValue = {
      id: userObj.id, firstName: userObj.firstName, lastName: userObj.lastName, email: userObj.email, //required values
      ...(this.props.app.affiliateID ? { referredByID: this.props.app.affiliateID } : {}), //affiliate logic
      agreedWithCertTerms: true, agreedOnTerms: Date.now(), //terms
      ...fieldsData /* dynamic data */
    };
    // Send API request to
    const resp = await this.props.app.api.user.create(userValue);
    // Simple 200 status means we got a update success
    if (resp.statusCode == 200) {
      console.log('created prog. user with success');
    } else {
      // errors can come with 2 status code 400 (bad request) or 401 (unauthorized) -
      // Other status codes are not expected, 50x means de role API is down and it should be
      // handled as a unknown error for it rarity
      console.log('ERROR while creating prog. user', resp);
      this.props.app.alertController.showAPIErrorAlert('Error', resp);
      return false;
    }
    return true;
  };
}

export default UserRegistrationModal;
