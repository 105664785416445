import React, { forwardRef } from 'react';
import MaskedInput from './MaskedInput';

const PhoneInput = forwardRef((props, ref) => (
  <MaskedInput
    mask="+1 (000) 000-0000[ **********]"
    ref={ref}
    {...props}
  />
));
PhoneInput.displayName = 'PhoneInput';

export default PhoneInput;
