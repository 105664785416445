import React from 'react';
import autoBind from 'react-autobind';
import { PageHeader, message, Layout, Row, Col, Table, Button, Tooltip, Popconfirm, Tag } from 'antd';
import { EditOutlined, DeleteOutlined, DisconnectOutlined, LinkOutlined } from '@ant-design/icons';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import CustomComponent from '../../../components/CustomComponent';
//
import Globals from '../../../config/Globals';
import config from '../../../config/config';
import Utils from '../../../components/Utils';
import CommonInstructorSelectionPopover from '../../commonComponents/Popovers/CommonInstructorSelectionPopover';
//
export default class AdminInstructorsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      instructors: [], sortedInfo: { columnKey: 'firstName', order: 'ascend' },
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    this.fetchData();
  }

  async fetchData() {
    this.startLoading();
    const resp = await this._loadAll();
    if (resp) this.setState({ ...resp, isLoading: false });
    else this.stopLoading();
  }

  //Actions
    //Table
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
    //Table row actions
  handleEditInstructor(instructorID) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.instructor, null, instructorID); }
  async handleToggleInstructorEnabled(instructor) {
    this.startLoading();
    const resp = await this._toogleInstructorEnabled(instructor);
    if (resp) await this.fetchData();
    this.stopLoading();
  }
  async handleDeleteInstructor(instructorID) {
    this.startLoading();
    const resp = await this._deleteInstructor(instructorID);
    if (resp) await this.fetchData();
    this.stopLoading();
  }
  async handleInstructorAddSelection(idmUser) {
    this.startLoading();
    const resp = await this._addInstructor(idmUser);
    if (resp) await this.fetchData();
    this.stopLoading();
  }
  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader className="pageHeader" title="Instructors" onBack={() => this.props.app.urlManager.pushBack()} />
        <Layout.Content>
          <Row type='flex' justify='end'>
            <Col>
              <CommonInstructorSelectionPopover app={this.props.app} onSelect={this.handleInstructorAddSelection} existingInstructors={this.state.instructors}>
                <Button style={{ marginBottom: 20 }} type="primary"> Add Instructor </Button>
              </CommonInstructorSelectionPopover>
            </Col>
          </Row>
          {this._renderInstructorsTable()}
        </Layout.Content>
      </Layout.Content>
    );
  }

  /* private UI */
  _renderInstructorsTable() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'First Name', key: 'firstName', dataIndex: 'firstName', 
        sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        sortOrder: sortedInfo.columnKey === 'firstName' && sortedInfo.order
      },
      {
        title: 'Last Name', key: 'lastName', dataIndex: 'lastName',
        sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        sortOrder: sortedInfo.columnKey === 'lastName' && sortedInfo.order
      },
      {
        title: 'Email', key: 'email', dataIndex: 'email',
        sorter: (a, b) => a.email.localeCompare(b.email),
        sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order
      },
      {
        title: 'Enabled', dataIndex: 'disabled', key: 'disabled',
        sorter: (a, b) => (a['disabled'] ? 1 : 0) - (b['disabled'] ? 1 : 0),
        sortOrder: sortedInfo.columnKey === 'disabled' && sortedInfo.order,
        render: disabled => {
          return (<Tag color={(!disabled ? 'green' : 'orange')}>{(!disabled ? 'Enabled' : 'Disabled')}</Tag>);
        }
      },
      {
        title: 'Added On', key: 'createdOn', width: '15%',
        render: props => props.createdOn ? Utils.getDateOnUIFormatByTimestamp(props.createdOn) : 'N/A',
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order
      },
      {
        title: 'Actions', width: 'auto', key: 'Actions', width: '15%',
        render: props => {
          return (<span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title={(props.disabled ? 'Enable instructor' : 'Disable instructor')}>
              <Popconfirm title={`Do you really want to ${(!props.disabled ? "disable" : "enable")} instructor '${props.firstName + ' ' + props.lastName}'?`} placement="bottomRight" onConfirm={this.handleToggleInstructorEnabled.bind(this, props)} okText="Yes" cancelText="No">
                <Button variant="none" icon={(!props.disabled ? <DisconnectOutlined /> : <LinkOutlined />)} shape="circle" />
              </Popconfirm>
            </Tooltip>
            <Tooltip placement="bottomLeft" title='Edit'>
              <Button style={{ marginLeft: 5 }} variant="none" icon={<EditOutlined />} shape="circle" onClick={this.handleEditInstructor.bind(this, props.id)} />
            </Tooltip>
            <Tooltip placement="bottomLeft" title='Delete'>
              <Popconfirm placement="top" title={`Are you sure that you want to delete the instructor '${props.firstName} ${props.lastName}'?`} 
                          onConfirm={this.handleDeleteInstructor.bind(this, props.id)} okText="Yes" cancelText="No" >
                <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle"/>
              </Popconfirm>
            </Tooltip>
          </span>);
        }
      },
    ];
    const props = {
      rowKey: 'id', loading: this.state.isLoading, onChange: this.handleFilterChange,
      locale: { emptyText: 'No instructors found!' },
      pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] }
    };
    return (
      <Layout.Content>
        <Table className="adminInstructorsTable" columns={columns} dataSource={this.state.instructors} {...props} />
      </Layout.Content>
    );
  }

  /* private API */
  async _loadAll() {
    const resp = await Promise.all([
      this.props.app.sharedCache().getInstructors(),
    ]);
    if (!resp[0]) {
      this.props.app.alertController.showErrorAlert(null, 'Error while loading instructors!');
      return false;
    } 
    return { instructors: resp[0] };
  }
  async _addInstructor(idmUser) {
    this.props.app.sharedCache().invalidateInstructors();
    const resp = await this.props.app.classroom.instructor.createInstructor(idmUser.id);
    if (resp.statusCode == 200) {
      message.success('Instructor added with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _toogleInstructorEnabled(instructor) {
    this.props.app.sharedCache().invalidateInstructors();
    const resp = await this.props.app.classroom.instructor.updateInstructor({
      id: instructor.id, disabled: !instructor.disabled
    });
    if (resp.statusCode == 200) {
      message.success(`Instructor ${(instructor.disabled ? 'enabled' : 'disabled')} with success!`);
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
  async _deleteInstructor(instructorID) {
    this.props.app.sharedCache().invalidateInstructors();
    const resp = await this.props.app.classroom.instructor.deleteInstructor(instructorID);
    if (resp.statusCode == 200) {
      message.success('Instructor deleted with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
}
