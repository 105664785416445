import React from 'react';
import autoBind from 'react-autobind';
import { PageHeader, message, Layout, Row, Col, Table, Button, Tooltip, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
//
import CommonLoadingView from '../../commonComponents/CommonLoadingView';
import CustomComponent from '../../../components/CustomComponent';
//
import Globals from '../../../config/Globals';
import config from '../../../config/config';
import Utils from '../../../components/Utils';
//
export default class AdminCertificationsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      certifications: [], sortedInfo: { columnKey: 'name', order: 'ascend' },
    };
  }
  // Life cycle
  async componentDidMount() {
    super.componentDidMount();
    this.fetchData();
  }
  // Remote
  async fetchData() {
    this.startLoading();
    const resp = await this._loadCertifications();
    if (resp) this.setState({ ...resp, isLoading: false });
    else this.stopLoading();
  }

  //Actions
  handleAddCertification() { this.props.app.urlManager.pushPage(config.ApplicationRoutes.certification, null, Globals.URL_NewIndentifier); }
    //Table
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
    //Table row actions
  handleEditCertification(certID) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.certification, null, certID); }
  async handleDeleteCertification(cert) {
    //double validation
    const confirmation = await this.props.app.alertController.showPromptAlert('Attention!', `Certification ${cert.id}-${cert.title} will be deleted and this operation is not reversible! Are you sure you want to delete it? (type the title to confirm)`);
    if (confirmation != cert.title) return;
    //continue
    this.startLoading();
    const resp = await this._deleteCertification(cert.id);
    await this.fetchData();
    this.stopLoading();
  }

  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader className="pageHeader" title="Certifications" onBack={() => this.props.app.urlManager.pushBack()} />
        <Layout.Content>
          <Row type='flex' justify='end'>
            <Col> <Button style={{ marginBottom: 20 }} type="primary" onClick={this.handleAddCertification}> Create new Certification </Button> </Col>
          </Row>
          {this._renderCertificationsTable()}
        </Layout.Content>
      </Layout.Content>
    );
  }

  /* private UI */
  _renderCertificationsTable() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Title', key: 'title', dataIndex: 'title',
        sorter: (a, b) => a.title.localeCompare(b.title),
        sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order
      },
      {
        title: 'Description', key: 'description', dataIndex: 'description',
        sorter: (a, b) => a.description.localeCompare(b.description),
        sortOrder: sortedInfo.columnKey === 'description' && sortedInfo.order
      },
      {
        title: 'Created On', key: 'createdOn', width: '10%',
        render: props => props.createdOn ? Utils.getDateOnUIFormatByTimestamp(props.createdOn) : 'N/A',
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order
      },
      {
        title: 'Display Order', key: 'displayOrder', width: '10%',
        render: props => props.ui.certificationOrder,
        sorter: (a, b) =>  a.ui.certificationOrder - b.ui.certificationOrder,       
        sortOrder: sortedInfo.columnKey === 'displayOrder' && sortedInfo.order,
      },
      {
        title: 'Hide in UI', key: 'hideFromUI', width: '10%',
        render: props => Utils.renderYesNo(props.ui?.hideFromUI),
        sorter: (a, b) =>  (a.ui?.hideFromUI === b.ui?.hideFromUI)? 0 : a.ui?.hideFromUI ? -1 : 1,
        sortOrder: sortedInfo.columnKey === 'hideFromUI' && sortedInfo.order,
      },
      {
        title: 'Actions', width: 'auto', key: 'Actions', width: '15%',
        render: props => {
          return (<span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title='Edit'>
              <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.handleEditCertification.bind(this, props.id)} />
            </Tooltip>
            <Tooltip placement="bottomLeft" title='Delete'>
              <Popconfirm placement="top" title={`Are you sure that you want to delete the certification '${props.id}-${props.name}'?`} 
                          onConfirm={this.handleDeleteCertification.bind(this, props)} okText="Yes" cancelText="No" >
                <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle"/>
              </Popconfirm>
            </Tooltip>
          </span>);
        }
      },
    ];
    const props = {
      rowKey: 'id', loading: this.state.isLoading, onChange: this.handleFilterChange,
      locale: { emptyText: 'No certifications found!' },
      pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] }
    };
    return (
      <Layout.Content>
        <Table className="adminCertificationsTable" columns={columns} dataSource={this.state.certifications} {...props} />
      </Layout.Content>
    );
  }

  /* private API */
  async _loadCertifications() {
    const resp = await Promise.all([
      this.props.app.config.certification.getCertifications(),
    ]);
    if (!resp[0] || resp[0].statusCode != 200) {
      this.props.app.alertController.showAPIErrorAlert('Could not load certifications!', resp[0]);
      return false;
    } 
    return { certifications: resp[0].body?.certifications };
  }
  async _deleteCertification(certID) {
    const resp = await this.props.app.config.certification.deleteCertification(certID);
    if (resp.statusCode == 200) {
      message.success('Certification deleted with success!');
      return true;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      return false;
    }
  }
}
