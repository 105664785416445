import React from 'react';
import autoBind from 'react-autobind';
import { Layout, Button } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CommonAssignedLicensesTable from '../commonComponents/CommonAssignedLicensesTable';
import CommonAssignedLicenseSearchBar from '../commonComponents/CommonAssignedLicenseSearchBar';
import CommonAssignLicenseDrawer from './Drawers/CommonAssignLicenseDrawer';
//

export default class CommonAssignedLicensesView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: true,
      searchObj: { consumed: false, product: null },
      assignedLicenses: [],
      isAssignLicenseDrawerVisible: false, orgID: null,
    };
    this._handleSearch = this._handleSearch.bind(this);
  }

  //Life cycle
  async componentDidMount() {
    await this.setOrgID();
    this._handleSearch(this.state.searchObj);
  }

  refreshPage() {
    this.setState({ isLoading: true });
    this._handleSearch(this.state.searchObj);
  }

  handleAssignLicenseDrawerClose() { this.setState({ isAssignLicenseDrawerVisible: false }); }
  handleAssignLicense() { this.setState({ isAssignLicenseDrawerVisible: true }) };

  // Event handler to update searchObj
  _updateSearchObj(searchObjUpdates) {
    this.setState(prevState => ({
      searchObj: {
        ...prevState.searchObj,
        ...searchObjUpdates
      }
    }));
  }

  // UI
  render() {
    const { isLoading, assignedLicenses, searchObj} = this.state;
    return (
      <>
        {this._renderAssignLicenseDrawer()}
        <Layout.Content >
          <CommonLoadingView isLoading={isLoading} />
            <div style={{ display: 'flex',  marginBottom: '10px' }}>
              <CommonAssignedLicenseSearchBar
                onSearch={this._search}
                onUpdateSearchObj={this._updateSearchObj}
                searchObj={searchObj}
                app={this.props.app}
              />
              <Button style={{ marginLeft: 16 }} key="1" type='primary' onClick={() => this._handleSearch(this.state.searchObj)}>Filter</Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px'}}>
              <Button key="2" type='primary'onClick={() => this.handleAssignLicense()}>Assign Licenses</Button>
            </div>
            <CommonAssignedLicensesTable
              assignedLicenses={assignedLicenses?.assignedLicenses}
              isLoading={isLoading}
              app={this.props.app}
              refreshPage={this.refreshPage}
            />
        </Layout.Content>
      </>
    );
  }

  /* Private UI */
  _renderAssignLicenseDrawer() {
    return (
      <CommonAssignLicenseDrawer
        shouldShowProductSelect={true}
        shouldShowUserSelect={true}
        isVisible={this.state.isAssignLicenseDrawerVisible}
        onClose={this.handleAssignLicenseDrawerClose}
        app={this.props.app}
        orgID={this.props.orgID}
        refreshPage={this.refreshPage}
      />
    )
  }
  /* Private methods */
  async setOrgID() {
    let orgID = null;
    if (this.props.app.isAdmin()) {
      orgID = this.props.orgID;
    } else {
      orgID = this.props.app.urlManager.selectedOrg;
    }
   this.setState({ orgID });
  }

  async _handleSearch(searchObj) {
    this.setState({ isLoading: true });
    try {
      await this._search(searchObj);
    } catch (error) {
      console.error("Error occurred while searching:", error);
    }
  }

  async _search(searchObj) {
    await this.setOrgID();
    const { orgID } = this.state;
    const { product, consumed } = searchObj;
    const productID = product ? product.id : null;
    const showConsumed = consumed ? true : false;

    this.setState({ isLoading: true });
    try {
      const resp = await this.props.app.license.key.getAssignedKeys(
        orgID, null, productID, showConsumed
      );
      if (resp.statusCode === 200 && resp.body) {
        this.setState({ assignedLicenses: resp.body });
      } else {
        console.error("Error occurred while fetching data:", resp.error);
      }
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  }
}
