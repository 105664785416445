import React from 'react';
import autoBind from 'react-autobind';
import { Row, Col, Result, Spin, Button } from 'antd';
import { InfoCircleFilled, WarningOutlined } from '@ant-design/icons';

import '../../../../../assets/stylesheets/OverallStatuses.less';

import { CourseCardActionsHOC } from '../Subcomponents/CourseCardActionsContext';

class CheckingStatus extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      reachedMaxPollingTries: false,
    };

    this.intervalId = null;
    this.POLLING_TRIES = 0;
    this.POLLING_INTERVAL = 5 * 1000; // 5 seconds
    this.MAX_POLLING_TRIES = 6; // 6 * 5 seconds = 30 seconds
  }

  componentDidMount() {
    this.startPolling();
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  async handleCheckResults() {
    this.setState({ reachedMaxPollingTries: false });

    await this.props.actions.reloadCertAndSession(false);

    this.startPolling();
  }

  startPolling() {
    // Reset polling states before start a new one
    clearInterval(this.intervalId);
    this.intervalId = null;
    this.POLLING_TRIES = 0;

    // Start polling
    this.intervalId = setInterval(async () => {
      this.POLLING_TRIES += 1;

      await this.props.actions.reloadCertAndSession(false);

      if (this.POLLING_TRIES >= this.MAX_POLLING_TRIES) {
        this.setState({ reachedMaxPollingTries: true });
        clearInterval(this.intervalId);
      }
    }, this.POLLING_INTERVAL);
  }

  render() {
    const { reachedMaxPollingTries } = this.state;

    return (
      <Row justify="center">
        <Col span={14} style={{ textAlign: 'center' }}>
          <Result
            icon={(
              reachedMaxPollingTries
                ? <WarningOutlined />
                : <Spin size="large" />
            )}
            title={(
              reachedMaxPollingTries
                ? 'Cannot find your results'
                : 'Checking results, please wait...'
            )}
            subTitle={(
              reachedMaxPollingTries
                ? 'Click the button below to try again'
                : ''
            )}
            extra={(
              reachedMaxPollingTries
                ? (
                  <Button
                    type="primary"
                    size="large"
                    onClick={this.handleCheckResults}
                  >
                    Check results
                  </Button>
                )
                : null
            )}
          />
        </Col>
      </Row>
    );
  }
}

CheckingStatus = CourseCardActionsHOC(CheckingStatus);
CheckingStatus.CardStatusHeader = function() {
  return <span className="warning"><InfoCircleFilled /> Checking Status</span>;
}

export default CheckingStatus;
