import React from 'react';
import autoBind from 'react-autobind';
import { Table } from 'antd';
//
import CustomComponent from '../../components/CustomComponent';
import Utils from '../../components/Utils';
//
import '../../assets/stylesheets/AdminSearchUsersView.less';
//
export default class CommonLicenseViewActivationTable extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: { columnKey: 'createdOn', order: 'descend' }, activations: [] };
  }

  //Table support
  handleFilterChange(pagination, filters, sorter) { 
    this.setState({ sortedInfo: sorter, filters }); 
  }

  getTableRowProps(record) {
    return {
      onClick: () => this.props.onRow && this.props.onRow(record),
    };
  }
  
  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Date', dataIndex: 'createdOn', key: 'createdOn',
        render: createdOn => createdOn ? Utils.getDateOnUIFormatByTimestamp(createdOn) : '',
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
      },
      { 
        title: 'Name', dataIndex: 'name', key: 'name',
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        sorter: (a, b) => a.name.localeCompare(b.name),
      },

    ];

    const props = { loading: this.props.isLoading, onChange: this.handleFilterChange,
                    locale: {emptyText: 'No Activations found!'},
                    pagination: { pageSize: 50, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] },};
    return (<Table columns={columns} dataSource={this.props.activations} onRow={this.getTableRowProps} {...props}/>);
  }
}

