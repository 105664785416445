import React from 'react';
import autoBind from 'react-autobind';
import { Layout, Row, Col, Button, Form, Input, Icon } from 'antd';
import { UserOutlined } from '@ant-design/icons';
//
// import { IDMGlobals } from '@ikonintegration/idmclient';
import CustomComponent from '../../components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
// resources
import '../../assets/stylesheets/CommonLogin.less';
//
export default class CommonUnauthorizedView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false };
    this.props.app.sessionWillLoadHandler = this.sessionWillLoadHandler;
    this.props.app.sessionDidLoadHandler = this.sessionDidLoadHandler;
    this.props.app.sessionDidFailLoadHandler = this.sessionDidFailLoadHandler;
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = this.props.app.themeManager.theme.applicationName;
  }

  //session delegate -
  sessionWillLoadHandler() {
    if (this._isMounted) this.startLoading();
  }
  sessionDidLoadHandler() {
    if (this._isMounted) this.stopLoading(); //most of the cases it will be umounted already
  }
  sessionDidFailLoadHandler(err) {
    if (!err) err = 'Could not load application information. Please, check your internet connection.\nYou will be redirected to login page in a few seconds!';
    this.props.app.alertController.showErrorAlert('Error!', err);
    if (this._isMounted) this.stopLoading();
    //Schedule login redirect
    setTimeout(async () => {
      this.startLoading();
      this.props.app.redirectToAuth();
    }, 3000);
  }

  // UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.isAuthenticating;
    return (
      <Layout.Content className="pageContentFullScreen">
        <CommonLoadingView isLoading={isLoading}/>
        {/* <Row type="flex" justify="center" align="middle" className="authorizationContainer">
          <Col span={5} className="authorizationBoxContainer" align="middle" justify="center">
            <div className="authorizationBox">
              <Row className="authorizationContainerLogo">
                <Col align="center">
                  Please, wait while you're redirected...
                </Col>
              </Row>
            </div>
          </Col>
        </Row> */}
      </Layout.Content>
    );
  }
}
