import React from "react";
import autoBind from 'react-autobind';
import { Drawer, Row, Col, Button, message } from 'antd';
//
import Utils from "../../../components/Utils";
import config from "../../../config/config";
//
import CustomComponent from "../../../components/CustomComponent";
import CommonLoadingView from "../CommonLoadingView";
//
import CommonCourseProviderForm from '../Forms/CommonCourseProviderForm';
//props are: app, onUpdate
export default class CommonCourseProviderDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false, isVisible: false, courseProviderID: null, data: null };
  }
  //Public
  show(courseProviderID) { this.setState({ isVisible: true, courseProviderID, data: null }, this._fetch); }
  //Private actions
  handleClose() {
    this.form.resetFields();
    this.setState({ isLoading: false, isVisible: false, courseProviderID: null, data: null });
  }
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (!formData) return;
    if (this.state.courseProviderID) this._update(formData);
    else this._create(formData);
  }

  //UI
  render() {
    return (
      <Drawer visible={this.state.isVisible} title={`${this.state.courseProviderID ? 'Edit' : 'Create'} Course Provider`}
              placement="right" onClose={this.handleClose} width={800}>
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <Row type='flex' justify='end'>
          <Col> <Button type="primary" onClick={this.handleSubmit}>{this.state.courseProviderID ? 'Save' : 'Create'}</Button> </Col>
        </Row>
        <CommonCourseProviderForm app={this.props.app} {...Utils.propagateRef(this, 'form')} isEdit={!!this.state.courseProviderID}/>
      </Drawer>
    );
  }

  // API Calls
  async _fetch() {
    if (!this.state.courseProviderID) return; //avoid loading empty TPs
    this.setState({ isLoading: true });
    const resp = await this.props.app.config.courseProvider.getCourseProvider(this.state.courseProviderID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      this.form.setFieldsValue(resp.body);
      this.setState({ data: resp.body });
    } else this.props.app.alertController.showAPIErrorAlert(null, resp);
    this.setState({ isLoading: false });
  }
  async _update(data) {
    if (!this._isMounted) return;
    this.startLoading();
    const resp = await this.props.app.config.courseProvider.updateCourseProvider(data);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Course Provider successfully updated!');
      this.handleClose();
      if (this.props.onUpdate) this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } this.stopLoading(true);
  }
  async _create(data) {
    if (!this._isMounted) return;
    this.startLoading();
    const resp = await this.props.app.config.courseProvider.createCourseProvider(data);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      message.success('Course Provider successfully created!');
      this.handleClose();
      if (this.props.onUpdate) this.props.onUpdate();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    } this.stopLoading(true);
  }
}
