import React from "react";
import autoBind from 'react-autobind';
import moment from 'moment';
import { Row } from 'antd';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
//
import Globals from "../../../config/Globals";
//
import CommonSessionCalendarEvent from './CommonSessionCalendarEvent';
import CommonStudentSessionCalendarEvent from './CommonStudentSessionCalendarEvent';
import UtilsCalendar from "../../../components/UtilsCalendar";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
//
import '../../../assets/stylesheets/CommonWeeklySessionsView.less';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'moment/locale/en-ca';
//
const DragAndDropCalendar = withDragAndDrop(Calendar);
const CalendarLocalizer = momentLocalizer(moment);
//props are: app, from, to, sessions, calendars, onSelect
export default class CommonMonthlySessionsView extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    //helpers (could be moved to customer, session or any or logic to accomodate other ranges :) )
    this.startTime = Globals.CalendarViewStartTime;
    this.endTime = Globals.CalendarViewEndTime;
    //state
    this.state = { events: [] };
  }
  componentDidUpdate(prevProps) {
    if (this.props.sessions != prevProps.sessions) {
      const events = UtilsCalendar.getEventsBasedOnSessions(this.props.sessions, this.props.calendars, this.props.app, this.onSelect, true);
      this.setState({ events });
    }
  }
  //Actions
    //Calendar events
  onSelect(sessionID) {
    if (this.props.onSelect) this.props.onSelect(sessionID);
  }
  //UI
  render() {
    return (
      <>
        <Row type='flex' justify='start' className='monthlySessionContainer'>
          <DragAndDropCalendar className='monthlyCalendar' localizer={CalendarLocalizer} events={this.state.events || []}
            draggableAccessor={'dragEnabledNo'} selectable={false} resizable={false}
            defaultView={Views.MONTH} views={[Views.MONTH]} toolbar={false}
            onNavigate={() => { }} popup
            formats={{
              // timeGutterFormat: 'HH:mm',
              eventTimeRangeFormat: ({ start, end }, culture, localizer) => {
                return localizer.format(start, 'hh:mm A') + ' - ' + localizer.format(end, 'hh:mm A');
              }
            }}
            date={this.props.from.toDate()}
            components={{ eventWrapper: this.props.app.isAdmin() ? CommonSessionCalendarEvent : CommonStudentSessionCalendarEvent }}
          />
        </Row>
      </>
    )
  }
}
