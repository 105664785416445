import React from 'react';
import ReactDOM from 'react-dom';
import { GridLoader } from 'react-spinners';
import '../../assets/stylesheets/CommonLoadingView.css';
//

function Loader({ loadingClass }) {
  return (
    <div className={loadingClass}>
      <div className='loadingWheel'>
        <GridLoader sizeUnit="px" size={20} color='#dedede'
                    loading={true}/>
      </div>
    </div>
  );
}

export default function CommonLoadingView({ isLoading, isFixed, usePortal }) {
  const loadingClass = `${isLoading ? '' : 'hide '}loading${isFixed != undefined ? ' loadingLogin' : ''}`;

  if (usePortal) {
    return ReactDOM.createPortal(
      <Loader loadingClass={loadingClass} />,
      document.getElementById('loader-container'),
    );
  }

  return (
    <Loader loadingClass={loadingClass} />
  );
}
