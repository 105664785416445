import React from "react";
import autoBind from "react-autobind";
import {
  Select,
  Form,
  Radio,
  InputNumber,
  Table,
  Divider,
  Button,
  Row,
  Typography,
  Tooltip,
  message,
  PageHeader,
  Layout,
  Popover,
  Checkbox,
} from "antd";
import { DeleteOutlined, QuestionCircleFilled } from "@ant-design/icons";
//
import CustomComponent from "../../../components/CustomComponent";
import Utils from "../../../components/Utils";
//
import Globals from "../../../config/Globals";
//
import "../../../assets/stylesheets/AdminPrices.less";
import CommonLoadingView from "../../commonComponents/CommonLoadingView";
import CommonPopoversContent from "../../commonComponents/Forms/_CommonPopoversContent";
import WhiteBox from "../../commonComponents/WhiteBox";
//
const { Option } = Select;
//
const OptionalPricesRules = [
  { validator: (rule, value) => {
    return new Promise((resolve, reject) => {
      if (value > Globals.LICENSES_PRICE_ABOVE) reject(`Must be lower than ${Globals.LICENSES_PRICE_ABOVE}`);
      else resolve();
    })
  }}
];
const PricesRules = OptionalPricesRules.concat([
  { required: true, message: "This field is required"}, 
  { validator: (rule, value) => {
    return new Promise((resolve, reject) => {
      if (value <= 0 || isNaN(value)) reject(`Must be positive valid number`);
      else resolve();
    })
  }}
]);
//
export default class AdminPricesView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    const tenantConfig = this.props.app.sharedCache().getTenantConfig();
    this.state = {
      isLoading: false,
      selectedProduct: null,
      products: props.app.sharedCache().products,
      orgModDisabled: tenantConfig.orgModDisabled,
      //form stuff
      bulkPrice: [],
      userPrice: null,
      promotions: [],
      discountPolicy: {},
      hideInPurchaseDialog: false,
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    await this.props.app.sharedCache().loadOrganizationCUs();
  }

  //Actions
  async handleCancel() {
    this.setState({
      selectedProduct: null,
      bulkPrice: [],
      promotions: [],
      userPrice: null,
      discountPolicy: null,
      hideInPurchaseDialog: null,
    });
  }
  async handleSelectProduct(productID) {
    await this.handleCancel();
    const product = this.state.products.find((product) => product.id === productID);
    //
    if (product) {
      if (!product.bulkPrice) product.bulkPrice = [];
      if (!product.promotions) product.promotions = [];
      //Add above price
      const hasAbovePrice = product.bulkPrice.find(price => (price.numLicenses === Globals.LICENSES_PRICE_ABOVE));
      if (!hasAbovePrice && !this.state.orgModDisabled) product.bulkPrice.push({ numLicenses: Globals.LICENSES_PRICE_ABOVE, discountedPrice: product.price });
      //
      this.setState({
        selectedProduct: product,
        bulkPrice: product.bulkPrice,
        userPrice: product.price,
        promotions: product.promotions,
        discountPolicy: product.discountPolicy,
        hideInPurchaseDialog: product.hideInPurchaseDialog,
      });
    }
  }
  async handleSavePrices() {
    //Validate
    const data = await this.form.validateFields(this.state.orgModDisabled ? [] : ['upperValue']);
    if (!data) return;
    //
    await this.handleDiscountPolicy();
    //
    const {
      selectedProduct,
      userPrice,
      bulkPrice,
      promotions,
      discountPolicy,
      hideInPurchaseDialog,
    } = this.state;
    if (userPrice < 0 || bulkPrice.some((price) => price.discountedPrice < 0)) {
      message.error("Prices should be greater than zero.");
      return;
    }
    //Start
    this.startLoading();
    //
    const newProductObject = {
      ...selectedProduct,
      price: userPrice,
      bulkPrice,
      promotions,
      discountPolicy,
      hideInPurchaseDialog,
    };
    newProductObject.bulkPrice = newProductObject.bulkPrice.sort(
      (a, b) => a.numLicenses - b.numLicenses
    );
    //Add if item is available and !orgModDisabled
    if (
      newProductObject.bulkPrice[newProductObject.bulkPrice.length - 1] &&
      !this.state.orgModDisabled
    )
      newProductObject.bulkPrice[
        newProductObject.bulkPrice.length - 1
      ].discountedPrice = data.upperValue;
    //Make request
    const resp = await this.props.app.license.product.updateProduct(
      newProductObject
    );
    if (resp.statusCode == 200 && resp.body) {
      message.success("Price successfully updated");
      await this.props.app.reloadProducts();
      this.setState({
        products: this.props.app.sharedCache().products,
        isLoading: false,
      });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  // User Price
  async handleUserPrice(userPrice) {
    if (userPrice >= 0) this.setState({ userPrice: userPrice });
  }
  async handleHideInPurchaseDialogChange(e) {
    const { checked } = e.target;
    this.setState({ hideInPurchaseDialog: checked });
  }
  //Bulk price
  async handleAddBulkPrice() {
    const data = await this.form.validateFields(['numLicenses','discountedPrice']);
    if (!data) return;
    this.setState({
      bulkPrice: this.state.bulkPrice.concat([
        { numLicenses: data.numLicenses, discountedPrice: data.discountedPrice }
      ])
    });
  }
  handleRemoveBulkPrice(price) {
    this.setState({ 
      bulkPrice: this.state.bulkPrice.filter((savedPrice) => (
        !(price.numLicenses === savedPrice.numLicenses && price.discountedPrice === savedPrice.discountedPrice)
      ))
    });
  }
  //Promotion price
  async handleAddPromotionPrice() {
    const data = await this.form.validateFields(['type', 'durationInDays', 'discountedPricePercentage', 'discountedPricePromotion']);
    if (!data) return;
    this.setState({
      promotions: this.state.promotions.concat([{ 
        type: data.type, durationInDays: data.durationInDays,
        discountedPrice: data.discountedPricePercentage && data.discountedPricePercentage > 0 ? undefined : data.discountedPricePromotion,
        discountedPricePercentage: data.discountedPricePercentage && data.discountedPricePercentage > 0 ? data.discountedPricePercentage / 100.0 : undefined,
      }])
    });
  }
  handleRemovePromotionPrice(item) {
    this.setState({ 
      promotions: this.state.promotions.filter((promotion) => (
        !(item.type === promotion.type && item.discountedPrice == promotion.discountedPrice && 
          item.discountedPricePercentage == promotion.discountedPricePercentage &&
          item.durationInDays == promotion.durationInDays)
      ))
    });
  }
  // discountPolicy
  async handleDiscountPolicy() {
    const data = await this.form.validateFields(['employerCUs', 'discountPercent']);
    if (!data) return;
    this.setState({
      discountPolicy: {
        employerCUs: data.employerCUs,
        discountPercent: data.discountPercent && data.discountPercent > 0 ? data.discountPercent / 100.0 : 0
      }
    });
  }
  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader className="pageHeader" title="Prices" onBack={() => this.props.app.urlManager.pushBack()} />
          {this._renderProductSelectionRow()}
          {this.state.selectedProduct && this._renderProductForm()}
      </Layout.Content>
    );
  }

  /* Private UI */
  _renderProductSelectionRow() {
    return (
      <WhiteBox>
        <Row type="flex" justify="start" align="middle">
          <Typography.Text strong style={{ marginRight: 10 }}>Products:</Typography.Text>
          <Select value={this.state.selectedProduct?.id} onChange={this.handleSelectProduct} style={{width: '400px' }}
                  placeholder="Select a product">
            {this.state.products.map((product) => (
              <Option key={product.id} value={product.id}>
                {product.description}
              </Option>
            ))}
          </Select>
        </Row>
      </WhiteBox>
    );
  }

  //Product form
  _renderProductForm() {
    return (
      <WhiteBox>
        <Form layout="vertical" {... Utils.propagateRef(this, 'form')}>
          <div className="price-form">
            {this._renderProductFormActions()}
            <Divider/>
            <div className="content">
              {this._renderSingleUsagePrice()}
              {this._renderOrganizationUsagePrice()}
            </div>
            <div className="content">
              <div>{' '}</div>
              {this._renderPromotionsUsagePrice()}
            </div>
            <div className="content">
              <div>{' '}</div>
              {this._renderNoCostEligibility()}
            </div>
            <Divider/>
            {this._renderProductFormActions()}
          </div>
        </Form>
      </WhiteBox>
    );
  }
  _renderProductFormActions() {
    const { userPrice } = this.state;
    return (
      <Row type="flex" justify="end">
        <Button type="primary" onClick={this.handleSavePrices} disabled={(!userPrice && userPrice != 0)}>
          Save
        </Button> {' '}
        <Button type="link" onClick={this.handleCancel}> Cancel </Button>
      </Row>
    );
  }
  /* Subforms */
  _renderSingleUsagePrice() {
    const { userPrice, hideInPurchaseDialog } = this.state;
    return (
      <div className="user-price">
        <h2>User price</h2>
        <Form.Item
          name="hideInPurchaseDialog"
          valuePropName="checked"
          initialValue={hideInPurchaseDialog}
        >
          <Checkbox
            id="hideInPurchaseDialog"
            onChange={this.handleHideInPurchaseDialogChange}
            allowClear
          >
            Prevent purchases of this product
          </Checkbox>
        </Form.Item>
        <Form.Item initialValue={userPrice} style={{ width: 120 }} rules={PricesRules}>
          <InputNumber
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            value={userPrice}
            onChange={this.handleUserPrice.bind(this)}
          />
        </Form.Item>
        <span>* Standard price charged to students</span>
      </div>
    );
  }
  _renderOrganizationUsagePrice() {
    const { bulkPrice, orgModDisabled } = this.state;
    const data = bulkPrice.sort((a, b) => a.numLicenses - b.numLicenses);
    const props = { rowKey: (p) => JSON.stringify(p), locale: { emptyText: 'No prices found' } };
    const columns = [
      {
        title: 'Licenses (up to)', key: 'numLicenses',
        render: (item) => {
          if (item.numLicenses !== Globals.LICENSES_PRICE_ABOVE) return item.numLicenses;
          const { bulkPrice } = this.state;
          let aboveNumber = 0;
          //
          const lastPrice = bulkPrice[bulkPrice.length - 2];
          if (lastPrice) aboveNumber = lastPrice.numLicenses;
          else aboveNumber = 0;
          //
          return <strong>Above {aboveNumber}</strong>;
        },
      },
      {
        title: 'Price', key: 'discountedPrice',
        render: (item) => (
          item.numLicenses !== Globals.LICENSES_PRICE_ABOVE
            ? `$${Utils.toCurrencyFormat(item.discountedPrice)}` : (
              <Form.Item label="Price" style={{ width: 100 }} name="upperValue" initialValue={item.discountedPrice}
                rules={PricesRules.concat([{ validator: (rule, value) => {
                  const hasBulkPrice = (bulkPrice.length - 1) > 0;
                  return new Promise((resolve, reject) => {
                    const smallestValue = bulkPrice.reduce((acc, val) => (acc && acc < val.discountedPrice) || val.discountedPrice == item.discountedPrice ? acc : val.discountedPrice, null);
                    if (value >= smallestValue && hasBulkPrice) reject(`Must be smaller than other prices!`);
                    else resolve();
                  })
                }}])}>
                <InputNumber 
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  disabled={orgModDisabled}
                />
              </Form.Item>
            )
        ),
      },
      {
        title: '', width: '15%', key: 'Actions',
        render: item => (
          item.numLicenses !== Globals.LICENSES_PRICE_ABOVE ? (
            <span className='tableButtonContainer'>
              <Tooltip placement="bottomLeft" title="Remove price">
                <Button variant="none" icon={<DeleteOutlined />} shape="circle"
                        onClick={this.handleRemoveBulkPrice.bind(this, item)}/>
              </Tooltip>
            </span>
          ) : null
        ),
      }
    ];
    //
    return (
      <div className="org-price">
        <h2>Organization prices</h2>
        <div className="org-price-form">
          <Form.Item initialValue={0} label="Licenses (up to)" name="numLicenses"
            rules={PricesRules.concat([{ validator: (rule, value) => {
                  return new Promise((resolve, reject) => {
                    if (this.state.bulkPrice.find((x) => x.numLicenses == value)) reject(`Entry already exists!`);
                    else resolve();
                  })
                }}])}>
            <InputNumber disabled={orgModDisabled}/>
          </Form.Item>
          <Form.Item initialValue={0} label="Price" style={{ marginLeft: 10 }} name="discountedPrice" rules={PricesRules}>
            <InputNumber
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              disabled={orgModDisabled}/>
          </Form.Item>
          <Button type="primary" onClick={this.handleAddBulkPrice} disabled={orgModDisabled}>+</Button>
        </div>
        <Table className="adminSearchUsersTable" {...props} pagination={false}
               columns={columns} dataSource={data || []} />
      </div>
    );
  }
  _renderPromotionsUsagePrice() {
    const { promotions } = this.state;
    const props = { rowKey: (props) => JSON.stringify(props), locale: { emptyText: 'No promotions found' } };
    const columns = [
      {
        title: 'Type', key: 'type',
        render: (item) => Utils.capitalizeString(item.type.replace(/_/g, ' ').toLowerCase())
      },
      {
        title: 'Discount', key: 'discountedPrice',
        render: (item) => ( item.discountedPrice ? `$${Utils.toCurrencyFormat(item.discountedPrice)}` : '-' )
      },
      {
        title: 'Discount (%)', key: 'discountedPricePercentage',
        render: (item) => ( item.discountedPricePercentage ? `${(item.discountedPricePercentage * 100.0).toFixed(1)} %` : '-' )
      },
      {
        title: 'Duration', key: 'durationInDays',
        render: (item) => ( item.durationInDays ? `${(item.durationInDays + ' days')}` : ' ' )
      },
      {
        title: '', width: '15%', key: 'Actions',
        render: item => <span className='tableButtonContainer'>
              <Tooltip placement="bottomLeft" title="Remove price">
                <Button variant="none" icon={<DeleteOutlined />} shape="circle"
                        onClick={this.handleRemovePromotionPrice.bind(this, item)}/>
              </Tooltip>
            </span>,
      }
    ];
    //
    return (
      <div className="promotion-price">
        <h2>Promotions</h2>
        <div className="promotion-price-form">
          <Row type='flex' align="middle" >
            <Form.Item initialValue={Globals.PromotionTypes.AFTER_FIRST_PURCHASE} label="Promotion type" name="type">
              <Radio.Group onChange={this.handleTypeChange} value={this.state.optionType}>
                <Radio value={Globals.PromotionTypes.AFTER_FIRST_PURCHASE}>First Purchase</Radio>
              </Radio.Group>
          </Form.Item>
          <Form.Item initialValue={0} label="Discount (fixed)" style={{ marginLeft: 10, marginRight: 10, maxWidth: 100 }} name="discountedPricePromotion" 
                     rules={OptionalPricesRules}>
            <InputNumber
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
          </Form.Item>
          <Typography.Text style={{marginTop: 14}}>{' OR '}</Typography.Text>
          <Form.Item initialValue={0} label="Discount %" style={{ marginLeft: 10, maxWidth: 100 }} name="discountedPricePercentage" rules={OptionalPricesRules}>
            <InputNumber
              formatter={value => `${value} %`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\%\s?|(,*)/g, '')}/>
          </Form.Item>
          <Form.Item initialValue={0} label="Duration" style={{ marginLeft: 10, maxWidth: 100 }} name="durationInDays" 
                     rules={PricesRules.concat([{ validator: (rule, value) => {
                      return new Promise((resolve, reject) => {
                        if (this.state.promotions.find((x) => x.durationInDays == value)) reject(`Entry already exists!`);
                        else resolve();
                      })
                  }}])}>
            <InputNumber
              formatter={value => `${value} days`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\ days\s?|(,*)/g, '')}/>
          </Form.Item>
          <Button style={{marginTop: 13, marginLeft: 10}} type="primary" onClick={this.handleAddPromotionPrice}>+</Button>
          </Row>
        </div>
        <Table className="promotionsTable" {...props} pagination={false} columns={columns} dataSource={promotions || []} />
      </div>
    );
  }
  _renderNoCostEligibility() {
    const cus = (this.props.app.sharedCache().getOrganizationCUs() || []);
    const employerCUs = this.state.discountPolicy?.employerCUs || [];
    const discountPercent = this.state.discountPolicy?.discountPercent ? (this.state.discountPolicy.discountPercent * 100.0).toFixed(0) : 0;
    return (
      <div>
        <h2>No Cost Eligibility</h2>
        <div className="org-price-form">
          <Row type='flex' align="middle" >
            <Form.Item name="employerCUs" initialValue={employerCUs} style={{width: 500}} label={<>
              Classification Units
                <Popover title='Classification Units' content={CommonPopoversContent.renderClassificationUnitsPopoverContent()}>
                  <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                </Popover>
              </>}
            >
              <Select showSearch mode="tags" options={cus.map(cu => ({ label: `${cu.id}`, value: cu.id }))} optionFilterProp="label"/>
            </Form.Item>
            <Form.Item name="discountPercent" initialValue={discountPercent} style={{ marginLeft: 10, maxWidth: 120 }} label={<>
              Discount %
                <Popover title='Discount %' content={CommonPopoversContent.renderDiscountPercentagePopoverContent()}>
                  <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                </Popover>
              </>}
            >
              <InputNumber
                formatter={value => `${value} %`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\%\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Row>
        </div>
      </div>
    );
  }
}
