import React, { Fragment } from "react";
import autoBind from 'react-autobind';
import moment from 'moment';
import { Popover, Row, Descriptions, Tag, Col, Button } from 'antd';
//
import CustomComponent from "../../../components/CustomComponent";
//
import Utils from "../../../components/Utils";
import Globals from "../../../config/Globals";
import { EditOutlined } from "@ant-design/icons";
//props are: session, app, studentMode, onSelect
export default class CommonSessionPopover extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.isLocked = this.props.session?.state == Globals.Session_State.LOCKED;
    this.state = {
      venues: [], cities: [],
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    this.fetchData();
  }

  async fetchData() {
    this.startLoading();
    const resp = await this._loadAll();
    if (resp) this.setState({ ...resp, isLoading: false });
    else this.stopLoading();
  }
  //UI
  render() {
    return (
      <Popover title={this._getPopoverTitle()} placement='bottom' trigger='click' content={this._getPopoverContent()}>
        {this.props.children}
      </Popover>
    );
  }
  /* private UI */
  _getPopoverTitle() {
    const { studentMode, onSelect, session } = this.props;
    let fullFlag = false;
    let availableSeats = session.capacity -  (session.reservedCapacity || 0) - session.enrolmentCount;
    if(session.capacity != -1) fullFlag = availableSeats <= 0 && studentMode;
    const isEnrolmentAllowed = !this.props.session.allowEnrolmentUntil ||
                               (this.props.session.allowEnrolmentUntil && this.props.session.allowEnrolmentUntil > Date.now()) ||
                               this.props.app.isAdmin();
    return (
      <Row type='flex' justify='space-between' align='middle'>
        <Col>Session Information</Col>
        {((!this.isLocked && isEnrolmentAllowed) || !studentMode) && <Col><Button size='small' type={studentMode ? 'primary' : 'secondary'} style={{ lineHeight: '.6', padding: '8px', height: '30px' }} onClick={onSelect} disabled={fullFlag}> {studentMode ? 'Select' : <EditOutlined/>} </Button></Col>}
      </Row>
    )
  }
  _getPopoverContent() {
    const { session, studentMode } = this.props;
    const course = this.props.app.sharedCache().getCourseByID(session?.courseID);
    const venue = this.props.app.sharedCache().getVenueByID(session?.venueID);
    const city = this.props.app.sharedCache().getCityByID(venue?.cityID);
    let availableSeats = session.capacity == -1 ? 100 : session.capacity -  (session.reservedCapacity || 0) - session.enrolmentCount
    //let availableSeats = session.capacity -  (session.reservedCapacity || 0) - session.enrolmentCount
    if (availableSeats < 0) availableSeats = 0;
    if (session?.id == 'avEvent') {
      session.startDate = [session.event.startDate];
      session.endDate = [session.event.endDate];
    }
    return (
      <>
        {availableSeats === 0 && (
          <Tag color="red" span={2} style={{ marginBottom: '4px'}}>Session Full</Tag>
        )}
        <Descriptions size='small' column={2} style={{ width: '440px' }}>
        {this.props.session.allowEnrolmentUntil && this.props.session.allowEnrolmentUntil < Date.now() &&
          <Descriptions.Item span={2} label="Warning"><b>Enrolments for this session were closed on {Utils.getDateOnCardFormatByTimestamp(this.props.session.allowEnrolmentUntil)}</b></Descriptions.Item>}
        {this.props.session.allowEnrolmentUntil && this.props.session.allowEnrolmentUntil > Date.now() &&
          <Descriptions.Item span={2} label="Warning"><b>Enrolments for this session will be closed on {Utils.getDateOnCardFormatByTimestamp(this.props.session.allowEnrolmentUntil)}</b></Descriptions.Item>}
        {this.isLocked && <Descriptions.Item span={2} label="State"><b>{Globals.Session_State.LOCKED}</b></Descriptions.Item>}
        {this.isLocked && session?.lockReason && <Descriptions.Item span={2} label="Lock Reason">{session?.lockReason}</Descriptions.Item>}
        {!studentMode && <Descriptions.Item span={2} label="Internal Name">{session?.internalName}</Descriptions.Item>}
        <Descriptions.Item span={2} label="Display Name">{session?.name}</Descriptions.Item>
        <Descriptions.Item span={2} label="Course">{course?.description}</Descriptions.Item>
        <Descriptions.Item span={2} label="Type">{Globals.getTemplateTypeIcon(session?.type, true)}</Descriptions.Item>
        {!studentMode && <Descriptions.Item span={2} label="State"><Tag color={Globals.getColorBySessionState(session?.state)}>{Globals.getTextBySessionState(session?.state)}</Tag></Descriptions.Item>}
        {venue && (
          <Descriptions.Item span={2} label="Location">{venue.address?.street1}{city ? `, ${city.name}` : ''}</Descriptions.Item>
        )}
        <Descriptions.Item span={!studentMode ? 1 : 2} label="Capacity">{session?.capacity < 0 ? 'Unlimited' : session?.capacity}</Descriptions.Item>
        {!studentMode && <Descriptions.Item span={1} label="Published">{session?.isEventPublished ? 'Yes' : 'No'} </Descriptions.Item>}
        <Descriptions.Item span={2} label="Available Seats">{availableSeats}</Descriptions.Item>
        <Descriptions.Item span={2} label="Dates">{(session?.startDate || []).map((s, i) => {
          return <Fragment key={i}> {moment(new Date(s)).format(`${Globals.DefaultDateFormat} hh:mm A`)} - {moment(new Date(session?.endDate?.[i])).format('hh:mm A')}<br></br></Fragment>;
        })} </Descriptions.Item>
        {!studentMode && <Descriptions.Item span={2} label="Created On">{Utils.getDateAndTimeOnUIFormatByTimestamp(session?.createdOn)}</Descriptions.Item>}
       </Descriptions>
      </>
    );
  }
  /* private API */
  async _loadAll() {
    const resp = await Promise.all([
      this.props.app.sharedCache().getCities(),
      this.props.app.sharedCache().getVenues()
    ]);
    if (!resp[0]) {
      this.props.app.alertController.showErrorAlert(null, 'Could not load cities!');
      return false;
    }
    if (!resp[1]) {
      this.props.app.alertController.showErrorAlert(null, 'Could not load venues!');
      return false;
    } return { cities: resp[0], venues: resp[1] };
  }
}
