import { Component } from 'react';
import autoBind from 'react-autobind';
//
export default class CustomComponent extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this._isMounted = true;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Util
  startLoading() {
    this.setState({ isLoading: true });
  }

  stopLoading(setState) {
    if (setState == undefined) setState = true;
    this.state.isLoading = false;
    if (setState) this.setState(this.state);
  }
}
