import React from "react";
import autoBind from 'react-autobind';
import { Form, Divider, Row, Col, Layout, Table, Tag, Switch, Tooltip, Button, message } from 'antd';
import { CopyOutlined, CheckOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import { CopyToClipboard } from 'react-copy-to-clipboard';
//
import Utils from "../../../components/Utils";
import UtilsSession from "../../../components/UtilsSession";
import Globals from "../../../config/Globals";
//
import CommonOrgReferralTable from "../CommonOrgReferralTable";

//props: app, courses, venues
export default class CommonSessionReadForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { session: null, sessionIDCopied: false, };
  }
  //Public
  setFieldsValue(session) {
    this.setState({ session }, this._loadReferralNames);
  }

  handleSesssionIDCopy() {
    this.setState({ sessionIDCopied: true }, () => {
      message.success('Copied to clipboard');
      setTimeout(() => {
        if (this._isMounted) this.setState({ sessionIDCopied: false });
      }, 3500);
    });
  }

  //UI
  render() { return this._renderMainForm(); }

  /* Private UI */
  _renderMainForm() {
    const isSchedulable = !(this.state.session?.type == Globals.Session_Type.ONLINE_EXT || this.state.session?.type == Globals.Session_Type.ONLINE);
    const isOnlineExt = (this.state.session?.type == Globals.Session_Type.ONLINE_EXT || this.state.session?.type == Globals.Session_Type.SCHEDULED_ONLINE_EXT);
    const isVILT = (this.state.session?.type == Globals.Session_Type.SCHEDULED_VILT);
    const isWebinar = (this.state.session?.type == Globals.Session_Type.SCHEDULED_WEBINAR);
    const isPresential = (this.state.session?.type == Globals.Session_Type.SCHEDULED_PRESENTIAL);
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Row>
          <Col span={12}> <Form.Item label="Internal name"> {this.state.session?.internalName}
            <Tooltip placement="bottomLeft" title='Copy ID'>
              <CopyToClipboard text={this.state.session?.id} onCopy={this.handleSesssionIDCopy.bind(this, this.state.session?.id)}>
                <Button style={{ marginLeft: 5 }} variant="none" shape="circle">
                  {!this.state.sessionIDCopied ? <CopyOutlined /> : <CheckOutlined />}
                </Button>
              </CopyToClipboard>
            </Tooltip>
          </Form.Item>
          </Col>
          <Col span={4} offset={1}> <Form.Item label="State"> <Tag color={Globals.getColorBySessionState(this.state.session?.state)}>{Globals.getTextBySessionState(this.state.session?.state)}</Tag> </Form.Item> </Col>
        </Row>
        <Row>
          <Col span={7}>
            <Form.Item label={'Course'}> {(this.props.courses || []).find((c) => this.state.session?.courseID == c.id)?.description} </Form.Item>
          </Col>
          <Col span={15} offset={1}> <Form.Item label="Display Name"> {this.state.session?.name} </Form.Item> </Col>
        </Row>
        <Row type="flex" justify="start" align='middle'>
          <Col> <Form.Item label='Session Type'> {Globals.getTemplateTypeIcon(this.state.session?.type, true)} </Form.Item> </Col>
          <Col offset={1}> <Form.Item label='Created On'> {Utils.getDateAndTimeOnUIFormatByTimestamp(this.state.session?.createdOn)} </Form.Item> </Col>
        </Row>
        {this._renderGeneralSubform(isSchedulable)}
        {this._renderMaterialsSubform()}
        {this._renderContentSubform()}
        {isSchedulable && this._renderSchedulableSubform(isPresential)}
        {(isOnlineExt || isWebinar || isVILT) && this._renderOnlineIntegrationSubform()}
        {this._renderNotificationsSubform()}
      </Form>
    );
  }
  /* Subforms */
  _renderGeneralSubform(isSchedulable) {
    const cancelationPolicies = this.props.app.sharedCache().getTenantConfig().customer?.cancelationPolicies || [];
    const cancelationPolicy = (cancelationPolicies || []).find((c) => this.state.session?.cancelationPolicyID == c.id);

    return (
      <>
        <Divider />
        <Row>
          <Col span={4}>
            <Form.Item label='Capacity'> {!this.state.session?.capacity || this.state.session?.capacity < 0 ? 'Unlimited' : this.state.session?.capacity} </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label='Reserved Capacity'> {this.state.session?.reservedCapacity} </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label='Minimum Capacity'> {this.state.session?.minCapacity} </Form.Item>
          </Col>
          <Col span={3} offset={1}>
            <Form.Item label='Release Type'> {Utils.capitalizeString((this.state.session?.releaseType || '').toLowerCase())} </Form.Item>
          </Col>
          <Col span={2} offset={1}>
            <Form.Item label='Cancelation Policy'> {cancelationPolicy ? cancelationPolicy.description : '-'} </Form.Item>
          </Col>
          <Col span={2} offset={1}>
            <Form.Item label='Calendar Color'> <div style={{ background: this.state.session?.calendarColor, marginLeft: 20, width: 32, height: 32 }}> </div> </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <Form.Item label='Requires Instructor'> {this.state.session?.requiresInstructor ? 'Yes' : 'No'} </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label='Requires Admin Review'> {this.state.session?.requiresAdminReview ? 'Yes' : 'No'} </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label='Ask for Referrals?'> {this.state.session?.askForReferrals ? 'Yes' : 'No'} </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label='Private Session'> {this.state.session?.isPrivate ? 'Yes' : 'No'} </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label='Prevent Enrolment After'>{this.state.session?.allowEnrolmentUntil ? Utils.getDateOnUIFormatByTimestamp(this.state.session?.allowEnrolmentUntil) : '-'}</Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <Form.Item label='Charge Deferred'> {this.state.session?.chargeDeferred ? 'Yes' : 'No'} </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label='Eligible for No Cost'> {this.state.session?.isEligibleForNoCost ? 'Yes' : 'No'} </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label='Confirm info at enrolment'> {this.state.session?.confirmInfoOnEnrolment ? 'Yes' : 'No'} </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label='Optional Auto Enrol In Session'> {this.state.session?.autoEnrolOnSession} </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderMaterialsSubform() {
    return (
      <>
        <Divider>Materials</Divider>
        <Row>
          <Col span={4}>
            <Form.Item label='Course Material'> {Utils.capitalizeString((this.state.session?.hasMaterial || '').toLowerCase())} </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label='Charge for Course Materials'>
              {this.state.session?.chargeForMaterials ? 'Yes' : 'No'}
            </Form.Item>
          </Col>
          {this.state.session?.hasMaterial != Globals.Session_HasMaterial.NONE && <Col span={4} offset={1}>
            <Form.Item label='Course Material Product'> {this.state.session?.courseMaterialProductID} </Form.Item>
          </Col>}
          {this.state.session?.hasMaterial != Globals.Session_HasMaterial.NONE && <Col span={3} offset={1}>
            <Form.Item label='Course Material Shipped'> {this.state.session?.isCourseMaterialShipped ? 'Yes' : 'No'} </Form.Item>
          </Col>}
          {this.state.session?.hasMaterial != Globals.Session_HasMaterial.NONE && <Col span={2} offset={1}>
            <Form.Item label='Material Sent'> {this.state.session?.isMaterialSent ? 'Yes' : 'No'} </Form.Item>
          </Col>}
          {this.state.session?.hasMaterial != Globals.Session_HasMaterial.NONE && <Col span={2} offset={1}>
            <Form.Item label='Material Information'> {this.state.session?.materialShippingInfo} </Form.Item>
          </Col>}
        </Row>
      </>
    );
  }
  _renderContentSubform() {
    return (
      <>
        <Divider />
        {this.state.session?.askForReferrals && <Row>
          <Col span={4}>
            <Form.Item label='Referral Amount'> {this.state.session.isReferralPercentage ? `${parseFloat(this.state.session?.referralAmount).toFixed(2)}% of total price` : Utils.safelyFixCurrency(this.state.session?.referralAmount || 0)} </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label='Is Referral using percentage of amount?'> {this.state.session?.isReferralPercentage ? 'Yes' : 'No'} </Form.Item>
          </Col>
          <Col span={15}>
            <CommonOrgReferralTable ids={this.state.session?.possibleReferrals || []} orgs={this.state.referralOrgs} />
          </Col>
        </Row>}
        <Divider />
        <Row>
          <Col span={24}>
            <Form.Item label='Overview'> <div style={{ marginTop: '1em' }}><ReactMarkdown children={this.state.session?.overview || ''} linkTarget='_blank' /></div> </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label='Resources'> <div style={{ marginTop: '1em' }}><ReactMarkdown children={this.state.session?.content?.sections?.[0]?.content || ''} linkTarget='_blank' /></div> </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <Form.Item label='Internal Notes'> <div style={{ marginTop: '1em' }}><ReactMarkdown children={this.state.session?.internalNotes || ''} /></div> </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label='Instructor Notes'> <div style={{ marginTop: '1em' }}><ReactMarkdown children={this.state.session?.instructorNotes || ''} /></div> </Form.Item>
          </Col>
        </Row>
        {this.state.session?.launchSchedule?.length > 0 && <Row>
          <Col span={24}>
            <Form.Item label='Dates'>
              {this.state.session.launchSchedule.map((schedule, index) => (
                <div key={index}>
                  <strong>{Globals.WeekDays[schedule.dayOfWeek]}</strong>, from {schedule.startTime} to {schedule.endTime}
                </div>
              ))}</Form.Item>
          </Col>
        </Row>}
        <Row>
          <Col span={24}>
            <Form.Item label='Special Instructions'> <div style={{ marginTop: '1em' }}><ReactMarkdown children={this.state.session?.specialInstructions || ''} /></div> </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label='Reason for session lock'> <div style={{ marginTop: '1em' }}><ReactMarkdown children={this.state.session?.lockReason || ''} /></div> </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderSchedulableSubform(isPresential) {
    const { session } = this.state;
    const { hours, minutes } = UtilsSession.convertMinutesToHoursAndMinutes(session?.duration || 0);

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    return (
      <>
        <Divider>Date & Time</Divider>
        <Row>
          <Col span={4}>
            <Form.Item label="Publish to Public Calendar">
              <Switch onChange={this.props.onPublishToogle} checked={!!this.state.session?.isEventPublished} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={3}>
            <Form.Item label='Duration'>{`${formattedHours}:${formattedMinutes}`}</Form.Item>
          </Col>
          {isPresential && <Col span={8} offset={1}>
            <Form.Item label='Venue'>
              {this._getCityAndVenueNames()}
            </Form.Item>
          </Col>}
          {isPresential && <Col span={11} offset={1}>
            <Form.Item label="Venue Complement"> {this.state.session?.venueComplement} </Form.Item>
          </Col>}
          {!isPresential && <Col span={6} offset={1}> {this._renderDatesRow()} </Col>}
        </Row>
        {isPresential && this._renderDatesRow()}
      </>
    );
  }
  _getCityAndVenueNames() {
    const venue = this.props.app.sharedCache().getVenueByID(this.state.session?.venueID);
    const city = this.props.app.sharedCache().getCityByID(venue?.cityID);
    const cityName = city ? `${city.name}: ` : '';

    return `${cityName}${venue?.name || '-'}`;
  }
  _renderOnlineIntegrationSubform() {
    const allowedOnlineCourseProviders = this.props.app.sharedCache().getTenantConfig().customer?.allowedOnlineCourseProviders || [];
    const provider = (allowedOnlineCourseProviders || []).find((c) => this.state.session?.courseProviderID == c.id);
    const isVILT = (this.state.session?.type == Globals.Session_Type.SCHEDULED_VILT);
    const isWebinar = (this.state.session?.type == Globals.Session_Type.SCHEDULED_WEBINAR);

    return (
      <>
        <Divider>Online Integration</Divider>
        <Row>
          {!(isWebinar || isVILT) && (
            <Col span={4}>
              <Form.Item label='Single Quizz Attempt'> {this.state.session?.considerFailures ? 'Yes' : 'No'} </Form.Item>
            </Col>
          )}
          <Col span={4} offset={isWebinar || isVILT ? 0 : 1}>
            <Form.Item label='Online Access Period'> {this.state.session?.onlineAccessPeriod || 0} Minutes </Form.Item>
          </Col>
          {!(isWebinar || isVILT) && (
            <>
              <Col span={8} offset={1}>
                <Form.Item label='Course Provider'> {provider ? `${provider.id} (${provider.type})` : '-'} </Form.Item>
              </Col>
              <Col span={4} offset={1}>
                <Form.Item label='Online Course Template ID'> {this.state.session?.providerCourseTemplateID} </Form.Item>
              </Col>
            </>
          )}
          {(isWebinar || isVILT) && (
            <Col span={19} offset={1}>
              <Form.Item label='Launch URL'>
                <div style={{ marginTop: '1em' }}><ReactMarkdown children={this.state.session?.accessLink || ''} /></div>
              </Form.Item>
            </Col>
          )}
        </Row>
        {((this.props.app.isSysAdmin() || this.props.app.isAdmin()) && !isWebinar && !isVILT) && <Row>
          <Col span={4}>
            <Form.Item label='Online Course ID'> {this.state.session?.onlineCourseID} </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label='Online Quiz ID'> {this.state.session?.onlineQuizID} </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label='Online Grade ID'> {this.state.session?.onlineGradeID} </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label='Online Score Grade ID'> {this.state.session?.onlineScoreGradeID} </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label='Grade Check Delay'> {this.state.session?.checkGradeDelay} </Form.Item>
          </Col>
        </Row>}
      </>
    );
  }
  _renderNotificationsSubform() {
    return (
      <>
        <Divider>Notifications</Divider>
        <Row>
          <Col span={10}>
            <Form.Item label='Notification Emails'> {this.state.session?.notificationDetails?.adminEmails?.join(', ') || ''} </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label='Disabled Emails'> {this.state.session?.disabledEmails?.join(', ') || ''} </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Form.Item label='Send enrolment emails to admins?'> {this.state.session?.notificationDetails?.shouldNotifyEnrolmentToAdmins ? 'Yes' : 'No'} </Form.Item>
          </Col>
          <Col span={8} offset={1}>
            <Form.Item label='Send notifications when session becomes full?'> {this.state.session?.notificationDetails?.notifyWhenSessionFull ? 'Yes' : 'No'} </Form.Item>
          </Col>
          <Col span={8} offset={1}>
            <Form.Item label='Disable license related emails?'> {this.state.session?.disableLicenseEmails ? 'Yes' : 'No'} </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  /* private UI */
  _renderDatesRow() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Date', key: 'startDate',
        render: props => props.startDate ? `${Utils.getDateAndTimeOnUIFormatByTimestamp(props.startDate)} ${props.endDate ? `to ${Utils.getTimeInAmPmFormatFromTimestamp(props.endDate)}` : ''}` : 'N/A',
      },
    ];
    const props = {
      rowKey: 'startDate', loading: this.props.isLoading, onChange: this.handleFilterChange,
      locale: { emptyText: 'No dates added to this session yet!' },
      pagination: { pageSize: 5, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] }
    };
    const dates = this.state.session?.startDate?.map((s, i) => ({ startDate: s, endDate: this.state.session?.endDate?.[i] }));
    return (
      <Form.Item label='Dates'>
        <Table className="sessionDatesTable" columns={columns} dataSource={dates || []} {...props} />
      </Form.Item>
    );
  }

  /* private api */
  async _loadReferralNames() {
    if (!(this.state?.session?.possibleReferrals?.length > 0)) {
      this.setState({ referralOrgs: null });
      return;
    }
    let orgsResp = await this.props.app.organization.organization.searchOrganizationsByIDs({ orgIDs: this.state.session?.possibleReferrals });
    if (orgsResp.statusCode == 200 && orgsResp.body?.orgs) {
      this.setState({ referralOrgs: orgsResp.body.orgs });
    } else this.props.app.alertController.showAPIErrorAlert(null, orgsResp);
  }
}
