import React, { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

const inputStyle = {
  height: '32px',
  width: '100%',
  border: '1px solid #ccc',
  background: '#fff',
  padding: '8px',
  fontSize: '14px',
};

const MaskedInput = forwardRef((props, ref) => (
  <IMaskInput
    mask={props.mask}
    inputRef={ref}
    value={props.value}
    style={inputStyle}
    onChange={(event) => props.onChange?.(event.target.value, event.target.value)}
  />
));
MaskedInput.displayName = 'MaskedInput';

export default MaskedInput;