import React from 'react';
import autoBind from 'react-autobind';
//
import Globals from '../../../../config/Globals';
//Statuses
import PaymentRequired from './OverallStatuses/PaymentRequired';
import CourseAvailable from './OverallStatuses/CourseAvailable';
import Completed from './OverallStatuses/Completed';
import Failed from './OverallStatuses/Failed';
import Cooldown from './OverallStatuses/Cooldown';
import Locked from './OverallStatuses/Locked';
import Invalidated from './OverallStatuses/Invalidated';
import Expired from './OverallStatuses/Expired';
import Revoked from './OverallStatuses/Revoked';
import Scheduled from './OverallStatuses/Scheduled';
import ReadyToSchedule from './OverallStatuses/ReadyToSchedule';
import MissingRequirements from './OverallStatuses/MissingRequirements';
import WaitingResultRelease from './OverallStatuses/WaitingResultRelease';
import AccessDenied from './OverallStatuses/AccessDenied';
import CheckingStatus from './OverallStatuses/CheckingStatus';
//
import CourseTabWrapper from './CourseTabWrapper';

//props are: isVisible. courseSpecs, currentCourse, status, app
//certificationProcess, certificationSpecs, session, user,
//onUpdate, onLicensePurchase, onLicenseRedeem
export default class CourseTabOverallTab extends CourseTabWrapper {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  //UI
  render() {
    //Check if not visible, don't render -- Please, change root element to
    //'display: none' if you need to retain any subcomponent
    if (!this.props.isVisible) return (<></>);

    const bodyByStatus = {
      [Globals.CourseStatuses.DEFAULT]: () => null,
      [Globals.CourseStatuses.PAYMENT_REQUIRED]: this._renderPaymentRequired,
      [Globals.CourseStatuses.COURSE_AVAILABLE]: this._renderCourseAvailable,
      [Globals.CourseStatuses.COMPLETED]: this._renderCompleted,
      [Globals.CourseStatuses.FAILED]: this._renderFailed,
      [Globals.CourseStatuses.COOLDOWN]: this._renderCooldown,
      [Globals.CourseStatuses.LOCKED]: this._renderLocked,
      [Globals.CourseStatuses.INVALIDATED]: this._renderInvalidated,
      [Globals.CourseStatuses.EXPIRED]: this._renderExpired,
      [Globals.CourseStatuses.REVOKED]: this._renderRevoked,
      [Globals.CourseStatuses.SCHEDULED]: this._renderScheduled,
      [Globals.CourseStatuses.READY_TO_SCHEDULE]: this._renderReadyToSchedule,
      [Globals.CourseStatuses.MISSING_REQUIREMENTS]: this._renderMissingRequirements,
      [Globals.CourseStatuses.WAITING_RESULT_RELEASE]: this._renderWaitingResultRelease,
      [Globals.CourseStatuses.ACCESS_DENIED]: this._renderAccessDenied,
      [Globals.CourseStatuses.CHECKING_STATUS]: this._renderCheckingStatus,
    };

    const { status } = this.props;
    const { courseType } = this.props.courseSpecs;

    const currentStatus = (
      (
        status === Globals.CourseStatuses.COURSE_AVAILABLE
        || status === Globals.CourseStatuses.WAITING_RESULT_RELEASE
      ) && courseType === Globals.Course_Types_Keys.EXTERNALREQUIREMENT
        ? Globals.CourseStatuses.CHECKING_STATUS
        : status
    );
    return super.render((bodyByStatus[currentStatus] || bodyByStatus[Globals.CourseStatuses.DEFAULT])());
  }

  // Private methods
  _renderPaymentRequired() {
    return (
      <PaymentRequired app={this.props.app} courseSpecs={this.props.courseSpecs}
                       certificationProcess={this.props.certificationProcess}
                       availableKeys={this.props.availableKeys}
                       disableActions={this.props.disableActions}/>
    );
  }
  _renderCheckingStatus() {
    return <CheckingStatus />;
  }
  _renderCourseAvailable() {
    return (
      <CourseAvailable app={this.props.app} courseSpecs={this.props.courseSpecs}
                       currentCourse={this.props.currentCourse} certificationProcess={this.props.certificationProcess}
                       user={this.props.user} session={this.props.session}
                       disableActions={this.props.disableActions}/>
    );
  }
  _renderCompleted() {
    return (
      <Completed app={this.props.app} courseSpecs={this.props.courseSpecs} currentCourse={this.props.currentCourse}
                 certificationProcess={this.props.certificationProcess} session={this.props.session}
                 disableActions={this.props.disableActions}/>
    );
  }
  _renderFailed() {
    return (
      <Failed app={this.props.app} courseSpecs={this.props.courseSpecs} currentCourse={this.props.currentCourse}
              certificationProcess={this.props.certificationProcess} session={this.props.session}
              availableKeys={this.props.availableKeys}
              disableActions={this.props.disableActions}/>
    );
  }
  _renderScheduled() {
    return (
      <Scheduled app={this.props.app} courseSpecs={this.props.courseSpecs} currentCourse={this.props.currentCourse}
                 certificationProcess={this.props.certificationProcess} session={this.props.session}
                 disableActions={this.props.disableActions}/>
    );
  }
  _renderCooldown() {
    return (
      <Cooldown app={this.props.app} courseSpecs={this.props.courseSpecs} currentCourse={this.props.currentCourse}
                certificationProcess={this.props.certificationProcess} session={this.props.session}
                disableActions={this.props.disableActions}/>
    );
  }

  _renderLocked() {
    return (
      <Locked app={this.props.app} courseSpecs={this.props.courseSpecs}
              certificationProcess={this.props.certificationProcess} session={this.props.session}
              disableActions={this.props.disableActions}/>
    );
  }

  _renderAccessDenied() {
    return (
      <AccessDenied app={this.props.app} courseSpecs={this.props.courseSpecs}
              certificationProcess={this.props.certificationProcess} session={this.props.session}
              disableActions={this.props.disableActions}/>
    );
  }

  _renderRevoked() {
    return (
      <Revoked app={this.props.app} courseSpecs={this.props.courseSpecs} currentCourse={this.props.currentCourse}
               certificationProcess={this.props.certificationProcess} session={this.props.session}
               availableKeys={this.props.availableKeys}
               disableActions={this.props.disableActions}/>
    );
  }

  _renderExpired() {
    return (
      <Expired app={this.props.app} courseSpecs={this.props.courseSpecs} currentCourse={this.props.currentCourse}
               certificationProcess={this.props.certificationProcess} session={this.props.session}
               availableKeys={this.props.availableKeys}
               disableActions={this.props.disableActions}/>
    );
  }

  _renderReadyToSchedule() {
    return (
      <ReadyToSchedule app={this.props.app} courseSpecs={this.props.courseSpecs}
                       certificationProcess={this.props.certificationProcess} session={this.props.session}
                       disableActions={this.props.disableActions}/>
    );
  }

  _renderMissingRequirements() {
    return (
      <MissingRequirements app={this.props.app} courseSpecs={this.props.courseSpecs}
                           certificationProcess={this.props.certificationProcess} session={this.props.session}
                           disableActions={this.props.disableActions}/>
    );
  }

  _renderWaitingResultRelease() {
    return (
      <WaitingResultRelease app={this.props.app} courseSpecs={this.props.courseSpecs}
                            certificationProcess={this.props.certificationProcess} session={this.props.session}
                            disableActions={this.props.disableActions}/>
    );
  }
  _renderInvalidated() {
    return (
      <Invalidated app={this.props.app} courseSpecs={this.props.courseSpecs} currentCourse={this.props.currentCourse}
               certificationProcess={this.props.certificationProcess} session={this.props.session}
               availableKeys={this.props.availableKeys}
               disableActions={this.props.disableActions}/>
    );
  }
}
