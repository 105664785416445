import Globals from '../config/Globals';
import Utils from './Utils';
//
export default class DataUtils {}
DataUtils.processUserCertifications = function(userCerts, tenantCerts) {
  console.log('*-*- tenantcerts', tenantCerts);
  let certs = (tenantCerts || []).sort((certa, certb) => (certb?.ui?.certificationOrder || 0) - (certa?.ui?.certificationOrder || 0));
  console.log('*-*- certs', certs);

  if (userCerts.length > 0) {
    for (let i = 0; i < userCerts.length; i++) {
      const certificationId = userCerts[i].certificationID;
      const correspondingCert = tenantCerts.find(cert => cert.id === certificationId);
      if (correspondingCert) {
        userCerts[i].ui = {
          ...userCerts[i].ui,
          certificationOrder: correspondingCert.ui.certificationOrder,
          hideFromUI: correspondingCert.ui.hideFromUI
        };
      }
      certs = certs.filter(cert => cert.id !== certificationId);
      certs.push(userCerts[i]);
    }
  }

  // let DOWN = ()=> { return (Utils.isFirefox() ? -1 : 1); };
  // let UP = () => { return (Utils.isFirefox() ? 1 : -1); };
  // let STAY = ()=> { return 0; };

  // certs.sort( (a,b) => {
  //   if (a.state != Globals.CertificationProcess_State.COMPLETED &&
  //       a.state != Globals.CertificationProcess_State.FAILED &&
  //       a.state != Globals.CertificationProcess_State.CANCELLED) {
  //     if (!b.state) return UP(); //no proc on B, go UP()
  //     if (a.state == b.state) return (a.updatedOn - b.updatedOn ? DOWN() : UP());
  //     return UP();
  //   } else if (a.state == Globals.CertificationProcess_State.COMPLETED) {
  //     if (!b.state) return UP();
  //     if (a.state == b.state) return (a.updatedOn < b.updatedOn ? DOWN() : UP());
  //     if (b.state == Globals.CertificationProcess_State.FAILED || b.state == Globals.CertificationProcess_State.CANCELLED) return UP();
  //     return DOWN();
  //   } else if (a.state == Globals.CertificationProcess_State.FAILED) {
  //     if (!b.state) return UP();
  //     if (a.state == b.state) return (a.updatedOn < b.updatedOn ? DOWN() : UP());
  //     if (b.state == Globals.CertificationProcess_State.CANCELLED) return UP();
  //     return DOWN();
  //   } else if (a.state == Globals.CertificationProcess_State.CANCELLED) {
  //     if (!b.state) return UP();
  //     if (a.state == b.state) return (a.updatedOn < b.updatedOn ? DOWN() : UP());
  //     return DOWN();
  //   }  else {
  //     if (a.state && b.state) return (a.updatedOn < b.updatedOn ? DOWN() : UP());
  //     else if (!a.state) return UP();
  //     else if (!b.state) return DOWN();
  //   } return STAY();
  // });
  //
  certs.sort((a, b) => {
    const certificationOrderA = a?.ui?.certificationOrder;
    const certificationOrderB = b?.ui?.certificationOrder;
    if (certificationOrderA === certificationOrderB) {
      const nameA = a.title?.toLowerCase() || '';
      const nameB = b.title?.toLowerCase() || '';
      return nameA.localeCompare(nameB);
    }
    if (typeof certificationOrderA === 'undefined') {
      return 1;
    }
    if (typeof certificationOrderB === 'undefined') {
      return -1;
    }
    return certificationOrderA - certificationOrderB;
  });

  return certs;
};
