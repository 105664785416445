import React from "react";
import autoBind from 'react-autobind';
import { PageHeader, Layout, Table } from 'antd';
import moment from 'moment';
//
import Utils from '../../components/Utils';
import Globals from '../../config/Globals';
//
import CommonLoadingView from '../commonComponents/CommonLoadingView';
import CustomComponent from '../../components/CustomComponent';
//
export default class CommonCoursesView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      courses: [],
      isLoading: false,
      user: null,
      sortedInfo: { columnKey: 'title', order: 'ascend' }
    };
  }

  async componentDidMount() {
    await this._loadData();
  }

  //Actions
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }

  //UI
  render() {
    const { user } = this.state;
    const title = user ? `${user.firstName} ${user.lastName}` : "";
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        <PageHeader className="pageHeader" title={title} onBack={() => this.props.app.urlManager.pushBack()} />
        <Layout.Content>
          {this. _renderTable()}
        </Layout.Content>
      </Layout.Content>
    );
  }

  //Private UI
  _renderTable() {
    const props = {
      rowKey: 'id', loading: this.state.isLoading, onChange: this.handleFilterChange, locale: { emptyText: 'No courses found!' },
      pagination: {pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter']},
    };

    return (
      <>
        <Table className="adminSearchUsersTable" onRow={this._onRow} columns={this._getTableColumns()} dataSource={this.state.courses}
          scroll={{ x: true }} sticky {...props}/>
      </>
    );
  }

  _getTableColumns() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    return [
      {
        title: 'Certification', key: 'title', width: '10%',
        sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order,
        render: (text, record) => {
          const cert = this.props.app.sharedCache().getCourseByID(record.courseID);
          const certification = this.props.app.sharedCache().getCertificationByID(cert.certificationID);
          return certification ? certification.title : '';
        },
        sorter: (a, b) => {
          const certA = this.props.app.sharedCache().getCourseByID(a.courseID);
          const certB = this.props.app.sharedCache().getCourseByID(b.courseID);
          const certificationA = this.props.app.sharedCache().getCertificationByID(certA.certificationID);
          const certificationB = this.props.app.sharedCache().getCertificationByID(certB.certificationID);
          return certificationA.title.localeCompare(certificationB.title);
        }
      },
      {
        title: 'Course', key: 'courseID', width: '15%',
        sorter: (a, b) => (a.courseID || '').localeCompare(b.courseID || ''),
        sortOrder: sortedInfo.columnKey === 'courseID' && sortedInfo.order,
        render: (text, record) => this.props.app.sharedCache().getCourseByID(record.courseID).displayName || '',
      },
      {
        title: 'Result Date', key: 'resultDate', width: '10%',
        render: (text, record) => record.courseResult?.resultDate ? Utils.getDateOnUIFormatByTimestamp(record.courseResult?.resultDate) : '-',
        sorter: (a, b) => (a.record.courseResult?.resultDate || 0) - (b.record.courseResult?.resultDate || 0),
        sortOrder: sortedInfo.columnKey === 'resultDate' && sortedInfo.order
      },
      {
        title: 'Status',  key: 'state', width: '10%',
        render: (text, record) => Utils.capitalizeString(record.state.replace(/_/g, ' ').toLowerCase()),
        sortOrder: sortedInfo.columnKey === 'state' && sortedInfo.order,
        sorter: (a, b) => (a.state || '').localeCompare(b.state || ''),
      },
      {
        title: 'Grade',  key: 'resultGrade', width: '10%',
        render: (text, record) => parseInt(record.courseResult?.resultGrade || record.courseResultV1?.grade || 0),
        sortOrder: sortedInfo.columnKey === 'resultGrade' && sortedInfo.order,
        sorter: (a, b) => (parseInt(a.courseResult?.resultGrade || a.courseResultV1?.grade || 0) || 0) -
                            (parseInt(b.courseResult?.resultGrade || b.courseResultV1?.grade || 0) || 0),
      },
      { title: 'Expiry Date', key: 'expiryDate', width: '10%',
        render: expiryDate => expiryDate ? Utils.getDateOnUIFormatByTimestamp(expiryDate) : '-',
        sortOrder: sortedInfo.columnKey === 'expiryDate' && sortedInfo.order,
        sorter: (a, b) => a.expiryDate - b.expiryDate,
      },
    ]
  }

  /* private */
  async _loadData() {
    this.startLoading();
    await this.getUser();
    await this.getCourses();
    this.stopLoading();
  }

  //API call
 async getUser() {
   const id = this.props.match.params.id;
   const resp = await this.props.app.api.user.getByID(id);
   this.setState({ user: resp.body });
 }

 async getCourses() {
  const { user } = this.state;
  const resp = await this.props.app.api.certification.getExpandedByUserID(user?.id)
  if (resp.statusCode == 200 && resp.body) {
    this.setState({ courses: resp.body.procs.courses })
  } else {
    this.props.app.alertController.showAPIErrorAlert(null, resp);
  }
 }
}
