import React from 'react';
import { Row, Col } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
//
import CourseCardActions from '../Subcomponents/CourseCardActions';
//
export default class Revoked extends React.Component {
  render() {
    const { courseSpecs, app, certificationProcess, session } = this.props;
    const isWaived = !!certificationProcess.waivedBy;
    return (
      <>
        <Row justify="center">
          <Col span={24} style={{ textAlign: 'center' }}>
            {`Your ${courseSpecs.description} license was revoked.`}
          </Col>
        </Row>
        <CourseCardActions
          app={app}
          courseSpecs={courseSpecs}
          certificationProcess={certificationProcess}
          availableKeys={this.props.availableKeys}
          session={session}
          actionPurchase
          actionRedeem
          actionActivate
          actionUpload
          disableActions={this.props.disableActions}
          message={isWaived ?
            `To take the ${this.props.courseSpecs.description} you need to activate this ${this.props.courseSpecs.uiTerm}. Please click continue the activation.` :
            `To take the ${this.props.courseSpecs.description} please click the buttons below to make a purchase or enter a license key.`
          }/>
      </>
    );
  }
}
Revoked.CardStatusHeader = function() {
  return <span className="danger"><CloseCircleFilled /> License revoked</span>;
}
