import React from 'react';
import { Button, Result } from 'antd';

export default class ApplicationWaitingSubComponent extends React.Component {
  render() {
    return (
      <Result
        status="info"
        title="Your application is being reviewed, please come back later and check again."
        subTitle="Once your application is processed, you will receive an email notification indicating whether it was approved."
        extra={[
          <Button key='downloadapplication' type="primary" onClick={this.props.onDownloadApplication}>
            Download Application
          </Button>
        ]}
      />
    );
  }
}
