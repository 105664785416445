import React from 'react';

import config from '../../config/config';

export default function Logo({ app, isCenter, isLeft, isFooter, isLogin, authorized }) {
  let className = 'headingLogo';
  if (authorized) className += ' authorized';
  const { imagesSizes } = app.themeManager.theme.config;

  if (isCenter) {
    let sizes = {};
    if (imagesSizes?.logoCenter?.width) sizes.width = imagesSizes?.logoCenter?.width;
    if (imagesSizes?.logoCenter?.height) sizes.height = imagesSizes?.logoCenter?.height;

    return (
      <a href={config.ApplicationRoutes.login}>
        <img className={className} height="60" alt="logo" src={app.themeManager.theme.getLogo({ type: 'center' })} {...sizes} />
      </a>
    );
  }

  if (isLeft) {
    let sizes = {};
    if (imagesSizes?.logoLeft?.width) sizes.width = imagesSizes?.logoLeft?.width;
    if (imagesSizes?.logoLeft?.height) sizes.height = imagesSizes?.logoLeft?.height;

    return (
      <img alt="logo" src={app.themeManager.theme.getLogo({ type: 'left' })} height="35" {...sizes} />
    );
  }

  if (isFooter) {
    let sizes = {};
    if (imagesSizes?.logoFooter?.width) sizes.width = imagesSizes?.logoFooter?.width;
    if (imagesSizes?.logoFooter?.height) sizes.height = imagesSizes?.logoFooter?.height;

    return (
      <img className="appFooterLogo" alt="logo" src={app.themeManager.theme.getLogo({ type: 'footer' })} {...sizes} />
    );
  }

  if (isLogin) {
    let sizes = {};
    if (imagesSizes?.logoLogin?.width) sizes.width = imagesSizes?.logoLogin?.width;
    if (imagesSizes?.logoLogin?.height) sizes.height = imagesSizes?.logoLogin?.height;

    return <img alt="logo" src={app.themeManager.theme.getLogo({ type: 'center' })} width="100" style={{ marginBottom: 32 }} {...sizes} />;
  }

  return null;
}
