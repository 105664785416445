export default {
  applicationName: 'SiteReadyMB',
  organizationName: 'SiteReadyMB',
  showLeftLogoOnSignIn: true,
  websiteLabel: 'constructionsafety.ca',
  websiteLink: 'https://constructionsafety.ca/',
  imagesSizes: {
    logoLeft: {
      height: 55,
    },
  },
};
