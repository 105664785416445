import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Table, Tooltip, Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
//
import Globals from '../../config/Globals';
//props are: contacts, onDelete, isLoading
export default class CommonOrgContactsTable extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: null };
  }

  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Name', key: 'name', width: '35%',
        sorter: (a, b) => (`${a.firstName} ${a.lastName}`).localeCompare(`${b.firstName} ${b.lastName}`),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        render: (props) => `${props.firstName} ${props.lastName}`
      },
      {
        title: 'Email', key: 'email', width: '35%',
        sorter: (a, b) => (a.email || '').localeCompare(b.email || ''),
        sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order,
        render: (props) => props.email || '',
      },
      {
        title: 'Phone', key: 'phone', width: '20%',
        render: (props) => props.phone?.[0]?.label ? `${props.phone?.[0]?.label || ''} - ${props.phone?.[0]?.number || ''}` : '-', sorter: false
      },
      {
        title: 'Actions', width: '10%', key: 'Actions',
         render: props => {
          return (
            <span className='tableButtonContainer'>
              <Tooltip placement="bottomLeft" title="Delete">
                <Popconfirm placement="top" title={`Are you sure you want to remove the ${props?.description} contact from this organization?`}
                  onConfirm={() => this.props.onDelete(props)} okText="Yes" cancelText="No">
                  <Button variant="none" icon={<DeleteOutlined />} shape="circle" style={{marginLeft: 6}}/>
                </Popconfirm>
              </Tooltip>
            </span>
          );
        }
      }
    ];
    const props = {
      rowKey: 'id', loading: this.props.isLoading, onChange: this.handleFilterChange, locale: { emptyText: 'No contacts found!' },
      pagination: {pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter']},
    };
    return (<Table className="adminOrgContactsTable" columns={columns} dataSource={this.props.contacts} {...props}/>);
  }
}
