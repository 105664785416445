import React from 'react';
import autoBind from 'react-autobind';
import { Row, Col, Card, Button } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
//
import CustomComponent from '../../../components/CustomComponent';
//props are: app, session, student, selectedStudent
export default class CommonStudentReplaceConfirmation extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  //Life cycle
  componentDidMount() {
    super.componentDidMount();
  }

  //UI
  render() {
    return (
      <>
        {/* Search bar */}
        <Row>
          <Col span={24}>
            <h2 style={{ marginLeft: 10 }}>Confirm Replacement into {this.props.session.name}</h2>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Card title="Enrolled Student" bordered={false}>
              {this.props.student.firstName + ' ' + this.props.student.lastName}<br/>
              {this.props.student.email}
            </Card>
          </Col>
          <Col><div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 50 }}><SwapOutlined/></div></Col>
          <Col span={8}>
            <Card title="Target Student" bordered={false}>
              {this.props.selectedStudent.firstName + ' ' + this.props.selectedStudent.lastName}<br/>
              {this.props.selectedStudent.email}
            </Card>
          </Col>
        </Row>
      </>
    );
  }

}
