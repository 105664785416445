//Polyfills
import "core-js/stable";
import 'core-js/modules/es.promise';
import 'core-js/modules/es.string.includes';
import 'core-js/modules/es.object.assign';
import 'core-js/modules/es.object.keys';
import 'core-js/modules/es.symbol';
import 'core-js/modules/es.symbol.async-iterator';
import "regenerator-runtime/runtime";
//Axios
import * as es6Promise from 'es6-promise';
import 'isomorphic-fetch';
//Excel
const rewritePattern = require('regexpu-core');
const { generateRegexpuOptions } = require('@babel/helper-create-regexp-features-plugin/lib/util');
const { RegExp } = global;
try {
  new RegExp('a', 'u');
} catch (err) {
  global.RegExp = function (pattern, flags) {
    if (flags && flags.includes('u')) {
      return new RegExp(rewritePattern(pattern, flags, generateRegexpuOptions({ flags, pattern })));
    }
    return new RegExp(pattern, flags);
  };
  global.RegExp.prototype = RegExp;
}
//Axios
es6Promise.polyfill(); // below all import end