import React from 'react';
import autoBind from 'react-autobind';
import { Row, Divider, Button } from 'antd';
//
//props are: onCancel, courseSpecs
export default class CommonLicensePurchaseModalStep_ResultView extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //UI
  render() {
    return (
      <>
        <div className={"ant-result ant-result-error"}>
          <div className="ant-result-icon"><span role="img" aria-label="close-circle" className="anticon anticon-close-circle"><svg viewBox="64 64 896 896" focusable="false" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path></svg></span></div>
          <h1 style={{ fontSize: 18, textAlign: 'center' }}>
            No Sessions available!
          </h1>
          <div className="ant-result-subtitle">Opss.. No sessions are available for {this.props.courseSpecs?.description} within a reasonable time frame. <br></br> Please, contact the administrator if you believe this is an error.</div>
        </div>
        {this._renderButtonsFooter()}
      </>
    );
  }
  _renderButtonsFooter() {
    return (
      <Row type="flex" justify="end">
        <Divider />
        <Button form="summary" htmlType="submit" key="submit" type="primary" onClick={this.props.onCancel} > Cancel </Button>
      </Row>
    );
  }
}
