import React from 'react';
import { withRouter } from 'react-router-dom';
//
import UnauthorizedViewController from './UnauthorizedViewController';
import AuthorizedViewController from './AuthorizedViewController';
import Authenticator from './components/Authenticator';
//
class App extends Authenticator {
  renderAuthorizedView() {
    return ( <AuthorizedViewController app={this} history={this.props.history}/> );
  }

  renderUnauthorizedView() {
    return ( <UnauthorizedViewController app={this} history={this.props.history}/> );
  }
}
export default withRouter(App);
