import React from 'react';
import autoBind from 'react-autobind';
import ReactMarkdown from 'react-markdown';

import Globals from '../../../../config/Globals';
import Utils from '../../../../components/Utils';
import CustomComponent from '../../../../components/CustomComponent';
import { Form, Checkbox, Collapse, Row, Col } from 'antd';

import '../../../../assets/stylesheets/CommonRegistration.css';

const { Panel } = Collapse;

class UserAgreementStep extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isFormValidAgreement: false,
      // isRead: false,
    };
    // this.handleScroll = this.handleScroll.bind(this);
    this.minMaxHeight = 200;
  }
  componentDidMount() {
    const resp = this.props.app.sharedCache().getTenantConfig();
    console.log('Tenant config', resp);
    if (!(resp && resp.organizationRegText)) this.props.app.alertController.showErrorAlert('Error!', 'Unable to load Code of Ethics.');
  };

  // handleScroll(event) {
  //   const totalScroll = event.target.scrollHeight;
  //   const scrolled = Number(event.target.scrollTop) + Number(event.target.offsetHeight);
  //   if (scrolled >= totalScroll) {
  //     this.setState({ isRead: true });
  //   }
  // }

  handleSubmit = async () => {
    let resp = '';
    try { resp = await this.form.validateFields(); }
    catch (err) {
      resp = err;
      console.error(err);
    } return resp;
  };

  handleValidation = async event => {
    if (event.target.checked) {
      this.props.onChange({ isFormValidAgreement: true });
    } else {
      this.props.onChange({ isFormValidAgreement: false });
    }
  };

  render() {
    const tenant = this.props.app.sharedCache().getTenantConfig();
    console.log(tenant)
    return (
      <>
        <Collapse bordered={false} style={{ marginTop: 15, marginBottom: 15 }} activeKey="1">
          <Panel key="1" showArrow={false}>
            <div
              id="scroll"
              style={{ minHeight: this.minMaxHeight, maxHeight: this.minMaxHeight, minWidth: '100%', maxWidth: 628, overflow: 'auto' }}
              // onScroll={this.handleScroll}
            >
              <div id="agreementContainer"
                // eslint-disable-next-line no-return-assign
                ref={agreementContainer => (this.agreementContainer = agreementContainer)}
                style={{ height: 'auto', padding: '0 30px' }}>
                <span style={{ whiteSpace: 'pre-line', lineHeight: 1.6, textAlign: 'justify', fontSize: 11 }}>
                  {tenant.organizationRegText.split('\t').map((item, key) => {
                    return (
                      <span key={key} style={{ marginLeft: 25 }}>
                        <ReactMarkdown children={item} linkTarget='_blank'/>
                      </span>
                    );
                  })}
                </span>
              </div>
            </div>
          </Panel>
        </Collapse>
        <>
          <Form id="agreementItemForm" {...Utils.propagateRef(this, 'form')}>
            <Form.Item name="agreedOnTerms" initialValue={false} valuePropName="checked" rules={[{ required: true, transform: value => value || undefined, type: 'boolean', message: 'Please agree the terms and conditions.' }]}>
              <Row>
                <Col>
                  <Checkbox onChange={this.handleValidation}>
                    {Utils.replacePlaceholders(tenant.organizationRegCheckboxText || Globals.LABEL_AGREED_ON_TERMS, this.props.app.themeManager.theme)}
                  </Checkbox>
                </Col>
                {!tenant.organizationRegCheckboxText && <Col>
                  <span style={{ color: '#af3947', fontSize: 12 }}>
                    * Please note, you must read the privacy policy in full before you can agree
                  </span>
                </Col>}
              </Row>
            </Form.Item>
          </Form>
        </>
      </>
    );
  }
}

export default UserAgreementStep;
